/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCaseCloseApprove: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M798.45376 158.98624c32.48128 0 58.83904 26.5216 58.92096 59.31008V923.648c-0.06144 32.80896-26.4192 59.392-58.92096 59.41248H99.88096C67.44064 982.9376 41.1648 956.49792 40.96 923.77088v-705.3312c0.02048-32.80896 26.37824-59.43296 58.90048-59.45344h698.59328zM891.24864 40.96c50.66752 0.08192 91.70944 41.53344 91.79136 92.672v755.07712a34.304 34.304 0 0 1-34.03776 34.36544h-0.04096a34.2016 34.2016 0 0 1-34.03776-34.36544V133.81632a24.02304 24.02304 0 0 0-23.81824-24.08448H143.27808a34.26304 34.26304 0 0 1-32.768-34.38592c0-18.49344 14.45888-33.66912 32.768-34.38592h747.97056zM616.98048 778.24H284.13952c-9.89184 0-17.89952 9.17504-17.89952 20.48s8.00768 20.48 17.89952 20.48h332.84096c9.89184 0 17.89952-9.17504 17.89952-20.48s-8.00768-20.48-17.89952-20.48z m-164.2496-450.56c-59.33056 0-107.6224 46.98112-107.6224 104.73472 0 37.08928 20.2752 71.2704 52.57216 90.0096l-10.48576 82.5344h-87.79776c-18.26816 0-33.15712 14.49984-33.15712 32.33792v67.62496c0 17.83808 14.88896 32.3584 33.1776 32.3584h302.2848c18.28864 0 33.1776-14.52032 33.1776-32.3584v-67.62496c0-17.83808-14.88896-32.3584-33.1776-32.3584h-83.61984l-10.4448-82.39104c32.39936-18.71872 52.75648-52.9408 52.75648-90.112 0-57.7536-48.29184-104.7552-107.64288-104.7552z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCaseCloseApprove.defaultProps = {
  size: 18,
};

export default IconABusinessCaseCloseApprove;
