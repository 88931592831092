/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const A24Filled3: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M533.333333 117.333333a32 32 0 0 0-64 0V128H224A138.666667 138.666667 0 0 0 85.333333 266.666667v405.333333A138.666667 138.666667 0 0 0 224 810.666667h102.314667l-62.933334 75.52a32 32 0 0 0 49.194667 40.96L409.6 810.666667h149.674667c14.250667-49.322667 59.733333-85.333333 113.664-85.333334h11.818666A149.333333 149.333333 0 1 1 938.666667 618.666667V266.666667A138.666667 138.666667 0 0 0 800 128H533.333333v-10.666667z m-277.333333 213.333334A32 32 0 0 1 288 298.666667h170.666667a32 32 0 0 1 0 64h-170.666667A32 32 0 0 1 256 330.666667zM288 426.666667h277.333333a32 32 0 0 1 0 64h-277.333333a32 32 0 0 1 0-64z m0 128h234.666667a32 32 0 0 1 0 64h-234.666667a32 32 0 0 1 0-64zM896 618.666667a106.666667 106.666667 0 1 1-213.333333 0 106.666667 106.666667 0 0 1 213.333333 0z m85.333333 229.333333C981.333333 914.389333 926.464 981.333333 789.333333 981.333333S597.333333 914.645333 597.333333 848v-4.394667c0-41.813333 33.877333-75.605333 75.648-75.605333h232.704c41.813333 0 75.648 33.834667 75.648 75.605333v4.394667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default A24Filled3;
