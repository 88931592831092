/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconUnknownType: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M135.68 0c-12.8 0-26.624 5.12-35.84 15.36-10.24 9.216-15.36 23.04-15.36 35.84v919.04c0 12.8 5.12 26.624 15.36 35.84 10.24 10.24 23.04 15.36 35.84 15.36h749.056c12.8 0 26.624-5.12 35.84-15.36 10.24-10.24 15.36-23.04 15.36-35.84v-680.96L646.144 0H135.68z"
        fill={getIconColor(color, 0, '#576A95')}
      />

      <path
        d="M514.56 348.672c35.328 0 64.512 9.216 86.016 28.672 22.016 18.944 32.768 45.056 32.768 78.336 0 27.136-7.168 49.664-19.968 67.072-5.12 5.632-20.48 19.968-46.592 43.008-9.728 7.68-16.896 16.896-22.016 26.624-5.632 10.752-8.192 23.04-8.192 35.84v7.68H474.112v-7.68c0-20.48 3.072-38.912 10.752-53.248 7.168-14.848 28.16-37.376 63.488-69.12l6.656-7.68c9.728-11.776 14.848-25.088 14.848-38.912 0-17.92-5.632-32.256-15.36-42.496-10.24-10.24-25.088-15.36-43.52-15.36-23.552 0-40.448 7.168-51.2 22.016-9.216 12.288-13.824 30.72-13.824 53.76H384c0-40.448 11.264-71.68 35.328-94.72 23.552-22.528 55.296-33.792 95.232-33.792z m-9.728 314.368c11.776 0 22.528 3.584 30.72 11.264 7.68 7.68 11.776 17.408 11.776 29.184s-4.608 22.528-11.776 30.208c-8.704 7.68-18.432 11.264-30.72 11.264s-22.016-4.608-29.184-11.776c-8.704-7.68-12.288-17.408-12.288-29.184s3.584-22.016 12.288-29.184c7.68-8.192 17.408-11.776 29.184-11.776z"
        fill={getIconColor(color, 1, '#ffffff')}
      />

      <path
        d="M935.424 289.28h-238.08c-12.8 0-26.624-5.12-35.84-15.36-10.24-9.216-15.36-23.04-15.36-35.84V0l289.28 289.28z"
        fill={getIconColor(color, 2, '#9AA6BF')}
      />
    </svg>
  );
};

IconUnknownType.defaultProps = {
  size: 18,
};

export default IconUnknownType;
