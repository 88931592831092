/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconYewushichang: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M657.493333 3.925333a37.973333 37.973333 0 0 1 18.304 4.821334 37.632 37.632 0 0 1 3.285334 2.133333l0.810666 0.597333a36.309333 36.309333 0 0 1 2.816 2.389334l0.426667 0.426666 242.474667 236.373334 0.469333 0.426666c0.682667 0.682667 1.28 1.408 1.92 2.133334l-2.389333-2.56a35.968 35.968 0 0 1 6.144 7.978666 34.602667 34.602667 0 0 1 4.138666 12.245334l-0.853333-4.181334c0.426667 1.450667 0.725333 2.986667 0.896 4.437334l0.298667 4.565333v661.632c0 45.653333-37.973333 82.730667-84.821334 82.730667H172.586667c-46.848 0-84.821333-37.034667-84.821334-82.730667V86.656c0-45.653333 37.973333-82.730667 84.821334-82.730667z m-36.394666 70.912H172.586667a11.946667 11.946667 0 0 0-12.117334 11.818667v850.688a11.946667 11.946667 0 0 0 12.117334 11.818667h678.826666a11.946667 11.946667 0 0 0 12.117334-11.818667V311.125333h-206.08a36.096 36.096 0 0 1-36.010667-30.634666l-0.341333-4.778667V74.837333zM512 382.037333c127.189333 0 230.314667 100.522667 230.314667 224.469334 0 123.989333-103.125333 224.512-230.314667 224.512s-230.314667-100.522667-230.314667-224.512c0-123.946667 103.125333-224.469333 230.314667-224.469334z m0 70.869334c-87.04 0-157.568 68.778667-157.568 153.6 0 84.864 70.528 153.6 157.568 153.6s157.568-68.736 157.568-153.6c0-84.821333-70.528-153.6-157.568-153.6z m-24.234667 47.274666c18.389333 0 33.621333 13.354667 36.010667 30.634667l0.341333 4.821333v59.050667h60.586667c18.432 0 33.664 13.354667 36.053333 30.677333l0.341334 4.778667c0 17.92-13.653333 32.768-31.445334 35.114667l-4.906666 0.341333h-96.981334a36.096 36.096 0 0 1-36.053333-30.634667l-0.298667-4.821333v-94.506667c0-19.584 16.256-35.413333 36.352-35.413333z m206.037334-375.253333v115.328h118.272L693.76 124.885333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconYewushichang.defaultProps = {
  size: 18,
};

export default IconYewushichang;
