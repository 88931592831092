/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const BookToolbox24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M597.333333 170.666667h-21.333333A64 64 0 0 0 512 234.666667V298.666667h106.666667v-10.666667a32 32 0 0 1 64 0V298.666667h128v-10.666667a32 32 0 0 1 64 0V298.666667H981.333333V234.666667A64 64 0 0 0 917.333333 170.666667H896V128a74.666667 74.666667 0 0 0-74.666667-74.666667h-149.333333A74.666667 74.666667 0 0 0 597.333333 128v42.666667z m64-42.666667a10.666667 10.666667 0 0 1 10.666667-10.666667h149.333333a10.666667 10.666667 0 0 1 10.666667 10.666667v42.666667h-170.666667V128zM512 448v-85.333333h106.666667v32a32 32 0 0 0 64 0V362.666667h128v32a32 32 0 0 0 64 0V362.666667H981.333333v85.333333a64 64 0 0 1-64 64h-341.333333a64 64 0 0 1-64-64z m42.666667-317.866667a106.666667 106.666667 0 0 0-85.333334 104.533334v213.333333a106.666667 106.666667 0 0 0 106.666667 106.666667h298.666667v245.333333a32 32 0 0 1-32 32H234.666667a42.666667 42.666667 0 0 0 42.666666 42.666667h565.333334a32 32 0 0 1 0 64H277.333333A106.666667 106.666667 0 0 1 170.666667 832v-640A106.666667 106.666667 0 0 1 277.333333 85.333333h285.354667c-5.205333 13.226667-8.021333 27.605333-8.021333 42.666667v2.133333z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default BookToolbox24Filled;
