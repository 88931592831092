/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconKehu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M593.92 630.329s-34.133-9.102-43.236-13.653c-18.204-11.378-29.582-34.134-29.582-56.89 0-25.03 20.48-45.51 34.134-61.44 34.133-40.96 52.337-95.573 52.337-157.013 0-127.43-91.022-229.83-202.524-229.83S202.524 216.177 202.524 343.608c0 61.44 20.48 116.053 52.338 157.013 20.48 11.378 34.134 36.41 34.134 61.44 0 22.756-11.378 43.236-29.583 56.89v2.275c-2.275 0-4.55 2.275-4.55 2.275-11.379 6.827-22.756 11.378-36.41 11.378-86.47 29.582-159.289 75.093-202.524 131.982h2.275C6.827 782.792 2.276 800.996 2.276 819.2c0 54.613 45.51 100.124 102.4 100.124h600.746c59.165 0 104.676-43.235 104.676-100.124 0-22.756-9.102-43.236-20.48-61.44-43.236-56.889-111.502-100.124-195.698-127.431z m407.324 100.124c-38.684-47.786-97.848-88.746-172.942-111.502 0 0-29.582-6.827-38.684-11.378-15.93-11.377-25.031-29.582-25.031-50.062 0-22.755 18.204-40.96 29.582-52.338 29.582-36.409 47.787-84.195 47.787-138.809 0-111.502-79.645-204.8-179.77-204.8-15.928 0-31.857 2.276-45.51 6.827 34.133 50.062 52.337 109.227 52.337 175.218 0 70.542-25.03 138.809-68.266 195.698l-4.551 4.55v2.276c-4.552 4.551-11.378 11.378-13.654 18.205 0 2.275 0 2.275 2.276 2.275 4.55 2.276 11.378 4.551 18.204 4.551h2.276l9.102 4.552c93.298 29.582 172.942 81.92 225.28 147.91 22.756 29.583 36.409 63.716 36.409 100.125 0 20.48-4.551 36.409-11.378 54.613h65.991c52.338 0 93.298-38.684 93.298-88.746-2.276-25.031-9.102-43.236-22.756-59.165z m0 0"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconKehu.defaultProps = {
  size: 18,
};

export default IconKehu;
