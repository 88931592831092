/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZhanghuguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M632.149333 480.548571c51.2 0 92.94019 12.141714 132.632381 38.424381l12.970667 9.167238 2.486857 1.852953a8.923429 8.923429 0 0 1-4.583619 15.993905l-2.486857-0.146286c-13.799619-2.730667-14.531048-2.535619-29.110857-2.535619a217.185524 217.185524 0 0 0-95.329524 412.42819H224.402286a179.297524 179.297524 0 0 1-169.545143-237.616762l22.235428-64.755809a256.048762 256.048762 0 0 1 242.151619-172.812191h312.905143z m174.470096 104.350477c17.212952 4.388571 33.401905 11.166476 48.274285 19.992381a48.810667 48.810667 0 0 0 64.463238 64.463238c8.777143 14.872381 15.60381 31.061333 19.992381 48.274285a48.761905 48.761905 0 0 0 0 91.087238c-4.388571 17.16419-11.215238 33.353143-20.041143 48.274286a48.761905 48.761905 0 0 0-64.365714 64.463238c-14.921143 8.777143-31.110095 15.555048-48.274286 19.943619a48.761905 48.761905 0 0 0-45.59238-31.451428c-20.772571 0-38.473143 13.06819-45.54362 31.451428a183.588571 183.588571 0 0 1-48.274285-19.992381 48.810667 48.810667 0 0 0-64.414476-64.463238 182.808381 182.808381 0 0 1-19.992381-48.274285 48.761905 48.761905 0 0 0 31.451428-45.494858c0-20.821333-13.116952-38.521905-31.451428-45.592381 4.388571-17.16419 11.166476-33.353143 19.992381-48.274285a48.761905 48.761905 0 0 0 64.365714-64.463238c14.969905-8.777143 31.158857-15.555048 48.323047-19.894857a48.761905 48.761905 0 0 0 45.54362 31.402666c20.821333 0 38.521905-13.06819 45.543619-31.402666z m-42.569143 104.740571a76.507429 76.507429 0 1 0 0.048762 153.014857 76.507429 76.507429 0 0 0 0-153.014857zM470.552381 78.019048a178.224762 178.224762 0 1 1 0 356.449523 178.224762 178.224762 0 0 1 0-356.449523z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconZhanghuguanli.defaultProps = {
  size: 18,
};

export default IconZhanghuguanli;
