/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFeiyongguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M127.982754 435.279074a25.596551 25.596551 0 0 0 25.596551-25.596551 358.351712 358.351712 0 1 1 656.807495 198.373269 25.596551 25.596551 0 1 0 42.490275 28.412172A409.544814 409.544814 0 1 0 102.386203 409.682523a25.596551 25.596551 0 0 0 25.596551 25.596551z"
        fill={getIconColor(color, 0, '#020202')}
      />

      <path
        d="M637.354117 194.671496L511.931017 320.094595l-125.423099-125.423099-36.091137 36.347102 101.106376 101.106376H358.351712v51.193102h127.982754v67.83086h-128.238719v51.193101H486.334466v188.134649h51.193102v-188.134649h127.726789v-51.193101H537.527568v-67.83086h127.982754v-51.193102h-93.171445l101.106376-101.106376L637.354117 194.671496z"
        fill={getIconColor(color, 1, '#020202')}
      />

      <path
        d="M996.473725 793.630786l-5.631241-2.303689-307.15861-130.286444a153.579305 153.579305 0 0 0-58.360136-12.798276c-51.193102 0-88.820032 35.067275-47.097654 119.023962l55.800481 76.789652a7.167034 7.167034 0 0 1-6.655103 11.006517H567.219567C386.251953 844.823888 248.286543 746.789098 145.90034 623.925654 102.386203 573.244483 72.95017 554.559001 51.193102 554.559001c-55.28855 0-55.544515 119.535893-46.585723 150.507719C40.186585 828.18613 124.143272 1008.641814 444.100158 1023.999744H972.668933a51.193102 51.193102 0 0 0 51.193102-47.097654v-141.036995A46.585723 46.585723 0 0 0 996.473725 793.630786zM972.668933 972.806642H446.403847C166.633546 959.496436 89.587928 815.131889 53.496791 691.244583a196.06958 196.06958 0 0 1 4.351414-80.885101 228.065268 228.065268 0 0 1 48.633447 46.841688C235.744233 812.060303 389.579504 896.01699 563.124119 906.511576h64.247343a59.128032 59.128032 0 0 0 51.193101-31.739724 58.10417 58.10417 0 0 0-3.327551-59.128032L622.252152 742.437685a76.789653 76.789653 0 0 1-10.750552-39.418689 36.603068 36.603068 0 0 1 13.822138-2.047724 102.386203 102.386203 0 0 1 38.394826 8.702827l307.15861 130.286444h2.047724z"
        fill={getIconColor(color, 2, '#020202')}
      />
    </svg>
  );
};

IconFeiyongguanli.defaultProps = {
  size: 18,
};

export default IconFeiyongguanli;
