/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentBranch24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M128.298667 234.666667c0-76.970667 61.909333-141.312 142.976-148.608 81.066667-7.338667 154.794667 44.757333 170.24 120.32 15.488 75.52-32.384 149.802667-110.506667 171.562666 31.957333 98.688 128.384 166.058667 237.568 166.058667h14.933333c17.322667-74.410667 91.221333-124.586667 171.434667-116.352 80.213333 8.192 140.970667 72.106667 141.056 148.352 0.128 76.373333-60.714667 140.501333-141.013333 148.736-80.341333 8.192-154.325333-42.154667-171.52-116.736h-14.890667c-96.896 0.085333-188.501333-41.941333-248.362667-113.92v149.333333c78.762667 16.384 131.84 86.186667 123.178667 161.962667-8.704 75.776-76.373333 133.205333-157.013333 133.290667-80.853333 0.128-148.736-57.344-157.44-133.248-8.704-75.904 44.586667-145.792 123.52-162.005334V380.586667c-72.533333-15.061333-124.16-75.776-124.16-145.92z m158.037333-85.333334c-49.877333 0-90.325333 38.186667-90.325333 85.333334s40.448 85.333333 90.325333 85.333333c49.92 0 90.325333-38.186667 90.325333-85.333333s-40.448-85.333333-90.325333-85.333334z m0 554.666667c-49.877333 0-90.325333 38.186667-90.325333 85.333333s40.448 85.333333 90.325333 85.333334c49.92 0 90.325333-38.186667 90.325333-85.333334s-40.448-85.333333-90.325333-85.333333z m361.301333-128c0 47.146667 40.405333 85.333333 90.282667 85.333333 49.92 0 90.325333-38.186667 90.325333-85.333333s-40.405333-85.333333-90.325333-85.333333c-49.877333 0-90.282667 38.186667-90.282667 85.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentBranch24Regular;
