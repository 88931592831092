/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const FluentStarFilled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M460.288 132.394667c21.12-42.837333 82.176-42.837333 103.296 0l100.608 203.818666 224.981333 32.682667c47.232 6.869333 66.090667 64.938667 31.914667 98.261333l-162.816 158.72 38.442667 224c8.106667 47.061333-41.301333 82.944-83.584 60.757334l-201.216-105.813334-201.173334 105.813334c-42.24 22.186667-91.648-13.653333-83.626666-60.757334l38.442666-224-162.773333-158.72c-34.176-33.28-15.317333-91.392 31.914667-98.261333L359.68 336.213333l100.608-203.818666z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default FluentStarFilled;
