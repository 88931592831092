/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Gongzuojiaojie: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M469.333333 597.333333l-3.584-3.114666a32 32 0 0 0-41.685333 3.114666l-18.730667 18.688V448h213.333334v168.106667l-21.845334-18.773334-3.584-3.114666a32 32 0 0 0-41.685333 48.341333l76.458667 73.386667 3.584 3.114666a32 32 0 0 0 41.642666-3.072l73.386667-73.386666 3.114667-3.626667a32 32 0 0 0-3.114667-41.642667l-3.584-3.114666a32 32 0 0 0-41.642667 3.114666l-18.730666 18.688v-200.106666l-0.298667-4.309334a32 32 0 0 0-31.701333-27.648L649.813333 384h-105.813333V245.333333l-0.298667-4.352A32 32 0 0 0 511.957333 213.333333l-4.309333 0.298667a32 32 0 0 0-27.690667 31.701333V384H373.333333l-4.352 0.298667A32 32 0 0 0 341.333333 415.957333v200.149334L322.56 597.333333l-3.541333-3.114666A32 32 0 0 0 277.333333 642.56l73.344 73.386667 3.584 3.114666a32 32 0 0 0 41.685334-3.072L469.333333 642.56l3.072-3.626667A32 32 0 0 0 469.333333 597.333333z m-384.042666-85.333333c0 235.605333 191.018667 426.666667 426.666666 426.666667s426.666667-191.061333 426.666667-426.666667c0-235.690667-191.018667-426.666667-426.666667-426.666667s-426.666667 190.976-426.666666 426.666667z m789.333333 0a362.666667 362.666667 0 1 1-725.333333 0 362.666667 362.666667 0 0 1 725.333333 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Gongzuojiaojie;
