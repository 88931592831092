/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Noteadd: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M738.947913 33.783057c-132.730969 0-240.322515 107.600489-240.322516 240.322515 0 132.730969 107.596017 240.322515 240.322516 240.322515s240.326987-107.596017 240.326987-240.322515-107.596017-240.322515-240.326987-240.322515z m145.752314 255.172751c0 12.296943-10.061135 22.358079-22.358079 22.358078h-50.855685c-12.296943 0-22.358079 10.061135-22.358079 22.358079v43.008c0 12.296943-10.061135 22.358079-22.358078 22.358079h-41.09862c-12.296943 0-22.358079-10.061135-22.358079-22.358079l-0.004472-43.012472c0-12.296943-10.061135-22.358079-22.358078-22.358078h-40.392105c-12.296943 0-22.358079-10.061135-22.358079-22.358079v-41.112035c0-12.296943 10.061135-22.358079 22.358079-22.358078h40.392105c12.296943 0 22.358079-10.061135 22.358078-22.358079V154.892297c0-12.296943 10.061135-22.358079 22.358079-22.358079h41.103092c12.296943 0 22.358079 10.061135 22.358078 22.358079v48.230847c0 12.296943 10.061135 22.358079 22.358079 22.358079h50.855685c12.296943 0 22.358079 10.061135 22.358079 22.358078v41.116507z"
        fill={getIconColor(color, 0, '#a5b8fc')}
      />

      <path
        d="M819.244716 776.670463h-171.316541c-24.593886 0-44.716157 20.122271-44.716158 44.716157V992.69869"
        fill={getIconColor(color, 1, '#eef3ff')}
      />

      <path
        d="M546.735511 767.047546c0-24.593886 20.122271-44.716157 44.716157-44.716157h183.076891c24.593886 0 44.716157-20.122271 44.716157-44.716158v-72.797903c0-24.593886-19.688524-40.553083-43.750288-35.482271 0 0-8.455825 1.779703-36.148542 1.779703-163.263162 0-295.609572-132.359825-295.609572-295.614044v-20.408454c3.304524-21.539773-14.116891-39.166882-38.710777-39.166882H87.196507c-24.593886 0-44.716157 20.122271-44.716158 44.716157V947.982533c0 24.593886 20.122271 44.716157 44.716158 44.716157h414.822847c24.593886 0 44.716157-20.122271 44.716157-44.716157v-180.934987z"
        fill={getIconColor(color, 2, '#636df1')}
      />
    </svg>
  );
};

export default Noteadd;
