/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconKaifaguanliLinear: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M896 76.8c33.9456 0 66.5088 13.1072 90.5216 36.4288A122.5728 122.5728 0 0 1 1024 201.1392L1023.9744 230.4H1024v76.8h-0.0256L1024 822.8608a122.368 122.368 0 0 1-32.8192 83.1488l-4.6592 4.7616A129.9456 129.9456 0 0 1 896 947.2H128c-70.6816 0-128-55.68-128-124.3392V201.1392C0 132.48 57.3184 76.8 128 76.8z m51.1744 230.4h-870.4L76.8 810.6752c0 31.616 25.5744 57.4976 57.9072 59.5968L139.008 870.4H884.992C919.3728 870.4 947.2 843.648 947.2 810.6752L947.1744 307.2z m-625.3568 150.3488a35.7376 35.7376 0 0 1 0 51.5328l-109.696 110.4128 109.696 110.4128a35.7376 35.7376 0 0 1 0 51.5072 35.9936 35.9936 0 0 1-25.6 11.0592c-9.1392 0-18.2784-3.6864-25.6-11.0592l-135.296-136.1664a35.7376 35.7376 0 0 1 0-51.5328l135.296-136.1664c14.6432-14.72 36.5824-14.72 51.2 0z m243.2-33.1264c20.1216 7.3728 31.104 27.6224 25.6 47.872l-85.9392 294.4a36.736 36.736 0 0 1-34.7392 25.7792c-3.6608 0-7.3216 0-10.9568-1.8432-20.1216-5.5296-31.104-25.7792-25.6-46.0032l85.9392-294.4256c5.4784-20.2496 25.6-31.2832 45.696-25.7792z m188.3648 33.1264l135.296 136.192c14.6432 12.8768 14.6432 36.7872 0 51.5072l-135.296 136.192a35.9936 35.9936 0 0 1-25.6 11.008c-9.1648 0-18.304-3.6608-25.6-11.008a35.7376 35.7376 0 0 1 0-51.5328l109.696-110.4128-109.696-110.4128a35.7376 35.7376 0 0 1 0-51.5328c14.6176-14.72 36.5568-14.72 51.2 0zM884.992 153.6H139.008C104.6272 153.6 76.8 180.352 76.8 213.3248L76.7744 230.4h870.4L947.2 213.3248c0-31.616-25.5744-57.4976-57.9072-59.5968L884.992 153.6z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconKaifaguanliLinear.defaultProps = {
  size: 18,
};

export default IconKaifaguanliLinear;
