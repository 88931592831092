/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconCaigouguanliCaigoushenpi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M546.3 827.8c0-45.4 36.9-82.3 82.3-82.3H657c-22.2-22.5-35.4-53.2-35.4-86.2 0-68.1 55.4-123.6 123.6-123.6 23.1 0 44.6 6.5 63 17.5V145.4c0-25.2-20.5-45.7-45.7-45.7h-601c-25.2 0-45.7 20.5-45.7 45.7v736.8c0 25.2 20.5 45.7 45.7 45.7h398.8c-8.8-13.1-13.9-28.8-13.9-45.7v-54.4zM264.8 319.1h394c15.2 0 27.4 12.3 27.4 27.4 0 15.1-12.3 27.4-27.4 27.4h-394c-15.2 0-27.4-12.3-27.4-27.4 0-15.2 12.2-27.4 27.4-27.4z m0 160.5h394c15.2 0 27.4 12.3 27.4 27.4 0 15.1-12.3 27.4-27.4 27.4h-394c-15.2 0-27.4-12.3-27.4-27.4 0-15.1 12.2-27.4 27.4-27.4z m244.2 206H264.8c-15.2 0-27.4-12.3-27.4-27.4 0-15.1 12.3-27.4 27.4-27.4H509c15.1 0 27.4 12.3 27.4 27.4 0 15.2-12.3 27.4-27.4 27.4z m0 0"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M861.7 782.1h-89.1v-40.6c34.5-11.6 59.6-43.8 59.6-82.2 0-48-39-87-87-87s-87 39-87 87c0 38.3 25.1 70.6 59.6 82.2v40.6h-89.1c-25.2 0-45.7 20.5-45.7 45.7v54.4c0 25.2 20.5 45.7 45.7 45.7h233.1c25.2 0 45.7-20.5 45.7-45.7v-54.4c-0.1-25.2-20.6-45.7-45.8-45.7"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconCaigouguanliCaigoushenpi.defaultProps = {
  size: 18,
};

export default IconCaigouguanliCaigoushenpi;
