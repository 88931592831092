/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFinish: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 906.18945313c-53.17382813 0-104.85351563-10.45898438-153.45703125-31.02539063-46.93359375-19.86328125-89.12109375-48.25195313-125.24414063-84.46289063-36.2109375-36.12304688-64.59960938-78.31054688-84.46289062-125.24414062C128.26953125 616.85351563 117.81054687 565.17382813 117.81054687 512s10.45898438-104.85351563 31.02539063-153.45703125c19.86328125-46.93359375 48.25195313-89.12109375 84.46289063-125.33203125 36.2109375-36.2109375 78.31054688-64.59960938 125.33203124-84.46289063 48.515625-20.47851563 100.1953125-30.9375 153.36914063-30.93749999s104.85351563 10.45898438 153.45703125 31.02539062c46.93359375 19.86328125 89.12109375 48.25195313 125.33203125 84.46289063 36.2109375 36.2109375 64.59960938 78.31054688 84.46289063 125.33203124 20.56640625 48.60351563 31.02539063 100.28320313 31.02539062 153.45703126s-10.45898438 104.85351563-31.02539063 153.45703124c-19.95117188 46.84570313-48.33984375 89.03320313-84.55078124 125.15625001-36.2109375 36.2109375-78.31054688 64.59960938-125.33203125 84.46289062-48.515625 20.56640625-100.1953125 31.02539063-153.36914063 31.02539063z m0-731.25c-185.88867188 0-337.06054688 151.171875-337.06054688 337.06054687s151.171875 337.06054688 337.06054688 337.06054688 337.06054688-151.171875 337.06054688-337.06054688-151.171875-337.06054688-337.06054688-337.06054687z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M512 878.06445313c-49.39453125 0-97.3828125-9.66796875-142.47070313-28.74023438-43.59375-18.45703125-82.70507813-44.82421875-116.36718749-78.48632813-33.66210938-33.66210938-60.02929688-72.7734375-78.48632813-116.36718749-19.07226563-45.08789063-28.74023438-93.07617188-28.74023437-142.47070313 0-49.39453125 9.66796875-97.3828125 28.74023437-142.47070313 18.45703125-43.59375 44.82421875-82.70507813 78.48632813-116.36718749 33.66210938-33.66210938 72.7734375-60.02929688 116.3671875-78.48632813 45.08789063-19.07226563 93.07617188-28.74023438 142.47070312-28.74023437 49.39453125 0 97.3828125 9.66796875 142.47070313 28.74023437 43.59375 18.45703125 82.70507813 44.82421875 116.36718749 78.48632813 33.66210938 33.66210938 60.02929688 72.7734375 78.48632813 116.3671875 19.07226563 45.08789063 28.74023438 93.07617188 28.74023437 142.47070312 0 49.39453125-9.66796875 97.3828125-28.74023437 142.47070313-18.45703125 43.59375-44.82421875 82.70507813-78.48632813 116.36718749-33.66210938 33.66210938-72.7734375 60.02929688-116.3671875 78.48632813-45.08789063 19.07226563-93.07617188 28.74023438-142.47070312 28.74023437z m0-731.25C310.64257812 146.81445312 146.81445312 310.64257812 146.81445312 512S310.64257812 877.18554688 512 877.18554688 877.18554688 713.35742188 877.18554688 512 713.35742188 146.81445312 512 146.81445312z"
        fill={getIconColor(color, 1, '#333333')}
      />

      <path
        d="M710.89648438 372.78125c-10.98632813-10.8984375-28.91601563-10.8984375-39.81445313 0L452.41015625 591.54101563 352.91796875 492.04882812c-10.98632813-10.8984375-28.91601563-10.8984375-39.81445313 1e-8-10.8984375 10.98632813-10.8984375 28.91601563 0 39.81445312l119.35546876 119.35546875c5.44921875 5.44921875 12.65625 8.17382813 19.86328125 8.17382813 7.20703125 0 14.4140625-2.72460938 19.95117187-8.17382813l238.62304688-238.62304688c10.8984375-10.98632813 10.8984375-28.828125-1e-8-39.81445312z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconFinish.defaultProps = {
  size: 18,
};

export default IconFinish;
