/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAdministrationTenantThresholdSettings: FunctionComponent<Props> = ({
  size,
  color,
  style: _style,
  ...rest
}) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M74.60864 40.96c18.49344 0 33.64864 15.1552 33.64864 33.64864v842.81344h79.0528V402.65728c0-12.61568 10.36288-23.7568 22.7328-25.10848l2.51904-0.12288h132.89472c13.45536 0 25.23136 11.776 25.23136 25.23136v514.7648h75.69408V505.26208c0-12.61568 10.36288-23.7568 22.7328-25.088l2.51904-0.14336h132.89472c13.45536 0 23.552 11.776 25.23136 25.23136v412.16h75.69408V629.76c0-12.61568 10.36288-23.7568 22.7328-25.10848l2.51904-0.12288h131.21536c15.13472 0 25.23136 11.776 26.91072 25.23136l-0.02048 287.66208h60.57984c18.51392 0 33.64864 15.1552 33.64864 33.64864 0 18.51392-15.1552 31.96928-33.64864 31.96928H74.60864C56.09472 983.04 40.96 967.8848 40.96 949.39136V74.60864C40.96 56.09472 56.1152 40.96 74.60864 40.96z m275.88608 181.67808c10.09664 0 18.51392 3.3792 25.23136 11.776l90.84928 102.62528h148.04992c10.09664 0 20.1728 5.05856 26.91072 13.45536l94.208 136.27392h136.27392c18.49344 0 33.62816 15.1552 33.62816 33.64864 1.69984 20.1728-13.45536 33.64864-31.9488 33.64864h-154.76736c-10.09664 0-20.19328-5.05856-26.9312-13.47584l-94.208-136.25344h-146.35008c-10.09664 0-18.51392-3.35872-25.23136-11.776l-90.84928-102.6048H195.72736c-18.49344 0-33.64864-15.1552-33.64864-33.66912 0-18.49344 15.1552-33.64864 33.64864-33.64864h154.76736z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAdministrationTenantThresholdSettings.defaultProps = {
  size: 18,
};

export default IconAAdministrationTenantThresholdSettings;
