/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWendangzhongxin: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M848.363735 184.417105h-71.015662v-58.306173c0-34.994353-28.482442-63.477818-63.477818-63.477819h-567.304142c-34.995377 0-63.477818 28.483466-63.477819 63.477819v649.443294c0 34.994353 28.482442 63.477818 63.477819 63.477818h71.002351v58.306174c0 34.994353 28.482442 63.477818 63.477819 63.477819H848.363735c35.008687 0 63.477818-28.482442 63.477819-63.477819V247.894924c0-34.994353-28.469131-63.477818-63.477819-63.477819zM146.566113 782.995824c-4.103732 0-7.442622-3.337866-7.442623-7.442622V126.110932c0-4.103732 3.337866-7.442622 7.442623-7.442623h567.305165c4.103732 0 7.442622 3.337866 7.442623 7.442623v58.306173H281.045259c-34.994353 0-63.477818 28.482442-63.477819 63.477819v535.1009h-71.001327z m709.240245 114.342394c0 4.103732-3.337866 7.442622-7.441599 7.442623H281.045259c-4.103732 0-7.442622-3.337866-7.442622-7.442623V247.894924c0-4.103732 3.337866-7.442622 7.442622-7.442623H848.363735c4.103732 0 7.441599 3.337866 7.441599 7.442623v649.443294z"
        fill={getIconColor(color, 0, '#3E3A39')}
      />

      <path
        d="M572.920153 676.588542H413.007695c-15.472955 0-28.017598 12.531332-28.017598 28.017598S397.53474 732.623738 413.007695 732.623738h159.912458c15.472955 0 28.017598-12.531332 28.017598-28.017598s-12.544643-28.017598-28.017598-28.017598zM719.19241 525.008463H413.007695c-15.472955 0-28.017598 12.531332-28.017598 28.017598s12.544643 28.017598 28.017598 28.017598h306.184715c15.472955 0 28.017598-12.531332 28.017598-28.017598s-12.544643-28.017598-28.017598-28.017598zM719.19241 378.981938H413.007695c-15.472955 0-28.017598 12.531332-28.017598 28.017598s12.544643 28.017598 28.017598 28.017598h306.184715c15.472955 0 28.017598-12.531332 28.017598-28.017598s-12.544643-28.017598-28.017598-28.017598z"
        fill={getIconColor(color, 1, '#3E3A39')}
      />
    </svg>
  );
};

IconWendangzhongxin.defaultProps = {
  size: 18,
};

export default IconWendangzhongxin;
