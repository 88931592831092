/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Wufengexian: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M766.030769 614.124308v0.275692h140.8a38.4 38.4 0 0 1 0 76.8H766.030769v115.2a89.6 89.6 0 0 0 89.6 89.6h51.2a38.4 38.4 0 0 1 0 76.8h-51.2a166.4 166.4 0 0 1-166.4-166.4v-192.275692h76.8zM906.830769 51.2a38.4 38.4 0 0 1 0 76.8h-51.2a89.639385 89.639385 0 0 0-89.6 89.6V332.8h140.8a38.4 38.4 0 0 1 0 76.8L787.692308 409.560615v-0.43323l-98.461539-0.039385V217.6a166.4 166.4 0 0 1 158.089846-166.203077l8.310154-0.196923zM196.923077 614.4v0.196923l98.422154-0.039385 0.039384 191.842462A166.4 166.4 0 0 1 128.984615 972.8h-51.2a38.4 38.4 0 0 1 0-76.8h51.2a89.6 89.6 0 0 0 89.6-89.6V691.2H77.784615a38.4 38.4 0 0 1 0-76.8H196.923077zM246.665846 99.918769A166.4 166.4 0 0 1 295.384615 217.6l-0.039384 191.763692H196.923077v0.236308H77.784615a38.4 38.4 0 0 1 0-76.8H218.584615V217.6A89.6 89.6 0 0 0 128.984615 128h-51.2a38.4 38.4 0 0 1 0-76.8h51.2c44.110769 0 86.449231 17.526154 117.681231 48.718769z"
        fill={getIconColor(color, 0, '#3F51B5')}
        opacity=".498"
      />
    </svg>
  );
};

export default Wufengexian;
