/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconChangyonggongju2: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M520.549 624.208l47.745 48.45-30.655 31.885c0 0-22.395 24.53 6.78 55.805l240.204 248.354c0 0 22.985 21.465 48.335 3.065l120.25-121.42c0 0 21.22-29.43-7.08-56.415L707.998 588.648c0 0-25.94-26.37-54.82-3.68l-31.24 33.11-50.1-51.51L520.549 624.208 520.549 624.208zM487.539 481.331 229.952 217.032 229.952 135.47 77.875 41.647 2.62 117.685l91.76 152.085 80.75 0.608 254.052 260.012 58.357-49.06L487.539 481.331zM71.98 925.918c0 0 0-40.475 40.67-40.475 0 0 36.547 1.23 37.725 40.475 0 0-1.177 36.79-39.197 39.245l-33.892 34.955c0 0 49.515 47.835 105.512 0l459.176-512.036c0 0 50.105 34.947 125.555 34.337 0 0 92.155 3.68 164.455-61.732 0 0 141.08-115.082 68.775-295.164L836.493 330.679 697.378 187.594 861.643 24.07c0 0-104.525-63.777-245.994 28.617 0 0-73.095 53.142-95.105 148.807 0 0-33.002 113.65 40.875 215.857L45.062 858.058c0 0-32.222 32.7-16.505 80.94L71.98 925.918 71.98 925.918zM71.98 925.918c0 0 3.14 38.43 39.2 39.245l-33.892 34.955c0 0-44.572-39.81-48.725-61.12l43.42-13.08L71.98 925.918z"
        fill={getIconColor(color, 0, '#272636')}
      />
    </svg>
  );
};

IconChangyonggongju2.defaultProps = {
  size: 18,
};

export default IconChangyonggongju2;
