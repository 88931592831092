/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceTransferPostMyList: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M923.72992 40.96a59.14624 59.14624 0 0 1 59.20768 55.41888l0.1024 3.46112v824.32a59.0848 59.0848 0 0 1-55.82848 58.7776l-3.4816 0.1024H182.19008a59.14624 59.14624 0 0 1-59.20768-55.41888L122.88 924.16V703.34464h44.544c32.72704 0 59.2896-26.37824 59.2896-58.88a59.0848 59.0848 0 0 0-55.808-58.7776l-3.4816-0.1024H122.88v-147.16928h44.544c32.72704 0 59.2896-26.37824 59.2896-58.88a59.0848 59.0848 0 0 0-55.808-58.7776l-3.4816-0.1024H122.88V99.84a59.0848 59.0848 0 0 1 55.82848-58.7776L182.19008 40.96h741.53984zM635.22816 245.76h-187.10528c-31.82592 0-22.79424 19.456-22.07744 20.8896l0.04096 0.08192 74.87488 94.39232s-20.0704 69.65248-41.41056 145.48992l-3.21536 11.40736-6.30784 22.54848C429.056 615.6288 409.6 687.5136 409.6 695.64416c0 17.6128 117.43232 82.59584 131.39968 82.59584 13.98784 0 131.39968-66.88768 131.31776-82.59584 0-7.04512-18.432-75.22304-38.7072-148.21376l-1.72032-6.08256-4.1984-15.07328a28206.6944 28206.6944 0 0 0-33.9968-120.46336l-1.8432-6.5536-2.70336-9.46176-8.02816-28.11904-0.16384-0.55296 70.7584-94.4128S668.30336 245.76 635.22816 245.76zM163.7376 600.3712c22.6304 0 40.96 19.7632 40.96 44.21632 0 24.30976-18.3296 44.09344-40.96 44.19584H81.92c-22.6304 0-40.96-19.7632-40.96-44.19584 0-24.45312 18.3296-44.2368 40.96-44.2368z m437.10464-233.63584l66.72384 217.82528 24.9856-36.47488c4.15744-6.06208 4.89472-15.83104 2.10944-22.97856l-1.20832-2.53952-92.61056-155.83232zM163.84 335.31904c22.6304 0 40.96 19.78368 40.96 44.21632 0 24.43264-18.3296 44.21632-40.96 44.21632H82.0224c-22.6304 0-40.96-19.78368-40.96-44.21632 0-24.43264 18.3296-44.21632 40.96-44.21632z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceTransferPostMyList.defaultProps = {
  size: 18,
};

export default IconAHumanResourceTransferPostMyList;
