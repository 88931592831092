/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIcon21: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M511.996418 23.520693c-269.76705 0-488.454748 218.688721-488.454748 488.454748 0 269.768073 218.688721 488.453724 488.454748 488.453724 269.766027 0 488.454748-218.685651 488.454748-488.453724C1000.451166 242.209414 781.762445 23.520693 511.996418 23.520693zM769.334391 715.155956l-54.156434 54.156434L511.996418 566.129828 308.813856 769.31239l-54.155411-54.156434L457.841008 511.975441 254.658446 308.792878l54.155411-54.155411L511.996418 457.82003l203.182562-203.182562 54.156434 54.155411L566.151829 511.975441 769.334391 715.155956z"
        fill={getIconColor(color, 0, '#231815')}
      />
    </svg>
  );
};

IconIcon21.defaultProps = {
  size: 18,
};

export default IconIcon21;
