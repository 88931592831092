/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWodeGuanyuwomen: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M560.389406 197.836244c33.929066 8.187361 50.303789 47.964543 42.706237 81.893609-8.187361 33.929066-39.777181 62.589829-73.706247 54.402468s-46.79492-47.38473-39.187371-81.303799c8.177365-33.939063 36.258315-63.189636 70.187381-54.992278z m-145.653061 250.939131s102.367011-89.501157 142.144192-71.946814c26.911327 7.017738 12.286041 128.108715 8.187361 144.483438-1.169623 14.625287-24.572081 198.885906-18.713969 207.073267 8.187361 30.420197 17.544346 6.437925 46.215106-17.544346 0 0 67.268322-45.625297-4.678492 49.134166-59.660773 63.759453-117.572111 55.572091-126.349282 7.607549-7.017738-36.268312 21.643025-255.62762 28.660764-304.761786 2.339246-16.374723-18.134156 0-18.134156 0s-55.572091 34.508879-69.027755 11.116417c-3.518866-6.437925 1.749436-16.38472 11.696231-25.161891z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M511.835053 0.329894c-281.509279 0-511.835053 230.325774-511.835053 511.835053s230.325774 511.835053 511.835053 511.835053 511.835053-230.325774 511.835053-511.835053S793.344332 0.329894 511.835053 0.329894z m0 943.695879c-238.513135 0-431.860826-193.347691-431.860826-431.860826s193.347691-431.860826 431.860826-431.860826 431.860826 193.347691 431.860826 431.860826-193.347691 431.860826-431.860826 431.860826z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconWodeGuanyuwomen.defaultProps = {
  size: 18,
};

export default IconWodeGuanyuwomen;
