/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentColor24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M163.797333 249.941333c125.44-167.082667 385.28-215.68 570.197334-100.693333 182.613333 113.493333 249.770667 331.818667 174.933333 536.618667-70.613333 193.408-256.682667 269.994667-390.784 172.714666-50.218667-36.437333-69.717333-82.133333-79.104-156.330666l-4.522667-42.112-1.92-16.981334c-5.248-39.850667-13.269333-57.685333-30.08-67.072-22.826667-12.714667-38.058667-13.013333-68.053333-1.408l-14.976 6.229334-7.637333 3.328c-43.264 18.773333-72.021333 25.386667-108.416 17.749333l-8.533334-2.005333-6.997333-2.005334c-118.997333-36.864-136.618667-198.272-24.106667-348.032z m551.594667 201.472a53.333333 53.333333 0 1 0 103.04-27.605333 53.333333 53.333333 0 0 0-103.04 27.605333z m21.12 148.821334a53.333333 53.333333 0 1 0 102.997333-27.605334 53.333333 53.333333 0 0 0-102.997333 27.605334z m-105.557333-276.949334a53.333333 53.333333 0 1 0 103.04-27.605333 53.333333 53.333333 0 0 0-103.04 27.605333z m-1.194667 383.914667a53.333333 53.333333 0 1 0 103.04-27.605333 53.333333 53.333333 0 0 0-103.04 27.605333z m-149.205333-425.386667a53.333333 53.333333 0 1 0 103.04-27.562666 53.333333 53.333333 0 0 0-103.04 27.562666z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentColor24Filled;
