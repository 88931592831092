/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Lichongyujian: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M842.573913 291.617391A159.165217 159.165217 0 0 1 1001.73913 450.782609v391.791304A159.165217 159.165217 0 0 1 842.573913 1001.73913h-391.791304a159.165217 159.165217 0 0 1-159.165218-159.165217V781.356522h73.46087v61.217391a85.726609 85.726609 0 0 0 85.704348 85.704348h391.791304a85.704348 85.704348 0 0 0 85.704348-85.704348v-391.791304a85.704348 85.704348 0 0 0-85.704348-85.704348H781.356522v-73.46087h61.217391zM573.217391 22.26087a159.165217 159.165217 0 0 1 159.165218 159.165217v391.791304a159.165217 159.165217 0 0 1-159.165218 159.165218H181.426087A159.165217 159.165217 0 0 1 22.26087 573.217391V181.426087A159.165217 159.165217 0 0 1 181.426087 22.26087z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Lichongyujian;
