/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceLawyerlicenseAudits: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M248.07424 193.26976v7.49568c0.2048 36.51584 4.9152 128.57344 100.08576 131.97312l5.48864 0.1024h325.3248c9.25696 0.16384 106.27072-1.37216 97.4848-132.87424l-0.53248-6.69696h190.01344c30.5152 0 56.1152 22.40512 57.9584 52.8384l0.1024 3.52256 0.04096 370.688a299.04896 299.04896 0 0 0-22.1184-24.69888c-28.0576-28.09856-59.61728-49.0496-94.69952-62.8736A291.67616 291.67616 0 0 0 799.37536 512c-36.82304 0-72.88832 6.92224-108.17536 20.74624-35.28704 13.824-66.9696 34.77504-95.0272 62.8736-28.0576 28.07808-49.09056 59.67872-63.11936 94.80192A288.58368 288.58368 0 0 0 512 798.39232 288.58368 288.58368 0 0 0 550.44096 942.08H58.0608c-30.5152 0-56.1152-22.40512-57.9584-52.81792L0 885.71904V249.63072c0-31.00672 24.4736-54.55872 54.55872-56.25856l3.50208-0.1024h190.01344zM197.9392 620.01152c-77.37344 20.992-79.13472 57.344-79.17568 58.9824V781.04576h300.8512v-91.25888c0-57.4464-84.93056-66.62144-89.51808-67.072h-0.2048l-60.70272 64.38912-71.2704-67.09248z m71.2704-158.35136c-42.25024 0-76.55424 37.5808-76.55424 83.21024 0 45.62944 34.304 83.18976 76.53376 83.18976 42.22976 0 76.53376-37.56032 76.53376-83.18976s-34.304-83.21024-76.53376-83.21024zM628.1216 104.69376s96.74752-18.10432 100.18816 85.504l0.1024 5.77536-0.24576 2.048c-2.82624 22.40512-11.30496 77.66016-56.13568 80.97792l-4.3008 0.16384H358.912l-0.4096-0.06144c-5.12-0.88064-54.9888-11.1616-59.98592-77.47584l-0.3072-5.65248-0.02048-0.69632-0.02048-1.4336v-2.048c0.2048-18.3296 6.02112-89.45664 92.40576-87.08096 0 0-2.53952-101.02784 114.9952-104.57088L512 0.04096h0.43008L512.67584 0h3.03104c19.84512 0.2048 112.4352 6.4512 112.4352 104.69376zM512 61.78816c-31.66208 0-58.0608 26.8288-60.70272 59.04384 0 32.21504 29.04064 59.04384 60.70272 59.04384 34.304 0 60.70272-26.8288 60.70272-59.04384 0-32.19456-29.04064-59.04384-60.70272-59.04384z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M799.232 573.44c28.93824 0 57.18016 5.4272 84.74624 16.30208 27.5456 10.8544 52.34688 27.32032 74.40384 49.39776 22.03648 22.05696 38.5024 46.8992 49.3568 74.48576 10.8544 27.58656 16.26112 55.86944 16.26112 84.82816 0 28.9792-5.4272 57.26208-16.2816 84.84864s-27.29984 52.4288-49.3568 74.48576c-22.03648 22.07744-46.83776 38.62528-74.38336 49.664a226.03776 226.03776 0 0 1-84.74624 16.54784c-28.93824 0-57.26208-5.5296-84.992-16.54784a219.5456 219.5456 0 0 1-74.67008-49.664c-22.03648-22.07744-38.56384-46.8992-49.60256-74.48576A226.75456 226.75456 0 0 1 573.44 798.45376c0-28.95872 5.5296-57.2416 16.52736-84.82816 11.03872-27.58656 27.56608-52.4288 49.60256-74.48576 22.05696-22.07744 46.94016-38.54336 74.67008-49.39776a231.17824 231.17824 0 0 1 84.992-16.30208z m91.9552 328.04864h-184.9344a10.09664 10.09664 0 0 0-9.9328 10.24c0 5.67296 4.44416 10.28096 9.9328 10.28096h184.9344a10.09664 10.09664 0 0 0 9.9328-10.26048c0-5.67296-4.44416-10.24-9.9328-10.24z m-91.25888-225.52576c-32.9728 0-59.8016 23.51104-59.8016 52.4288 0 18.55488 11.264 35.67616 29.20448 45.056l-5.81632 41.30816H714.752c-10.15808 0-18.432 7.24992-18.432 16.1792v33.85344c0 8.92928 8.27392 16.19968 18.432 16.19968h167.936c10.15808 0 18.432-7.2704 18.432-16.1792v-33.87392c0-8.92928-8.27392-16.1792-18.432-16.1792h-46.44864l-5.81632-41.24672c18.00192-9.37984 29.32736-26.50112 29.32736-45.11744 0-28.91776-26.8288-52.4288-59.8016-52.4288z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceLawyerlicenseAudits.defaultProps = {
  size: 18,
};

export default IconAHumanResourceLawyerlicenseAudits;
