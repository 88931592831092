/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconLaborRelations: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M864 117.333333l0.021333 474.752A223.594667 223.594667 0 0 1 949.333333 768c0 123.712-100.288 224-224 224-61.013333 0-116.352-24.405333-156.757333-64H160v-810.666667h704z m-138.666667 490.666667a160 160 0 1 0 0 320 160 160 0 0 0 0-320z m74.666667-426.666667h-576v682.666667h298.88A223.146667 223.146667 0 0 1 501.333333 768c0-123.712 100.288-224 224-224 26.176 0 51.306667 4.48 74.666667 12.757333V181.333333zM725.333333 661.333333l31.36 63.509334 70.08 10.197333-50.709333 49.450667 11.946667 69.802666L725.333333 821.333333l-62.698666 32.96 11.968-69.802666-50.709334-49.450667 70.101334-10.197333L725.333333 661.333333z m-256-85.333333v64h-149.333333v-64h149.333333z m234.666667-149.333333v64H320v-64h384z m0-149.333334v64H320v-64h384z"
        fill={getIconColor(color, 0, '#5d73fa')}
      />
    </svg>
  );
};

IconLaborRelations.defaultProps = {
  size: 18,
};

export default IconLaborRelations;
