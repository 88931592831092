/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Guanlianzhangdan5: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M636.970667 725.333333c13.44 0 24.362667 11.477333 24.362666 25.6 0 14.122667-10.922667 25.6-24.362666 25.6h-24.405334c-40.405333 0-73.130667 34.389333-73.130666 76.8s32.725333 76.8 73.130666 76.8h24.405334c13.44 0 24.362667 11.477333 24.362666 25.6 0 14.122667-10.922667 25.6-24.362666 25.6h-24.405334c-67.328 0-121.898667-57.301333-121.898666-128s54.613333-128 121.898666-128z m243.797333 0c67.328 0 121.898667 57.301333 121.898667 128s-54.613333 128-121.898667 128h-24.405333c-13.44 0-24.362667-11.477333-24.362667-25.6 0-14.122667 10.922667-25.6 24.362667-25.6h24.405333c40.405333 0 73.130667-34.389333 73.130667-76.8s-32.725333-76.8-73.130667-76.8h-24.405333c-13.44 0-24.362667-11.477333-24.362667-25.6 0-14.122667 10.922667-25.6 24.362667-25.6zM448 42.666667c235.648 0 426.666667 191.018667 426.666667 426.666666 0 77.738667-20.778667 150.613333-57.088 213.376L618.666667 682.666667a170.666667 170.666667 0 0 0-165.290667 213.290666L448 896c-235.648 0-426.666667-191.018667-426.666667-426.666667s191.018667-426.666667 426.666667-426.666666z m401.066667 768c14.122667 0 25.6 19.114667 25.6 42.666666s-11.477333 42.666667-25.6 42.666667h-204.8c-14.122667 0-25.6-19.114667-25.6-42.666667s11.477333-42.666667 25.6-42.666666h204.8zM513.877333 277.333333H381.013333c-14.933333 0-27.477333 11.093333-29.312 25.898667l-14.762666 118.186667a29.525333 29.525333 0 0 0 29.781333 33.152c22.698667-0.341333 45.226667-0.341333 67.754667 0l11.093333 0.384c41.813333 2.005333 73.728 42.453333 67.968 83.754666a73.856 73.856 0 0 1-136.661333 27.434667 29.525333 29.525333 0 0 0-50.816 30.122667 132.949333 132.949333 0 1 0 132.821333-199.509334c-9.514667-1.28-34.389333-1.493333-53.546667-1.493333h-5.546666l7.381333-58.88h106.752a29.525333 29.525333 0 0 0 0-59.050667z"
        fill={getIconColor(color, 0, '#636df1')}
      />
    </svg>
  );
};

export default Guanlianzhangdan5;
