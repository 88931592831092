/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShoujiandizhi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 908.3375c-9.478125 0-14.7-1.134375-18.028125-5.04375001-2.00625001-2.34375001-203.296875-241.265625-256.94062499-356.97187499-19.378125-41.446875-29.60625001-86.446875-29.60625001-130.36875 0-165.628125 136.621875-300.3 304.575-300.3 167.94375001 0 304.575 135.609375 304.575 302.259375 0 50.34375001-9.440625 88.3875-32.56875001 131.2875-63.965625 118.528125-251.821875 351.3-253.70624999 353.625-2.240625 2.803125-6.24375001 5.5125-17.83125001 5.5125h-0.46874999z m0-608.23125001c-64.59375001 0-117.15 52.546875-117.15 117.14062501 0 64.584375 52.55625001 117.140625 117.15 117.140625 64.584375 0 117.140625-52.55625001 117.140625-117.140625 0-64.59375001-52.546875-117.140625-117.140625-117.14062501z m0 0"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconShoujiandizhi.defaultProps = {
  size: 18,
};

export default IconShoujiandizhi;
