/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconJisuan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M437 305h-38v-38c0-19.9-16.1-36-36-36s-36 16.1-36 36v38h-38c-19.9 0-36 16.1-36 36s16.1 36 36 36h38v38c0 19.9 16.1 36 36 36s36-16.1 36-36v-38h38c19.9 0 36-16.1 36-36s-16.1-36-36-36zM592 377h148c19.9 0 36-16.1 36-36s-16.1-36-36-36H592c-19.9 0-36 16.1-36 36s16.1 36 36 36zM740 570H592c-19.9 0-36 16.1-36 36s16.1 36 36 36h148c19.9 0 36-16.1 36-36s-16.1-36-36-36zM740 670H592c-19.9 0-36 16.1-36 36s16.1 36 36 36h148c19.9 0 36-16.1 36-36s-16.1-36-36-36zM441.3 578.2c-14.1-14.1-36.9-14.1-50.9 0l-26.9 26.9-26.9-26.9c-14.1-14.1-36.9-14.1-50.9 0-14.1 14.1-14.1 36.9 0 50.9l26.9 26.9-26.9 26.9c-14.1 14.1-14.1 36.9 0 50.9 7 7 16.2 10.5 25.5 10.5s18.4-3.5 25.5-10.5l26.9-26.9 26.9 26.9c7 7 16.2 10.5 25.5 10.5 9.2 0 18.4-3.5 25.5-10.5 14.1-14.1 14.1-36.9 0-50.9L414.4 656l26.9-26.9c14-14 14-36.8 0-50.9z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconJisuan.defaultProps = {
  size: 18,
};

export default IconJisuan;
