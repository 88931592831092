/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTishi1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 64a448 448 0 1 1 0 896A448 448 0 0 1 512 64z m0 64a384 384 0 1 0 0 768A384 384 0 0 0 512 128z m31.616 256v64h-0.384v256h62.912v64h-192v-64h65.088V448h-29.76V384h94.08z m-36.672-44.8a41.6 41.6 0 1 0 0-83.2 41.6 41.6 0 0 0 0 83.2z"
        fill={getIconColor(color, 0, '#000000')}
      />
    </svg>
  );
};

IconTishi1.defaultProps = {
  size: 18,
};

export default IconTishi1;
