/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconLie: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M819.285333 853.333333a30.72 30.72 0 0 1 24.021334 12.544c6.485333 8.106667 10.026667 18.773333 10.026666 30.122667 0 23.552-15.274667 42.666667-34.048 42.666667H34.048C15.274667 938.666667 0 919.552 0 896c0-23.509333 15.274667-42.666667 34.048-42.666667z m0-384a30.72 30.72 0 0 1 24.021334 12.544c6.485333 8.106667 10.026667 18.773333 10.026666 30.122667 0 23.509333-15.274667 42.666667-34.048 42.666667H34.048C15.274667 554.666667 0 535.509333 0 512c0-23.509333 15.274667-42.666667 34.048-42.666667z m163.84-384c10.88 0 21.077333 4.437333 28.842667 12.544 7.765333 8.106667 12.032 18.773333 12.032 30.122667 0 23.552-18.346667 42.666667-40.832 42.666667H40.874667C18.304 170.666667 0 151.552 0 128c0-23.509333 18.346667-42.666667 40.874667-42.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconLie.defaultProps = {
  size: 18,
};

export default IconLie;
