/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const PeopleSettings24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M149.333333 554.666667h397.184A276.394667 276.394667 0 0 0 469.333333 746.666667c0 46.378667 11.392 90.112 31.530667 128.554666-44.8 14.933333-94.208 20.778667-138.197333 20.778667-116.138667 0-270.293333-40.789333-277.12-182.186667L85.333333 704v-85.333333a64 64 0 0 1 57.856-63.701334L149.333333 554.666667zM896 320a149.333333 149.333333 0 1 1-298.666667 0 149.333333 149.333333 0 0 1 298.666667 0zM362.666667 85.333333a192 192 0 1 1 0 384 192 192 0 0 1 0-384z m246.485333 510.933334a85.333333 85.333333 0 0 1-61.482667 106.538666l-24.917333 6.144a244.437333 244.437333 0 0 0 0.256 77.098667l23.04 5.546667a85.333333 85.333333 0 0 1 61.866667 107.093333l-7.978667 26.965333c18.773333 16.469333 40.106667 29.824 63.36 39.296l21.034667-22.101333a85.333333 85.333333 0 0 1 123.690666 0l21.290667 22.4a225.365333 225.365333 0 0 0 63.232-38.954667l-8.448-29.269333a85.333333 85.333333 0 0 1 61.525333-106.496l24.874667-6.144a244.437333 244.437333 0 0 0-0.256-77.141333l-23.04-5.546667a85.333333 85.333333 0 0 1-61.824-107.093333l7.936-26.88a225.194667 225.194667 0 0 0-63.317333-39.381334l-21.034667 22.144a85.333333 85.333333 0 0 1-123.733333 0l-21.248-22.4c-23.210667 9.386667-44.544 22.613333-63.274667 38.912l8.448 29.269334zM746.666667 810.666667c-34.133333 0-61.866667-28.672-61.866667-64 0-35.370667 27.733333-64 61.866667-64 34.133333 0 61.866667 28.629333 61.866666 64 0 35.328-27.733333 64-61.866666 64z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default PeopleSettings24Filled;
