/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Shoukuanpingzheng: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M947.2 254.70887C993.124174 280.932174 1024 329.839304 1024 385.847652v264.904348c0 63.577043-25.622261 124.527304-71.234783 169.494261A245.025391 245.025391 0 0 1 780.8 890.434783H256c-54.895304 0.022261-105.627826-28.827826-133.075478-75.686957h657.875478c91.89287 0 166.4-73.416348 166.4-163.995826zM780.8 133.565217C830.263652 133.565217 870.4 173.122783 870.4 221.874087v428.877913c0 48.773565-40.114087 88.30887-89.6 88.30887H89.6C40.114087 739.06087 0 699.525565 0 650.752V221.874087C0 173.122783 40.136348 133.565217 89.6 133.565217h691.2z m-665.6 378.434783h-51.2v75.686957h51.2c21.214609 0 38.4 16.940522 38.4 37.843478v50.465391h76.8V625.530435c0-62.70887-51.578435-113.530435-115.2-113.530435z m691.2 0h-51.2c-63.621565 0-115.2 50.821565-115.2 113.530435v50.465391h76.8V625.530435c0-20.902957 17.185391-37.843478 38.4-37.843478h51.2V512zM435.2 335.404522c-63.621565 0-115.2 50.821565-115.2 113.530435 0 62.686609 51.578435 113.530435 115.2 113.530434 63.621565 0 115.2-50.843826 115.2-113.530434 0-62.70887-51.578435-113.530435-115.2-113.530435z m-204.8-138.774261H153.6V247.095652c0 20.902957-17.185391 37.843478-38.4 37.843478h-51.2v75.686957h51.2a116.068174 116.068174 0 0 0 81.474783-33.257739A112.706783 112.706783 0 0 0 230.4 247.095652V196.630261z m486.4 0h-76.8V247.095652c0 62.70887 51.578435 113.530435 115.2 113.530435h51.2v-75.686957h-51.2c-21.214609 0-38.4-16.940522-38.4-37.843478V196.630261z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Shoukuanpingzheng;
