/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconGongnengguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M360.5504 77.5936h-251.392A83.712 83.712 0 0 0 25.6 161.1776v251.392a83.712 83.712 0 0 0 83.584 83.5584h251.3664a83.712 83.712 0 0 0 83.584-83.584V161.1776a83.712 83.712 0 0 0-83.584-83.584m613.376 150.144l-177.7408-177.7152a83.712 83.712 0 0 0-118.1952 0l-24.576 24.6016-153.1648 153.088a82.9952 82.9952 0 0 0-24.3968 58.9568 82.9952 82.9952 0 0 0 24.3968 59.264l24.6016 24.576 128.5376 128.64 24.6016 24.6016a83.584 83.584 0 0 0 118.1952-0.0256l153.1392-153.1904 24.6272-24.576a83.6096 83.6096 0 0 0 0-118.1952M360.5504 579.84h-251.392A83.712 83.712 0 0 0 25.6 663.424v251.3664a83.712 83.712 0 0 0 83.584 83.584h251.3664a83.712 83.712 0 0 0 83.584-83.584v-251.392a83.712 83.712 0 0 0-83.584-83.5584m502.2208 0h-251.3664a83.712 83.712 0 0 0-83.5584 83.584v251.3664a83.712 83.712 0 0 0 83.5584 83.584h251.392a83.712 83.712 0 0 0 83.5584-83.584v-251.392a83.712 83.712 0 0 0-83.584-83.5584"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconGongnengguanli.defaultProps = {
  size: 18,
};

export default IconGongnengguanli;
