/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceBusinessAreaCauseOfActionEmployeeGo: FunctionComponent<Props> = ({
  size,
  color,
  style: _style,
  ...rest
}) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 629.82144l138.46528 72.82688-26.48064-154.19392 112.00512-109.21984-154.80832-22.528L512 276.41856l-69.18144 140.288-154.80832 22.528 112.00512 109.11744-26.48064 154.19392L512 629.84192zM512 983.04C251.92448 983.04 40.96 772.15744 40.96 511.93856 40.96 251.84256 251.82208 40.96 512 40.96s471.04 210.88256 471.04 471.10144C983.04 772.15744 772.07552 983.04 512 983.04z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceBusinessAreaCauseOfActionEmployeeGo.defaultProps = {
  size: 18,
};

export default IconAHumanResourceBusinessAreaCauseOfActionEmployeeGo;
