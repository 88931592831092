/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAdministrationTenantSettings: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M961.57696 600.33024l-79.85152-60.57984c0.83968-8.3968 1.67936-17.65376 1.67936-27.7504s-0.8192-19.3536-1.67936-27.7504l79.872-60.55936c21.85216-16.83456 27.72992-46.2848 13.43488-71.51616l-89.088-150.54848a57.52832 57.52832 0 0 0-49.60256-28.61056c-6.71744 0-12.61568 0.83968-19.33312 3.3792l-94.98624 37.00736c-15.9744-10.93632-32.768-20.19328-49.58208-27.77088l-14.29504-96.72704C653.94688 61.15328 630.41536 40.96 601.82528 40.96h-179.87584c-28.5696 0-52.10112 20.19328-56.32 47.104l-13.43488 97.56672c-16.81408 7.5776-32.78848 16.83456-49.60256 27.77088l-94.98624-37.00736a56.46336 56.46336 0 0 0-20.1728-3.3792c-20.1728 0-39.50592 10.09664-48.7424 27.77088L48.7424 352.17408a54.8864 54.8864 0 0 0 13.45536 71.49568l79.85152 60.57984c-0.83968 10.07616-1.67936 19.33312-1.67936 27.7504 0 8.3968 0 17.67424 1.67936 27.7504l-79.85152 60.55936c-21.85216 16.83456-27.72992 46.2848-13.45536 71.51616l89.088 150.54848a57.52832 57.52832 0 0 0 49.60256 28.61056c6.73792 0 12.61568-0.83968 19.33312-3.35872l94.98624-37.02784c15.9744 10.93632 32.768 20.19328 49.60256 27.77088l14.27456 96.72704c3.35872 26.91072 27.7504 47.94368 56.32 47.94368h179.87584c28.59008 0 52.1216-20.19328 56.32-47.104l14.29504-97.56672c16.7936-7.5776 32.768-16.83456 49.58208-27.77088l94.98624 37.02784c6.71744 2.51904 13.45536 3.35872 20.1728 3.35872 20.1728 0 39.5264-10.09664 48.7424-27.77088l89.94816-152.22784c13.45536-24.41216 7.5776-53.8624-14.29504-70.656zM713.6256 512c0 111.02208-90.78784 201.87136-201.728 201.87136-110.96064 0-201.728-90.84928-201.728-201.87136 0-111.02208 90.76736-201.87136 201.728-201.87136 110.94016 0 201.728 90.84928 201.728 201.87136z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAdministrationTenantSettings.defaultProps = {
  size: 18,
};

export default IconAAdministrationTenantSettings;
