/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPanelRightExpand24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M631.765333 394.624a32 32 0 0 0-45.184-2.389333l-106.666666 96a32 32 0 0 0 0 47.530666l106.666666 96a32 32 0 0 0 42.837334-47.573333l-44.714667-40.192h151.296a32 32 0 1 0 0-64h-151.296l44.714667-40.192a32 32 0 0 0 2.346666-45.184zM85.333333 736.042667A117.333333 117.333333 0 0 0 202.666667 853.333333h618.666666A117.333333 117.333333 0 0 0 938.666667 736V288A117.333333 117.333333 0 0 0 821.333333 170.666667H202.666667A117.333333 117.333333 0 0 0 85.333333 288v448z m117.333334 53.333333c-29.44 0-53.333333-23.893333-53.333334-53.333333V287.957333c0-29.44 23.893333-53.333333 53.333334-53.333333h138.837333V789.333333H202.666667z m202.837333 0V234.666667h415.829333c29.44 0 53.333333 23.893333 53.333334 53.333333v448c0 29.44-23.893333 53.333333-53.333334 53.333333H405.504z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentPanelRightExpand24Regular;
