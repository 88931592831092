/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialReceiptsCreate: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M942.08 40.96a81.92 81.92 0 0 1 81.92 81.92l0.04096 532.6848a267.61216 267.61216 0 0 0-36.63872-45.44512c-26.05056-26.09152-55.35744-45.54752-87.92064-58.368-32.5632-12.86144-65.9456-19.27168-100.1472-19.27168-34.2016 0-67.6864 6.41024-100.4544 19.2512s-62.17728 32.29696-88.22784 58.38848c-26.07104 26.09152-45.60896 55.43936-58.63424 88.04352A267.9808 267.9808 0 0 0 532.48 798.4128c0 34.24256 6.51264 67.66592 19.53792 100.27008 13.02528 32.60416 32.5632 61.93152 58.63424 88.02304L607.08864 983.04H81.92a81.92 81.92 0 0 1-81.92-81.92V122.88a81.92 81.92 0 0 1 81.92-81.92h860.16zM456.2944 255.26272a40.96 40.96 0 0 0-57.67168 5.24288L327.68 345.57952l-70.94272-85.07392-3.52256-3.74784a40.96 40.96 0 0 0-59.392 56.17664L240.2304 368.64H204.8l-4.77184 0.26624A40.96 40.96 0 0 0 204.8 450.56h81.89952v40.96H204.8l-4.77184 0.26624A40.96 40.96 0 0 0 204.8 573.44h81.89952L286.72 655.36l0.26624 4.77184A40.96 40.96 0 0 0 368.64 655.36l-0.02048-81.92H450.56l4.77184-0.26624A40.96 40.96 0 0 0 450.56 491.52h-81.94048v-40.96H450.56l4.77184-0.26624A40.96 40.96 0 0 0 450.56 368.64h-35.45088l46.42816-55.7056 3.072-4.13696a40.96 40.96 0 0 0-8.31488-53.53472z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M799.232 573.44c28.93824 0 57.18016 5.4272 84.74624 16.30208 27.5456 10.8544 52.34688 27.32032 74.40384 49.39776 22.03648 22.05696 38.5024 46.8992 49.3568 74.48576 10.8544 27.58656 16.26112 55.86944 16.26112 84.82816 0 28.9792-5.4272 57.26208-16.2816 84.84864s-27.29984 52.4288-49.3568 74.48576c-22.03648 22.07744-46.83776 38.62528-74.38336 49.664a226.03776 226.03776 0 0 1-84.74624 16.54784c-28.93824 0-57.26208-5.5296-84.992-16.54784a219.5456 219.5456 0 0 1-74.67008-49.664c-22.03648-22.07744-38.56384-46.8992-49.60256-74.48576A226.75456 226.75456 0 0 1 573.44 798.45376c0-28.95872 5.5296-57.2416 16.52736-84.82816 11.03872-27.58656 27.56608-52.4288 49.60256-74.48576 22.05696-22.07744 46.94016-38.54336 74.67008-49.39776a231.17824 231.17824 0 0 1 84.992-16.30208z m-1.96608 112.68096a32.80896 32.80896 0 0 0-47.7184 0l-84.31616 87.77728-2.80576 3.29728a36.18816 36.18816 0 0 0 2.80576 46.34624l84.33664 87.77728 3.1744 2.92864a32.768 32.768 0 0 0 44.52352-2.92864l2.80576-3.31776a36.18816 36.18816 0 0 0-2.80576-46.32576l-60.47744-62.99648 60.47744-62.91456 2.80576-3.29728a36.18816 36.18816 0 0 0-2.80576-46.34624z m134.92224 0a32.80896 32.80896 0 0 0-47.7184 0l-84.29568 87.77728-2.80576 3.29728a36.18816 36.18816 0 0 0 2.80576 46.34624l84.31616 87.77728 3.1744 2.92864a32.768 32.768 0 0 0 44.544-2.92864l2.80576-3.31776a36.18816 36.18816 0 0 0-2.80576-46.32576l-60.49792-62.99648 60.47744-62.91456 2.80576-3.29728a36.18816 36.18816 0 0 0-2.80576-46.34624z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconAFinancialReceiptsCreate.defaultProps = {
  size: 18,
};

export default IconAFinancialReceiptsCreate;
