/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentArrowSyncCircle24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M682.666667 352a32 32 0 0 1 64 0v138.666667a32 32 0 0 1-32 32H597.333333a32 32 0 0 1 0-64h54.186667A149.418667 149.418667 0 0 0 512 362.666667c-46.634667 0-86.912 19.797333-114.048 52.48a32 32 0 1 1-49.237333-40.96C388.096 326.826667 446.421333 298.666667 512 298.666667a212.906667 212.906667 0 0 1 170.666667 85.333333v-32zM341.333333 640v32a32 32 0 0 1-64 0v-128a32 32 0 0 1 32-32H426.666667a32 32 0 0 1 0 64H377.045333a149.888 149.888 0 0 0 249.258667 32.64 32 32 0 1 1 48.725333 41.472A213.888 213.888 0 0 1 341.333333 640z m170.666667-554.666667C276.352 85.333333 85.333333 276.352 85.333333 512s191.018667 426.666667 426.666667 426.666667 426.666667-191.018667 426.666667-426.666667S747.648 85.333333 512 85.333333z m362.666667 426.666667a362.666667 362.666667 0 1 1-725.333334 0 362.666667 362.666667 0 0 1 725.333334 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentArrowSyncCircle24Regular;
