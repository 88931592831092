/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconBangongyongpindizhiyihaolingyongliucheng: FunctionComponent<Props> = ({
  size,
  color,
  style: _style,
  ...rest
}) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1072 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M447.701333 529.664l-4.992 35.2 35.157334-5.034667 384-384-30.165334-30.165333-384 384z m-55.296 128.170667a42.666667 42.666667 0 0 1-42.24-48.725334l15.104-105.6a42.624 42.624 0 0 1 12.032-24.149333l424.234667-424.192a42.666667 42.666667 0 0 1 60.330667 0l90.453333 90.538667a42.624 42.624 0 0 1 0 60.288l-424.149333 424.234666a42.837333 42.837333 0 0 1-24.149334 12.074667l-105.6 15.104c-2.005333 0.256-4.010667 0.426667-6.016 0.426667z"
        fill={getIconColor(color, 0, '#8BB3F1')}
      />

      <path
        d="M853.333333 426.666667v426.666666H213.333333V170.666667h384V85.333333H170.666667a42.666667 42.666667 0 0 0-42.666667 42.666667v128H42.666667v85.333333h85.333333v341.333334H42.666667v85.333333h85.333333v128a42.666667 42.666667 0 0 0 42.666667 42.666667h725.333333a42.666667 42.666667 0 0 0 42.666667-42.666667V426.666667h-85.333334z"
        fill={getIconColor(color, 1, '#1768E4')}
      />
    </svg>
  );
};

IconBangongyongpindizhiyihaolingyongliucheng.defaultProps = {
  size: 18,
};

export default IconBangongyongpindizhiyihaolingyongliucheng;
