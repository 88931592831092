/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconKuaididanhao: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M895.625 525.5l-65.25-180c-10.125-25.875-21.375-32.625-32.625-32.625H620c-10.125 0-16.875 6.75-16.875 16.875v226.125h-32.625V186.875C571.625 137.375 557 118.25 505.25 118.25H210.5c-50.625 0-65.25 20.25-65.25 68.625v371.25c-28.125 0-25.875 20.25-25.875 20.25v210.375c0 10.125 6.75 20.25 16.875 20.25h95.625c6.75 56.25 52.875 99 108 99 56.25 0 102.375-43.875 110.25-99H602c9 52.875 55.125 99 110.25 99 59.625 0 104.625-49.5 111.375-111.375h65.25c10.125 0 16.875-6.75 16.875-16.875V584c0-12.375-3.375-39.375-10.125-58.5z m-644.625-41.625c0-15.75 12.375-28.125 28.125-28.125H354.5c15.75 0 28.125 12.375 28.125 28.125S370.25 512 354.5 512h-74.25c-16.875 0-29.25-12.375-29.25-28.125z m88.875 379.125c-38.25 0-67.5-31.5-67.5-67.5s29.25-67.5 67.5-67.5 67.5 31.5 67.5 67.5-29.25 67.5-67.5 67.5zM433.25 392.75H279.125c-15.75 0-28.125-12.375-28.125-28.125s12.375-28.125 28.125-28.125H433.25c15.75 0 28.125 12.375 28.125 28.125 0 14.625-13.5 28.125-28.125 28.125z m0-120.375H279.125c-15.75 0-28.125-12.375-28.125-28.125s12.375-28.125 28.125-28.125H433.25c15.75 0 28.125 12.375 28.125 28.125s-13.5 28.125-28.125 28.125z m279 590.625c-38.25 0-67.5-31.5-67.5-67.5s29.25-67.5 67.5-67.5 67.5 31.5 67.5 67.5-29.25 67.5-67.5 67.5z m97.875-345.375c-14.625 6.75-28.125-5.625-31.5-13.5-16.875-38.25-36-97.875-36-97.875-4.5-13.5 0-27 13.5-31.5 13.5-4.5 27 0 31.5 13.5l36 97.875c4.5 13.5 0 27-13.5 31.5z"
        fill={getIconColor(color, 0, '#707070')}
      />
    </svg>
  );
};

IconKuaididanhao.defaultProps = {
  size: 18,
};

export default IconKuaididanhao;
