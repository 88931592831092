/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTuominguizeguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M192 120.32c0 33.28 27.136 60.416 60.416 60.416S312.832 153.6 312.832 120.32c0-33.28-27.136-60.416-60.416-60.416-33.28 0.512-60.416 27.136-60.416 60.416zM71.68 301.056c0 33.28 27.136 60.416 60.416 60.416s60.416-27.136 60.416-60.416c0-33.28-27.136-60.416-60.416-60.416S71.68 267.776 71.68 301.056zM252.416 421.888c0 33.28 27.136 60.416 60.416 60.416 33.28 0 60.416-27.136 60.416-60.416 0-33.28-27.136-60.416-60.416-60.416-33.28 0-60.416 26.624-60.416 60.416 0-0.512 0 0 0 0zM665.088 421.888c0 33.28 27.136 60.416 60.416 60.416 33.28 0 60.416-27.136 60.416-60.416 0-33.28-27.136-60.416-60.416-60.416-33.28 0-60.416 26.624-60.416 60.416 0-0.512 0 0 0 0zM665.088 602.112c0 33.28 27.136 60.416 60.416 60.416 33.28 0 60.416-27.136 60.416-60.416 0-33.28-27.136-60.416-60.416-60.416s-60.416 27.136-60.416 60.416zM845.824 602.112c0 33.28 27.136 60.416 60.416 60.416 33.28 0 60.416-27.136 60.416-60.416 0-33.28-27.136-60.416-60.416-60.416-33.28 0.512-60.416 27.136-60.416 60.416zM845.824 421.888c0 33.28 27.136 60.416 60.416 60.416 33.28 0 60.416-27.136 60.416-60.416 0-33.28-27.136-60.416-60.416-60.416-33.28 0-60.416 26.624-60.416 60.416 0-0.512 0 0 0 0zM132.096 843.264c0 33.28 27.136 60.416 60.416 60.416 33.28 0 60.416-27.136 60.416-60.416 0-33.28-27.136-60.416-60.416-60.416s-60.416 27.136-60.416 60.416zM192 632.32c0 49.664 40.448 90.624 90.112 90.624 49.664 0 90.624-40.448 90.624-90.112 0-49.664-40.448-90.112-90.112-90.112-50.176-0.512-90.624 39.936-90.624 89.6z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M476.672 1010.176l278.016-166.912V727.04c0-2.56-2.048-4.096-4.096-4.096h-77.312c-2.56 0-4.096 2.048-4.096 4.096v69.632l-111.616 69.12c-2.56 1.536-6.144-0.512-6.144-3.584V166.4c0-3.072 3.072-5.12 6.144-3.584l111.104 64V296.96c0 2.56 2.048 4.096 4.096 4.096h77.312c2.56 0 4.096-2.048 4.096-4.096V180.736L476.672 13.824c-10.24-6.144-23.04 1.024-23.04 12.8v970.24c0 12.288 12.8 19.456 23.04 13.312z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconTuominguizeguanli.defaultProps = {
  size: 18,
};

export default IconTuominguizeguanli;
