/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialInvoice: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M901.12 81.92a122.88 122.88 0 0 1 122.88 122.88v142.27456c-60.6208 30.18752-102.4 92.61056-102.4 164.94592 0 72.31488 41.7792 134.7584 102.4 164.92544V819.2a122.88 122.88 0 0 1-122.88 122.88H122.88a122.88 122.88 0 0 1-122.88-122.88v-142.25408C60.6208 646.7584 102.4 584.33536 102.4 512s-41.7792-134.7584-102.4-164.92544V204.8a122.88 122.88 0 0 1 122.88-122.88h778.24z m-194.56 675.84h-389.12a30.72 30.72 0 0 0-30.72 30.72v20.48a30.72 30.72 0 0 0 30.72 30.74048h389.12a30.72 30.72 0 0 0 30.72-30.72v-20.48c0-16.9984-13.76256-30.72-30.72-30.72z m0-143.36h-389.12a30.72 30.72 0 0 0-30.72 30.72v20.48a30.72 30.72 0 0 0 30.72 30.74048h389.12a30.72 30.72 0 0 0 30.72-30.72v-20.48c0-16.9984-13.76256-30.74048-30.72-30.74048z m-83.61984-109.21984H401.05984c-6.59456 0-11.93984 6.10304-11.93984 13.63968 0 7.55712 5.3248 13.66016 11.93984 13.66016h221.88032c6.59456 0 11.93984-6.10304 11.93984-13.66016 0-7.53664-5.3248-13.63968-11.93984-13.63968zM513.45408 204.8c-39.56736 0-71.76192 31.3344-71.76192 69.8368 0 24.71936 13.5168 47.5136 35.06176 60.0064l-7.00416 54.9888H411.2384c-12.20608 0-22.1184 9.68704-22.1184 21.58592v45.07648c0 11.8784 9.91232 21.56544 22.1184 21.56544h201.5232c12.20608 0 22.1184-9.66656 22.1184-21.56544v-45.07648c0-11.89888-9.91232-21.58592-22.1184-21.58592h-55.74656l-6.9632-54.92736a69.632 69.632 0 0 0 35.16416-60.08832c0-38.5024-32.19456-69.81632-71.76192-69.81632z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialInvoice.defaultProps = {
  size: 18,
};

export default IconAFinancialInvoice;
