/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconQidong: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M907.3455925 512c0 218.38365555-177.04072952 395.42901993-395.42901992 395.42901993C293.52982712 907.42438507 116.4921875 730.38365555 116.4921875 512 116.4921875 293.61248207 293.52905464 116.57561493 511.91657257 116.57561493 730.30486298 116.57561493 907.3455925 293.61248207 907.3455925 512zM451.68505859 379.50488281c0-19.66106414-15.93463898-35.59570313-35.59570312-35.59570312-19.66106414 0-35.59570313 15.93463898-35.59570313 35.59570312v264.99023438c0 19.66106414 15.93463898 35.59570313 35.59570313 35.59570312 19.66106414 0 35.59570313-15.93463898 35.59570312-35.59570312V379.50488281z m191.3269043 0c0-19.66106414-15.93463898-35.59570313-35.59570312-35.59570312-19.66106414 0-35.59570313 15.93463898-35.59570313 35.59570312v264.99409676c0 19.66106414 15.93463898 35.59570313 35.59570313 35.59570312 19.66106414 0 35.59570313-15.93463898 35.59570312-35.59570312V379.50488281z"
        fill={getIconColor(color, 0, '#FFB100')}
      />
    </svg>
  );
};

IconQidong.defaultProps = {
  size: 18,
};

export default IconQidong;
