/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconBangzhu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512.956791 960.931592C266.363534 961.478038 64.969711 761.176083 64.479547 514.885723 63.98836 268.25051 264.216637 66.960041 510.574534 66.424852c245.564835-0.533143 447.856098 199.584618 448.462918 443.646216C959.656553 758.954485 760.363577 960.3831 512.956791 960.931592zM139.877771 517.064342c0.437975 206.380394 166.535921 372.055714 372.986923 372.036271 205.638497-0.019443 373.173165-167.17037 373.372709-372.517225C886.438994 310.307372 718.076472 142.700049 511.166005 143.194306 305.298288 143.68447 139.440819 310.697251 139.877771 517.064342z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M533.706371 610.072643c-28.453029 0-52.5611 0-78.629825 0-8.176217-49.873897 2.848884-92.693122 40.473807-127.700427 13.622252-12.674671 27.497261-25.355481 39.222303-39.698141 7.992021-9.776667 14.149255-21.878287 18.140149-33.929764 8.40646-25.38311-3.360538-53.949726-24.848944-64.948221-22.3838-11.456936-50.65161-5.606695-67.99972 15.589047-7.039323 8.599865-12.148691 19.075451-16.686031 29.339212-4.205788 9.514701-6.478552 19.883863-10.524704 32.82357-29.947056-7.268544-59.390645-14.414291-90.285283-21.913079 10.208503-58.411341 31.146371-108.754936 87.706551-132.638903 60.505026-25.549909 122.591059-26.342971 179.231057 10.735505 52.072983 34.089399 66.654073 117.146047 31.156604 172.232666-13.338796 20.700461-31.6877 39.1036-50.724265 54.993498C557.223994 532.264533 526.186094 559.103808 533.706371 610.072643z"
        fill={getIconColor(color, 1, '#333333')}
      />

      <path
        d="M543.036877 756.13323c-33.396621 0-64.593134 0-97.456612 0 0-33.579793 0-66.103534 0-100.111069 32.640398 0 64.35368 0 97.456612 0C543.036877 689.488367 543.036877 721.383798 543.036877 756.13323z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconBangzhu.defaultProps = {
  size: 18,
};

export default IconBangzhu;
