/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveBusinessFileMyCall: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M921.6 0H102.4C68.608 0 40.96 27.648 40.96 61.44v901.12c0 33.792 27.648 61.44 61.44 61.44h819.2c33.792 0 61.44-27.648 61.44-61.44V61.44c0-33.792-27.648-61.44-61.44-61.44zM444.928 614.912L285.696 774.144a41.10336 41.10336 0 0 1-57.856 0L155.648 701.952a41.10336 41.10336 0 0 1 0-57.856 41.10336 41.10336 0 0 1 57.856 0l43.52 43.52 130.56-130.56a41.10336 41.10336 0 0 1 57.856 0c15.36 15.872 15.36 41.984-0.512 57.856z m0-307.2L285.696 466.944a41.10336 41.10336 0 0 1-57.856 0l-72.704-72.192a41.10336 41.10336 0 0 1 0-57.856 41.10336 41.10336 0 0 1 57.856 0l43.52 43.52L387.072 249.856a41.10336 41.10336 0 0 1 57.856 0c15.872 15.872 15.872 41.984 0 57.856zM839.68 735.232H593.92c-22.528 0-40.96-18.432-40.96-40.96s18.432-40.96 40.96-40.96h245.76c22.528 0 40.96 18.432 40.96 40.96s-18.432 40.96-40.96 40.96z m0-307.2H593.92c-22.528 0-40.96-18.432-40.96-40.96s18.432-40.96 40.96-40.96h245.76c22.528 0 40.96 18.432 40.96 40.96s-18.432 40.96-40.96 40.96z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveBusinessFileMyCall.defaultProps = {
  size: 18,
};

export default IconAExecutiveBusinessFileMyCall;
