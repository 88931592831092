/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialContractsMyContracts: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M892.98944 0H130.90816C92.5696 0.12288 61.5424 32.80896 61.44 73.13408V950.8864c0.1024 40.3456 31.1296 73.0112 69.46816 73.13408h762.18368c38.31808-0.12288 69.36576-32.80896 69.46816-73.13408V73.1136C962.3552 32.78848 931.30752 0.12288 892.98944 0zM292.9664 182.96832h434.9952c20.09088 0 36.33152 16.34304 36.33152 36.57728s-16.24064 36.57728-36.31104 36.57728H292.9664c-20.0704 0-36.31104-16.34304-36.31104-36.57728s16.22016-36.57728 36.31104-36.57728z m0 146.16576h434.9952c20.09088 0 36.33152 16.34304 36.33152 36.57728s-16.24064 36.57728-36.31104 36.57728H292.9664c-20.0704 0-36.31104-16.34304-36.31104-36.57728s16.22016-36.57728 36.31104-36.57728z m145.34656 220.91776h-145.34656c-20.0704 0-36.31104-16.34304-36.31104-36.5568 0-20.23424 16.22016-36.57728 36.31104-36.57728h145.22368c20.0704 0 36.31104 16.34304 36.31104 36.57728 0 20.21376-16.11776 36.5568-36.18816 36.5568z m404.15232 124.1088l-97.36192 72.58112 34.48832 116.3264a11.18208 11.18208 0 0 1-4.07552 12.12416 11.14112 11.14112 0 0 1-6.47168 2.048 11.01824 11.01824 0 0 1-6.22592-2.048l-99.40992-69.8368-100.41344 68.7104a9.07264 9.07264 0 0 1-3.072 1.37216c-0.32768 0.1024-0.67584 0-1.1264 0.1024-0.67584 0-1.35168 0.22528-2.048 0.22528-0.67584-0.2048-1.45408-0.2048-2.1504-0.32768-0.32768 0-0.67584 0-1.024-0.24576-1.024-0.32768-2.048-0.90112-3.072-1.47456a11.18208 11.18208 0 0 1-3.95264-12.12416l35.7376-115.65056-96.44032-73.8304a10.8544 10.8544 0 0 1-3.74784-12.10368 11.24352 11.24352 0 0 1 10.32192-7.7824l62.17728-0.79872 18.39104-0.22528 18.37056-0.34816 15.6672-0.1024H614.4l3.85024-11.20256 36.53632-103.2192a11.10016 11.10016 0 0 1 10.32192-7.168c4.54656 0 8.64256 2.84672 10.3424 7.29088l35.51232 103.77216 3.74784 11.20256 7.24992 0.12288 15.6672 0.32768 18.14528 0.57344 18.39104 0.47104 62.17728 1.69984c4.75136 0.22528 8.84736 3.09248 10.19904 7.65952a11.10016 11.10016 0 0 1-4.096 11.8784z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialContractsMyContracts.defaultProps = {
  size: 18,
};

export default IconAFinancialContractsMyContracts;
