/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceUsersManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M129.59744 291.92192C129.59744 187.24864 216.576 102.4 323.8912 102.4c107.37664 0 194.3552 84.84864 194.3552 189.52192 0 104.6528-87.04 189.50144-194.3552 189.50144s-194.29376-84.84864-194.29376-189.50144z m267.10016 574.8736v-15.62624c0-115.32288 50.5856-216.65792 126.8736-276.3776-34.46784-19.82464-72.25344-30.14656-110.55104-30.18752h-161.9968C112.39424 544.60416 0 675.38944 0 836.75136v18.8416C0 921.6 112.39424 921.6 251.04384 921.6h154.86976a154.97216 154.97216 0 0 1-9.216-54.80448z m344.9856-635.61728c-89.04704 0-161.28 70.41024-161.28 157.2864 0 86.89664 72.23296 157.32736 161.28 157.32736 89.088 0 161.28-70.43072 161.28-157.30688 0-86.89664-72.13056-157.30688-161.28-157.30688z m0 0c-89.04704 0-161.28 70.41024-161.28 157.2864 0 86.89664 72.23296 157.32736 161.28 157.32736 89.088 0 161.28-70.43072 161.28-157.30688 0-86.89664-72.13056-157.30688-161.28-157.30688z m-60.416 367.08352c-115.0976 0-208.384 112.18944-208.384 250.5728v16.15872c0 56.56576 93.2864 56.56576 208.384 56.56576h134.4512c115.03616 0 208.2816-2.08896 208.2816-56.56576v-16.15872c0-138.38336-93.24544-250.5728-208.2816-250.5728h-134.4512z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceUsersManage.defaultProps = {
  size: 18,
};

export default IconAHumanResourceUsersManage;
