/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconKehuguanxi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M213.333333 341.333333c0-117.824 95.509333-213.333333 213.333334-213.333333s213.333333 95.509333 213.333333 213.333333-95.509333 213.333333-213.333333 213.333334-213.333333-95.509333-213.333334-213.333334z"
        fill={getIconColor(color, 0, '#000000')}
      />

      <path
        d="M85.333333 853.333333c0-188.522667 152.810667-341.333333 341.333334-341.333333s341.333333 152.810667 341.333333 341.333333a42.666667 42.666667 0 0 1-42.666667 42.666667H128a42.666667 42.666667 0 0 1-42.666667-42.666667zM746.666667 298.666667h192v85.333333h-192v-85.333333zM704 469.333333h234.666667v85.333334H704v-85.333334zM789.333333 640h149.333334v85.333333h-149.333334v-85.333333z"
        fill={getIconColor(color, 1, '#000000')}
      />
    </svg>
  );
};

IconKehuguanxi.defaultProps = {
  size: 18,
};

export default IconKehuguanxi;
