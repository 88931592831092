/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessBiddingManageChange: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M602.19392 126.99648c91.8528 53.6576 129.3312 177.664 78.25408 274.88256-17.12128 40.28416-44.31872 67.072-74.93632 87.20384 125.89056 26.78784 272.384 113.9712 272.384 365.4656 0 63.16032-75.53024 66.82624-314.63424 67.03104l-27.2384 0.02048h-65.1264c-242.03264-0.2048-314.61376-3.85024-314.61376-67.05152 0-248.1152 146.35008-338.57536 272.384-365.4656-98.7136-53.6576-132.77184-177.664-85.11488-278.26176 47.63648-100.57728 166.78912-137.48224 258.6624-83.82464z m-326.16448 13.7216c11.8784-1.00352 19.41504 12.3904 12.10368 21.6064a192.75776 192.75776 0 0 0-22.85568 37.02784c-43.29472 91.48416-19.06688 202.42432 59.98592 262.0416 8.9088 6.7584 6.16448 20.48-4.56704 23.63392-107.84768 31.39584-220.95872 113.19296-239.8208 301.32224a13.7216 13.7216 0 0 1-15.52384 12.04224C17.48992 791.7568 0 778.26048 0 750.24384c0-182.62016 97.792-259.93216 192.28672-288.95232a13.43488 13.43488 0 0 0 4.56704-23.40864c-61.2352-49.0496-79.52384-136.92928-45.01504-209.7152 24.6784-52.10112 72.99072-83.1488 124.19072-87.4496z m471.94112 0c51.2 4.3008 99.5328 35.34848 124.19072 87.4496 34.5088 72.78592 16.22016 160.6656-45.01504 209.7152a13.43488 13.43488 0 0 0 4.56704 23.40864C926.208 490.31168 1024 567.62368 1024 750.24384c0 28.01664-17.48992 41.51296-65.3312 48.14848a13.7216 13.7216 0 0 1-15.5648-12.04224c-18.8416-188.12928-131.95264-269.9264-239.80032-301.32224a13.4144 13.4144 0 0 1-4.56704-23.63392c79.0528-59.61728 103.28064-170.55744 59.98592-262.0416a192.75776 192.75776 0 0 0-22.85568-37.02784c-7.31136-9.216 0.22528-22.60992 12.10368-21.58592z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessBiddingManageChange.defaultProps = {
  size: 18,
};

export default IconABusinessBiddingManageChange;
