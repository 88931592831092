/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPanelRightContract24Regular: FunctionComponent<Props> = ({
  size = 18,
  color,
  style: _style,
  ...rest
}) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M392.234667 394.624a32 32 0 0 1 45.184-2.389333l106.666666 96a32 32 0 0 1 0 47.530666l-106.666666 96a32 32 0 0 1-42.837334-47.573333l44.714667-40.192H288a32 32 0 1 1 0-64h151.296l-44.714667-40.192a32 32 0 0 1-2.346666-45.226667zM938.666667 736A117.333333 117.333333 0 0 1 821.333333 853.333333H202.666667A117.333333 117.333333 0 0 1 85.333333 736V288A117.333333 117.333333 0 0 1 202.666667 170.666667h618.666666A117.333333 117.333333 0 0 1 938.666667 288v448z m-117.333334 53.333333c29.44 0 53.333333-23.893333 53.333334-53.333333V287.957333c0-29.44-23.893333-53.333333-53.333334-53.333333h-138.837333V789.333333h138.837333z m-202.837333 0v-554.666666H202.666667c-29.44 0-53.333333 23.893333-53.333334 53.333333v448c0 29.44 23.893333 53.333333 53.333334 53.333333h415.829333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentPanelRightContract24Regular;
