/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialElectronicInvoicesCreate: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M896.674994 0C956.353582 0 1003.518919 51.138447 1003.518919 112.844552v154.41886c0 61.726584-47.165336 112.844552-106.843925 112.844552h-4.77183v608.193222c0 29.081536-32.952248 45.916059-56.483716 28.876736l-93.880113-68.03441-93.880113 68.01393c-12.472293 9.03166-29.327295 9.03166-41.779109 0L512 949.163992l-93.880113 67.99345a35.594162 35.594162 0 0 1-41.779109 0l-93.880113-67.99345-93.880113 67.99345c-23.551948 17.059802-56.504196 0.22528-56.504196-28.876736V380.107964h-4.75135C67.646418 380.107964 20.481081 328.989996 20.481081 267.263412V112.844552C20.481081 51.138447 67.646418 0 127.325006 0h769.349988z m-182.845038 681.245221h-403.659912a35.635122 35.635122 0 0 0-2.928634 71.147364l2.928634 0.122879h403.659912a35.635122 35.635122 0 0 0 0-71.270243z m0-159.19069h-403.659912a35.635122 35.635122 0 0 0-2.928634 71.167844l2.928634 0.12288h403.659912a35.635122 35.635122 0 0 0 0-71.270244z m0-160.358047h-403.659912a35.635122 35.635122 0 0 0-2.928634 71.167844l2.928634 0.102399h403.659912a35.635122 35.635122 0 0 0 0-71.270243z m182.845038-290.405761H127.325006c-19.005398 0-35.614642 17.9814-35.614641 41.574309v154.41886c0 23.551948 16.609243 41.574309 35.635121 41.574309l4.73087-0.02048v-80.772943a71.270243 71.270243 0 0 1 71.249763-71.270243h617.347762a71.270243 71.270243 0 0 1 71.249763 71.270243l-0.02048 80.772943h4.77183c19.005398 0 35.614642-18.00188 35.614641-41.574309V112.844552c0-23.551948-16.609243-41.574309-35.635121-41.574309z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialElectronicInvoicesCreate.defaultProps = {
  size: 18,
};

export default IconAFinancialElectronicInvoicesCreate;
