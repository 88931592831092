/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZhongzhi: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M958.208 179.8912l-63.4112 48.384C808.3968 120.4224 673.9968 51.2 523.0848 51.2 262.4512 51.2 51.5072 256.9216 51.2 511.4624 50.8672 766.208 262.2464 972.8 523.0592 972.8c203.7504 0 377.3696-126.1824 443.4432-302.9248a8.7552 8.7552 0 0 0-5.504-11.2896l-63.7184-21.4016a9.0368 9.0368 0 0 0-11.3408 5.2736 375.04 375.04 0 0 1-89.4464 136.3712 388.096 388.096 0 0 1-122.8288 80.9728 392.1152 392.1152 0 0 1-150.3744 29.6192 391.68 391.68 0 0 1-150.3488-29.6192 384.384 384.384 0 0 1-122.8288-80.9728 375.04 375.04 0 0 1-82.816-120.0384 367.8208 367.8208 0 0 1-30.3616-146.8928c0-51.0208 10.24-100.4032 30.3616-146.9184A375.04 375.04 0 0 1 250.112 244.992a388.096 388.096 0 0 1 122.8288-80.9728 392.1152 392.1152 0 0 1 150.3488-29.6192 391.68 391.68 0 0 1 150.3744 29.6192 384.384 384.384 0 0 1 122.8032 80.9728c11.136 10.8544 21.5808 22.3744 31.232 34.432L760.064 331.008a8.704 8.704 0 0 0 3.3792 15.4624l197.3248 47.1808c5.632 1.3056 11.136-2.8672 11.136-8.448L972.8 186.7008c-0.1024-7.2448-8.7552-11.3152-14.592-6.8096z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IconZhongzhi;
