/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDesktopPulse24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M288 938.666667a32 32 0 0 1-4.352-63.701334l4.352-0.298666h74.624v-106.581334H181.333333a96 96 0 0 1-95.786666-89.429333L85.333333 672.085333V554.666667h64v117.418666c0 16.213333 12.032 29.568 27.648 31.701334l4.352 0.298666h661.290667a32 32 0 0 0 31.701333-27.648l0.298667-4.352V554.666667h64v117.418666a96 96 0 0 1-89.429333 95.786667l-6.570667 0.213333h-181.333333V874.666667h74.709333a32 32 0 0 1 4.352 63.701333L736 938.666667H288z m309.248-170.581334h-170.666667l0.042667 106.581334h170.666667l-0.042667-106.581334zM149.333333 405.333333H85.333333V224a96 96 0 0 1 89.429334-95.786667L181.333333 128h661.290667a96 96 0 0 1 95.786667 89.386667l0.213333 6.613333V405.333333h-64V224a32 32 0 0 0-27.648-31.744l-4.352-0.256H181.333333a32 32 0 0 0-31.701333 27.648L149.333333 224V405.333333z m264.362667-129.237333a32 32 0 0 0-58.88-1.194667L277.077333 448H117.333333a32 32 0 0 0 0 64h180.48a32 32 0 0 0 29.184-18.901333L382.293333 369.92l100.010667 249.941333a32 32 0 0 0 57.258667 4.437334l113.365333-191.914667 46.208 65.962667c5.973333 8.533333 15.786667 13.653333 26.197333 13.653333h181.333334a32 32 0 0 0 0-64h-164.693334l-65.109333-93.013333a32 32 0 0 0-53.76 2.048l-105.557333 178.688L413.696 276.053333z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default IcFluentDesktopPulse24Regular;
