/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceLawyerArchiveMyList: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M128.9216 275.53792h762.32704c75.776 0 87.9616-234.55744 87.9616-234.55744h0.02048V920.576a58.65472 58.65472 0 0 1-58.63424 58.65472H99.61472a58.65472 58.65472 0 0 1-58.63424-58.65472V40.96s12.1856 234.57792 87.94112 234.57792z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M40.96 40.96h938.25024l-0.8192 12.16512-1.20832 13.824-1.8432 17.73568-1.86368 15.31904-2.33472 16.60928-1.8432 11.61216-2.06848 11.8784-2.33472 12.0832c-11.8784 58.5728-32.9728 119.15264-69.77536 123.14624l-3.87072 0.2048H128.9216c-37.888 0-59.86304-58.65472-72.37632-117.28896l-2.4576-12.10368-2.21184-12.00128-1.9456-11.776-1.72032-11.40736-2.17088-16.24064-1.72032-14.78656-1.3312-12.98432-1.69984-21.03296L40.96 40.96z m870.13376 61.44H109.056l0.98304 6.71744c1.26976 8.31488 2.6624 16.4864 4.1984 24.49408l2.39616 11.81696c5.9392 27.81184 13.12768 50.25792 20.72576 64.4096l2.43712 4.23936h740.5568l1.31072-2.17088 1.14688-2.06848c7.59808-14.15168 14.80704-36.59776 20.74624-64.4096 2.47808-11.6736 4.66944-23.83872 6.59456-36.31104L911.09376 102.4z"
        fill={getIconColor(color, 1, '#333333')}
      />

      <path
        d="M552.96 163.84h-20.48l-20.48 68.25984L491.52 163.84h-20.48l30.72 102.4-30.72 102.4h20.48l20.48-68.25984L532.48 368.64h20.48l-30.72-102.4z"
        fill={getIconColor(color, 2, '#333333')}
      />

      <path
        d="M451.46112 392.8064a58.65472 58.65472 0 1 0 117.28896 0 58.65472 58.65472 0 0 0-117.28896 0z"
        fill={getIconColor(color, 3, '#333333')}
      />

      <path
        d="M471.04 163.84a40.96 40.96 0 1 0 81.92 0 40.96 40.96 0 0 0-81.92 0z"
        fill={getIconColor(color, 4, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceLawyerArchiveMyList.defaultProps = {
  size: 18,
};

export default IconAHumanResourceLawyerArchiveMyList;
