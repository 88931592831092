/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXiugaimima: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M995.82219 396.076651 879.73636 279.990821l116.085829-116.085829c37.495423-37.495423 37.495423-98.288002 0-135.783425-37.495423-37.495423-98.288002-37.495423-135.783425 0L523.579836 364.680483C472.886025 336.28395 414.293177 320.085927 352.000781 320.085927 157.624509 320.085927 0.043745 477.666691 0.043745 672.042964s157.580764 351.957036 351.957036 351.957036 351.957036-157.580764 351.957036-351.957036c0-62.292396-16.198023-120.885244-44.594556-171.579055l84.689662-84.689662 116.085829 116.085829c18.697718 18.697718 43.294715 28.09657 67.891712 28.09657s49.094007-9.398853 67.891712-28.09657C1033.317613 494.364653 1033.317613 433.572074 995.82219 396.076651zM465.086977 785.129159C434.890663 815.425461 394.695569 832.023435 352.000781 832.023435s-82.889882-16.597974-113.086196-46.894276C208.618284 754.932845 192.02031 714.737752 192.02031 672.042964s16.597974-82.889882 46.894276-113.086196C269.1109 528.660466 309.305993 512.062492 352.000781 512.062492s82.889882 16.597974 113.086196 46.894276C495.383278 589.153082 511.981252 629.348175 511.981252 672.042964S495.383278 754.932845 465.086977 785.129159z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconXiugaimima.defaultProps = {
  size: 18,
};

export default IconXiugaimima;
