/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconPagesTenantDashboard: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M553.984 128l342.016 0 0 256-342.016 0 0-256zM553.984 896l0-425.984 342.016 0 0 425.984-342.016 0zM128 896l0-256 342.016 0 0 256-342.016 0zM128 553.984l0-425.984 342.016 0 0 425.984-342.016 0z"
        fill={getIconColor(color, 0, '#444444')}
      />
    </svg>
  );
};

IconPagesTenantDashboard.defaultProps = {
  size: 18,
};

export default IconPagesTenantDashboard;
