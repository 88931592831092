/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentChannelArrowLeft24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M746.666667 512a234.666667 234.666667 0 1 1 0 469.333333 234.666667 234.666667 0 0 1 0-469.333333z m-554.666667-112.128v357.461333a74.666667 74.666667 0 0 0 68.565333 74.410667l6.101334 0.256h216.064c7.338667 22.784 17.578667 44.288 30.250666 64.042667L266.666667 896a138.666667 138.666667 0 0 1-138.453334-130.816L128 757.333333V399.872a95.872 95.872 0 0 0 64 0z m521.386667 222.592l-2.986667 2.432-107.306667 107.392-1.749333 2.133333-1.877333 3.285334-0.981334 2.517333-0.810666 3.626667-0.085334 4.693333 0.64 3.669333 1.237334 3.413334 1.450666 2.602666 2.133334 2.816 107.349333 107.392a21.333333 21.333333 0 0 0 32.64-27.221333l-2.474667-2.986667L670.336 768h204.373333a21.333333 21.333333 0 0 0 20.949334-17.493333L896 746.666667a21.333333 21.333333 0 0 0-17.493333-20.992L874.666667 725.333333h-204.330667l70.229333-70.229333a21.333333 21.333333 0 0 0 2.474667-27.221333l-2.474667-2.986667a21.333333 21.333333 0 0 0-27.221333-2.432z m-166.954667-67.669333c-18.176 18.944-33.706667 40.533333-45.909333 64H373.248a32 32 0 0 1-4.352-63.701334l4.352-0.298666h173.184zM757.333333 128a138.666667 138.666667 0 0 1 138.453334 130.816L896 266.666667v246.272a276.053333 276.053333 0 0 0-64-30.208V266.666667a74.666667 74.666667 0 0 0-68.522667-74.410667L757.333333 192H266.666667c-25.6 0-48.213333 12.885333-61.653334 32.554667a95.317333 95.317333 0 0 0-67.456-8.576 138.794667 138.794667 0 0 1 121.258667-87.765334L266.666667 128h490.666666z m-106.581333 277.162667a32 32 0 0 1 4.352 63.701333l-4.352 0.298667H373.248a32 32 0 0 1-4.352-63.701334l4.352-0.298666h277.504zM160 256a53.333333 53.333333 0 1 1 0 106.666667 53.333333 53.333333 0 0 1 0-106.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentChannelArrowLeft24Regular;
