/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWupinlingyong: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M981.184 358.144v535.701333c0 13.397333-2.730667 25.173333-8.192 35.242667-5.44 10.026667-11.52 18.474667-18.133333 25.173333-9.728 9.770667-19.584 16.597333-29.568 20.629334-9.962667 3.946667-20.138667 5.973333-30.464 5.973333H123.52c-23.616-0.618667-42.773333-8.853333-57.28-24.746667a83.584 83.584 0 0 1-22.741333-55.829333V358.144L129.877333 89.813333c0-1.792 6.997333-9.642667 20.906667-23.36 13.973333-13.738667 35.797333-21.504 65.472-23.381333H808.32c3.072-1.194667 15.893333 2.602667 38.656 11.456 22.762667 8.832 38.656 29.461333 47.786667 61.802667l86.4 241.792z m-112.768-66.858667l-53.674667-161.130666H203.52l-47.296 161.130666h712.170667z m-177.834667 403.392c11.84-11.925333 17.770667-28.074667 17.770667-48.554666 0-20.437333-5.930667-36.629333-17.770667-48.554667-11.84-11.946667-28.074667-18.154667-48.64-18.773333H376.405333c-20.629333 0.618667-36.864 6.912-48.64 18.773333-11.776 11.84-17.770667 28.053333-17.770666 48.554667 0 20.416 5.930667 36.629333 17.770666 48.554666 11.84 11.925333 28.010667 17.877333 48.64 17.877334h265.578667c20.544-0.064 36.778667-5.973333 48.64-17.877334z"
        fill={getIconColor(color, 0, '#FAAD14')}
      />
    </svg>
  );
};

IconWupinlingyong.defaultProps = {
  size: 18,
};

export default IconWupinlingyong;
