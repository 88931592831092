/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentAdd24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M501.333333 128a32 32 0 0 1 31.701334 27.648l0.298666 4.352 0.042667 309.333333h309.461333a32 32 0 0 1 4.352 63.701334l-4.352 0.298666h-309.461333l0.085333 309.333334a32 32 0 0 1-63.701333 4.309333l-0.298667-4.352-0.085333-309.290667H160.085333a32 32 0 0 1-4.352-63.701333L160.085333 469.333333h309.333334L469.333333 160a32 32 0 0 1 32-32z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentAdd24Regular;
