/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACustomersCustomerStorageManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M878.36672 198.08256v418.48832c0.47104 26.27584-23.28576 52.4288-51.97824 52.4288h-105.04192c-48.41472 0-52.3264 104.69376-209.34656 104.69376s-157.02016-104.67328-209.34656-104.67328H197.98016c-28.73344 0-52.34688-23.87968-52.34688-52.44928V198.08256c0-28.8768 26.17344-52.4288 52.34688-52.4288a52.34688 52.34688 0 1 0 0-104.69376H145.16224A104.16128 104.16128 0 0 0 40.96 145.16224v733.71648A104.16128 104.16128 0 0 0 145.16224 983.04h733.71648A104.16128 104.16128 0 0 0 983.04 878.83776V145.12128A104.16128 104.16128 0 0 0 878.83776 40.96h-52.81792a52.34688 52.34688 0 0 0 0 104.67328c26.17344 0 52.34688 23.8592 52.34688 52.4288z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M707.4816 386.56l-34.73408-26.33728a118.9888 118.9888 0 0 0 0.73728-12.06272c0-4.38272-0.36864-8.3968-0.73728-12.0832l34.73408-26.3168c9.48224-7.31136 12.04224-20.11136 5.8368-31.08864l-38.72768-65.45408a25.00608 25.00608 0 0 0-29.98272-10.97728l-41.28768 16.09728a140.12416 140.12416 0 0 0-21.56544-12.0832l-6.20544-42.04544A24.4736 24.4736 0 0 0 551.05536 143.36h-78.21312c-12.41088 0-22.65088 8.78592-24.4736 20.48l-5.85728 42.41408c-7.31136 3.29728-14.25408 7.33184-21.56544 12.0832l-41.28768-16.09728a24.55552 24.55552 0 0 0-8.76544-1.45408c-8.78592 0-17.18272 4.38272-21.1968 12.06272l-39.1168 65.82272c-6.20544 10.24-3.64544 23.7568 5.85728 31.08864l34.7136 26.33728c-0.36864 4.38272-0.73728 8.3968-0.73728 12.06272s0 7.68 0.73728 12.0832l-34.7136 26.3168c-9.50272 7.31136-12.06272 20.11136-5.85728 31.08864l38.74816 65.45408a25.00608 25.00608 0 0 0 29.96224 10.97728l41.30816-16.09728c6.94272 4.75136 14.25408 8.78592 21.54496 12.0832l6.22592 42.04544c1.45408 11.71456 12.06272 20.84864 24.4736 20.84864h78.2336c12.41088 0 22.6304-8.78592 24.4736-20.48l6.20544-42.41408c7.31136-3.29728 14.25408-7.33184 21.56544-12.0832l41.28768 16.09728c2.92864 1.10592 5.85728 1.45408 8.78592 1.45408 8.76544 0 17.16224-4.38272 21.1968-12.06272l39.09632-66.19136a23.79776 23.79776 0 0 0-6.20544-30.72zM599.7568 348.16A88.02304 88.02304 0 0 1 512 435.93728 88.02304 88.02304 0 0 1 424.22272 348.16 88.02304 88.02304 0 0 1 512 260.38272 88.02304 88.02304 0 0 1 599.77728 348.16z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconACustomersCustomerStorageManage.defaultProps = {
  size: 18,
};

export default IconACustomersCustomerStorageManage;
