/* tslint:disable */
import React, { FunctionComponent, SVGAttributes } from 'react';

import A20Filled2 from './A20Filled2';
import A20Regular1 from './A20Regular1';
import A24Filled1X from './A24Filled1X';
import A24Filled3 from './A24Filled3';
import A24Filled14 from './A24Filled14';
import A24Regular from './A24Regular';
import A24Regular1X101 from './A24Regular1X101';
import A24Regular2 from './A24Regular2';
import A24Regular101 from './A24Regular101';
import A30 from './A30';
import A30Xianxing from './A30Xianxing';
import A50 from './A50';
import A50Xianxing from './A50Xianxing';
import A100 from './A100';
import A100Xianxing from './A100Xianxing';
import AIcFluentBookGlobe24Filled1 from './AIcFluentBookGlobe24Filled1';
import AIcFluentBuilding24Regular1 from './AIcFluentBuilding24Regular1';
import AIcFluentCalendarLtr24Filled1 from './AIcFluentCalendarLtr24Filled1';
import AIcFluentCall24Regular2 from './AIcFluentCall24Regular2';
import AIcFluentColor24Regular1 from './AIcFluentColor24Regular1';
import AIcFluentInfo24Filled1 from './AIcFluentInfo24Filled1';
import AIcFluentLocation24Regular1 from './AIcFluentLocation24Regular1';
import AIcFluentMail24Regular2 from './AIcFluentMail24Regular2';
import AIcFluentPeopleCommunity24Regular1 from './AIcFluentPeopleCommunity24Regular1';
import AIcFluentPin24Filled2 from './AIcFluentPin24Filled2';
import AIcFluentPin24Regular1 from './AIcFluentPin24Regular1';
import AIcFluentPositionToFront24Filled1 from './AIcFluentPositionToFront24Filled1';
import AIcFluentPositionToFront24Regular1 from './AIcFluentPositionToFront24Regular1';
import AIcFluentPositionToFront24Regular2 from './AIcFluentPositionToFront24Regular2';
import ALtr16Filled from './ALtr16Filled';
import ATianjiaxiaji1X from './ATianjiaxiaji1X';
import Album24Filled from './Album24Filled';
import Aliyunpan01 from './Aliyunpan01';
import Anquanguanli from './Anquanguanli';
import Apps24Filled from './Apps24Filled';
import Apps24Regular from './Apps24Regular';
import Archive24Filled from './Archive24Filled';
import ArrowSyncCircle24Filled from './ArrowSyncCircle24Filled';
import Banbenfenfa from './Banbenfenfa';
import BanbenfenfaXianxing from './BanbenfenfaXianxing';
import Baoxiaoliebiao from './Baoxiaoliebiao';
import Beisen01 from './Beisen01';
import Beizhu from './Beizhu';
import Bencishoukuan from './Bencishoukuan';
import Biangengliebiao from './Biangengliebiao';
import Biangengshenhe from './Biangengshenhe';
import Biangengshenqing from './Biangengshenqing';
import Bianji from './Bianji';
import Biankuang from './Biankuang';
import Bianmaguize from './Bianmaguize';
import BianmaguizeXianxing from './BianmaguizeXianxing';
import BookContacts24Filled from './BookContacts24Filled';
import BookExclamationMark24Filled from './BookExclamationMark24Filled';
import BookQuestionMark24Filled from './BookQuestionMark24Filled';
import BookToolbox24Filled from './BookToolbox24Filled';
import BranchFork24Filled from './BranchFork24Filled';
import Briefcase24Filled from './Briefcase24Filled';
import Building24Filled from './Building24Filled';
import BuildingRetailToolbox24Filled from './BuildingRetailToolbox24Filled';
import Bumen from './Bumen';
import ChannelArrowLeft24Filled from './ChannelArrowLeft24Filled';
import ChannelNetWecom from './ChannelNetWecom';
import Chongmanyouce from './Chongmanyouce';
import ChongmanyouceXianxing from './ChongmanyouceXianxing';
import ClipboardCode24Filled from './ClipboardCode24Filled';
import Close from './Close';
import CloudBackup24Filled from './CloudBackup24Filled';
import ContentSettings24Filled from './ContentSettings24Filled';
import Csrnote from './Csrnote';
import Daochuzhongxin from './Daochuzhongxin';
import DaochuzhongxinXianxing from './DaochuzhongxinXianxing';
import DataPie24Filled from './DataPie24Filled';
import Desktop24Filled from './Desktop24Filled';
import DesktopPulseFilled from './DesktopPulseFilled';
import Dingding01 from './Dingding01';
import Dingding02 from './Dingding02';
import Dismiss24Filled from './Dismiss24Filled';
import Dismiss24Regular from './Dismiss24Regular';
import DocumentLandscapeData24Filled from './DocumentLandscapeData24Filled';
import DocumentLandscapeData24Regular from './DocumentLandscapeData24Regular';
import Fanyi01 from './Fanyi01';
import Fapiaoguanli from './Fapiaoguanli';
import FapiaoguanliXianxing from './FapiaoguanliXianxing';
import Fapiaoliebiao from './Fapiaoliebiao';
import Feishu from './Feishu';
import Female from './Female';
import Fenpeijiaose from './Fenpeijiaose';
import Fenpeiliebiao from './Fenpeiliebiao';
import FluentCube24Filled from './FluentCube24Filled';
import FluentStarFilled from './FluentStarFilled';
import FluentStarRegular from './FluentStarRegular';
import Fukuanfangguanli from './Fukuanfangguanli';
import Gonggonganjian from './Gonggonganjian';
import Gongshibaobiao from './Gongshibaobiao';
import GongshibaobiaoXianxing from './GongshibaobiaoXianxing';
import Gongshiliebiao from './Gongshiliebiao';
import GongshiliebiaoXianxing from './GongshiliebiaoXianxing';
import Gongshirili from './Gongshirili';
import GongshiriliXianxing from './GongshiriliXianxing';
import Gongshishezhi from './Gongshishezhi';
import GongshishezhiXianxing from './GongshishezhiXianxing';
import Gongzuojiaojie from './Gongzuojiaojie';
import Grid24Regular from './Grid24Regular';
import GridDots24Regular from './GridDots24Regular';
import Guanbikapian from './Guanbikapian';
import Guanlianzhangdan1 from './Guanlianzhangdan1';
import Guanlianzhangdan2 from './Guanlianzhangdan2';
import Guanlianzhangdan3 from './Guanlianzhangdan3';
import Guanlianzhangdan4 from './Guanlianzhangdan4';
import Guanlianzhangdan5 from './Guanlianzhangdan5';
import Guanlianzhangdan6 from './Guanlianzhangdan6';
import Guanlianzhangdan7 from './Guanlianzhangdan7';
import Guanlianzhangdan8 from './Guanlianzhangdan8';
import Guanlianzhangdan9 from './Guanlianzhangdan9';
import Hangfengexian from './Hangfengexian';
import Hanggao from './Hanggao';
import Hangliefengexian from './Hangliefengexian';
import Hexiaopingzheng from './Hexiaopingzheng';
import HomeFilled from './HomeFilled';
import Huishuai1 from './Huishuai1';
import Huishuai2 from './Huishuai2';
import IcFluentAdd24Filled from './IcFluentAdd24Filled';
import IcFluentAdd24Regular from './IcFluentAdd24Regular';
import IcFluentAddCircle24Filled from './IcFluentAddCircle24Filled';
import IcFluentAddCircle24Regular from './IcFluentAddCircle24Regular';
import IcFluentAddSquare24Filled from './IcFluentAddSquare24Filled';
import IcFluentAddSquare24Regular from './IcFluentAddSquare24Regular';
import IcFluentAlbum24Regular from './IcFluentAlbum24Regular';
import IcFluentAppsListDetail24Filled from './IcFluentAppsListDetail24Filled';
import IcFluentAppsListDetail24Regular from './IcFluentAppsListDetail24Regular';
import IcFluentArchive24Regular from './IcFluentArchive24Regular';
import IcFluentArrowCounterclockwise24Regular from './IcFluentArrowCounterclockwise24Regular';
import IcFluentArrowReply24Regular from './IcFluentArrowReply24Regular';
import IcFluentArrowSyncCircle24Regular from './IcFluentArrowSyncCircle24Regular';
import IcFluentBookContacts24Regular from './IcFluentBookContacts24Regular';
import IcFluentBookExclamationMark24Regular from './IcFluentBookExclamationMark24Regular';
import IcFluentBookGlobe24Regular from './IcFluentBookGlobe24Regular';
import IcFluentBookQuestionMark24Regular from './IcFluentBookQuestionMark24Regular';
import IcFluentBookQuestionMark24Regular1 from './IcFluentBookQuestionMark24Regular1';
import IcFluentBookToolbox24Regular from './IcFluentBookToolbox24Regular';
import IcFluentBorder24Regular from './IcFluentBorder24Regular';
import IcFluentBorderLeft24Regular from './IcFluentBorderLeft24Regular';
import IcFluentBorderRight24Regular from './IcFluentBorderRight24Regular';
import IcFluentBranch24Filled from './IcFluentBranch24Filled';
import IcFluentBranch24Regular from './IcFluentBranch24Regular';
import IcFluentBranchFork24Regular from './IcFluentBranchFork24Regular';
import IcFluentBuilding24Regular from './IcFluentBuilding24Regular';
import IcFluentBuildingRetail24Regular from './IcFluentBuildingRetail24Regular';
import IcFluentBuildingRetailToolbox24Regular from './IcFluentBuildingRetailToolbox24Regular';
import IcFluentCalendarClock24Filled from './IcFluentCalendarClock24Filled';
import IcFluentCalendarClock24Regular from './IcFluentCalendarClock24Regular';
import IcFluentCalendarEdit24Filled from './IcFluentCalendarEdit24Filled';
import IcFluentCalendarEdit24Regular from './IcFluentCalendarEdit24Regular';
import IcFluentCalendarLtr24Regular from './IcFluentCalendarLtr24Regular';
import IcFluentCaretDown24Filled from './IcFluentCaretDown24Filled';
import IcFluentCaretRight24Filled from './IcFluentCaretRight24Filled';
import IcFluentChannelArrowLeft24Regular from './IcFluentChannelArrowLeft24Regular';
import IcFluentCheckboxUnchecked24Filled from './IcFluentCheckboxUnchecked24Filled';
import IcFluentCheckboxUnchecked24Regular from './IcFluentCheckboxUnchecked24Regular';
import IcFluentCheckmarkCircle24Filled from './IcFluentCheckmarkCircle24Filled';
import IcFluentCheckmarkCircle24Regular from './IcFluentCheckmarkCircle24Regular';
import IcFluentCheckmarkSquare24Filled from './IcFluentCheckmarkSquare24Filled';
import IcFluentClipboardCode24Regular from './IcFluentClipboardCode24Regular';
import IcFluentClipboardTaskListRtl24Filled from './IcFluentClipboardTaskListRtl24Filled';
import IcFluentClipboardTaskListRtl24Regular from './IcFluentClipboardTaskListRtl24Regular';
import IcFluentClock24Filled from './IcFluentClock24Filled';
import IcFluentCloudBackup24Regular from './IcFluentCloudBackup24Regular';
import IcFluentColor24Filled from './IcFluentColor24Filled';
import IcFluentContentSettings24Regular from './IcFluentContentSettings24Regular';
import IcFluentCopy24Regular10101 from './IcFluentCopy24Regular10101';
import IcFluentCopy24Regular101011 from './IcFluentCopy24Regular101011';
import IcFluentCube24Regular from './IcFluentCube24Regular';
import IcFluentDataPie24Regular from './IcFluentDataPie24Regular';
import IcFluentDesktop24Regular from './IcFluentDesktop24Regular';
import IcFluentDesktopPulse24Regular from './IcFluentDesktopPulse24Regular';
import IcFluentDismissCircle24Filled from './IcFluentDismissCircle24Filled';
import IcFluentDismissCircle24Regular from './IcFluentDismissCircle24Regular';
import IcFluentDocumentBulletListClock24Filled from './IcFluentDocumentBulletListClock24Filled';
import IcFluentDocumentBulletListClock24Regular from './IcFluentDocumentBulletListClock24Regular';
import IcFluentDocumentCopy24Regular from './IcFluentDocumentCopy24Regular';
import IcFluentDocumentLandscapeData24Regular from './IcFluentDocumentLandscapeData24Regular';
import IcFluentDocumentMultiple24Filled from './IcFluentDocumentMultiple24Filled';
import IcFluentDocumentMultiple24Regular from './IcFluentDocumentMultiple24Regular';
import IcFluentDocumentText24Regular from './IcFluentDocumentText24Regular';
import IcFluentDualScreenSettings24Filled from './IcFluentDualScreenSettings24Filled';
import IcFluentDualScreenSettings24Regular01 from './IcFluentDualScreenSettings24Regular01';
import IcFluentEarth24Regular from './IcFluentEarth24Regular';
import IcFluentFolderAdd24Filled from './IcFluentFolderAdd24Filled';
import IcFluentFolderAdd24Regular01 from './IcFluentFolderAdd24Regular01';
import IcFluentFolderZip24Filled from './IcFluentFolderZip24Filled';
import IcFluentFolderZip24Regular from './IcFluentFolderZip24Regular';
import IcFluentGlobe24Filled from './IcFluentGlobe24Filled';
import IcFluentGrid24Filled from './IcFluentGrid24Filled';
import IcFluentGrid24Regular from './IcFluentGrid24Regular';
import IcFluentGridDots24Filled from './IcFluentGridDots24Filled';
import IcFluentHomeRegular from './IcFluentHomeRegular';
import IcFluentInfo24Regular from './IcFluentInfo24Regular';
import IcFluentLibrary24Regular from './IcFluentLibrary24Regular';
import IcFluentMoney24Filled from './IcFluentMoney24Filled';
import IcFluentMoney24Regular from './IcFluentMoney24Regular';
import IcFluentNews24Filled from './IcFluentNews24Filled';
import IcFluentNews24Regular from './IcFluentNews24Regular';
import IcFluentNotebookError24Regular from './IcFluentNotebookError24Regular';
import IcFluentNotepad24Regular from './IcFluentNotepad24Regular';
import IcFluentPanelRightContract24Regular from './IcFluentPanelRightContract24Regular';
import IcFluentPanelRightExpand24Regular from './IcFluentPanelRightExpand24Regular';
import IcFluentPeople24Regular from './IcFluentPeople24Regular';
import IcFluentPeopleCommunity24Filled from './IcFluentPeopleCommunity24Filled';
import IcFluentPeopleMoney24Regular from './IcFluentPeopleMoney24Regular';
import IcFluentPeopleSettings24Regular from './IcFluentPeopleSettings24Regular';
import IcFluentPeopleSwap24Regular from './IcFluentPeopleSwap24Regular';
import IcFluentPerson24Filled from './IcFluentPerson24Filled';
import IcFluentPerson24Regular from './IcFluentPerson24Regular';
import IcFluentPersonAccounts24Filled from './IcFluentPersonAccounts24Filled';
import IcFluentPersonAccounts24Regular from './IcFluentPersonAccounts24Regular';
import IcFluentPersonNote24Regular from './IcFluentPersonNote24Regular';
import IcFluentPositionToFront24Filled from './IcFluentPositionToFront24Filled';
import IcFluentReOrderDotsHorizontal24Regular from './IcFluentReOrderDotsHorizontal24Regular';
import IcFluentSearch24Filled from './IcFluentSearch24Filled';
import IcFluentSearch24Regular from './IcFluentSearch24Regular';
import IcFluentSelectAllOff24Regular from './IcFluentSelectAllOff24Regular';
import IcFluentSelectAllOn24Filled from './IcFluentSelectAllOn24Filled';
import IcFluentSend24Regular from './IcFluentSend24Regular';
import IcFluentShieldKeyhole24Filled from './IcFluentShieldKeyhole24Filled';
import IcFluentShieldKeyhole24Regular from './IcFluentShieldKeyhole24Regular';
import IcFluentShieldTask24Regular from './IcFluentShieldTask24Regular';
import IcFluentSignOut24Filled from './IcFluentSignOut24Filled';
import IcFluentSignOut24Regular from './IcFluentSignOut24Regular';
import IcFluentSlideLayout24Filled from './IcFluentSlideLayout24Filled';
import IcFluentSlideLayout24Regular from './IcFluentSlideLayout24Regular';
import IcFluentSlideSearch24Filled from './IcFluentSlideSearch24Filled';
import IcFluentSlideSearch24Regular from './IcFluentSlideSearch24Regular';
import IcFluentSpeaker224Regular from './IcFluentSpeaker224Regular';
import IcFluentStarSettings24Regular from './IcFluentStarSettings24Regular';
import IcFluentStoreMicrosoft24Regular from './IcFluentStoreMicrosoft24Regular';
import IcFluentSubtractCircle24Regular from './IcFluentSubtractCircle24Regular';
import IcFluentWallet24Filled from './IcFluentWallet24Filled';
import IcFluentWallet24Regular from './IcFluentWallet24Regular';
import IcFluentWarning24Filled from './IcFluentWarning24Filled';
import IcFluentWarning24Regular from './IcFluentWarning24Regular';
import IcFluentWhiteboard24Filled from './IcFluentWhiteboard24Filled';
import InformationIcon from './InformationIcon';
import Jieanshenqing from './Jieanshenqing';
import Jincoude from './Jincoude';
import Jishiqi from './Jishiqi';
import Kaishijishi from './Kaishijishi';
import Kapiancaozuo from './Kapiancaozuo';
import Kehuliebiao from './Kehuliebiao';
import Kuaisuchuangjian from './Kuaisuchuangjian';
import KuaisuchuangjianXianxing from './KuaisuchuangjianXianxing';
import Kuajingzhangdan from './Kuajingzhangdan';
import Kuansongde from './Kuansongde';
import Ldap01 from './Ldap01';
import Lianhezhangdan from './Lianhezhangdan';
import Library24Filled from './Library24Filled';
import Lichongbaohumingdan from './Lichongbaohumingdan';
import Lichongshenqing from './Lichongshenqing';
import Lichongyujian from './Lichongyujian';
import Liefengexian from './Liefengexian';
import LiuchengShenpijiedian from './LiuchengShenpijiedian';
import Male from './Male';
import Miaoshu from './Miaoshu';
import Neibuhuazhuan from './Neibuhuazhuan';
import Noteadd from './Noteadd';
import NotebookError24Filled from './NotebookError24Filled';
import Pdf from './Pdf';
import People24Filled from './People24Filled';
import PeopleMoney24Filled from './PeopleMoney24Filled';
import PeopleSettings24Filled from './PeopleSettings24Filled';
import PeopleSwap24Filled from './PeopleSwap24Filled';
import PersonNote24Filled from './PersonNote24Filled';
import Pingzhengguanli from './Pingzhengguanli';
import PingzhengguanliXianxing from './PingzhengguanliXianxing';
import Pinxiejiancha from './Pinxiejiancha';
import Png from './Png';
import Ppt from './Ppt';
import Putongzhangdan from './Putongzhangdan';
import Qichacha01 from './Qichacha01';
import Qiyeweixin01 from './Qiyeweixin01';
import Renliziyuan from './Renliziyuan';
import RenliziyuanXianxing from './RenliziyuanXianxing';
import Renyuanguanli from './Renyuanguanli';
import RenyuanguanliXianxing from './RenyuanguanliXianxing';
import Send24Filled from './Send24Filled';
import Settings24Filled from './Settings24Filled';
import Settings24Regular from './Settings24Regular';
import Shanchu from './Shanchu';
import Shenchafenpei from './Shenchafenpei';
import Shenjirizhi from './Shenjirizhi';
import Shipin from './Shipin';
import Shoukuandengji from './Shoukuandengji';
import Shoukuanguanli from './Shoukuanguanli';
import ShoukuanguanliXianxing from './ShoukuanguanliXianxing';
import Shoukuanpingzheng from './Shoukuanpingzheng';
import Shoukuanrenling from './Shoukuanrenling';
import Shuaxin from './Shuaxin';
import Shujuzhongxin from './Shujuzhongxin';
import Shujuzidian from './Shujuzidian';
import ShujuzidianXianxing from './ShujuzidianXianxing';
import Speaker224Filled from './Speaker224Filled';
import StarSettings24Filled from './StarSettings24Filled';
import StoreMicrosoft24Filled from './StoreMicrosoft24Filled';
import Tejincou from './Tejincou';
import Tekuansong from './Tekuansong';
import Tianjiagongshimiaoshu from './Tianjiagongshimiaoshu';
import Tijiao from './Tijiao';
import Tishi from './Tishi';
import Tongbuliebiao from './Tongbuliebiao';
import Tupian from './Tupian';
import Txt from './Txt';
import Weixin1 from './Weixin1';
import Weizhi from './Weizhi';
import Wenjianjia from './Wenjianjia';
import Wodegongshi from './Wodegongshi';
import WodegongshiXianxing from './WodegongshiXianxing';
import Writeoff from './Writeoff';
import Wubanmawentianchong from './Wubanmawentianchong';
import Wufengexian from './Wufengexian';
import Xiazai from './Xiazai';
import Xls from './Xls';
import Yewuanjian from './Yewuanjian';
import Yichuzhangdanbiaoshi from './Yichuzhangdanbiaoshi';
import Yidongbumen from './Yidongbumen';
import Yingshoujine from './Yingshoujine';
import Yingshoupingzheng from './Yingshoupingzheng';
import Yishoujine from './Yishoujine';
import Youbanmawentianchong from './Youbanmawentianchong';
import Youjian01 from './Youjian01';
import Youjianmoban from './Youjianmoban';
import YoujianmobanXianxing from './YoujianmobanXianxing';
import Zantingjishi from './Zantingjishi';
import Zhangdanguanli from './Zhangdanguanli';
import ZhangdanguanliXianxing from './ZhangdanguanliXianxing';
import Zhangdanliebiao from './Zhangdanliebiao';
import Zhangdanshenqing from './Zhangdanshenqing';
import Zhiweishezhi from './Zhiweishezhi';
import Zhongdengde from './Zhongdengde';
import Zuzhijigou from './Zuzhijigou';

export { default as Yichuzhangdanbiaoshi } from './Yichuzhangdanbiaoshi';
export { default as Yewuanjian } from './Yewuanjian';
export { default as Gonggonganjian } from './Gonggonganjian';
export { default as Guanlianzhangdan1 } from './Guanlianzhangdan1';
export { default as Guanlianzhangdan4 } from './Guanlianzhangdan4';
export { default as Guanlianzhangdan5 } from './Guanlianzhangdan5';
export { default as Guanlianzhangdan8 } from './Guanlianzhangdan8';
export { default as Guanlianzhangdan2 } from './Guanlianzhangdan2';
export { default as Guanlianzhangdan6 } from './Guanlianzhangdan6';
export { default as Guanlianzhangdan9 } from './Guanlianzhangdan9';
export { default as Guanlianzhangdan3 } from './Guanlianzhangdan3';
export { default as Guanlianzhangdan7 } from './Guanlianzhangdan7';
export { default as YoujianmobanXianxing } from './YoujianmobanXianxing';
export { default as Youjianmoban } from './Youjianmoban';
export { default as GongshibaobiaoXianxing } from './GongshibaobiaoXianxing';
export { default as IcFluentSubtractCircle24Regular } from './IcFluentSubtractCircle24Regular';
export { default as Writeoff } from './Writeoff';
export { default as Noteadd } from './Noteadd';
export { default as Csrnote } from './Csrnote';
export { default as Tishi } from './Tishi';
export { default as Yishoujine } from './Yishoujine';
export { default as Bencishoukuan } from './Bencishoukuan';
export { default as Yingshoujine } from './Yingshoujine';
export { default as Huishuai1 } from './Huishuai1';
export { default as Huishuai2 } from './Huishuai2';
export { default as Beizhu } from './Beizhu';
export { default as Xls } from './Xls';
export { default as Pdf } from './Pdf';
export { default as Ppt } from './Ppt';
export { default as Shipin } from './Shipin';
export { default as Wenjianjia } from './Wenjianjia';
export { default as Tupian } from './Tupian';
export { default as Weizhi } from './Weizhi';
export { default as Txt } from './Txt';
export { default as Png } from './Png';
export { default as Lichongshenqing } from './Lichongshenqing';
export { default as Biangengshenqing } from './Biangengshenqing';
export { default as Baoxiaoliebiao } from './Baoxiaoliebiao';
export { default as Biangengliebiao } from './Biangengliebiao';
export { default as Kehuliebiao } from './Kehuliebiao';
export { default as Gongshibaobiao } from './Gongshibaobiao';
export { default as Fenpeiliebiao } from './Fenpeiliebiao';
export { default as Biangengshenhe } from './Biangengshenhe';
export { default as Fapiaoliebiao } from './Fapiaoliebiao';
export { default as Zhangdanshenqing } from './Zhangdanshenqing';
export { default as Shoukuanpingzheng } from './Shoukuanpingzheng';
export { default as Shoukuanrenling } from './Shoukuanrenling';
export { default as Hexiaopingzheng } from './Hexiaopingzheng';
export { default as Yingshoupingzheng } from './Yingshoupingzheng';
export { default as Lichongbaohumingdan } from './Lichongbaohumingdan';
export { default as Tongbuliebiao } from './Tongbuliebiao';
export { default as Shoukuandengji } from './Shoukuandengji';
export { default as Zhangdanliebiao } from './Zhangdanliebiao';
export { default as Neibuhuazhuan } from './Neibuhuazhuan';
export { default as Fukuanfangguanli } from './Fukuanfangguanli';
export { default as Jieanshenqing } from './Jieanshenqing';
export { default as Lichongyujian } from './Lichongyujian';
export { default as Kuajingzhangdan } from './Kuajingzhangdan';
export { default as Lianhezhangdan } from './Lianhezhangdan';
export { default as Putongzhangdan } from './Putongzhangdan';
export { default as Tijiao } from './Tijiao';
export { default as Bianji } from './Bianji';
export { default as Kaishijishi } from './Kaishijishi';
export { default as Tianjiagongshimiaoshu } from './Tianjiagongshimiaoshu';
export { default as Miaoshu } from './Miaoshu';
export { default as InformationIcon } from './InformationIcon';
export { default as Zantingjishi } from './Zantingjishi';
export { default as Zuzhijigou } from './Zuzhijigou';
export { default as Bumen } from './Bumen';
export { default as Close } from './Close';
export { default as IcFluentPerson24Filled } from './IcFluentPerson24Filled';
export { default as LiuchengShenpijiedian } from './LiuchengShenpijiedian';
export { default as Daochuzhongxin } from './Daochuzhongxin';
export { default as DaochuzhongxinXianxing } from './DaochuzhongxinXianxing';
export { default as Pinxiejiancha } from './Pinxiejiancha';
export { default as Shujuzidian } from './Shujuzidian';
export { default as ShujuzidianXianxing } from './ShujuzidianXianxing';
export { default as Bianmaguize } from './Bianmaguize';
export { default as BianmaguizeXianxing } from './BianmaguizeXianxing';
export { default as Renyuanguanli } from './Renyuanguanli';
export { default as RenyuanguanliXianxing } from './RenyuanguanliXianxing';
export { default as GongshishezhiXianxing } from './GongshishezhiXianxing';
export { default as Gongshishezhi } from './Gongshishezhi';
export { default as GongshiriliXianxing } from './GongshiriliXianxing';
export { default as Gongshirili } from './Gongshirili';
export { default as Gongshiliebiao } from './Gongshiliebiao';
export { default as GongshiliebiaoXianxing } from './GongshiliebiaoXianxing';
export { default as Wodegongshi } from './Wodegongshi';
export { default as WodegongshiXianxing } from './WodegongshiXianxing';
export { default as IcFluentDocumentBulletListClock24Regular } from './IcFluentDocumentBulletListClock24Regular';
export { default as IcFluentDocumentBulletListClock24Filled } from './IcFluentDocumentBulletListClock24Filled';
export { default as IcFluentCalendarEdit24Regular } from './IcFluentCalendarEdit24Regular';
export { default as IcFluentCalendarEdit24Filled } from './IcFluentCalendarEdit24Filled';
export { default as ATianjiaxiaji1X } from './ATianjiaxiaji1X';
export { default as Jishiqi } from './Jishiqi';
export { default as Kapiancaozuo } from './Kapiancaozuo';
export { default as Zhiweishezhi } from './Zhiweishezhi';
export { default as Fenpeijiaose } from './Fenpeijiaose';
export { default as Gongzuojiaojie } from './Gongzuojiaojie';
export { default as Guanbikapian } from './Guanbikapian';
export { default as Yidongbumen } from './Yidongbumen';
export { default as Shenchafenpei } from './Shenchafenpei';
export { default as IcFluentArrowReply24Regular } from './IcFluentArrowReply24Regular';
export { default as Female } from './Female';
export { default as Male } from './Male';
export { default as Ldap01 } from './Ldap01';
export { default as Fanyi01 } from './Fanyi01';
export { default as Aliyunpan01 } from './Aliyunpan01';
export { default as Qiyeweixin01 } from './Qiyeweixin01';
export { default as Youjian01 } from './Youjian01';
export { default as Dingding02 } from './Dingding02';
export { default as Beisen01 } from './Beisen01';
export { default as Qichacha01 } from './Qichacha01';
export { default as IcFluentBorderRight24Regular } from './IcFluentBorderRight24Regular';
export { default as IcFluentBorder24Regular } from './IcFluentBorder24Regular';
export { default as IcFluentBorderLeft24Regular } from './IcFluentBorderLeft24Regular';
export { default as IcFluentReOrderDotsHorizontal24Regular } from './IcFluentReOrderDotsHorizontal24Regular';
export { default as IcFluentDocumentText24Regular } from './IcFluentDocumentText24Regular';
export { default as IcFluentDocumentCopy24Regular } from './IcFluentDocumentCopy24Regular';
export { default as IcFluentCaretRight24Filled } from './IcFluentCaretRight24Filled';
export { default as AIcFluentMail24Regular2 } from './AIcFluentMail24Regular2';
export { default as AIcFluentCall24Regular2 } from './AIcFluentCall24Regular2';
export { default as AIcFluentLocation24Regular1 } from './AIcFluentLocation24Regular1';
export { default as AIcFluentBuilding24Regular1 } from './AIcFluentBuilding24Regular1';
export { default as IcFluentPerson24Regular } from './IcFluentPerson24Regular';
export { default as A24Regular2 } from './A24Regular2';
export { default as A24Filled14 } from './A24Filled14';
export { default as IcFluentCaretDown24Filled } from './IcFluentCaretDown24Filled';
export { default as Hangliefengexian } from './Hangliefengexian';
export { default as Wufengexian } from './Wufengexian';
export { default as Wubanmawentianchong } from './Wubanmawentianchong';
export { default as Youbanmawentianchong } from './Youbanmawentianchong';
export { default as Hangfengexian } from './Hangfengexian';
export { default as Liefengexian } from './Liefengexian';
export { default as Tejincou } from './Tejincou';
export { default as Zhongdengde } from './Zhongdengde';
export { default as Jincoude } from './Jincoude';
export { default as Tekuansong } from './Tekuansong';
export { default as Kuansongde } from './Kuansongde';
export { default as A100Xianxing } from './A100Xianxing';
export { default as A100 } from './A100';
export { default as Chongmanyouce } from './Chongmanyouce';
export { default as ChongmanyouceXianxing } from './ChongmanyouceXianxing';
export { default as A50Xianxing } from './A50Xianxing';
export { default as A50 } from './A50';
export { default as A30Xianxing } from './A30Xianxing';
export { default as A30 } from './A30';
export { default as KuaisuchuangjianXianxing } from './KuaisuchuangjianXianxing';
export { default as Kuaisuchuangjian } from './Kuaisuchuangjian';
export { default as ShoukuanguanliXianxing } from './ShoukuanguanliXianxing';
export { default as Shoukuanguanli } from './Shoukuanguanli';
export { default as PingzhengguanliXianxing } from './PingzhengguanliXianxing';
export { default as Pingzhengguanli } from './Pingzhengguanli';
export { default as Fapiaoguanli } from './Fapiaoguanli';
export { default as FapiaoguanliXianxing } from './FapiaoguanliXianxing';
export { default as Renliziyuan } from './Renliziyuan';
export { default as RenliziyuanXianxing } from './RenliziyuanXianxing';
export { default as IcFluentMoney24Regular } from './IcFluentMoney24Regular';
export { default as IcFluentMoney24Filled } from './IcFluentMoney24Filled';
export { default as Zhangdanguanli } from './Zhangdanguanli';
export { default as ZhangdanguanliXianxing } from './ZhangdanguanliXianxing';
export { default as IcFluentPersonAccounts24Filled } from './IcFluentPersonAccounts24Filled';
export { default as IcFluentPersonAccounts24Regular } from './IcFluentPersonAccounts24Regular';
export { default as IcFluentBranch24Regular } from './IcFluentBranch24Regular';
export { default as IcFluentBranch24Filled } from './IcFluentBranch24Filled';
export { default as IcFluentCalendarClock24Regular } from './IcFluentCalendarClock24Regular';
export { default as IcFluentCalendarClock24Filled } from './IcFluentCalendarClock24Filled';
export { default as A24Regular1X101 } from './A24Regular1X101';
export { default as A24Filled1X } from './A24Filled1X';
export { default as IcFluentFolderAdd24Regular01 } from './IcFluentFolderAdd24Regular01';
export { default as IcFluentFolderAdd24Filled } from './IcFluentFolderAdd24Filled';
export { default as IcFluentFolderZip24Filled } from './IcFluentFolderZip24Filled';
export { default as IcFluentFolderZip24Regular } from './IcFluentFolderZip24Regular';
export { default as AIcFluentPositionToFront24Regular2 } from './AIcFluentPositionToFront24Regular2';
export { default as AIcFluentPositionToFront24Filled1 } from './AIcFluentPositionToFront24Filled1';
export { default as DocumentLandscapeData24Regular } from './DocumentLandscapeData24Regular';
export { default as DocumentLandscapeData24Filled } from './DocumentLandscapeData24Filled';
export { default as AIcFluentColor24Regular1 } from './AIcFluentColor24Regular1';
export { default as IcFluentColor24Filled } from './IcFluentColor24Filled';
export { default as IcFluentWallet24Regular } from './IcFluentWallet24Regular';
export { default as IcFluentWallet24Filled } from './IcFluentWallet24Filled';
export { default as IcFluentDocumentMultiple24Filled } from './IcFluentDocumentMultiple24Filled';
export { default as IcFluentDocumentMultiple24Regular } from './IcFluentDocumentMultiple24Regular';
export { default as IcFluentNews24Filled } from './IcFluentNews24Filled';
export { default as IcFluentNews24Regular } from './IcFluentNews24Regular';
export { default as IcFluentPeopleCommunity24Filled } from './IcFluentPeopleCommunity24Filled';
export { default as AIcFluentCalendarLtr24Filled1 } from './AIcFluentCalendarLtr24Filled1';
export { default as IcFluentCalendarLtr24Regular } from './IcFluentCalendarLtr24Regular';
export { default as AIcFluentPeopleCommunity24Regular1 } from './AIcFluentPeopleCommunity24Regular1';
export { default as IcFluentSlideLayout24Filled } from './IcFluentSlideLayout24Filled';
export { default as IcFluentSlideLayout24Regular } from './IcFluentSlideLayout24Regular';
export { default as IcFluentSignOut24Regular } from './IcFluentSignOut24Regular';
export { default as IcFluentSignOut24Filled } from './IcFluentSignOut24Filled';
export { default as A24Regular101 } from './A24Regular101';
export { default as A24Filled3 } from './A24Filled3';
export { default as IcFluentWhiteboard24Filled } from './IcFluentWhiteboard24Filled';
export { default as IcFluentShieldKeyhole24Regular } from './IcFluentShieldKeyhole24Regular';
export { default as IcFluentShieldKeyhole24Filled } from './IcFluentShieldKeyhole24Filled';
export { default as IcFluentDualScreenSettings24Regular01 } from './IcFluentDualScreenSettings24Regular01';
export { default as IcFluentDualScreenSettings24Filled } from './IcFluentDualScreenSettings24Filled';
export { default as IcFluentAppsListDetail24Filled } from './IcFluentAppsListDetail24Filled';
export { default as IcFluentAppsListDetail24Regular } from './IcFluentAppsListDetail24Regular';
export { default as AIcFluentBookGlobe24Filled1 } from './AIcFluentBookGlobe24Filled1';
export { default as IcFluentBookGlobe24Regular } from './IcFluentBookGlobe24Regular';
export { default as IcFluentClipboardTaskListRtl24Regular } from './IcFluentClipboardTaskListRtl24Regular';
export { default as IcFluentClipboardTaskListRtl24Filled } from './IcFluentClipboardTaskListRtl24Filled';
export { default as IcFluentCopy24Regular10101 } from './IcFluentCopy24Regular10101';
export { default as IcFluentCopy24Regular101011 } from './IcFluentCopy24Regular101011';
export { default as A20Regular1 } from './A20Regular1';
export { default as A20Filled2 } from './A20Filled2';
export { default as AIcFluentPin24Filled2 } from './AIcFluentPin24Filled2';
export { default as AIcFluentPin24Regular1 } from './AIcFluentPin24Regular1';
export { default as A24Regular } from './A24Regular';
export { default as IcFluentBookQuestionMark24Regular1 } from './IcFluentBookQuestionMark24Regular1';
export { default as AIcFluentPositionToFront24Regular1 } from './AIcFluentPositionToFront24Regular1';
export { default as IcFluentPositionToFront24Filled } from './IcFluentPositionToFront24Filled';
export { default as IcFluentGridDots24Filled } from './IcFluentGridDots24Filled';
export { default as IcFluentGrid24Filled } from './IcFluentGrid24Filled';
export { default as ALtr16Filled } from './ALtr16Filled';
export { default as IcFluentPanelRightExpand24Regular } from './IcFluentPanelRightExpand24Regular';
export { default as IcFluentPanelRightContract24Regular } from './IcFluentPanelRightContract24Regular';
export { default as IcFluentClock24Filled } from './IcFluentClock24Filled';
export { default as IcFluentSearch24Filled } from './IcFluentSearch24Filled';
export { default as IcFluentSearch24Regular } from './IcFluentSearch24Regular';
export { default as IcFluentSlideSearch24Filled } from './IcFluentSlideSearch24Filled';
export { default as IcFluentSlideSearch24Regular } from './IcFluentSlideSearch24Regular';
export { default as IcFluentStoreMicrosoft24Regular } from './IcFluentStoreMicrosoft24Regular';
export { default as IcFluentBranchFork24Regular } from './IcFluentBranchFork24Regular';
export { default as IcFluentBuildingRetail24Regular } from './IcFluentBuildingRetail24Regular';
export { default as IcFluentBuildingRetailToolbox24Regular } from './IcFluentBuildingRetailToolbox24Regular';
export { default as BanbenfenfaXianxing } from './BanbenfenfaXianxing';
export { default as IcFluentSend24Regular } from './IcFluentSend24Regular';
export { default as IcFluentPersonNote24Regular } from './IcFluentPersonNote24Regular';
export { default as IcFluentNotepad24Regular } from './IcFluentNotepad24Regular';
export { default as IcFluentGlobe24Filled } from './IcFluentGlobe24Filled';
export { default as IcFluentEarth24Regular } from './IcFluentEarth24Regular';
export { default as IcFluentGrid24Regular } from './IcFluentGrid24Regular';
export { default as IcFluentDesktop24Regular } from './IcFluentDesktop24Regular';
export { default as IcFluentClipboardCode24Regular } from './IcFluentClipboardCode24Regular';
export { default as IcFluentArrowCounterclockwise24Regular } from './IcFluentArrowCounterclockwise24Regular';
export { default as IcFluentCheckboxUnchecked24Filled } from './IcFluentCheckboxUnchecked24Filled';
export { default as IcFluentCheckboxUnchecked24Regular } from './IcFluentCheckboxUnchecked24Regular';
export { default as IcFluentCheckmarkSquare24Filled } from './IcFluentCheckmarkSquare24Filled';
export { default as IcFluentSelectAllOff24Regular } from './IcFluentSelectAllOff24Regular';
export { default as IcFluentSelectAllOn24Filled } from './IcFluentSelectAllOn24Filled';
export { default as IcFluentArrowSyncCircle24Regular } from './IcFluentArrowSyncCircle24Regular';
export { default as IcFluentContentSettings24Regular } from './IcFluentContentSettings24Regular';
export { default as IcFluentChannelArrowLeft24Regular } from './IcFluentChannelArrowLeft24Regular';
export { default as IcFluentNotebookError24Regular } from './IcFluentNotebookError24Regular';
export { default as IcFluentPeopleSettings24Regular } from './IcFluentPeopleSettings24Regular';
export { default as IcFluentPeopleMoney24Regular } from './IcFluentPeopleMoney24Regular';
export { default as IcFluentPeopleSwap24Regular } from './IcFluentPeopleSwap24Regular';
export { default as IcFluentBookQuestionMark24Regular } from './IcFluentBookQuestionMark24Regular';
export { default as IcFluentBookExclamationMark24Regular } from './IcFluentBookExclamationMark24Regular';
export { default as IcFluentDocumentLandscapeData24Regular } from './IcFluentDocumentLandscapeData24Regular';
export { default as IcFluentBookToolbox24Regular } from './IcFluentBookToolbox24Regular';
export { default as IcFluentBookContacts24Regular } from './IcFluentBookContacts24Regular';
export { default as IcFluentArchive24Regular } from './IcFluentArchive24Regular';
export { default as IcFluentAlbum24Regular } from './IcFluentAlbum24Regular';
export { default as IcFluentPeople24Regular } from './IcFluentPeople24Regular';
export { default as IcFluentCloudBackup24Regular } from './IcFluentCloudBackup24Regular';
export { default as IcFluentLibrary24Regular } from './IcFluentLibrary24Regular';
export { default as IcFluentCube24Regular } from './IcFluentCube24Regular';
export { default as IcFluentBuilding24Regular } from './IcFluentBuilding24Regular';
export { default as IcFluentDataPie24Regular } from './IcFluentDataPie24Regular';
export { default as IcFluentSpeaker224Regular } from './IcFluentSpeaker224Regular';
export { default as IcFluentStarSettings24Regular } from './IcFluentStarSettings24Regular';
export { default as IcFluentWarning24Filled } from './IcFluentWarning24Filled';
export { default as IcFluentWarning24Regular } from './IcFluentWarning24Regular';
export { default as IcFluentCheckmarkCircle24Regular } from './IcFluentCheckmarkCircle24Regular';
export { default as IcFluentCheckmarkCircle24Filled } from './IcFluentCheckmarkCircle24Filled';
export { default as IcFluentShieldTask24Regular } from './IcFluentShieldTask24Regular';
export { default as Dismiss24Filled } from './Dismiss24Filled';
export { default as Dismiss24Regular } from './Dismiss24Regular';
export { default as IcFluentDismissCircle24Filled } from './IcFluentDismissCircle24Filled';
export { default as IcFluentDismissCircle24Regular } from './IcFluentDismissCircle24Regular';
export { default as IcFluentInfo24Regular } from './IcFluentInfo24Regular';
export { default as AIcFluentInfo24Filled1 } from './AIcFluentInfo24Filled1';
export { default as IcFluentAddSquare24Filled } from './IcFluentAddSquare24Filled';
export { default as IcFluentAddCircle24Filled } from './IcFluentAddCircle24Filled';
export { default as IcFluentAddSquare24Regular } from './IcFluentAddSquare24Regular';
export { default as IcFluentAdd24Filled } from './IcFluentAdd24Filled';
export { default as IcFluentAddCircle24Regular } from './IcFluentAddCircle24Regular';
export { default as IcFluentAdd24Regular } from './IcFluentAdd24Regular';
export { default as IcFluentDesktopPulse24Regular } from './IcFluentDesktopPulse24Regular';
export { default as IcFluentHomeRegular } from './IcFluentHomeRegular';
export { default as Biankuang } from './Biankuang';
export { default as Shanchu } from './Shanchu';
export { default as Settings24Regular } from './Settings24Regular';
export { default as Hanggao } from './Hanggao';
export { default as Shuaxin } from './Shuaxin';
export { default as Xiazai } from './Xiazai';
export { default as GridDots24Regular } from './GridDots24Regular';
export { default as Weixin1 } from './Weixin1';
export { default as Dingding01 } from './Dingding01';
export { default as ChannelNetWecom } from './ChannelNetWecom';
export { default as Feishu } from './Feishu';
export { default as FluentStarRegular } from './FluentStarRegular';
export { default as FluentStarFilled } from './FluentStarFilled';
export { default as HomeFilled } from './HomeFilled';
export { default as Grid24Regular } from './Grid24Regular';
export { default as PersonNote24Filled } from './PersonNote24Filled';
export { default as Shenjirizhi } from './Shenjirizhi';
export { default as Building24Filled } from './Building24Filled';
export { default as People24Filled } from './People24Filled';
export { default as Desktop24Filled } from './Desktop24Filled';
export { default as Settings24Filled } from './Settings24Filled';
export { default as BuildingRetailToolbox24Filled } from './BuildingRetailToolbox24Filled';
export { default as Apps24Regular } from './Apps24Regular';
export { default as StoreMicrosoft24Filled } from './StoreMicrosoft24Filled';
export { default as Shujuzhongxin } from './Shujuzhongxin';
export { default as DataPie24Filled } from './DataPie24Filled';
export { default as ClipboardCode24Filled } from './ClipboardCode24Filled';
export { default as FluentCube24Filled } from './FluentCube24Filled';
export { default as Apps24Filled } from './Apps24Filled';
export { default as BranchFork24Filled } from './BranchFork24Filled';
export { default as ArrowSyncCircle24Filled } from './ArrowSyncCircle24Filled';
export { default as Speaker224Filled } from './Speaker224Filled';
export { default as Banbenfenfa } from './Banbenfenfa';
export { default as BookToolbox24Filled } from './BookToolbox24Filled';
export { default as Anquanguanli } from './Anquanguanli';
export { default as Library24Filled } from './Library24Filled';
export { default as CloudBackup24Filled } from './CloudBackup24Filled';
export { default as ContentSettings24Filled } from './ContentSettings24Filled';
export { default as DesktopPulseFilled } from './DesktopPulseFilled';
export { default as BookQuestionMark24Filled } from './BookQuestionMark24Filled';
export { default as Album24Filled } from './Album24Filled';
export { default as Briefcase24Filled } from './Briefcase24Filled';
export { default as Send24Filled } from './Send24Filled';
export { default as BookExclamationMark24Filled } from './BookExclamationMark24Filled';
export { default as Archive24Filled } from './Archive24Filled';
export { default as ChannelArrowLeft24Filled } from './ChannelArrowLeft24Filled';
export { default as PeopleSwap24Filled } from './PeopleSwap24Filled';
export { default as BookContacts24Filled } from './BookContacts24Filled';
export { default as NotebookError24Filled } from './NotebookError24Filled';
export { default as PeopleMoney24Filled } from './PeopleMoney24Filled';
export { default as PeopleSettings24Filled } from './PeopleSettings24Filled';
export { default as StarSettings24Filled } from './StarSettings24Filled';

export type IconNames =
  | 'yichuzhangdanbiaoshi'
  | 'yewuanjian'
  | 'gonggonganjian'
  | 'guanlianzhangdan1'
  | 'guanlianzhangdan4'
  | 'guanlianzhangdan5'
  | 'guanlianzhangdan8'
  | 'guanlianzhangdan2'
  | 'guanlianzhangdan6'
  | 'guanlianzhangdan9'
  | 'guanlianzhangdan3'
  | 'guanlianzhangdan7'
  | 'youjianmoban-xianxing'
  | 'youjianmoban'
  | 'gongshibaobiao-xianxing'
  | 'ic_fluent_subtract_circle_24_regular'
  | 'writeoff'
  | 'noteadd'
  | 'csrnote'
  | 'tishi'
  | 'yishoujine'
  | 'bencishoukuan'
  | 'yingshoujine'
  | 'huishuai1'
  | 'huishuai2'
  | 'beizhu'
  | 'xls'
  | 'pdf'
  | 'ppt'
  | 'shipin'
  | 'wenjianjia'
  | 'tupian'
  | 'weizhi'
  | 'txt'
  | 'png'
  | 'lichongshenqing'
  | 'biangengshenqing'
  | 'baoxiaoliebiao'
  | 'biangengliebiao'
  | 'kehuliebiao'
  | 'gongshibaobiao'
  | 'fenpeiliebiao'
  | 'biangengshenhe'
  | 'fapiaoliebiao'
  | 'zhangdanshenqing'
  | 'shoukuanpingzheng'
  | 'shoukuanrenling'
  | 'hexiaopingzheng'
  | 'yingshoupingzheng'
  | 'lichongbaohumingdan'
  | 'tongbuliebiao'
  | 'shoukuandengji'
  | 'zhangdanliebiao'
  | 'neibuhuazhuan'
  | 'fukuanfangguanli'
  | 'jieanshenqing'
  | 'lichongyujian'
  | 'kuajingzhangdan'
  | 'lianhezhangdan'
  | 'putongzhangdan'
  | 'tijiao'
  | 'bianji'
  | 'kaishijishi'
  | 'tianjiagongshimiaoshu'
  | 'miaoshu'
  | 'information_icon'
  | 'zantingjishi'
  | 'zuzhijigou'
  | 'bumen'
  | 'close'
  | 'ic_fluent_person_24_filled'
  | 'liucheng_shenpijiedian'
  | 'daochuzhongxin'
  | 'daochuzhongxin-xianxing'
  | 'pinxiejiancha'
  | 'shujuzidian'
  | 'shujuzidian-xianxing'
  | 'bianmaguize'
  | 'bianmaguize-xianxing'
  | 'renyuanguanli'
  | 'renyuanguanli-xianxing'
  | 'gongshishezhi-xianxing'
  | 'gongshishezhi'
  | 'gongshirili-xianxing'
  | 'gongshirili'
  | 'gongshiliebiao'
  | 'gongshiliebiao-xianxing'
  | 'wodegongshi'
  | 'wodegongshi-xianxing'
  | 'ic_fluent_document_bullet_list_clock_24_regular'
  | 'ic_fluent_document_bullet_list_clock_24_filled'
  | 'ic_fluent_calendar_edit_24_regular'
  | 'ic_fluent_calendar_edit_24_filled'
  | 'a-tianjiaxiaji1x'
  | 'jishiqi'
  | 'kapiancaozuo'
  | 'zhiweishezhi'
  | 'fenpeijiaose'
  | 'gongzuojiaojie'
  | 'guanbikapian'
  | 'yidongbumen'
  | 'shenchafenpei'
  | 'ic_fluent_arrow_reply_24_regular'
  | 'female'
  | 'male'
  | 'LDAP-01'
  | 'fanyi-01'
  | 'aliyunpan-01'
  | 'qiyeweixin-01'
  | 'youjian-01'
  | 'dingding02'
  | 'beisen-01'
  | 'qichacha-01'
  | 'ic_fluent_border_right_24_regular'
  | 'ic_fluent_border_24_regular'
  | 'ic_fluent_border_left_24_regular'
  | 'ic_fluent_re_order_dots_horizontal_24_regular'
  | 'ic_fluent_document_text_24_regular'
  | 'ic_fluent_document_copy_24_regular'
  | 'ic_fluent_caret_right_24_filled'
  | 'a-ic_fluent_mail_24_regular2'
  | 'a-ic_fluent_call_24_regular2'
  | 'a-ic_fluent_location_24_regular1'
  | 'a-ic_fluent_building_24_regular1'
  | 'ic_fluent_person_24_regular'
  | 'a-24Regular2'
  | 'a-24Filled14'
  | 'ic_fluent_caret_down_24_filled'
  | 'hangliefengexian'
  | 'wufengexian'
  | 'wubanmawentianchong'
  | 'youbanmawentianchong'
  | 'hangfengexian'
  | 'liefengexian'
  | 'tejincou'
  | 'zhongdengde'
  | 'jincoude'
  | 'tekuansong'
  | 'kuansongde'
  | 'a-100-xianxing'
  | 'a-100'
  | 'chongmanyouce'
  | 'chongmanyouce-xianxing'
  | 'a-50-xianxing'
  | 'a-50'
  | 'a-30-xianxing'
  | 'a-30'
  | 'kuaisuchuangjian-xianxing'
  | 'kuaisuchuangjian'
  | 'shoukuanguanli-xianxing'
  | 'shoukuanguanli'
  | 'pingzhengguanli-xianxing'
  | 'pingzhengguanli'
  | 'fapiaoguanli'
  | 'fapiaoguanli-xianxing'
  | 'renliziyuan'
  | 'renliziyuan-xianxing'
  | 'ic_fluent_money_24_regular'
  | 'ic_fluent_money_24_filled'
  | 'zhangdanguanli'
  | 'zhangdanguanli-xianxing'
  | 'ic_fluent_person_accounts_24_filled'
  | 'ic_fluent_person_accounts_24_regular'
  | 'ic_fluent_branch_24_regular'
  | 'ic_fluent_branch_24_filled'
  | 'ic_fluent_calendar_clock_24_regular'
  | 'ic_fluent_calendar_clock_24_filled'
  | 'a-24Regular1x1-01'
  | 'a-24Filled1x'
  | 'ic_fluent_folder_add_24_regular-01'
  | 'ic_fluent_folder_add_24_filled'
  | 'ic_fluent_folder_zip_24_filled'
  | 'ic_fluent_folder_zip_24_regular'
  | 'a-ic_fluent_position_to_front_24_regular2'
  | 'a-ic_fluent_position_to_front_24_filled1'
  | 'document_landscape_data_24_regular'
  | 'document_landscape_data_24_filled'
  | 'a-ic_fluent_color_24_regular1'
  | 'ic_fluent_color_24_filled'
  | 'ic_fluent_wallet_24_regular'
  | 'ic_fluent_wallet_24_filled'
  | 'ic_fluent_document_multiple_24_filled'
  | 'ic_fluent_document_multiple_24_regular'
  | 'ic_fluent_news_24_filled'
  | 'ic_fluent_news_24_regular'
  | 'ic_fluent_people_community_24_filled'
  | 'a-ic_fluent_calendar_ltr_24_filled1'
  | 'ic_fluent_calendar_ltr_24_regular'
  | 'a-ic_fluent_people_community_24_regular1'
  | 'ic_fluent_slide_layout_24_filled'
  | 'ic_fluent_slide_layout_24_regular'
  | 'ic_fluent_sign_out_24_regular'
  | 'ic_fluent_sign_out_24_filled'
  | 'a-24Regular1-01'
  | 'a-24Filled3'
  | 'ic_fluent_whiteboard_24_filled'
  | 'ic_fluent_shield_keyhole_24_regular'
  | 'ic_fluent_shield_keyhole_24_filled'
  | 'ic_fluent_dual_screen_settings_24_regular-01'
  | 'ic_fluent_dual_screen_settings_24_filled'
  | 'ic_fluent_apps_list_detail_24_filled'
  | 'ic_fluent_apps_list_detail_24_regular'
  | 'a-ic_fluent_book_globe_24_filled1'
  | 'ic_fluent_book_globe_24_regular'
  | 'ic_fluent_clipboard_task_list_rtl_24_regular'
  | 'ic_fluent_clipboard_task_list_rtl_24_filled'
  | 'ic_fluent_copy_24_regular1-01-01'
  | 'ic_fluent_copy_24_regular1-01-011'
  | 'a-20Regular1'
  | 'a-20Filled2'
  | 'a-ic_fluent_pin_24_filled2'
  | 'a-ic_fluent_pin_24_regular1'
  | 'a-24Regular'
  | 'ic_fluent_book_question_mark_24_regular1'
  | 'a-ic_fluent_position_to_front_24_regular1'
  | 'ic_fluent_position_to_front_24_filled'
  | 'ic_fluent_grid_dots_24_filled'
  | 'ic_fluent_grid_24_filled'
  | 'a-LTR16Filled'
  | 'ic_fluent_panel_right_expand_24_regular'
  | 'ic_fluent_panel_right_contract_24_regular'
  | 'ic_fluent_clock_24_filled'
  | 'ic_fluent_search_24_filled'
  | 'ic_fluent_search_24_regular'
  | 'ic_fluent_slide_search_24_filled'
  | 'ic_fluent_slide_search_24_regular'
  | 'ic_fluent_store_microsoft_24_regular'
  | 'ic_fluent_branch_fork_24_regular'
  | 'ic_fluent_building_retail_24_regular'
  | 'ic_fluent_building_retail_toolbox_24_regular'
  | 'banbenfenfa_xianxing'
  | 'ic_fluent_send_24_regular'
  | 'ic_fluent_person_note_24_regular'
  | 'ic_fluent_notepad_24_regular'
  | 'ic_fluent_globe_24_filled'
  | 'ic_fluent_earth_24_regular'
  | 'ic_fluent_grid_24_regular'
  | 'ic_fluent_desktop_24_regular'
  | 'ic_fluent_clipboard_code_24_regular'
  | 'ic_fluent_arrow_counterclockwise_24_regular'
  | 'ic_fluent_checkbox_unchecked_24_filled'
  | 'ic_fluent_checkbox_unchecked_24_regular'
  | 'ic_fluent_checkmark_square_24_filled'
  | 'ic_fluent_select_all_off_24_regular'
  | 'ic_fluent_select_all_on_24_filled'
  | 'ic_fluent_arrow_sync_circle_24_regular'
  | 'ic_fluent_content_settings_24_regular'
  | 'ic_fluent_channel_arrow_left_24_regular'
  | 'ic_fluent_notebook_error_24_regular'
  | 'ic_fluent_people_settings_24_regular'
  | 'ic_fluent_people_money_24_regular'
  | 'ic_fluent_people_swap_24_regular'
  | 'ic_fluent_book_question_mark_24_regular'
  | 'ic_fluent_book_exclamation_mark_24_regular'
  | 'ic_fluent_document_landscape_data_24_regular'
  | 'ic_fluent_book_toolbox_24_regular'
  | 'ic_fluent_book_contacts_24_regular'
  | 'ic_fluent_archive_24_regular'
  | 'ic_fluent_album_24_regular'
  | 'ic_fluent_people_24_regular'
  | 'ic_fluent_cloud_backup_24_regular'
  | 'ic_fluent_library_24_regular'
  | 'ic_fluent_cube_24_regular'
  | 'ic_fluent_building_24_regular'
  | 'ic_fluent_data_pie_24_regular'
  | 'ic_fluent_speaker_2_24_regular'
  | 'ic_fluent_star_settings_24_regular'
  | 'ic_fluent_warning_24_filled'
  | 'ic_fluent_warning_24_regular'
  | 'ic_fluent_checkmark_circle_24_regular'
  | 'ic_fluent_checkmark_circle_24_filled'
  | 'ic_fluent_shield_task_24_regular'
  | 'dismiss_24_filled'
  | 'dismiss_24_regular'
  | 'ic_fluent_dismiss_circle_24_filled'
  | 'ic_fluent_dismiss_circle_24_regular'
  | 'ic_fluent_info_24_regular'
  | 'a-ic_fluent_info_24_filled1'
  | 'ic_fluent_add_square_24_filled'
  | 'ic_fluent_add_circle_24_filled'
  | 'ic_fluent_add_square_24_regular'
  | 'ic_fluent_add_24_filled'
  | 'ic_fluent_add_circle_24_regular'
  | 'ic_fluent_add_24_regular'
  | 'ic_fluent_desktop_pulse_24_regular'
  | 'ic_fluent_home_regular'
  | 'biankuang'
  | 'shanchu'
  | 'settings_24_regular'
  | 'hanggao'
  | 'shuaxin'
  | 'xiazai'
  | 'grid_dots_24_regular'
  | 'weixin1'
  | 'dingding01'
  | 'channel-net_wecom'
  | 'feishu'
  | 'fluent_star_regular'
  | 'fluent_star_filled'
  | 'home_filled'
  | 'grid_24_regular'
  | 'person_note_24_filled'
  | 'shenjirizhi'
  | 'building_24_filled'
  | 'people_24_filled'
  | 'desktop_24_filled'
  | 'settings_24_filled'
  | 'building_retail_toolbox_24_filled'
  | 'apps_24_regular'
  | 'store_microsoft_24_filled'
  | 'shujuzhongxin'
  | 'data_pie_24_filled'
  | 'clipboard_code_24_filled'
  | 'fluent_cube_24_filled'
  | 'apps_24_filled'
  | 'branch_fork_24_filled'
  | 'arrow_sync_circle_24_filled'
  | 'speaker_2_24_filled'
  | 'banbenfenfa'
  | 'book_toolbox_24_filled'
  | 'anquanguanli'
  | 'library_24_filled'
  | 'cloud_backup_24_filled'
  | 'content_settings_24_filled'
  | 'desktop_pulse_filled'
  | 'book_question_mark_24_filled'
  | 'album_24_filled'
  | 'briefcase_24_filled'
  | 'send_24_filled'
  | 'book_exclamation_mark_24_filled'
  | 'archive_24_filled'
  | 'channel_arrow_left_24_filled'
  | 'people_swap_24_filled'
  | 'book_contacts_24_filled'
  | 'notebook_error_24_filled'
  | 'people_money_24_filled'
  | 'people_settings_24_filled'
  | 'star_settings_24_filled';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  name: IconNames;
  size?: number;
  color?: string | string[];
}

const IconFont: FunctionComponent<Props> = ({ name, ...rest }) => {
  switch (name) {
    case 'yichuzhangdanbiaoshi':
      return <Yichuzhangdanbiaoshi {...rest} />;
    case 'yewuanjian':
      return <Yewuanjian {...rest} />;
    case 'gonggonganjian':
      return <Gonggonganjian {...rest} />;
    case 'guanlianzhangdan1':
      return <Guanlianzhangdan1 {...rest} />;
    case 'guanlianzhangdan4':
      return <Guanlianzhangdan4 {...rest} />;
    case 'guanlianzhangdan5':
      return <Guanlianzhangdan5 {...rest} />;
    case 'guanlianzhangdan8':
      return <Guanlianzhangdan8 {...rest} />;
    case 'guanlianzhangdan2':
      return <Guanlianzhangdan2 {...rest} />;
    case 'guanlianzhangdan6':
      return <Guanlianzhangdan6 {...rest} />;
    case 'guanlianzhangdan9':
      return <Guanlianzhangdan9 {...rest} />;
    case 'guanlianzhangdan3':
      return <Guanlianzhangdan3 {...rest} />;
    case 'guanlianzhangdan7':
      return <Guanlianzhangdan7 {...rest} />;
    case 'youjianmoban-xianxing':
      return <YoujianmobanXianxing {...rest} />;
    case 'youjianmoban':
      return <Youjianmoban {...rest} />;
    case 'gongshibaobiao-xianxing':
      return <GongshibaobiaoXianxing {...rest} />;
    case 'ic_fluent_subtract_circle_24_regular':
      return <IcFluentSubtractCircle24Regular {...rest} />;
    case 'writeoff':
      return <Writeoff {...rest} />;
    case 'noteadd':
      return <Noteadd {...rest} />;
    case 'csrnote':
      return <Csrnote {...rest} />;
    case 'tishi':
      return <Tishi {...rest} />;
    case 'yishoujine':
      return <Yishoujine {...rest} />;
    case 'bencishoukuan':
      return <Bencishoukuan {...rest} />;
    case 'yingshoujine':
      return <Yingshoujine {...rest} />;
    case 'huishuai1':
      return <Huishuai1 {...rest} />;
    case 'huishuai2':
      return <Huishuai2 {...rest} />;
    case 'beizhu':
      return <Beizhu {...rest} />;
    case 'xls':
      return <Xls {...rest} />;
    case 'pdf':
      return <Pdf {...rest} />;
    case 'ppt':
      return <Ppt {...rest} />;
    case 'shipin':
      return <Shipin {...rest} />;
    case 'wenjianjia':
      return <Wenjianjia {...rest} />;
    case 'tupian':
      return <Tupian {...rest} />;
    case 'weizhi':
      return <Weizhi {...rest} />;
    case 'txt':
      return <Txt {...rest} />;
    case 'png':
      return <Png {...rest} />;
    case 'lichongshenqing':
      return <Lichongshenqing {...rest} />;
    case 'biangengshenqing':
      return <Biangengshenqing {...rest} />;
    case 'baoxiaoliebiao':
      return <Baoxiaoliebiao {...rest} />;
    case 'biangengliebiao':
      return <Biangengliebiao {...rest} />;
    case 'kehuliebiao':
      return <Kehuliebiao {...rest} />;
    case 'gongshibaobiao':
      return <Gongshibaobiao {...rest} />;
    case 'fenpeiliebiao':
      return <Fenpeiliebiao {...rest} />;
    case 'biangengshenhe':
      return <Biangengshenhe {...rest} />;
    case 'fapiaoliebiao':
      return <Fapiaoliebiao {...rest} />;
    case 'zhangdanshenqing':
      return <Zhangdanshenqing {...rest} />;
    case 'shoukuanpingzheng':
      return <Shoukuanpingzheng {...rest} />;
    case 'shoukuanrenling':
      return <Shoukuanrenling {...rest} />;
    case 'hexiaopingzheng':
      return <Hexiaopingzheng {...rest} />;
    case 'yingshoupingzheng':
      return <Yingshoupingzheng {...rest} />;
    case 'lichongbaohumingdan':
      return <Lichongbaohumingdan {...rest} />;
    case 'tongbuliebiao':
      return <Tongbuliebiao {...rest} />;
    case 'shoukuandengji':
      return <Shoukuandengji {...rest} />;
    case 'zhangdanliebiao':
      return <Zhangdanliebiao {...rest} />;
    case 'neibuhuazhuan':
      return <Neibuhuazhuan {...rest} />;
    case 'fukuanfangguanli':
      return <Fukuanfangguanli {...rest} />;
    case 'jieanshenqing':
      return <Jieanshenqing {...rest} />;
    case 'lichongyujian':
      return <Lichongyujian {...rest} />;
    case 'kuajingzhangdan':
      return <Kuajingzhangdan {...rest} />;
    case 'lianhezhangdan':
      return <Lianhezhangdan {...rest} />;
    case 'putongzhangdan':
      return <Putongzhangdan {...rest} />;
    case 'tijiao':
      return <Tijiao {...rest} />;
    case 'bianji':
      return <Bianji {...rest} />;
    case 'kaishijishi':
      return <Kaishijishi {...rest} />;
    case 'tianjiagongshimiaoshu':
      return <Tianjiagongshimiaoshu {...rest} />;
    case 'miaoshu':
      return <Miaoshu {...rest} />;
    case 'information_icon':
      return <InformationIcon {...rest} />;
    case 'zantingjishi':
      return <Zantingjishi {...rest} />;
    case 'zuzhijigou':
      return <Zuzhijigou {...rest} />;
    case 'bumen':
      return <Bumen {...rest} />;
    case 'close':
      return <Close {...rest} />;
    case 'ic_fluent_person_24_filled':
      return <IcFluentPerson24Filled {...rest} />;
    case 'liucheng_shenpijiedian':
      return <LiuchengShenpijiedian {...rest} />;
    case 'daochuzhongxin':
      return <Daochuzhongxin {...rest} />;
    case 'daochuzhongxin-xianxing':
      return <DaochuzhongxinXianxing {...rest} />;
    case 'pinxiejiancha':
      return <Pinxiejiancha {...rest} />;
    case 'shujuzidian':
      return <Shujuzidian {...rest} />;
    case 'shujuzidian-xianxing':
      return <ShujuzidianXianxing {...rest} />;
    case 'bianmaguize':
      return <Bianmaguize {...rest} />;
    case 'bianmaguize-xianxing':
      return <BianmaguizeXianxing {...rest} />;
    case 'renyuanguanli':
      return <Renyuanguanli {...rest} />;
    case 'renyuanguanli-xianxing':
      return <RenyuanguanliXianxing {...rest} />;
    case 'gongshishezhi-xianxing':
      return <GongshishezhiXianxing {...rest} />;
    case 'gongshishezhi':
      return <Gongshishezhi {...rest} />;
    case 'gongshirili-xianxing':
      return <GongshiriliXianxing {...rest} />;
    case 'gongshirili':
      return <Gongshirili {...rest} />;
    case 'gongshiliebiao':
      return <Gongshiliebiao {...rest} />;
    case 'gongshiliebiao-xianxing':
      return <GongshiliebiaoXianxing {...rest} />;
    case 'wodegongshi':
      return <Wodegongshi {...rest} />;
    case 'wodegongshi-xianxing':
      return <WodegongshiXianxing {...rest} />;
    case 'ic_fluent_document_bullet_list_clock_24_regular':
      return <IcFluentDocumentBulletListClock24Regular {...rest} />;
    case 'ic_fluent_document_bullet_list_clock_24_filled':
      return <IcFluentDocumentBulletListClock24Filled {...rest} />;
    case 'ic_fluent_calendar_edit_24_regular':
      return <IcFluentCalendarEdit24Regular {...rest} />;
    case 'ic_fluent_calendar_edit_24_filled':
      return <IcFluentCalendarEdit24Filled {...rest} />;
    case 'a-tianjiaxiaji1x':
      return <ATianjiaxiaji1X {...rest} />;
    case 'jishiqi':
      return <Jishiqi {...rest} />;
    case 'kapiancaozuo':
      return <Kapiancaozuo {...rest} />;
    case 'zhiweishezhi':
      return <Zhiweishezhi {...rest} />;
    case 'fenpeijiaose':
      return <Fenpeijiaose {...rest} />;
    case 'gongzuojiaojie':
      return <Gongzuojiaojie {...rest} />;
    case 'guanbikapian':
      return <Guanbikapian {...rest} />;
    case 'yidongbumen':
      return <Yidongbumen {...rest} />;
    case 'shenchafenpei':
      return <Shenchafenpei {...rest} />;
    case 'ic_fluent_arrow_reply_24_regular':
      return <IcFluentArrowReply24Regular {...rest} />;
    case 'female':
      return <Female {...rest} />;
    case 'male':
      return <Male {...rest} />;
    case 'LDAP-01':
      return <Ldap01 {...rest} />;
    case 'fanyi-01':
      return <Fanyi01 {...rest} />;
    case 'aliyunpan-01':
      return <Aliyunpan01 {...rest} />;
    case 'qiyeweixin-01':
      return <Qiyeweixin01 {...rest} />;
    case 'youjian-01':
      return <Youjian01 {...rest} />;
    case 'dingding02':
      return <Dingding02 {...rest} />;
    case 'beisen-01':
      return <Beisen01 {...rest} />;
    case 'qichacha-01':
      return <Qichacha01 {...rest} />;
    case 'ic_fluent_border_right_24_regular':
      return <IcFluentBorderRight24Regular {...rest} />;
    case 'ic_fluent_border_24_regular':
      return <IcFluentBorder24Regular {...rest} />;
    case 'ic_fluent_border_left_24_regular':
      return <IcFluentBorderLeft24Regular {...rest} />;
    case 'ic_fluent_re_order_dots_horizontal_24_regular':
      return <IcFluentReOrderDotsHorizontal24Regular {...rest} />;
    case 'ic_fluent_document_text_24_regular':
      return <IcFluentDocumentText24Regular {...rest} />;
    case 'ic_fluent_document_copy_24_regular':
      return <IcFluentDocumentCopy24Regular {...rest} />;
    case 'ic_fluent_caret_right_24_filled':
      return <IcFluentCaretRight24Filled {...rest} />;
    case 'a-ic_fluent_mail_24_regular2':
      return <AIcFluentMail24Regular2 {...rest} />;
    case 'a-ic_fluent_call_24_regular2':
      return <AIcFluentCall24Regular2 {...rest} />;
    case 'a-ic_fluent_location_24_regular1':
      return <AIcFluentLocation24Regular1 {...rest} />;
    case 'a-ic_fluent_building_24_regular1':
      return <AIcFluentBuilding24Regular1 {...rest} />;
    case 'ic_fluent_person_24_regular':
      return <IcFluentPerson24Regular {...rest} />;
    case 'a-24Regular2':
      return <A24Regular2 {...rest} />;
    case 'a-24Filled14':
      return <A24Filled14 {...rest} />;
    case 'ic_fluent_caret_down_24_filled':
      return <IcFluentCaretDown24Filled {...rest} />;
    case 'hangliefengexian':
      return <Hangliefengexian {...rest} />;
    case 'wufengexian':
      return <Wufengexian {...rest} />;
    case 'wubanmawentianchong':
      return <Wubanmawentianchong {...rest} />;
    case 'youbanmawentianchong':
      return <Youbanmawentianchong {...rest} />;
    case 'hangfengexian':
      return <Hangfengexian {...rest} />;
    case 'liefengexian':
      return <Liefengexian {...rest} />;
    case 'tejincou':
      return <Tejincou {...rest} />;
    case 'zhongdengde':
      return <Zhongdengde {...rest} />;
    case 'jincoude':
      return <Jincoude {...rest} />;
    case 'tekuansong':
      return <Tekuansong {...rest} />;
    case 'kuansongde':
      return <Kuansongde {...rest} />;
    case 'a-100-xianxing':
      return <A100Xianxing {...rest} />;
    case 'a-100':
      return <A100 {...rest} />;
    case 'chongmanyouce':
      return <Chongmanyouce {...rest} />;
    case 'chongmanyouce-xianxing':
      return <ChongmanyouceXianxing {...rest} />;
    case 'a-50-xianxing':
      return <A50Xianxing {...rest} />;
    case 'a-50':
      return <A50 {...rest} />;
    case 'a-30-xianxing':
      return <A30Xianxing {...rest} />;
    case 'a-30':
      return <A30 {...rest} />;
    case 'kuaisuchuangjian-xianxing':
      return <KuaisuchuangjianXianxing {...rest} />;
    case 'kuaisuchuangjian':
      return <Kuaisuchuangjian {...rest} />;
    case 'shoukuanguanli-xianxing':
      return <ShoukuanguanliXianxing {...rest} />;
    case 'shoukuanguanli':
      return <Shoukuanguanli {...rest} />;
    case 'pingzhengguanli-xianxing':
      return <PingzhengguanliXianxing {...rest} />;
    case 'pingzhengguanli':
      return <Pingzhengguanli {...rest} />;
    case 'fapiaoguanli':
      return <Fapiaoguanli {...rest} />;
    case 'fapiaoguanli-xianxing':
      return <FapiaoguanliXianxing {...rest} />;
    case 'renliziyuan':
      return <Renliziyuan {...rest} />;
    case 'renliziyuan-xianxing':
      return <RenliziyuanXianxing {...rest} />;
    case 'ic_fluent_money_24_regular':
      return <IcFluentMoney24Regular {...rest} />;
    case 'ic_fluent_money_24_filled':
      return <IcFluentMoney24Filled {...rest} />;
    case 'zhangdanguanli':
      return <Zhangdanguanli {...rest} />;
    case 'zhangdanguanli-xianxing':
      return <ZhangdanguanliXianxing {...rest} />;
    case 'ic_fluent_person_accounts_24_filled':
      return <IcFluentPersonAccounts24Filled {...rest} />;
    case 'ic_fluent_person_accounts_24_regular':
      return <IcFluentPersonAccounts24Regular {...rest} />;
    case 'ic_fluent_branch_24_regular':
      return <IcFluentBranch24Regular {...rest} />;
    case 'ic_fluent_branch_24_filled':
      return <IcFluentBranch24Filled {...rest} />;
    case 'ic_fluent_calendar_clock_24_regular':
      return <IcFluentCalendarClock24Regular {...rest} />;
    case 'ic_fluent_calendar_clock_24_filled':
      return <IcFluentCalendarClock24Filled {...rest} />;
    case 'a-24Regular1x1-01':
      return <A24Regular1X101 {...rest} />;
    case 'a-24Filled1x':
      return <A24Filled1X {...rest} />;
    case 'ic_fluent_folder_add_24_regular-01':
      return <IcFluentFolderAdd24Regular01 {...rest} />;
    case 'ic_fluent_folder_add_24_filled':
      return <IcFluentFolderAdd24Filled {...rest} />;
    case 'ic_fluent_folder_zip_24_filled':
      return <IcFluentFolderZip24Filled {...rest} />;
    case 'ic_fluent_folder_zip_24_regular':
      return <IcFluentFolderZip24Regular {...rest} />;
    case 'a-ic_fluent_position_to_front_24_regular2':
      return <AIcFluentPositionToFront24Regular2 {...rest} />;
    case 'a-ic_fluent_position_to_front_24_filled1':
      return <AIcFluentPositionToFront24Filled1 {...rest} />;
    case 'document_landscape_data_24_regular':
      return <DocumentLandscapeData24Regular {...rest} />;
    case 'document_landscape_data_24_filled':
      return <DocumentLandscapeData24Filled {...rest} />;
    case 'a-ic_fluent_color_24_regular1':
      return <AIcFluentColor24Regular1 {...rest} />;
    case 'ic_fluent_color_24_filled':
      return <IcFluentColor24Filled {...rest} />;
    case 'ic_fluent_wallet_24_regular':
      return <IcFluentWallet24Regular {...rest} />;
    case 'ic_fluent_wallet_24_filled':
      return <IcFluentWallet24Filled {...rest} />;
    case 'ic_fluent_document_multiple_24_filled':
      return <IcFluentDocumentMultiple24Filled {...rest} />;
    case 'ic_fluent_document_multiple_24_regular':
      return <IcFluentDocumentMultiple24Regular {...rest} />;
    case 'ic_fluent_news_24_filled':
      return <IcFluentNews24Filled {...rest} />;
    case 'ic_fluent_news_24_regular':
      return <IcFluentNews24Regular {...rest} />;
    case 'ic_fluent_people_community_24_filled':
      return <IcFluentPeopleCommunity24Filled {...rest} />;
    case 'a-ic_fluent_calendar_ltr_24_filled1':
      return <AIcFluentCalendarLtr24Filled1 {...rest} />;
    case 'ic_fluent_calendar_ltr_24_regular':
      return <IcFluentCalendarLtr24Regular {...rest} />;
    case 'a-ic_fluent_people_community_24_regular1':
      return <AIcFluentPeopleCommunity24Regular1 {...rest} />;
    case 'ic_fluent_slide_layout_24_filled':
      return <IcFluentSlideLayout24Filled {...rest} />;
    case 'ic_fluent_slide_layout_24_regular':
      return <IcFluentSlideLayout24Regular {...rest} />;
    case 'ic_fluent_sign_out_24_regular':
      return <IcFluentSignOut24Regular {...rest} />;
    case 'ic_fluent_sign_out_24_filled':
      return <IcFluentSignOut24Filled {...rest} />;
    case 'a-24Regular1-01':
      return <A24Regular101 {...rest} />;
    case 'a-24Filled3':
      return <A24Filled3 {...rest} />;
    case 'ic_fluent_whiteboard_24_filled':
      return <IcFluentWhiteboard24Filled {...rest} />;
    case 'ic_fluent_shield_keyhole_24_regular':
      return <IcFluentShieldKeyhole24Regular {...rest} />;
    case 'ic_fluent_shield_keyhole_24_filled':
      return <IcFluentShieldKeyhole24Filled {...rest} />;
    case 'ic_fluent_dual_screen_settings_24_regular-01':
      return <IcFluentDualScreenSettings24Regular01 {...rest} />;
    case 'ic_fluent_dual_screen_settings_24_filled':
      return <IcFluentDualScreenSettings24Filled {...rest} />;
    case 'ic_fluent_apps_list_detail_24_filled':
      return <IcFluentAppsListDetail24Filled {...rest} />;
    case 'ic_fluent_apps_list_detail_24_regular':
      return <IcFluentAppsListDetail24Regular {...rest} />;
    case 'a-ic_fluent_book_globe_24_filled1':
      return <AIcFluentBookGlobe24Filled1 {...rest} />;
    case 'ic_fluent_book_globe_24_regular':
      return <IcFluentBookGlobe24Regular {...rest} />;
    case 'ic_fluent_clipboard_task_list_rtl_24_regular':
      return <IcFluentClipboardTaskListRtl24Regular {...rest} />;
    case 'ic_fluent_clipboard_task_list_rtl_24_filled':
      return <IcFluentClipboardTaskListRtl24Filled {...rest} />;
    case 'ic_fluent_copy_24_regular1-01-01':
      return <IcFluentCopy24Regular10101 {...rest} />;
    case 'ic_fluent_copy_24_regular1-01-011':
      return <IcFluentCopy24Regular101011 {...rest} />;
    case 'a-20Regular1':
      return <A20Regular1 {...rest} />;
    case 'a-20Filled2':
      return <A20Filled2 {...rest} />;
    case 'a-ic_fluent_pin_24_filled2':
      return <AIcFluentPin24Filled2 {...rest} />;
    case 'a-ic_fluent_pin_24_regular1':
      return <AIcFluentPin24Regular1 {...rest} />;
    case 'a-24Regular':
      return <A24Regular {...rest} />;
    case 'ic_fluent_book_question_mark_24_regular1':
      return <IcFluentBookQuestionMark24Regular1 {...rest} />;
    case 'a-ic_fluent_position_to_front_24_regular1':
      return <AIcFluentPositionToFront24Regular1 {...rest} />;
    case 'ic_fluent_position_to_front_24_filled':
      return <IcFluentPositionToFront24Filled {...rest} />;
    case 'ic_fluent_grid_dots_24_filled':
      return <IcFluentGridDots24Filled {...rest} />;
    case 'ic_fluent_grid_24_filled':
      return <IcFluentGrid24Filled {...rest} />;
    case 'a-LTR16Filled':
      return <ALtr16Filled {...rest} />;
    case 'ic_fluent_panel_right_expand_24_regular':
      return <IcFluentPanelRightExpand24Regular {...rest} />;
    case 'ic_fluent_panel_right_contract_24_regular':
      return <IcFluentPanelRightContract24Regular {...rest} />;
    case 'ic_fluent_clock_24_filled':
      return <IcFluentClock24Filled {...rest} />;
    case 'ic_fluent_search_24_filled':
      return <IcFluentSearch24Filled {...rest} />;
    case 'ic_fluent_search_24_regular':
      return <IcFluentSearch24Regular {...rest} />;
    case 'ic_fluent_slide_search_24_filled':
      return <IcFluentSlideSearch24Filled {...rest} />;
    case 'ic_fluent_slide_search_24_regular':
      return <IcFluentSlideSearch24Regular {...rest} />;
    case 'ic_fluent_store_microsoft_24_regular':
      return <IcFluentStoreMicrosoft24Regular {...rest} />;
    case 'ic_fluent_branch_fork_24_regular':
      return <IcFluentBranchFork24Regular {...rest} />;
    case 'ic_fluent_building_retail_24_regular':
      return <IcFluentBuildingRetail24Regular {...rest} />;
    case 'ic_fluent_building_retail_toolbox_24_regular':
      return <IcFluentBuildingRetailToolbox24Regular {...rest} />;
    case 'banbenfenfa_xianxing':
      return <BanbenfenfaXianxing {...rest} />;
    case 'ic_fluent_send_24_regular':
      return <IcFluentSend24Regular {...rest} />;
    case 'ic_fluent_person_note_24_regular':
      return <IcFluentPersonNote24Regular {...rest} />;
    case 'ic_fluent_notepad_24_regular':
      return <IcFluentNotepad24Regular {...rest} />;
    case 'ic_fluent_globe_24_filled':
      return <IcFluentGlobe24Filled {...rest} />;
    case 'ic_fluent_earth_24_regular':
      return <IcFluentEarth24Regular {...rest} />;
    case 'ic_fluent_grid_24_regular':
      return <IcFluentGrid24Regular {...rest} />;
    case 'ic_fluent_desktop_24_regular':
      return <IcFluentDesktop24Regular {...rest} />;
    case 'ic_fluent_clipboard_code_24_regular':
      return <IcFluentClipboardCode24Regular {...rest} />;
    case 'ic_fluent_arrow_counterclockwise_24_regular':
      return <IcFluentArrowCounterclockwise24Regular {...rest} />;
    case 'ic_fluent_checkbox_unchecked_24_filled':
      return <IcFluentCheckboxUnchecked24Filled {...rest} />;
    case 'ic_fluent_checkbox_unchecked_24_regular':
      return <IcFluentCheckboxUnchecked24Regular {...rest} />;
    case 'ic_fluent_checkmark_square_24_filled':
      return <IcFluentCheckmarkSquare24Filled {...rest} />;
    case 'ic_fluent_select_all_off_24_regular':
      return <IcFluentSelectAllOff24Regular {...rest} />;
    case 'ic_fluent_select_all_on_24_filled':
      return <IcFluentSelectAllOn24Filled {...rest} />;
    case 'ic_fluent_arrow_sync_circle_24_regular':
      return <IcFluentArrowSyncCircle24Regular {...rest} />;
    case 'ic_fluent_content_settings_24_regular':
      return <IcFluentContentSettings24Regular {...rest} />;
    case 'ic_fluent_channel_arrow_left_24_regular':
      return <IcFluentChannelArrowLeft24Regular {...rest} />;
    case 'ic_fluent_notebook_error_24_regular':
      return <IcFluentNotebookError24Regular {...rest} />;
    case 'ic_fluent_people_settings_24_regular':
      return <IcFluentPeopleSettings24Regular {...rest} />;
    case 'ic_fluent_people_money_24_regular':
      return <IcFluentPeopleMoney24Regular {...rest} />;
    case 'ic_fluent_people_swap_24_regular':
      return <IcFluentPeopleSwap24Regular {...rest} />;
    case 'ic_fluent_book_question_mark_24_regular':
      return <IcFluentBookQuestionMark24Regular {...rest} />;
    case 'ic_fluent_book_exclamation_mark_24_regular':
      return <IcFluentBookExclamationMark24Regular {...rest} />;
    case 'ic_fluent_document_landscape_data_24_regular':
      return <IcFluentDocumentLandscapeData24Regular {...rest} />;
    case 'ic_fluent_book_toolbox_24_regular':
      return <IcFluentBookToolbox24Regular {...rest} />;
    case 'ic_fluent_book_contacts_24_regular':
      return <IcFluentBookContacts24Regular {...rest} />;
    case 'ic_fluent_archive_24_regular':
      return <IcFluentArchive24Regular {...rest} />;
    case 'ic_fluent_album_24_regular':
      return <IcFluentAlbum24Regular {...rest} />;
    case 'ic_fluent_people_24_regular':
      return <IcFluentPeople24Regular {...rest} />;
    case 'ic_fluent_cloud_backup_24_regular':
      return <IcFluentCloudBackup24Regular {...rest} />;
    case 'ic_fluent_library_24_regular':
      return <IcFluentLibrary24Regular {...rest} />;
    case 'ic_fluent_cube_24_regular':
      return <IcFluentCube24Regular {...rest} />;
    case 'ic_fluent_building_24_regular':
      return <IcFluentBuilding24Regular {...rest} />;
    case 'ic_fluent_data_pie_24_regular':
      return <IcFluentDataPie24Regular {...rest} />;
    case 'ic_fluent_speaker_2_24_regular':
      return <IcFluentSpeaker224Regular {...rest} />;
    case 'ic_fluent_star_settings_24_regular':
      return <IcFluentStarSettings24Regular {...rest} />;
    case 'ic_fluent_warning_24_filled':
      return <IcFluentWarning24Filled {...rest} />;
    case 'ic_fluent_warning_24_regular':
      return <IcFluentWarning24Regular {...rest} />;
    case 'ic_fluent_checkmark_circle_24_regular':
      return <IcFluentCheckmarkCircle24Regular {...rest} />;
    case 'ic_fluent_checkmark_circle_24_filled':
      return <IcFluentCheckmarkCircle24Filled {...rest} />;
    case 'ic_fluent_shield_task_24_regular':
      return <IcFluentShieldTask24Regular {...rest} />;
    case 'dismiss_24_filled':
      return <Dismiss24Filled {...rest} />;
    case 'dismiss_24_regular':
      return <Dismiss24Regular {...rest} />;
    case 'ic_fluent_dismiss_circle_24_filled':
      return <IcFluentDismissCircle24Filled {...rest} />;
    case 'ic_fluent_dismiss_circle_24_regular':
      return <IcFluentDismissCircle24Regular {...rest} />;
    case 'ic_fluent_info_24_regular':
      return <IcFluentInfo24Regular {...rest} />;
    case 'a-ic_fluent_info_24_filled1':
      return <AIcFluentInfo24Filled1 {...rest} />;
    case 'ic_fluent_add_square_24_filled':
      return <IcFluentAddSquare24Filled {...rest} />;
    case 'ic_fluent_add_circle_24_filled':
      return <IcFluentAddCircle24Filled {...rest} />;
    case 'ic_fluent_add_square_24_regular':
      return <IcFluentAddSquare24Regular {...rest} />;
    case 'ic_fluent_add_24_filled':
      return <IcFluentAdd24Filled {...rest} />;
    case 'ic_fluent_add_circle_24_regular':
      return <IcFluentAddCircle24Regular {...rest} />;
    case 'ic_fluent_add_24_regular':
      return <IcFluentAdd24Regular {...rest} />;
    case 'ic_fluent_desktop_pulse_24_regular':
      return <IcFluentDesktopPulse24Regular {...rest} />;
    case 'ic_fluent_home_regular':
      return <IcFluentHomeRegular {...rest} />;
    case 'biankuang':
      return <Biankuang {...rest} />;
    case 'shanchu':
      return <Shanchu {...rest} />;
    case 'settings_24_regular':
      return <Settings24Regular {...rest} />;
    case 'hanggao':
      return <Hanggao {...rest} />;
    case 'shuaxin':
      return <Shuaxin {...rest} />;
    case 'xiazai':
      return <Xiazai {...rest} />;
    case 'grid_dots_24_regular':
      return <GridDots24Regular {...rest} />;
    case 'weixin1':
      return <Weixin1 {...rest} />;
    case 'dingding01':
      return <Dingding01 {...rest} />;
    case 'channel-net_wecom':
      return <ChannelNetWecom {...rest} />;
    case 'feishu':
      return <Feishu {...rest} />;
    case 'fluent_star_regular':
      return <FluentStarRegular {...rest} />;
    case 'fluent_star_filled':
      return <FluentStarFilled {...rest} />;
    case 'home_filled':
      return <HomeFilled {...rest} />;
    case 'grid_24_regular':
      return <Grid24Regular {...rest} />;
    case 'person_note_24_filled':
      return <PersonNote24Filled {...rest} />;
    case 'shenjirizhi':
      return <Shenjirizhi {...rest} />;
    case 'building_24_filled':
      return <Building24Filled {...rest} />;
    case 'people_24_filled':
      return <People24Filled {...rest} />;
    case 'desktop_24_filled':
      return <Desktop24Filled {...rest} />;
    case 'settings_24_filled':
      return <Settings24Filled {...rest} />;
    case 'building_retail_toolbox_24_filled':
      return <BuildingRetailToolbox24Filled {...rest} />;
    case 'apps_24_regular':
      return <Apps24Regular {...rest} />;
    case 'store_microsoft_24_filled':
      return <StoreMicrosoft24Filled {...rest} />;
    case 'shujuzhongxin':
      return <Shujuzhongxin {...rest} />;
    case 'data_pie_24_filled':
      return <DataPie24Filled {...rest} />;
    case 'clipboard_code_24_filled':
      return <ClipboardCode24Filled {...rest} />;
    case 'fluent_cube_24_filled':
      return <FluentCube24Filled {...rest} />;
    case 'apps_24_filled':
      return <Apps24Filled {...rest} />;
    case 'branch_fork_24_filled':
      return <BranchFork24Filled {...rest} />;
    case 'arrow_sync_circle_24_filled':
      return <ArrowSyncCircle24Filled {...rest} />;
    case 'speaker_2_24_filled':
      return <Speaker224Filled {...rest} />;
    case 'banbenfenfa':
      return <Banbenfenfa {...rest} />;
    case 'book_toolbox_24_filled':
      return <BookToolbox24Filled {...rest} />;
    case 'anquanguanli':
      return <Anquanguanli {...rest} />;
    case 'library_24_filled':
      return <Library24Filled {...rest} />;
    case 'cloud_backup_24_filled':
      return <CloudBackup24Filled {...rest} />;
    case 'content_settings_24_filled':
      return <ContentSettings24Filled {...rest} />;
    case 'desktop_pulse_filled':
      return <DesktopPulseFilled {...rest} />;
    case 'book_question_mark_24_filled':
      return <BookQuestionMark24Filled {...rest} />;
    case 'album_24_filled':
      return <Album24Filled {...rest} />;
    case 'briefcase_24_filled':
      return <Briefcase24Filled {...rest} />;
    case 'send_24_filled':
      return <Send24Filled {...rest} />;
    case 'book_exclamation_mark_24_filled':
      return <BookExclamationMark24Filled {...rest} />;
    case 'archive_24_filled':
      return <Archive24Filled {...rest} />;
    case 'channel_arrow_left_24_filled':
      return <ChannelArrowLeft24Filled {...rest} />;
    case 'people_swap_24_filled':
      return <PeopleSwap24Filled {...rest} />;
    case 'book_contacts_24_filled':
      return <BookContacts24Filled {...rest} />;
    case 'notebook_error_24_filled':
      return <NotebookError24Filled {...rest} />;
    case 'people_money_24_filled':
      return <PeopleMoney24Filled {...rest} />;
    case 'people_settings_24_filled':
      return <PeopleSettings24Filled {...rest} />;
    case 'star_settings_24_filled':
      return <StarSettings24Filled {...rest} />;
  }

  return null;
};

export default IconFont;
