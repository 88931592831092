/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconJishiqi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M662.588235 0c11.294118 0 18.823529 3.644235 26.352941 10.962824s11.294118 14.637176 11.294118 25.6c0 10.992941-3.764706 18.281412-11.294118 25.6s-15.058824 10.992941-26.352941 10.992941h-112.941176v75.414588c111.194353 10.511059 204.408471 53.308235 282.352941 125.711059C914.823529 358.4 960 460.8 963.764706 585.185882c-3.764706 124.325647-45.176471 230.4-131.764706 310.844236C745.411765 976.444235 640 1020.355765 512 1024c-128-3.644235-237.176471-43.881412-320-128S64 709.481412 60.235294 585.155765c3.764706-124.355765 45.176471-230.4 131.764706-310.874353 77.944471-72.432941 171.158588-115.230118 282.383059-125.711059L474.352941 73.185882H361.411765c-11.294118 0-18.823529-3.674353-26.352941-10.992941s-11.294118-14.607059-11.294118-25.6c0-10.962824 3.764706-18.281412 11.294118-25.6S350.117647 0 361.411765 0h301.17647z m-150.588235 219.437176c-105.411765 3.644235-195.764706 36.562824-267.294118 106.044236S139.294118 482.785882 135.529412 585.185882c3.764706 102.4 37.647059 190.162824 109.17647 259.644236C316.235294 914.281412 406.588235 947.2 512 950.844235c105.411765-3.644235 195.764706-36.562824 267.294118-106.044235s105.411765-157.244235 109.17647-259.644235c0-106.074353-37.647059-190.192941-109.17647-259.674353C707.764706 256 617.411765 223.081412 512 219.437176z m0 73.125648c11.294118 0 18.823529 3.674353 26.352941 10.992941 7.529412 7.288471 11.294118 14.607059 11.294118 25.6l0.030117 192.783059c5.180235 3.222588 10.119529 7.228235 15.028706 12.016941 15.058824 14.607059 22.588235 29.244235 22.588236 51.2 0 21.925647-7.529412 36.562824-22.588236 51.2-15.058824 14.607059-30.117647 21.925647-52.705882 21.925647s-37.647059-7.318588-52.705882-21.925647c-15.058824-14.637176-22.588235-29.274353-22.588236-51.2 0-21.955765 7.529412-36.592941 22.588236-51.2a89.148235 89.148235 0 0 1 15.058823-12.047059v-192.752941c0-10.992941 3.764706-18.311529 11.294118-25.6 7.529412-7.318588 15.058824-10.992941 26.352941-10.992941z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconJishiqi.defaultProps = {
  size: 18,
};

export default IconJishiqi;
