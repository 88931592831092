/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Hangliefengexian: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M844.8 614.124308v0.275692h140.8a38.4 38.4 0 0 1 0 76.8H844.8v115.2a89.6 89.6 0 0 0 89.6 89.6h51.2a38.4 38.4 0 0 1 0 76.8h-51.2a166.4 166.4 0 0 1-166.4-166.4v-192.275692h76.8zM985.6 51.2a38.4 38.4 0 0 1 0 76.8h-51.2a89.639385 89.639385 0 0 0-89.6 89.6V332.8h140.8a38.4 38.4 0 0 1 0 76.8L866.461538 409.560615v-0.43323l-98.461538-0.039385V217.6a166.4 166.4 0 0 1 158.089846-166.203077l8.310154-0.196923zM157.538462 614.4v0.196923l98.422153-0.039385 0.039385 191.842462A166.4 166.4 0 0 1 89.6 972.8h-51.2a38.4 38.4 0 0 1 0-76.8h51.2a89.6 89.6 0 0 0 89.6-89.6V691.2H38.4a38.4 38.4 0 0 1 0-76.8H157.538462zM207.281231 99.918769A166.4 166.4 0 0 1 256 217.6l-0.039385 191.763692H157.538462v0.236308H38.4a38.4 38.4 0 0 1 0-76.8H179.2V217.6A89.6 89.6 0 0 0 89.6 128h-51.2a38.4 38.4 0 0 1 0-76.8h51.2c44.110769 0 86.449231 17.526154 117.681231 48.718769z"
        fill={getIconColor(color, 0, '#3F51B5')}
        opacity=".498"
      />

      <path
        d="M512 0c21.188923 0 38.4 17.211077 38.4 38.4V315.076923H551.384615v472.615385h-0.984615v197.907692c0 19.692308-14.808615 35.918769-33.910154 38.124308L512 1024a38.4 38.4 0 0 1-38.4-38.4L473.560615 787.692308H472.615385V315.076923h0.94523l0.039385-276.676923c0-19.692308 14.808615-35.918769 33.910154-38.124308z"
        fill={getIconColor(color, 1, '#3F51B5')}
      />

      <path
        d="M1024 512a38.4 38.4 0 0 1-38.4 38.4H708.923077V551.384615H236.307692v-0.984615H38.4a38.4 38.4 0 0 1-38.124308-33.910154L0 512c0-21.188923 17.211077-38.4 38.4-38.4L236.307692 473.560615V472.615385h472.615385v0.94523l276.676923 0.039385c19.692308 0 35.918769 14.808615 38.124308 33.910154z"
        fill={getIconColor(color, 2, '#3F51B5')}
      />
    </svg>
  );
};

export default Hangliefengexian;
