/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconArrowheadRight: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M324.266667 179.2l426.666666 298.666667c21.333333 12.8 25.6 38.4 8.533334 59.733333l-8.533334 8.533333-426.666666 298.666667c-21.333333 12.8-46.933333 8.533333-59.733334-8.533333-4.266667-8.533333-8.533333-17.066667-8.533333-25.6V213.333333c0-25.6 17.066667-42.666667 42.666667-42.666666 8.533333 0 17.066667 4.266667 25.6 8.533333z"
        fill={getIconColor(color, 0, '#515151')}
      />
    </svg>
  );
};

IconArrowheadRight.defaultProps = {
  size: 18,
};

export default IconArrowheadRight;
