/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Yingshoujine: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M985.429333 620.672c51.2 25.6 44.8 83.2 19.2 96l-377.514666 281.514667c-64 38.4-147.2 0-147.2 0s-217.514667-102.4-313.514667-102.4S0.042667 979.029333 0.042667 979.029333v-326.314666s38.4-19.2 76.8-44.8c89.6-57.6 166.357333-12.8 166.357333-12.8s83.2 64 198.357333 128c115.2 70.4 198.357333 44.8 198.357334 44.8 44.8 0 287.957333-179.2 345.514666-147.2z"
        fill={getIconColor(color, 0, '#DB791F')}
      />

      <path
        d="M597.376 0c186.752 0 341.333333 148.138667 341.333333 334.933333 0 186.709333-154.581333 347.733333-341.333333 347.733334s-341.333333-154.581333-341.333333-341.333334 154.581333-341.333333 341.333333-341.333333z m70.954667 180.437333l-71.552 67.370667-71.253334-67.114667a36.949333 36.949333 0 0 0-50.048 0 31.957333 31.957333 0 0 0 0 47.104l77.098667 72.618667h-73.685333c-19.413333 0-30.848 12.885333-30.848 31.146667s11.434667 32.426667 30.848 32.426666h76.16v39.68h-76.16c-19.413333 0-30.848 11.861333-30.848 30.122667 0 18.261333 11.434667 33.450667 30.848 33.450667h76.16v47.701333c0 22.826667 18.048 39.722667 42.325333 39.722667 24.234667 0 42.325333-16.896 42.325333-39.722667V467.2h78.378667c19.370667 0 28.629333-12.16 28.629333-30.464 0-23.765333-13.482667-33.109333-32.896-33.109333h-74.112v-39.68h76.16c19.413333 0 30.848-14.165333 30.848-32.426667s-11.434667-31.146667-30.848-31.146667l-74.666666-0.042666 77.184-72.832a31.957333 31.957333 0 0 0 0-47.104 36.949333 36.949333 0 0 0-50.048 0z"
        fill={getIconColor(color, 1, '#DB791F')}
      />
    </svg>
  );
};

export default Yingshoujine;
