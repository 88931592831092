/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentSlideLayout24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M277.333333 373.333333a10.666667 10.666667 0 0 1 10.666667-10.666666h448a10.666667 10.666667 0 0 1 10.666667 10.666666v74.666667h-469.333334V373.333333z m0 138.666667v138.666667c0 5.888 4.778667 10.666667 10.666667 10.666666H384V512H277.333333z m458.666667 149.333333H448V512h298.666667v138.666667a10.666667 10.666667 0 0 1-10.666667 10.666666z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M85.333333 288A117.333333 117.333333 0 0 1 202.666667 170.666667h618.666666A117.333333 117.333333 0 0 1 938.666667 288v448A117.333333 117.333333 0 0 1 821.333333 853.333333H202.666667A117.333333 117.333333 0 0 1 85.333333 736V288zM288 298.666667A74.666667 74.666667 0 0 0 213.333333 373.333333v277.333334c0 41.216 33.450667 74.666667 74.666667 74.666666h448A74.666667 74.666667 0 0 0 810.666667 650.666667v-277.333334A74.666667 74.666667 0 0 0 736 298.666667H288z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

export default IcFluentSlideLayout24Filled;
