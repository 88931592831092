/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconRichengfull: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M128 256h768a128 128 0 0 1 128 128v512a128 128 0 0 1-128 128H128a128 128 0 0 1-128-128V384a128 128 0 0 1 128-128z m777.1392-160h-780.8a48 48 0 0 1 0-96h780.8a48 48 0 0 1 0 96z"
        fill={getIconColor(color, 0, '#5D73FA')}
      />

      <path
        d="M259.2 304A48 48 0 0 1 211.2 256V48a48 48 0 0 1 96 0V256c0 26.5216-21.504 48-48 48z m512 0A48 48 0 0 1 723.2 256V48a48 48 0 0 1 96 0V256c0 26.5216-21.504 48-48 48z"
        fill={getIconColor(color, 1, '#5D73FA')}
      />

      <path
        d="M467.2 560h-208a48 48 0 0 1 0-96h208a48 48 0 0 1 0 96z m304 256h-512a48 48 0 0 1 0-96h512a48 48 0 0 1 0 96z"
        fill={getIconColor(color, 2, '#DEE3FE')}
      />
    </svg>
  );
};

IconRichengfull.defaultProps = {
  size: 18,
};

export default IconRichengfull;
