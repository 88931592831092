/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCasesCommonly: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M475.99616 40.96c31.80544 0 61.80864 13.80352 82.20672 37.80608l70.79936 81.6128c7.20896 7.7824 16.7936 12.5952 27.60704 12.5952h170.41408c85.8112 0 156.01664 70.20544 156.01664 156.01664v498.03264C983.04 912.83456 912.83456 983.04 827.02336 983.04H196.97664C111.16544 983.04 40.96 912.83456 40.96 827.02336V196.97664C40.96 111.16544 111.16544 40.96 196.97664 40.96z m285.02016 558.03904H274.98496c-19.80416 0-36.00384 16.19968-36.00384 36.00384s16.1792 36.00384 36.00384 36.00384h486.03136c19.80416 0 36.00384-16.1792 36.00384-36.00384 0-19.80416-16.1792-36.00384-36.00384-36.00384z m-186.01984-180.0192h-300.032c-19.78368 0-35.98336 16.22016-35.98336 36.02432 0 19.78368 16.1792 36.00384 36.00384 36.00384h300.032c19.78368 0 35.98336-16.22016 35.98336-36.00384 0-19.80416-16.1792-36.00384-36.00384-36.00384z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCasesCommonly.defaultProps = {
  size: 18,
};

export default IconABusinessCasesCommonly;
