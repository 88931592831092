/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconGongyingshangliebiao: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M697.774316 638.357132a171.160402 171.160402 0 0 1 208.305741 26.111817 170.827604 170.827604 0 0 1 30.873384 199.729802l71.884297 71.858697c20.198259 20.198259 20.198259 52.735631 0.127999 72.908289a51.558039 51.558039 0 0 1-72.78029 0l-71.884297-71.858697a171.006803 171.006803 0 0 1-225.944018-239.102326l106.341655 106.316056a12.79991 12.79991 0 0 0 18.150273 0l41.266911-41.52291a12.79991 12.79991 0 0 0 0-18.124673zM869.037117 0C897.964914 0 921.593549 24.678227 921.593549 54.860416v506.697253c-96.588124-54.732417-219.95366-39.372524-301.565889 45.823679-96.229726 100.274498-99.378504 261.066973-9.574333 365.411842H52.556432C23.628635 972.79319 0 948.114963 0 917.932774V54.860416C0 24.678227 23.654234 0 52.556432 0zM408.931537 409.597133H205.464162C176.792362 409.597133 153.598925 426.774613 153.598925 447.996864S176.817962 486.396595 205.464162 486.396595h203.467375c28.671799 0 51.865237-17.17748 51.865237-38.399731s-23.219037-38.399731-51.865237-38.399731z m100.837695-255.998208H207.025751C177.509157 153.598925 153.598925 170.776405 153.598925 191.998656S177.509157 230.398387 207.025751 230.398387h302.743481C539.285825 230.398387 563.196058 213.220907 563.196058 191.998656S539.285825 153.598925 509.769232 153.598925z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconGongyingshangliebiao.defaultProps = {
  size: 18,
};

export default IconGongyingshangliebiao;
