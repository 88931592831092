/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const AIcFluentPeopleCommunity24Regular1: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M629.333333 640c41.216 0 74.666667 33.450667 74.666667 74.666667l-0.042667 41.045333c4.992 93.44-64.469333 140.672-189.098666 140.672-124.16 0-194.858667-46.506667-194.858667-139.050667v-42.666666c0-41.216 33.450667-74.666667 74.666667-74.666667h234.666666z m0 64h-234.666666a10.666667 10.666667 0 0 0-10.666667 10.666667v42.666666c0 50.176 37.845333 75.050667 130.858667 75.050667 92.501333 0 127.786667-24.064 125.141333-74.965333V714.666667a10.666667 10.666667 0 0 0-10.666667-10.666667z m-469.333333-277.333333h186.709333a170.965333 170.965333 0 0 0-4.053333 64H160a10.666667 10.666667 0 0 0-10.666667 10.666666v42.666667c0 50.176 37.845333 75.050667 130.858667 75.050667 19.712 0 36.821333-1.109333 51.498667-3.285334a117.034667 117.034667 0 0 0-50.048 67.242667l-1.450667 0.042667C156.032 683.050667 85.333333 636.544 85.333333 544v-42.666667c0-41.216 33.450667-74.666667 74.666667-74.666666z m704 0c41.216 0 74.666667 33.450667 74.666667 74.666666l-0.042667 41.045334c4.992 93.44-64.469333 140.672-189.098667 140.672l-7.210666-0.085334a117.546667 117.546667 0 0 0-51.968-68.522666c16.512 3.072 36.138667 4.608 59.178666 4.608 92.501333 0 127.786667-24.064 125.141334-74.965334V501.333333a10.666667 10.666667 0 0 0-10.666667-10.666666h-182.613333a172.8 172.8 0 0 0-4.096-64h186.709333zM512 341.333333a128 128 0 1 1 0 256 128 128 0 0 1 0-256z m0 64a64 64 0 1 0 0 128 64 64 0 0 0 0-128zM277.333333 128a128 128 0 1 1 0 256 128 128 0 0 1 0-256z m469.333334 0a128 128 0 1 1 0 256 128 128 0 0 1 0-256z m-469.333334 64a64 64 0 1 0 0 128 64 64 0 0 0 0-128z m469.333334 0a64 64 0 1 0 0 128 64 64 0 0 0 0-128z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default AIcFluentPeopleCommunity24Regular1;
