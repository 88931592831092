/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const ContentSettings24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M765.184 128.213333L757.333333 128H266.666667l-7.850667 0.213333A138.666667 138.666667 0 0 0 128 266.666667v490.666666l0.213333 7.850667A138.666667 138.666667 0 0 0 266.666667 896h246.272a275.754667 275.754667 0 0 1-36.181334-85.333333H266.666667l-5.461334-0.256A53.333333 53.333333 0 0 1 213.333333 757.333333L213.376 341.333333H810.666667v135.424c30.762667 7.253333 59.52 19.626667 85.376 36.181334V266.666667l-0.213334-7.850667a138.666667 138.666667 0 0 0-130.645333-130.56z m-296.106667 304.768a32 32 0 0 0-31.744-27.648h-149.333333l-4.352 0.298667A32 32 0 0 0 256 437.333333v298.666667l0.298667 4.352A32 32 0 0 0 288 768h149.333333l4.352-0.298667A32 32 0 0 0 469.333333 736v-298.666667l-0.298666-4.352zM320 469.333333h85.333333v234.666667h-85.333333V469.333333z m448-32a32 32 0 0 0-32-32h-191.829333l-4.309334 0.298667A32 32 0 0 0 544.170667 469.333333h191.829333l4.352-0.298666A32 32 0 0 0 768 437.333333z m-158.762667 158.933334a85.333333 85.333333 0 0 1-61.482666 106.538666l-24.96 6.144a244.437333 244.437333 0 0 0 0.298666 77.098667l23.04 5.546667a85.333333 85.333333 0 0 1 61.866667 107.093333l-7.978667 26.965333c18.730667 16.469333 40.106667 29.824 63.317334 39.296l21.034666-22.101333a85.333333 85.333333 0 0 1 123.733334 0l21.248 22.4a225.28 225.28 0 0 0 63.274666-38.954667l-8.448-29.269333a85.333333 85.333333 0 0 1 61.482667-106.496l24.917333-6.144a244.437333 244.437333 0 0 0-0.298666-77.141333l-22.997334-5.546667a85.333333 85.333333 0 0 1-61.866666-107.093333l7.978666-26.88a225.194667 225.194667 0 0 0-63.36-39.381334l-21.034666 22.144a85.333333 85.333333 0 0 1-123.690667 0l-21.290667-22.4a225.28 225.28 0 0 0-63.232 38.912l8.448 29.269334zM746.666667 810.666667c-34.133333 0-61.866667-28.672-61.866667-64 0-35.370667 27.733333-64 61.866667-64 34.176 0 61.866667 28.629333 61.866666 64 0 35.328-27.690667 64-61.866666 64z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default ContentSettings24Filled;
