/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShangchuan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M501.034667 480c14.634667-7.125333 29.226667-3.541333 43.861333 7.125333l106.069333 103.082667a33.706667 33.706667 0 0 1 0 49.792 40.576 40.576 0 0 1-54.869333 0l-43.904-39.125333v302.250666a36.138667 36.138667 0 0 1-36.565333 35.541334c-18.261333 0-36.565333-14.208-36.565334-35.541334v-302.250666L435.2 640a40.576 40.576 0 0 1-54.869333 0 33.706667 33.706667 0 0 1 0-49.792l109.696-103.082667c3.669333-3.584 7.338667-7.125333 11.008-7.125333zM512 85.333333c160.896 0 292.565333 110.208 321.834667 256 109.653333 28.458667 190.165333 128 190.165333 241.792 0 138.666667-113.365333 248.874667-256 248.874667h-36.565333v-71.125333H768c102.4 0 182.869333-78.208 182.869333-177.749334C950.869333 483.541333 870.4 405.333333 768 405.333333c0-138.666667-113.365333-248.874667-256-248.874666S256 266.666667 256 405.333333c-102.4 0-182.869333 78.208-182.869333 177.792C73.130667 682.666667 153.6 760.874667 256 760.874667h36.565333V832H256c-142.634667 0-256-110.208-256-248.874667C0 469.333333 80.469333 369.792 190.165333 341.333333 219.434667 195.541333 351.061333 85.333333 512 85.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconShangchuan.defaultProps = {
  size: 18,
};

export default IconShangchuan;
