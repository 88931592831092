/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconA8Shoukuandengji: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M479 256h-64v64h-63v-64h-64v64h-32v64h96v64h-96v64h96v64h64v-64h96v-64h-96v-64h96v-64h-33zM686.4 593.6l-97.2 97.1L544 736l45.2 45.3 97.2 97.1 45.3-45.2-65.2-65.2H896v-64H666.5l65.2-65.2z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M192 832V192h640v128h64V128H128v768h320v-64z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconA8Shoukuandengji.defaultProps = {
  size: 18,
};

export default IconA8Shoukuandengji;
