/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconCaidanquanxian: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M778.666667 512.568889V280.888889H896v-64h-117.333333v-64H896v-64h-117.333333v-28.444445h-85.333334v452.124445a224 224 0 1 0 85.333334 0z m-42.666667 358.542222A138.666667 138.666667 0 1 1 874.666667 732.444444a138.808889 138.808889 0 0 1-138.666667 138.666667zM398.222222 60.444444H113.777778a49.777778 49.777778 0 0 0-49.777778 49.777778v156.231111a49.777778 49.777778 0 0 0 49.777778 49.777778h284.444444a49.777778 49.777778 0 0 0 49.777778-49.777778V110.435556a49.777778 49.777778 0 0 0-49.777778-49.991112z m-35.555555 170.666667H149.333333v-85.333333h213.333334zM398.222222 380.444444H113.777778a49.777778 49.777778 0 0 0-49.777778 49.777778v156.231111a49.777778 49.777778 0 0 0 49.777778 49.777778h284.444444a49.777778 49.777778 0 0 0 49.777778-49.777778V430.435556a49.777778 49.777778 0 0 0-49.777778-49.991112z m-35.555555 170.666667H149.333333v-85.333333h213.333334zM398.222222 700.444444H113.777778a49.777778 49.777778 0 0 0-49.777778 49.777778v156.088889a49.777778 49.777778 0 0 0 49.777778 49.777778h284.444444a49.777778 49.777778 0 0 0 49.777778-49.777778v-155.875555a49.777778 49.777778 0 0 0-49.777778-49.991112z m-35.555555 170.666667H149.333333v-85.333333h213.333334z"
        fill={getIconColor(color, 0, '#231815')}
      />
    </svg>
  );
};

IconCaidanquanxian.defaultProps = {
  size: 18,
};

export default IconCaidanquanxian;
