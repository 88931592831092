/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Miaoshu: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M874.666667 256a42.666667 42.666667 0 0 0-42.666667-42.666667H201.642667a42.666667 42.666667 0 0 0-42.666667 42.666667v594.474667a8.533333 8.533333 0 0 0 14.592 6.016l104.704-104.704 1.706667-1.706667H832a42.666667 42.666667 0 0 0 42.666667-42.666667V256z m-149.12 76.629333a29.824 29.824 0 1 1 0 59.648H308.096a29.824 29.824 0 0 1 0-59.648h417.450667z m0 119.296a29.824 29.824 0 1 1 0 59.605334H308.096a29.824 29.824 0 1 1 0-59.605334h417.450667z m29.866666 149.077334a29.824 29.824 0 0 0-29.866666-29.866667H308.096a29.824 29.824 0 1 0 0 59.690667h417.450667a29.824 29.824 0 0 0 29.866666-29.866667z"
        fill={getIconColor(color, 0, '#3A7FCC')}
      />
    </svg>
  );
};

export default Miaoshu;
