/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const StarSettings24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M460.288 132.394667c21.12-42.837333 82.176-42.837333 103.296 0l100.608 203.818666 224.981333 32.682667c47.232 6.869333 66.090667 64.938667 31.914667 98.261333l-38.613333 37.632a277.333333 277.333333 0 0 0-402.816 317.013334l-168.96 88.832c-42.24 22.186667-91.605333-13.653333-83.541334-60.757334l38.4-224-162.773333-158.72c-34.176-33.28-15.317333-91.392 31.914667-98.261333L359.68 336.213333l100.608-203.818666z m148.906667 463.872a85.333333 85.333333 0 0 1-61.482667 106.538666l-24.917333 6.144a244.437333 244.437333 0 0 0 0.256 77.098667l23.04 5.546667a85.333333 85.333333 0 0 1 61.866666 107.093333l-7.978666 26.965333c18.773333 16.469333 40.106667 29.824 63.317333 39.296l21.077333-22.101333a85.333333 85.333333 0 0 1 123.690667 0l21.248 22.4a225.322667 225.322667 0 0 0 63.274667-38.954667l-8.448-29.269333a85.333333 85.333333 0 0 1 61.482666-106.496l24.917334-6.144a243.882667 243.882667 0 0 0-0.256-77.141333l-23.04-5.546667a85.333333 85.333333 0 0 1-61.866667-107.093333l7.978667-26.88a225.194667 225.194667 0 0 0-63.317334-39.381334l-21.034666 22.144a85.333333 85.333333 0 0 1-123.733334 0l-21.248-22.4c-23.210667 9.386667-44.544 22.613333-63.274666 38.912l8.448 29.269334zM746.666667 810.666667c-34.133333 0-61.866667-28.672-61.866667-64 0-35.370667 27.733333-64 61.866667-64 34.133333 0 61.866667 28.629333 61.866666 64 0 35.328-27.733333 64-61.866666 64z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default StarSettings24Filled;
