/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Neibuhuazhuan: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M607.321043 683.074783a36.774957 36.774957 0 0 1 15.872 62.397217l-59.436521 59.65913H899.116522l-59.481044-59.65913a36.774957 36.774957 0 0 1 0.467479-51.44487 36.507826 36.507826 0 0 1 51.266782-0.445217l121.922783 122.434783c14.246957 14.313739 14.246957 37.509565 0 51.845565l-121.922783 122.256696a36.507826 36.507826 0 0 1-51.266782-0.48974 36.774957 36.774957 0 0 1-0.422957-51.422608l59.503304-59.681392H563.712l59.481043 59.659131a36.774957 36.774957 0 0 1-15.872 62.419478 36.507826 36.507826 0 0 1-35.817739-10.551652l-121.900521-122.212174a36.730435 36.730435 0 0 1 0-51.867826l121.900521-122.368a36.507826 36.507826 0 0 1 35.817739-10.529391zM73.149217 560.39513l487.624348 0.044522a73.238261 73.238261 0 0 1 72.793044 66.337391l0.356174 6.989914v8.325565a85.125565 85.125565 0 0 0-96.946087 16.873739l-121.900522 122.345739a85.74887 85.74887 0 0 0 0 121.09913l33.302261 33.302261c-43.742609 11.397565-89.711304 15.983304-131.405913 15.983305-132.741565 0-308.936348-46.747826-316.727652-208.873739L0 731.603478V633.766957a73.282783 73.282783 0 0 1 66.114783-73.015653l7.034434-0.356174z m828.994783 0a73.238261 73.238261 0 0 1 72.793043 66.29287l0.333914 7.078957v74.863304l-49.352348-49.619478a85.125565 85.125565 0 0 0-109.612522-9.572174 85.77113 85.77113 0 0 0-27.89287 106.785391h-106.807652c0.333913-3.628522 0.578783-7.279304 0.734609-10.907826l0.356174-13.712696V633.766957c0-24.153043-7.279304-46.525217-19.456-65.446957l-5.520696-7.92487zM316.950261 22.26087c121.188174 0 219.425391 98.57113 219.425391 220.16 0 121.544348-98.237217 220.137739-219.425391 220.137739-121.210435 0-219.447652-98.57113-219.447652-220.16C97.52487 120.832 195.762087 22.26087 316.994783 22.26087z m438.850782 97.836521c94.274783 0 170.674087 76.666435 170.674087 171.230609s-76.399304 171.230609-170.674087 171.230609c-94.252522 0-170.651826-76.666435-170.651826-171.230609s76.399304-171.230609 170.651826-171.230609z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Neibuhuazhuan;
