/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentMoney24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M874.666667 279.936c38.272 23.637333 64 67.754667 64 118.272v238.933333c0 57.344-21.333333 112.341333-59.349334 152.874667-38.016 40.533333-89.6 63.317333-143.317333 63.317333H298.666667c-45.738667 0-88.021333-26.026667-110.890667-68.266666H736c76.586667 0 138.666667-66.218667 138.666667-147.925334zM714.666667 170.666667c25.472 0 49.877333 10.794667 67.882666 29.994666 18.005333 19.2 28.117333 45.226667 28.117334 72.405334v341.333333c0 27.178667-10.112 53.205333-28.117334 72.405333-18.005333 19.2-42.410667 29.994667-67.882666 29.994667H181.333333C128.298667 716.8 85.333333 670.933333 85.333333 614.4v-341.333333C85.333333 216.533333 128.298667 170.666667 181.333333 170.666667h533.333334zM618.666667 238.933333h-341.333334v34.133334c0 56.533333-42.965333 102.4-96 102.4H149.333333V512h32c53.034667 0 96 45.866667 96 102.4v34.133333h341.333334v-34.133333c0-56.533333 42.965333-102.4 96-102.4h32V375.466667h-32c-53.034667 0-96-45.866667-96-102.4v-34.133334z m128 341.333334h-32c-17.664 0-32 15.274667-32 34.133333v34.133333h32c17.664 0 32-15.274667 32-34.133333v-34.133333z m-565.333334 0H149.333333v34.133333c0 18.858667 14.336 34.133333 32 34.133333H213.333333v-34.133333c0-18.858667-14.336-34.133333-32-34.133333z m266.666667-273.066667c70.698667 0 128 61.141333 128 136.533333 0 75.392-57.301333 136.533333-128 136.533334-45.738667 0-87.978667-26.026667-110.848-68.266667a144.469333 144.469333 0 0 1 0-136.533333c22.869333-42.24 65.109333-68.266667 110.848-68.266667z m0 68.266667c-35.328 0-64 30.549333-64 68.266666 0 37.717333 28.672 68.266667 64 68.266667s64-30.549333 64-68.266667c0-37.717333-28.672-68.266667-64-68.266666zM213.333333 238.933333h-32c-17.664 0-32 15.274667-32 34.133334v34.133333h32c17.664 0 32-15.274667 32-34.133333v-34.133334z m501.333334 0H682.666667v34.133334c0 18.858667 14.336 34.133333 32 34.133333h32v-34.133333c0-18.858667-14.336-34.133333-32-34.133334z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentMoney24Regular;
