/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPeopleSettings24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M729.173333 456.106667a149.333333 149.333333 0 1 0-149.333333-149.333334 149.333333 149.333333 0 0 0 149.333333 149.333334z m0-234.666667a85.333333 85.333333 0 1 1-85.333333 85.333333 85.333333 85.333333 0 0 1 85.333333-85.333333zM345.173333 456.106667a192 192 0 1 0-192-192 192 192 0 0 0 192 192z m0-320a128 128 0 1 1-128 128 128 128 0 0 1 128-128zM358.826667 818.346667h-27.306667a321.706667 321.706667 0 0 1-139.093333-32 101.12 101.12 0 0 1-60.586667-95.573334v-68.266666a21.76 21.76 0 0 1 5.973333-11.093334 20.053333 20.053333 0 0 1 10.666667-5.546666h334.08a274.773333 274.773333 0 0 1 46.08-64h-375.466667a85.333333 85.333333 0 0 0-85.333333 85.333333v73.813333c6.826667 141.226667 160.853333 182.186667 276.906667 182.186667a447.573333 447.573333 0 0 0 138.24-20.906667 288.426667 288.426667 0 0 1-23.04-59.733333 369.493333 369.493333 0 0 1-101.12 15.786667z"
        fill={getIconColor(color, 0, '#212121')}
      />

      <path
        d="M952.746667 693.76l-23.04-5.546667a85.333333 85.333333 0 0 1-62.72-102.826666v-4.266667l8.106666-26.88a230.4 230.4 0 0 0-63.146666-39.253333l-21.333334 22.186666a85.333333 85.333333 0 0 1-120.32 2.986667l-3.413333-2.986667-21.333333-22.186666a229.12 229.12 0 0 0-63.146667 38.826666l8.533333 29.013334a85.333333 85.333333 0 0 1-58.453333 105.813333h-2.986667l-24.746666 5.973333a234.666667 234.666667 0 0 0 0 77.226667l23.04 5.546667a85.333333 85.333333 0 0 1 63.146666 102.826666v4.266667l-8.106666 26.88a217.173333 217.173333 0 0 0 63.573333 39.253333l20.906667-21.76a85.333333 85.333333 0 0 1 120.746666-2.986666l2.986667 2.986666 21.333333 22.186667a229.12 229.12 0 0 0 63.146667-38.826667l-8.533333-29.44a85.333333 85.333333 0 0 1 58.453333-105.386666h3.413333l24.746667-6.4a246.613333 246.613333 0 0 0-0.853333-77.226667zM810.666667 872.106667a143.36 143.36 0 0 0-79.786667-24.746667 140.373333 140.373333 0 0 0-79.786667 25.173333 138.24 138.24 0 0 0-20.053333-85.333333 140.373333 140.373333 0 0 0-59.306667-53.333333 141.653333 141.653333 0 0 0 80.64-139.52 140.373333 140.373333 0 0 0 79.36 24.32 139.093333 139.093333 0 0 0 78.933334-24.32 140.8 140.8 0 0 0 20.48 85.333333 138.666667 138.666667 0 0 0 59.306666 53.76A141.226667 141.226667 0 0 0 810.666667 872.106667z"
        fill={getIconColor(color, 1, '#212121')}
      />

      <path
        d="M667.306667 733.44a64 61.866667 90 1 0 123.733333 0 64 61.866667 90 1 0-123.733333 0Z"
        fill={getIconColor(color, 2, '#212121')}
      />
    </svg>
  );
};

export default IcFluentPeopleSettings24Regular;
