/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconBusinessAreaSpecializationSetting: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M261.28384 549.2736l127.3856 127.0784c12.12416 11.75552 27.2384 18.2272 42.76224 18.2272h172.2368c14.9504 0 29.696-6.02112 41.49248-16.95744l138.1376-128.47104h191.75424c26.99264 0 48.9472 28.0576 48.9472 62.464V900.096c0 34.4064-21.95456 62.464-48.9472 62.464H48.9472v-0.12288c-26.99264 0-48.9472-28.03712-48.9472-62.464V611.5328c0-34.42688 21.95456-62.464 48.9472-62.464h192.55296c7.08608 0 15.6672 0 19.78368 0.2048z m612.22912-154.33728c9.03168 0 16.6912 10.48576 16.6912 22.79424s-7.65952 22.79424-16.6912 22.79424H797.4912c-14.97088 0-29.73696 6.0416-41.51296 16.9984l-125.3376 116.49024c-8.43776 7.86432-18.39104 11.96032-28.79488 11.96032h-183.64416a12.84096 12.84096 0 0 1-9.03168-3.9936l-126.70976-123.33056c-12.00128-11.61216-27.21792-18.1248-42.63936-18.1248H143.62624c-9.03168 0-16.6912-10.48576-16.6912-22.79424 0-12.288 7.65952-22.79424 16.6912-22.79424h729.9072z m-11.20256-169.71776c15.44192 0 27.89376 16.42496 27.89376 36.59776v1.24928c0 20.86912-12.9024 37.72416-28.91776 37.72416H155.7504v-0.1024c-15.89248 0-28.93824-16.9984-28.93824-37.72416 0-20.86912 12.92288-37.74464 28.93824-37.74464h706.56z m0-163.77856c15.44192 0 27.89376 16.40448 27.89376 36.57728v1.26976c0 20.84864-12.9024 37.72416-28.91776 37.72416H155.7504v-0.12288c-15.89248 0-28.93824-16.97792-28.93824-37.72416C126.81216 78.31552 139.73504 61.44 155.7504 61.44h706.56z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconBusinessAreaSpecializationSetting.defaultProps = {
  size: 18,
};

export default IconBusinessAreaSpecializationSetting;
