/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const InformationIcon: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M1024 512A512 512 0 1 1 0 512a512 512 0 0 1 1024 0"
        fill={getIconColor(color, 0, '#59647D')}
      />

      <path
        d="M439.588571 638.902857h120.978286C545.645714 510.537143 749.568 493.714286 749.568 357.814857c0-123.830857-98.742857-187.172571-233.691429-187.172571-99.620571 0-181.540571 45.641143-241.078857 114.541714l76.288 69.778286c45.641143-47.469714 92.16-73.508571 148.992-73.508572 73.508571 0 118.198857 31.670857 118.198857 88.429715 0 90.331429-198.217143 122.88-178.761142 269.019428z m61.44 248.539429c46.518857 0 81.92-34.377143 81.92-82.797715 0-48.420571-35.401143-81.92-81.92-81.92-47.542857 0-82.870857 33.499429-82.870857 81.92 0 48.347429 34.377143 82.797714 82.797715 82.797715z"
        fill={getIconColor(color, 1, '#FFFFFF')}
      />
    </svg>
  );
};

export default InformationIcon;
