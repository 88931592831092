/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconRizhiguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 0v438.857143h438.857143v512a73.142857 73.142857 0 0 1-73.142857 73.142857H146.285714a73.142857 73.142857 0 0 1-73.142857-73.142857V73.142857a73.142857 73.142857 0 0 1 73.142857-73.142857h365.714286z m219.428571 658.285714H292.571429a73.142857 73.142857 0 1 0 0 146.285715h438.857142a73.142857 73.142857 0 0 0 0-146.285715zM658.285714 0l292.498286 292.498286L658.285714 292.571429V0z"
        fill={getIconColor(color, 0, '#7C8295')}
      />
    </svg>
  );
};

IconRizhiguanli.defaultProps = {
  size: 18,
};

export default IconRizhiguanli;
