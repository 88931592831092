/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTucenggouxuan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M896.969659 16.576036 119.702341 16.576036c-61.317329 0-111.038333 49.665189-111.038333 111.038333l0 777.265288c0 61.265573 49.721004 111.039348 111.038333 111.039348l777.266302 0c61.314284 0 111.036304-49.773775 111.036304-111.039348L1008.004947 127.614369C1008.005962 66.241225 958.283943 16.576036 896.969659 16.576036zM877.124894 340.796156 443.975232 773.888988c-11.680554 11.736369-26.883542 17.734958-42.168729 18.026211-15.069031 1.231989-30.562257-3.90096-42.085514-15.423203L133.583021 550.406799c-20.767234-20.820005-20.767234-54.548493 0-75.368498 20.820005-20.81899 54.59416-20.81899 75.414165 0L389.301916 655.385655l401.179853-401.337149c23.964925-23.853295 62.727926-23.853295 86.643125 0C901.092864 278.126075 901.092864 316.834275 877.124894 340.796156z"
        fill={getIconColor(color, 0, '#272636')}
      />
    </svg>
  );
};

IconTucenggouxuan.defaultProps = {
  size: 18,
};

export default IconTucenggouxuan;
