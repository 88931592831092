/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZhangdanluru: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M970.112 192C999.872 192 1024 215.872 1024 245.344V832c0 88.352-72.384 160-161.696 160H161.696C72.416 992 0 920.352 0 832V245.344c0-14.144 5.664-27.712 15.776-37.728A54.176 54.176 0 0 1 53.888 192h916.224z m-320.704 165.76c-15.136-11.008-35.2-5.76-44.8 11.648l-93.12 169.824-92.096-167.904c-9.6-17.44-29.632-22.656-44.8-11.648-15.168 11.04-19.68 34.08-10.144 51.52l84.032 153.28h-64.032c-11.584 0-22.304 7.104-28.096 18.656a42.24 42.24 0 0 0 0 37.312c5.76 11.552 16.512 18.656 28.096 18.656h95.072v45.6h-95.072c-17.92 0-32.448 16.736-32.448 37.344s14.528 37.312 32.448 37.312h95.136v67.296c0 20.64 14.528 37.344 32.448 37.344s32.448-16.704 32.448-37.344v-67.36h95.072c17.92 0 32.448-16.704 32.448-37.312 0-20.608-14.528-37.344-32.448-37.344v-0.064h-95.072v-45.6h95.072c17.92 0 32.448-16.704 32.448-37.312 0-20.608-14.528-37.344-32.448-37.344H574.4l85.12-155.008c9.6-17.408 5.024-40.544-10.112-51.52zM862.304 32c34.048 0 51.968 15.968 53.76 47.872l0.16 5.44c0 35.584-17.984 53.344-53.92 53.344H161.696c-34.048 0-51.968-15.936-53.76-47.872l-0.16-5.44C107.776 49.792 125.76 32 161.696 32h700.608z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconZhangdanluru.defaultProps = {
  size: 18,
};

export default IconZhangdanluru;
