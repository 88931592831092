/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const HomeFilled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M450.133333 108.074667a96 96 0 0 1 123.733334 0l288 242.986666c21.674667 18.218667 34.133333 45.056 34.133333 73.386667v418.218667a74.666667 74.666667 0 0 1-74.666667 74.666666h-128a74.666667 74.666667 0 0 1-74.666666-74.666666v-213.333334a32 32 0 0 0-32-32h-149.333334a32 32 0 0 0-32 32v213.333334a74.666667 74.666667 0 0 1-74.666666 74.666666h-128A74.666667 74.666667 0 0 1 128 842.666667V424.405333c0-28.288 12.458667-55.125333 34.133333-73.386666l288-242.944z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default HomeFilled;
