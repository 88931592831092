/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentCalendarEdit24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1038 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M895.904 188A156 156 0 0 0 739.904 32H188A156 156 0 0 0 32 188v551.952a156 156 0 0 0 156 156h235.872l17.088-68.352 0.96-3.648H188a84 84 0 0 1-84-84v-444h719.904v131.184a158.4 158.4 0 0 1 72-10.56V188zM188 104h551.952c46.368 0 84 37.584 84 84V224h-720v-36c0-46.416 37.632-84 84-84h0.048z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M789.776 497.6a119.232 119.232 0 1 1 179.52 155.52l-5.088 6.624-5.952 6.576-266.928 266.88c-12.576 12.576-27.36 22.56-44.016 29.472l-10.176 3.84-9.6 2.784-82.8 20.736a65.04 65.04 0 0 1-80.64-67.2l0.624-5.76 1.152-5.904 20.688-82.752c4.32-17.184 11.904-33.36 22.56-47.904l6.768-8.496 6.816-7.344 255.792-255.648-0.24-0.24 11.52-11.184z m27.84 55.824l-6.144 5.712-243.024 242.88c-10.176 10.176-17.856 22.416-22.56 35.856l-2.4 8.208-20.496 81.84a18.768 18.768 0 0 1-0.624 1.92l12.672-2.448 80.64-20.16c13.92-3.456 26.832-10.032 37.776-19.152l6.336-5.76 243.072-242.976c33.792-34.896 39.36-71.856 12.48-98.64-22.848-22.896-58.176-18.624-91.536 7.584l-6.24 5.136zM492.8 358.4a201.648 201.648 0 0 1 199.68 173.376l-71.328 71.184 2.208-8.16c2.256-8.448 3.648-17.088 4.224-25.92l0.288-8.88A135.264 135.264 0 0 0 492.8 424.928 135.264 135.264 0 0 0 357.728 560a135.264 135.264 0 0 0 169.824 130.56l8.16-2.208-55.68 55.68L472.64 752l-6.24 7.872A201.648 201.648 0 0 1 492.8 358.4z"
        fill={getIconColor(color, 1, '#333333')}
      />

      <path
        d="M479.024 436.64a26.88 26.88 0 0 0-26.88 26.928v110.208l0.096 2.736c1.92 14.16 13.248 24.192 26.784 24.192h73.44l2.736-0.144c14.88-2.016 25.104-14.4 24.144-28.608l-0.528-3.792-1.44-4.512a26.88 26.88 0 0 0-24.864-16.8l-46.608-0.048V463.568l-0.096-2.736a27.12 27.12 0 0 0-26.784-24.192z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

export default IcFluentCalendarEdit24Regular;
