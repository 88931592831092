/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZiyuanguanliLinear: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M459.3664 128a19.8656 19.8656 0 0 1 19.9168 19.84v392.96h396.8256c10.9056 0 19.7632 8.7552 19.8912 19.6608V563.2a433.92 433.92 0 0 1-127.488 307.712A433.7152 433.7152 0 0 1 460.8 998.4a432.512 432.512 0 0 1-169.4208-34.2016 436.6336 436.6336 0 0 1-231.5776-231.6032A432.5376 432.5376 0 0 1 25.6 563.2 433.92 433.92 0 0 1 152.6272 255.9488 433.7408 433.7408 0 0 1 459.3664 128z m-57.6256 76.8c-88.576 4.5056-132.2496 41.9072-194.688 104.9088A356.352 356.352 0 0 0 102.4 562.944a356.3264 356.3264 0 0 0 105.0112 253.5936A356.1728 356.1728 0 0 0 460.9792 921.6a356.1728 356.1728 0 0 0 253.5424-105.0368c63.616-63.2832 100.992-104.2176 104.6784-193.8944H419.712a17.9968 17.9968 0 0 1-17.9968-17.9968V204.8z m131.6352-179.0976a433.5104 433.5104 0 0 1 170.4704 34.6112 437.6832 437.6832 0 0 1 139.3664 94.3104 441.9072 441.9072 0 0 1 94.2848 140.0064c23.04 54.2208 35.072 112.5632 35.3024 171.5456a20.3008 20.3008 0 0 1-5.8368 14.3616 19.968 19.968 0 0 1-14.208 5.9648H532.0448a20.1216 20.1216 0 0 1-20.0448-20.1472V45.9776a20.1216 20.1216 0 0 1 19.5584-20.2752h1.792zM588.8 102.4v307.2h307.2a325.12 325.12 0 0 0-94.6944-212.736A319.104 319.104 0 0 0 588.8 102.4z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconZiyuanguanliLinear.defaultProps = {
  size: 18,
};

export default IconZiyuanguanliLinear;
