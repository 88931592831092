/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessStampFilesUploadTemplate: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M891.957841 1023.97952H132.083118c-27.688406 0-50.134037-23.408172-50.134037-52.284394V52.284394C81.949081 23.408172 104.394712 0 132.083118 0h759.874723C919.646247 0 942.071399 23.408172 942.071399 52.284394v919.369773a53.451731 53.451731 0 0 1-14.663387 36.98614c-9.420612 9.830203-22.158917 15.339213-35.470651 15.339213z m-568.923022-200.597588a45.116538 45.116538 0 0 0-45.177976 44.952701 45.116538 45.116538 0 0 0 45.177976 44.973181h466.627468A45.116538 45.116538 0 0 0 834.758345 868.334633a45.116538 45.116538 0 0 0-45.116538-44.952701H323.034819z m138.564909-197.894282a45.116538 45.116538 0 0 0-45.116538 44.973181 45.116538 45.116538 0 0 0 45.116538 44.952701h328.062559a45.116538 45.116538 0 0 0 45.116537-44.952701 45.116538 45.116538 0 0 0-45.116537-44.973181h-328.083039z m170.431151-201.846843a45.116538 45.116538 0 0 0-45.116537 44.952701 45.116538 45.116538 0 0 0 45.116537 44.973181h157.569969a45.116538 45.116538 0 0 0 45.116538-44.973181 45.116538 45.116538 0 0 0-45.116538-44.952701h-157.569969z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessStampFilesUploadTemplate.defaultProps = {
  size: 18,
};

export default IconABusinessStampFilesUploadTemplate;
