/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconCheckboxUnselected: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M799 161c16.954 0 32.986 6.696 45.145 18.855C856.304 192.014 863 208.046 863 225v574c0 16.954-6.696 32.986-18.855 45.145C831.986 856.304 815.954 863 799 863H225c-16.954 0-32.986-6.696-45.145-18.855C167.696 831.986 161 815.954 161 799V225c0-16.954 6.696-32.986 18.855-45.145C192.014 167.696 208.046 161 225 161h574m0-64H225c-70.4 0-128 57.6-128 128v574c0 70.4 57.6 128 128 128h574c70.4 0 128-57.6 128-128V225c0-70.4-57.6-128-128-128z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconCheckboxUnselected.defaultProps = {
  size: 18,
};

export default IconCheckboxUnselected;
