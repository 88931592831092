/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXuanzhong: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M864.298667 0C952.362667 0 1024 70.485333 1024 157.184v709.632C1024 953.514667 952.362667 1024 864.298667 1024H159.701333C71.637333 1024 0 953.514667 0 866.816V157.184C0 70.485333 71.637333 0 159.701333 0h704.597334z m0 72.533333H159.701333c-47.445333 0-86.016 37.973333-86.016 84.650667v709.632c0 46.677333 38.570667 84.650667 86.016 84.650667h704.597334c47.445333 0 86.016-37.973333 86.016-84.650667V157.184c0-46.677333-38.570667-84.650667-86.016-84.650667zM741.546667 383.658667c14.506667 14.293333 14.592 37.632 0.256 52.138666l-243.328 245.546667c-11.52 11.690667-27.221333 18.261333-43.605334 18.176h-0.512a61.013333 61.013333 0 0 1-43.861333-18.858667l-123.690667-128.853333a36.864 36.864 0 0 1 53.12-51.029333l114.986667 119.765333 234.496-236.629333a36.906667 36.906667 0 0 1 52.138667-0.256z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconXuanzhong.defaultProps = {
  size: 18,
};

export default IconXuanzhong;
