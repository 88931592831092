/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentWallet24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M821.333333 257.92V245.333333A117.333333 117.333333 0 0 0 704 128H224a96 96 0 0 0-95.744 102.784A32.298667 32.298667 0 0 0 128 234.666667v522.666666A138.666667 138.666667 0 0 0 266.666667 896h533.333333a117.333333 117.333333 0 0 0 117.333333-117.333333v-405.333334a117.333333 117.333333 0 0 0-96-115.413333zM224 192H704c29.44 0 53.333333 23.893333 53.333333 53.333333V256H224a32 32 0 0 1 0-64z m469.333333 362.666667h85.333334a32 32 0 0 1 0 64h-85.333334a32 32 0 0 1 0-64z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentWallet24Filled;
