/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconMeetingManagementMyMeeting: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1150 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M502.0266467 287.63540965m-171.14322292 0a171.14322215 171.14322215 0 1 0 342.28644507 0 171.14322215 171.14322215 0 1 0-342.28644507 0Z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M996.15052317 803.15218937c8.34844998-96.52895147-13.04445327-264.01972697-135.14053223-339.15577594a78.78849566 78.78849566 0 1 0-74.61427067 52.17781155 80.35382985 80.35382985 0 0 0 42.78580496-12.52267496c125.22674806 70.96182401 117.92185473 256.71483361 114.79118637 293.76108019h-78.78849566V690.44811627a156.53343469 156.53343469 0 0 0-156.53343547-156.53343547H294.35895553a156.53343469 156.53343469 0 0 0-156.53343471 156.53343547v172.70855632a44.35113991 44.35113991 0 0 0 44.35113992 44.35113991h796.75518416a44.35113991 44.35113991 0 0 0 44.3511399-44.35113991V846.98155095a44.35113991 44.35113991 0 0 0-27.13246163-43.82936158z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconMeetingManagementMyMeeting.defaultProps = {
  size: 18,
};

export default IconMeetingManagementMyMeeting;
