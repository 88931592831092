/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAdministrationTenantDataDictionary: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M61.44 851.84512C61.44 949.51424 140.63616 1024 238.42816 1024h675.88096A48.29184 48.29184 0 0 0 962.56 975.79008a48.16896 48.16896 0 0 0-48.25088-48.18944c-44.3392 0-80.4864-31.47776-80.4864-75.776 0-44.27776 36.1472-75.75552 80.4864-75.75552 25.16992 0 45.01504-19.37408 47.32928-43.9296H962.56V80.384A80.42496 80.42496 0 0 0 882.0736 0H141.9264C97.44384 0 61.44 35.98336 61.44 84.992v739.28704m241.29536-615.34208h418.4064c26.66496 0 48.27136 16.30208 48.27136 36.41344s-21.6064 36.39296-48.27136 36.39296h-418.4064c-26.66496 0-48.25088-16.2816-48.25088-36.39296 0-20.11136 21.58592-36.39296 48.25088-36.39296z m0 227.75808h418.4064c26.66496 0 48.27136 16.2816 48.27136 36.39296 0 20.11136-21.6064 36.39296-48.27136 36.39296h-418.4064c-26.66496 0-48.25088-16.2816-48.25088-36.39296 0-20.11136 21.58592-36.39296 48.25088-36.39296z m-64.3072 490.9056c-44.3392 0-80.4864-31.47776-80.4864-75.776 0-44.27776 36.1472-75.75552 80.4864-75.75552h518.9632c-12.47232 24.20736-20.0704 46.592-20.0704 75.776 0 29.16352 7.72096 51.52768 20.0704 75.75552h-518.9632z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAdministrationTenantDataDictionary.defaultProps = {
  size: 18,
};

export default IconAAdministrationTenantDataDictionary;
