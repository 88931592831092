/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPerson24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M757.504 597.333333a95.957333 95.957333 0 0 1 96 95.957334v24.533333c0 38.144-13.653333 75.093333-38.485333 104.021333-66.986667 78.250667-168.832 116.864-303.018667 116.864-134.229333 0-236.032-38.613333-302.848-116.906666a160 160 0 0 1-38.314667-103.893334v-24.618666a95.957333 95.957333 0 0 1 95.957334-96h490.709333z m0 64H266.794667a31.957333 31.957333 0 0 0-32 31.957334v24.618666c0 22.869333 8.192 44.970667 23.04 62.336 53.461333 62.634667 137.344 94.464 254.165333 94.464s200.789333-31.829333 254.378667-94.464a96 96 0 0 0 23.082666-62.421333v-24.533333a31.957333 31.957333 0 0 0-31.957333-32zM512 85.504a213.333333 213.333333 0 1 1 0 426.666667 213.333333 213.333333 0 0 1 0-426.666667z m0 64a149.333333 149.333333 0 1 0 0 298.666667 149.333333 149.333333 0 0 0 0-298.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentPerson24Regular;
