/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksTaskDashboard: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M462.82752 888.85248v-131.8912H108.40064c-59.82208 0-108.3392-46.3872-108.3392-103.6288V144.5888C0.06144 87.36768 48.55808 40.96 108.40064 40.96h807.34208c59.82208 0 108.3392 46.40768 108.3392 103.6288V653.312c0 57.26208-48.51712 103.66976-108.3392 103.66976H561.31584v131.8912h251.04384c11.75552 0 23.01952 4.44416 31.3344 12.3904 8.2944 7.96672 12.96384 18.7392 12.96384 29.98272v9.4208c0 23.38816-19.82464 42.37312-44.29824 42.37312H211.7632c-24.45312 0-44.27776-18.98496-44.27776-42.3936v-9.4208c0-11.22304 4.64896-21.99552 12.96384-29.96224a45.32224 45.32224 0 0 1 31.3344-12.41088h251.04384z m-118.1696-659.41504c-27.15648 0.02048-49.152 21.0944-49.17248 47.06304v263.7824c0.90112 25.35424 22.67136 45.48608 49.21344 45.48608s48.29184-20.13184 49.21344-45.50656V276.54144c0-12.4928-5.20192-24.4736-14.4384-33.30048a50.3808 50.3808 0 0 0-34.816-13.80352z m295.40352 94.12608c-27.19744 0-49.23392 21.0944-49.23392 47.104v169.59488c0 26.0096 22.03648 47.104 49.23392 47.104 27.19744 0 49.2544-21.0944 49.2544-47.104v-169.53344c0-26.0096-22.05696-47.104-49.2544-47.104v-0.06144z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksTaskDashboard.defaultProps = {
  size: 18,
};

export default IconAWorksTaskDashboard;
