/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconOperation: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M1024 0l0 1024-1024 0L0 0z"
        fill={getIconColor(color, 0, '#FF0000')}
        fillOpacity="0"
      />

      <path
        d="M512 256.00000001m0-74.66666701a74.666667 74.666667 0 1 0 0 149.333334 74.666667 74.666667 0 1 0 0-149.333334Z"
        fill={getIconColor(color, 1, '#111111')}
      />

      <path
        d="M512 512m0-74.66666699a74.666667 74.666667 0 1 0 0 149.33333398 74.666667 74.666667 0 1 0 0-149.33333398Z"
        fill={getIconColor(color, 2, '#111111')}
      />

      <path
        d="M512 767.99999999m0-74.66666699a74.666667 74.666667 0 1 0 0 149.333334 74.666667 74.666667 0 1 0 0-149.333334Z"
        fill={getIconColor(color, 3, '#111111')}
      />
    </svg>
  );
};

IconOperation.defaultProps = {
  size: 18,
};

export default IconOperation;
