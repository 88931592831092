/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconPdf: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M139.571423 0.00041a49.049571 49.049571 0 0 0-35.839978 15.35999A54.169567 54.169567 0 0 0 88.371454 51.200379v921.599447a49.151971 49.151971 0 0 0 15.359991 35.839978 50.58557 50.58557 0 0 0 35.839978 15.359991H890.879773a49.151971 49.151971 0 0 0 35.839978-15.359991A50.58557 50.58557 0 0 0 942.079742 972.799826V290.099436L651.571116 0.00041z m0 0"
        fill={getIconColor(color, 0, '#FA2B2B')}
      />

      <path
        d="M942.079742 290.099436H702.771086a52.531168 52.531168 0 0 1-51.19997-51.19997V0.00041z m0 0"
        fill={getIconColor(color, 1, '#FFC2CB')}
      />

      <path
        d="M205.619384 477.491323h81.919951c60.415964 0 103.526338 20.479988 103.526338 81.919951 0 59.289564-43.110374 86.527948-102.399939 86.527948h-40.959975v102.809538h-42.086375z m79.564752 134.14392c43.519974 0 64.204761-16.58879 64.204762-52.326369S326.860911 512.000102 283.750537 512.000102h-35.430379v99.94234zM444.108841 477.491323h70.451157c83.25115 0 130.559922 45.977572 130.559922 134.451119s-47.308772 136.806318-128.409523 136.806318h-72.601556zM512 713.727981c58.265565 0 89.599946-34.303979 89.599946-101.785539S569.855965 512.000102 512 512.000102h-25.087985v201.727879zM701.747086 477.491323h160.767904v35.737579H744.55026v82.84155h100.24954v35.839978h-100.24954v116.83833h-42.803174z"
        fill={getIconColor(color, 2, '#FFFFFF')}
      />
    </svg>
  );
};

IconPdf.defaultProps = {
  size: 18,
};

export default IconPdf;
