/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWodezhangdan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M716.8 797.642105v223.663158l237.136842-237.136842h-223.663158c-5.389474 0-13.473684 5.389474-13.473684 13.473684z"
        fill={getIconColor(color, 0, '#44A7DF')}
      />

      <path
        d="M900.042105 0H123.957895C91.621053 0 67.368421 24.252632 67.368421 56.589474v910.821052C67.368421 999.747368 91.621053 1024 123.957895 1024H660.210526v-226.357895c0-37.726316 32.336842-70.063158 70.063158-70.063158H956.631579V56.589474C956.631579 24.252632 932.378947 0 900.042105 0z m-150.905263 498.526316H293.726316c-16.168421 0-29.642105-13.473684-29.642105-29.642105 0-16.168421 13.473684-29.642105 29.642105-29.642106h452.715789c16.168421 0 29.642105 13.473684 29.642106 29.642106 0 16.168421-13.473684 29.642105-26.947369 29.642105z m0-199.410527H293.726316c-16.168421 0-29.642105-13.473684-29.642105-29.642105s13.473684-26.947368 29.642105-26.947368h452.715789c16.168421 0 29.642105 13.473684 29.642106 29.642105s-13.473684 26.947368-26.947369 26.947368z"
        fill={getIconColor(color, 1, '#44A7DF')}
      />
    </svg>
  );
};

IconWodezhangdan.defaultProps = {
  size: 18,
};

export default IconWodezhangdan;
