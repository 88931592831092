/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPersonNote24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M405.333333 490.666667a213.333333 213.333333 0 1 0-213.333333-213.333334 213.333333 213.333333 0 0 0 213.333333 213.333334z m0-362.666667A149.333333 149.333333 0 1 1 256 277.333333 149.333333 149.333333 0 0 1 405.333333 128zM860.16 532.906667h-269.653333a99.413333 99.413333 0 0 0-99.84 98.986666v229.12a99.84 99.84 0 0 0 99.84 99.413334h269.653333a99.413333 99.413333 0 0 0 99.413333-99.413334v-229.12a98.986667 98.986667 0 0 0-99.413333-98.986666z m47.786667 325.12a51.626667 51.626667 0 0 1-51.626667 51.2h-256a51.626667 51.626667 0 0 1-51.626667-51.2V640a51.626667 51.626667 0 0 1 51.626667-51.2h256a51.626667 51.626667 0 0 1 51.626667 51.2z"
        fill={getIconColor(color, 0, '#212121')}
      />

      <path
        d="M853.333333 661.333333h-256a23.893333 23.893333 0 0 0 0 47.36h256a23.893333 23.893333 0 0 0 0-47.36zM160 640h288v-21.333333a128 128 0 0 1 7.253333-42.666667H160a96 96 0 0 0-96 96v24.32a160 160 0 0 0 38.4 104.106667c66.986667 78.08 170.666667 116.906667 302.933333 116.906666h49.493334a123.306667 123.306667 0 0 1-6.826667-42.666666V853.333333h-42.666667c-116.906667 0-200.96-32-256-94.293333a96.426667 96.426667 0 0 1-21.333333-62.72v-24.32a32 32 0 0 1 32-32zM853.333333 784.64h-256a22.613333 22.613333 0 0 0-21.333333 23.466667 22.186667 22.186667 0 0 0 21.333333 23.466666h256a22.613333 22.613333 0 0 0 21.333334-23.466666 22.613333 22.613333 0 0 0-21.333334-23.466667z"
        fill={getIconColor(color, 1, '#212121')}
      />
    </svg>
  );
};

export default IcFluentPersonNote24Regular;
