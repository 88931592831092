/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACustomersCustomerClueMyClue: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M666.5216 0c45.4656 0 89.21088 17.12128 122.4704 47.9232l116.26496 107.68384A178.21696 178.21696 0 0 1 962.56 286.4128v648.23296a89.088 89.088 0 0 1-26.3168 63.1808A90.13248 90.13248 0 0 1 872.69376 1024H151.30624c-23.81824 0-46.6944-9.4208-63.54944-26.17344A89.088 89.088 0 0 1 61.44 934.64576V89.35424C61.44 39.99744 101.6832 0 151.30624 0h515.21536zM410.15296 235.70432c-39.5264 0-73.74848 24.28928-89.47712 58.75712h-54.31296a42.00448 42.00448 0 0 0-41.472 42.27072 42.00448 42.00448 0 0 0 41.472 42.33216h54.31296c15.72864 34.69312 49.93024 58.81856 89.4976 58.81856 39.58784 0 73.728-24.12544 89.51808-58.81856h183.808c35.38944 0 59.24864 24.30976 59.24864 60.49792 0 36.18816-23.81824 60.47744-59.24864 60.47744H366.85824c-81.22368 0-141.96736 62.40256-141.96736 145.12128 0 82.65728 61.1328 145.1008 142.1312 145.1008h183.84896a98.304 98.304 0 0 0 89.53856 58.79808c39.54688 0 73.74848-24.35072 89.4976-58.79808h54.31296a42.00448 42.00448 0 0 0 41.45152-42.2912 42.00448 42.00448 0 0 0-41.45152-42.33216h-54.33344c-15.72864-34.6112-49.70496-58.81856-89.47712-58.81856a98.87744 98.87744 0 0 0-89.76384 58.81856h-183.78752c-35.6352 0-59.26912-24.28928-59.26912-60.47744 0-36.1472 23.81824-60.49792 59.26912-60.49792h316.416c81.05984 0 142.39744-62.38208 142.39744-145.1008 0-82.65728-61.17376-145.1008-142.17216-145.1008h-183.808a98.77504 98.77504 0 0 0-89.53856-58.81856v0.06144z m233.22624 484.90496c20.7872 0 37.5808 16.9984 37.5808 37.92896a37.76512 37.76512 0 0 1-37.5808 37.90848 37.96992 37.96992 0 0 1-26.66496-11.264 37.53984 37.53984 0 0 1-10.8544-26.70592c0-20.8896 16.85504-37.86752 37.51936-37.86752zM418.14016 303.39072c20.72576 0 37.51936 16.9984 37.51936 37.96992 0 20.8896-16.7936 37.888-37.51936 37.888-10.0352-0.08192-19.6608-4.11648-26.68544-11.22304a37.53984 37.53984 0 0 1-10.89536-26.70592c0-20.95104 16.85504-37.92896 37.5808-37.92896z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconACustomersCustomerClueMyClue.defaultProps = {
  size: 18,
};

export default IconACustomersCustomerClueMyClue;
