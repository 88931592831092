/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveStationManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M921.8048 930.77504H819.4048v18.16576c0 30.208-22.97856 54.5792-51.2 54.5792-28.2624 0-51.22048-24.4736-51.22048-54.5792v-18.16576H307.26144v18.16576c0 30.208-22.95808 54.5792-51.2 54.5792-28.2624 0-51.2-24.4736-51.2-54.5792v-18.16576H102.4C64.73728 930.77504 0 861.7984 0 821.51424V421.0688c0-40.16128 30.5152-72.84736 68.23936-72.84736h68.25984c37.6832 0 68.23936 32.54272 68.23936 72.84736v291.30752h614.52288V421.04832c0-40.16128 30.5152-72.84736 68.23936-72.84736h68.25984c37.70368 0 68.23936 32.54272 68.23936 72.84736V821.6576c0.22528 40.16128-64.47104 109.1584-102.1952 109.1584v-0.04096z m-68.25984-655.40096c-56.54528 0-102.4 48.9472-102.4 109.28128v254.85312H273.05984V384.63488c0-60.33408-45.85472-109.28128-102.4-109.28128-12.00128 0-23.47008-4.096-34.2016 0V129.78176C136.47872 69.44768 182.31296 20.48 238.91968 20.48h546.28352c56.58624 0 102.42048 48.9472 102.42048 109.28128v145.69472c-10.752-4.1984-22.05696-0.1024-34.07872-0.1024z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveStationManage.defaultProps = {
  size: 18,
};

export default IconAExecutiveStationManage;
