/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessPerformanceCaseMyList: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M334.88896 194.00704c98.85696 1.92512 177.47968 83.7632 175.73888 182.8864h-175.7184v182.4768a179.03616 179.03616 0 0 1-125.97248-54.5792 179.99872 179.99872 0 0 1-50.56512-127.8976 179.6096 179.6096 0 0 1 50.29888-128.28672 178.64704 178.64704 0 0 1 126.21824-54.59968z m39.13728 392.43776v-182.4768h175.73888c1.31072 99.0208-77.35296 180.55168-176.128 182.4768h0.4096z m299.4176-196.21888h168.69376c11.53024 0.63488 20.39808 10.42432 19.968 21.97504a21.95456 21.95456 0 0 1-19.968 22.75328h-169.08288a21.95456 21.95456 0 0 1-19.16928-22.7328 21.23776 21.23776 0 0 1 19.16928-23.16288l0.4096 1.16736z m0-113.80736h168.69376c11.59168 0.83968 20.41856 10.73152 19.968 22.36416a21.95456 21.95456 0 0 1-19.968 22.77376h-169.08288a21.95456 21.95456 0 0 1-19.16928-22.77376 21.56544 21.56544 0 0 1 19.16928-22.36416h0.4096z m0-110.26432h168.69376c11.65312 1.024 20.41856 11.0592 19.968 22.75328a21.56544 21.56544 0 0 1-19.968 22.36416h-169.08288c-11.264-1.2288-19.6608-10.99776-19.16928-22.36416-1.14688-11.8784 7.3728-22.50752 19.16928-23.94112l0.4096 1.18784zM103.58784 717.90592h814.08c9.44128-0.88064 18.1248-5.5296 24.10496-12.92288 5.9392-7.39328 8.704-16.87552 7.59808-26.3168V80.19968A35.20512 35.20512 0 0 0 917.66784 40.96H103.58784c-19.5584 2.048-33.75104 19.61984-31.70304 39.23968v600.04352a35.28704 35.28704 0 0 0 31.70304 37.6832z m861.06112 52.98176H56.60672c-9.0112 0-15.64672 21.97504-15.64672 32.5632 0 10.60864 7.43424 32.9728 15.64672 32.9728h242.2784l-62.23872 107.13088a30.26944 30.26944 0 0 0 7.04512 34.14016c10.48576 5.87776 23.552 4.096 32.11264-4.3008l78.27456-136.97024h328.37632l71.61856 136.56064c6.49216 9.87136 19.53792 12.96384 29.75744 7.0656 8.72448-6.8608 11.91936-18.67776 7.82336-29.04064l-59.10528-115.36384h234.84416c9.0112 0 15.64672-22.36416 15.64672-32.9728 0-10.58816-9.40032-31.78496-18.39104-31.78496z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessPerformanceCaseMyList.defaultProps = {
  size: 18,
};

export default IconABusinessPerformanceCaseMyList;
