/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconGaizhangshenhe: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M668.5184 1024H150.6816C67.4816 1024 0 952.32 0 863.8976V160.1024C0 71.68 67.456 0 150.6816 0h517.8368C751.7184 0 819.2 71.68 819.2 160.1024v703.872C819.2 952.32 751.744 1024 668.5184 1024z"
        fill={getIconColor(color, 0, '#FFAC3E')}
      />

      <path
        d="M666.0352 224.768H153.216C125.1328 224.768 102.4 203.1616 102.4 176.384 102.4 149.6576 125.1328 128 153.216 128h512.768C694.0672 128 716.8 149.6576 716.8 176.384c0 26.752-22.6816 48.384-50.7648 48.384z m-147.3024 177.664H153.216C125.1328 402.432 102.4 380.7744 102.4 354.048s22.7328-48.384 50.816-48.384h365.4656c28.0576 0 50.816 21.6576 50.816 48.384s-22.6816 48.384-50.7648 48.384zM452.7872 588.8H153.216C125.1328 588.8 102.4 567.1424 102.4 540.416c0-26.752 22.7328-48.384 50.816-48.384h299.52c28.0832 0 50.816 21.632 50.816 48.384 0 26.7264-22.6816 48.384-50.7648 48.384z"
        fill={getIconColor(color, 1, '#FFFFFF')}
      />

      <path
        d="M957.7984 795.5456h-74.496c-21.5808-9.8816-43.52-30.5408-32.8704-73.1392 42.752-31.7696 69.0176-84.0704 63.616-142.1568-6.9632-74.112-66.7904-134.4512-141.696-142.6688-96.9216-10.624-179.0464 64.128-179.0464 157.7728 0 52.7872 26.1376 99.584 66.304 128.4608 2.048 25.088-4.608 55.2448-41.344 71.7824h-63.0528c-35.584 0-64.3584 28.5184-64.3584 63.5904v98.432c0 35.1232 28.8512 63.5648 64.3584 63.5648h402.5856c35.584 0 64.4096-28.4928 64.4096-63.6416V859.136c0-35.1488-28.8512-63.5904-64.4096-63.5904z"
        fill={getIconColor(color, 2, '#FFAC3E')}
      />

      <path
        d="M754.048 436.5312c23.1936 0 45.2352 4.864 65.152 13.5936V864c0 76.8512-51.0208 141.056-119.0656 156.4928H526.592a43.5968 43.5968 0 0 1-35.7888-42.752V839.168c0-24.0384 19.712-43.52 44.032-43.52h83.4304c36.736-16.5632 43.392-46.7456 41.344-71.808a158.0288 158.0288 0 0 1-66.304-128.4608c0-87.7568 72.0128-158.8224 160.768-158.8224z"
        fill={getIconColor(color, 3, '#FF7C0E')}
      />

      <path
        d="M887.04 971.9552H625.9712a49.3312 49.3312 0 0 1-49.664-49.0496c0-27.1104 22.2208-49.0496 49.664-49.0496H887.04c27.4432 0 49.664 21.9392 49.664 49.0496a49.3312 49.3312 0 0 1-49.664 49.0496z"
        fill={getIconColor(color, 4, '#FFFFFF')}
      />
    </svg>
  );
};

IconGaizhangshenhe.defaultProps = {
  size: 18,
};

export default IconGaizhangshenhe;
