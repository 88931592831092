/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Bumen: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M430.829268 338.919024V246.958829c0-30.645073-23.851707-55.470829-53.297951-55.470829H240.615024C211.218732 191.488 187.317073 216.28878 187.317073 246.958829v91.960195c0 30.645073 23.876683 55.495805 53.297951 55.495805h136.966244c29.396293 0 53.248-24.850732 53.248-55.495805z m352.605659 55.495805h-137.016195c-29.371317 0-53.248 24.825756-53.248 55.47083v91.960195c0 30.645073 23.876683 55.495805 53.297951 55.495805h136.916293c29.446244 0 53.297951-24.850732 53.297951-55.495805v-91.960195c0-30.645073-23.851707-55.470829-53.297951-55.47083h0.049951z m-259.87122 126.201756h-197.057561v-57.144195a28.771902 28.771902 0 0 0-29.046634-28.472195 28.771902 28.771902 0 0 0-28.971707 28.522146V771.746341c0 15.734634 12.987317 28.547122 28.971707 28.547122h226.154147a28.771902 28.771902 0 0 0 28.971707-28.547122 28.771902 28.771902 0 0 0-28.971707-28.522146h-197.132488V577.685854h197.132488a28.771902 28.771902 0 0 0 28.971707-28.572098 28.771902 28.771902 0 0 0-28.971707-28.522146l-0.049952 0.024975z"
        fill={getIconColor(color, 0, '#3D84F5')}
      />

      <path
        d="M783.434927 637.927024h-137.016195c-29.371317 0-53.248 24.825756-53.248 55.47083v91.960195c0 30.645073 23.876683 55.495805 53.297951 55.495805h136.916293c29.446244 0 53.297951-24.850732 53.297951-55.495805v-91.960195c0-30.645073-23.851707-55.470829-53.297951-55.47083h0.049951z"
        fill={getIconColor(color, 1, '#3D84F5')}
      />
    </svg>
  );
};

export default Bumen;
