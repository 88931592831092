/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShoucang1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M1024 384c0 0-115.2 153.6-262.4 268.8 0 0 0 0 0 0s0 0 0 0c0 0 0 0 0 0C819.2 851.2 832 1024 832 1024l-320-192c0 0 0 0 0 0 0 0 0 0 0 0-153.6 108.8-320 192-320 192s12.8-172.8 70.4-371.2c0 0 0 0 0 0s0 0 0 0c0 0 0 0 0 0C115.2 544 0 384 0 384l352-32L512 0l160 352L1024 384z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconShoucang1.defaultProps = {
  size: 18,
};

export default IconShoucang1;
