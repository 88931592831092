/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWeituodanguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1041 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M153.799816 499.491806a35.773136 35.773136 0 0 0 35.773136-35.773137V74.445266c0-2.010697 1.147207-2.861851 1.430926-2.861851h483.307409v91.480545A78.947612 78.947612 0 0 0 753.073865 241.777196h99.646689v221.916802a35.773136 35.773136 0 1 0 71.546273 0V206.00406a11.410397 11.410397 0 0 0-0.135691-2.146389v-0.567436a10.694934 10.694934 0 0 0-0.296054-2.146388c-0.135691-1.147207-0.283718-2.010697-0.419409-2.726159a44.852112 44.852112 0 0 0-2.467113-7.401339c-0.419409-0.86349-0.715463-1.726979-1.134872-2.467113s-0.86349-1.430925-1.295234-2.146388a14.938368 14.938368 0 0 0-1.578952-2.282079 14.531295 14.531295 0 0 0-1.714644-2.146389l-1.813328-1.875005-0.135691-0.148027L734.891244 9.868587c-0.715463-0.715463-1.233556-1.147207-1.862671-1.714643s-1.233556-1.011516-1.998361-1.443261a45.789615 45.789615 0 0 0-4.58883-2.861851 30.604535 30.604535 0 0 0-4.81087-2.134053 27.138241 27.138241 0 0 0-4.934226-1.233556 18.811736 18.811736 0 0 0-2.713824-0.283718A11.953162 11.953162 0 0 0 711.762061 0.000136H191.028549c-40.226275 0-73.014205 33.306024-73.014205 74.44513v389.113041a35.834814 35.834814 0 0 0 35.785472 35.933499z m592.107087-380.095743L799.171869 170.230923h-46.098004a7.277983 7.277983 0 0 1-7.154627-7.154627z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M267.373356 409.220146h501.193978a35.773136 35.773136 0 1 0 0-71.546273h-501.193978a35.773136 35.773136 0 1 0 0 71.546273zM267.373356 252.373446h355.745339a35.773136 35.773136 0 1 0 0-71.546273H267.373356a35.773136 35.773136 0 1 0 0 71.546273zM1027.416816 753.863477L771.305829 552.436048a35.884157 35.884157 0 0 0-12.742637-6.4515c-92.763444-25.053531-310.510825-83.881837-328.557756-86.459971-32.923621-4.872548-76.72721 19.181802-93.626933 51.537988-17.750877 33.922802-13.569121 85.46079 28.495154 118.248719 5.439984 4.292776 17.60285 13.741819 115.386868 58.976333L315.790446 685.709484c-60.148212-56.681918-150.345858-141.587607-154.354916-145.016894-16.887388-14.605308-49.675317-18.503346-62.985391-19.46552-33.071648-2.467113-57.977152 3.28126-73.877695 17.26979-19.736903 17.26979-27.915382 45.382541-23.326552 81.168013C3.700669 638.760327 10.830625 665.960246 22.857801 679.689729c1.233556 1.578952 3.441622 3.861032 250.411955 241.22196a46.653104 46.653104 0 0 0 32.504212 13.174383h426.440458l57.125998 68.141657a60.320909 60.320909 0 0 0 46.529749 21.759935h1.430926a60.629299 60.629299 0 0 0 46.961493-23.906323l149.260328-196.135473a35.921163 35.921163 0 0 0-6.106104-50.082391zM835.586455 945.693838l-59.12436-70.584099a35.834814 35.834814 0 0 0-27.495972-12.730303H315.790446C236.596123 786.207327 99.597346 654.500507 78.121129 633.604061c-4.440803-10.016478-7.87009-31.640722-5.871729-40.707362 10.59625-2.294415 34.6506 0 44.408032 3.577313 15.888207 14.889026 102.064459 95.921348 160.053947 150.604905a35.40307 35.40307 0 0 0 23.906323 9.73276l352.168026 5.588011a35.773136 35.773136 0 0 0 14.889026-68.573402C553.27473 643.916593 425.145224 584.927924 408.529219 572.617031c-14.025537-10.879968-11.299377-24.338068-9.017298-28.630845 2.72616-5.304293 14.802677-12.742638 20.612728-13.569121 23.05517 4.934226 199.429068 52.117759 312.521522 82.746966L955.2661 788.242695z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconWeituodanguanli.defaultProps = {
  size: 18,
};

export default IconWeituodanguanli;
