/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const GongshiriliXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M784.597333 85.333333A154.069333 154.069333 0 0 1 938.666667 239.402667V784.64A154.069333 154.069333 0 0 1 784.597333 938.666667H239.36A154.069333 154.069333 0 0 1 85.333333 784.597333V239.36A154.069333 154.069333 0 0 1 239.402667 85.333333H784.64z m82.944 260.736H156.458667V784.64c0 45.781333 37.12 82.944 82.944 82.944H784.64c45.824 0 82.944-37.12 82.944-82.944V346.026667zM310.528 630.528a59.264 59.264 0 1 1 0 118.528 59.264 59.264 0 0 1 0-118.528z m201.472 0a59.264 59.264 0 1 1 0 118.528 59.264 59.264 0 0 1 0-118.528z m-201.472-189.653333a59.264 59.264 0 1 1 0 118.528 59.264 59.264 0 0 1 0-118.528z m201.472 0a59.264 59.264 0 1 1 0 118.528 59.264 59.264 0 0 1 0-118.528z m201.472 0a59.264 59.264 0 1 1 0 118.528 59.264 59.264 0 0 1 0-118.528z m71.125333-284.416H239.36c-45.824 0-82.944 37.12-82.944 82.944v35.541333h711.082667v-35.541333c0-45.824-37.12-82.944-82.944-82.944z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default GongshiriliXianxing;
