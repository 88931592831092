/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const AIcFluentMail24Regular2: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M224 170.666667h576a138.666667 138.666667 0 0 1 138.453333 130.816L938.666667 309.333333v405.333334a138.666667 138.666667 0 0 1-130.816 138.453333L800 853.333333H224a138.666667 138.666667 0 0 1-138.453333-130.816L85.333333 714.666667v-405.333334a138.666667 138.666667 0 0 1 130.816-138.453333L224 170.666667h576-576zM874.666667 399.914667l-347.733334 183.04a32 32 0 0 1-25.728 1.834666l-4.096-1.792L149.333333 399.957333v314.709334a74.666667 74.666667 0 0 0 68.522667 74.410666l6.144 0.256h576a74.666667 74.666667 0 0 0 74.410667-68.565333l0.256-6.101333V399.914667zM800 234.666667H224a74.666667 74.666667 0 0 0-74.410667 68.522666L149.333333 309.333333v18.304l362.666667 190.848 362.666667-190.890666V309.333333a74.666667 74.666667 0 0 0-68.565334-74.410666L800 234.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default AIcFluentMail24Regular2;
