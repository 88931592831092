/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPerson24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M757.504 597.333333a95.957333 95.957333 0 0 1 96 95.957334v39.168a117.333333 117.333333 0 0 1-21.888 68.224C765.653333 892.970667 657.92 938.666667 512 938.666667c-146.005333 0-253.653333-45.738667-319.445333-138.112a117.333333 117.333333 0 0 1-21.76-68.053334v-39.253333a95.957333 95.957333 0 0 1 95.957333-96h490.709333zM512 85.504a213.333333 213.333333 0 1 1 0 426.666667 213.333333 213.333333 0 0 1 0-426.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentPerson24Filled;
