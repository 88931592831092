/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACustomersMyCustomersManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M494.87872 552.96C680.30464 593.24416 819.2 761.1392 819.2 962.56H0c0-201.4208 138.89536-369.31584 324.32128-409.6L409.6 618.84416 494.87872 552.96z m-118.25152 108.62592l-1.16736 1.24928 27.32032 29.92128L348.16 897.2288 409.6 942.08l61.44-44.87168-54.62016-204.45184L450.56 662.85568c-13.06624-9.56416-63.5904-9.97376-73.9328-1.26976z m609.44384-83.59936c20.86912 0 37.92896 17.08032 37.92896 37.92896a38.03136 38.03136 0 0 1-37.92896 37.92896h-151.69536a38.03136 38.03136 0 0 1-37.92896-37.92896c0-20.84864 17.05984-37.92896 37.92896-37.92896h151.69536zM419.45088 122.88c118.72256 0 214.67136 94.8224 214.67136 211.61984 0 116.81792-95.9488 211.64032-214.67136 211.64032-118.70208 0-214.65088-94.8224-214.65088-211.64032C204.8 217.7024 300.7488 122.88 419.45088 122.88z m566.62016 303.4112c20.86912 0 37.92896 17.05984 37.92896 37.92896a38.03136 38.03136 0 0 1-37.92896 37.92896H720.5888a38.03136 38.03136 0 0 1-37.92896-37.92896c0-20.86912 17.08032-37.92896 37.92896-37.92896z m0-151.71584c20.86912 0 37.92896 17.08032 37.92896 37.92896a38.03136 38.03136 0 0 1-37.92896 37.92896H720.5888a38.03136 38.03136 0 0 1-37.92896-37.92896c0-20.84864 17.08032-37.92896 37.92896-37.92896h265.48224z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconACustomersMyCustomersManage.defaultProps = {
  size: 18,
};

export default IconACustomersMyCustomersManage;
