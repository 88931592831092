/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconChuangjian: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 0a76.8 76.8 0 0 1 76.8 76.8v358.4h358.4a76.8 76.8 0 0 1 0 153.6h-358.4v358.4a76.8 76.8 0 0 1-153.6 0v-358.4h-358.4a76.8 76.8 0 0 1 0-153.6h358.4v-358.4A76.8 76.8 0 0 1 512 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconChuangjian.defaultProps = {
  size: 18,
};

export default IconChuangjian;
