/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveStationBookingRecord: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M773.59104 40.96a52.28544 52.28544 0 0 1 52.4288 52.34688h52.61312A104.36608 104.36608 0 0 1 983.04 197.95968V878.3872A104.52992 104.52992 0 0 1 878.63296 983.04H145.36704A104.36608 104.36608 0 0 1 40.96 878.36672V197.98016a104.52992 104.52992 0 0 1 104.40704-104.67328h52.61312A52.6336 52.6336 0 0 1 250.40896 40.96h523.18208zM407.67488 507.00288H286.72v59.26912h37.0688V819.2l11.30496-4.03456c48.5376-17.83808 90.9312-40.20224 127.1808-67.13344V678.912l-4.07552 2.80576c-12.61568 8.54016-29.4912 18.14528-50.52416 28.79488v-203.50976zM737.28 388.42368H456.43776v59.24864h118.94784v290.5088l0.14336 3.09248c0.22528 14.97088-6.94272 21.85216-21.6064 20.64384h-37.02784v53.32992h76.04224l4.73088-0.08192c44.9536-1.536 67.42016-25.1904 67.42016-71.04512V447.67232H737.28v-59.26912zM384.28672 368.64h-76.10368l4.9152 10.6496c14.27456 31.76448 24.33024 62.50496 30.208 92.11904h78.0288l-1.61792-9.05216A292.82304 292.82304 0 0 0 384.28672 368.64zM721.6128 93.30688h-419.2256a52.4288 52.4288 0 0 0-52.08064 52.16256v0.16384c0 29.10208 23.3472 52.34688 52.08064 52.34688h419.2256c28.2624 0 52.08064-23.4496 52.08064-52.34688v-0.16384a52.34688 52.34688 0 0 0-52.08064-52.16256z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveStationBookingRecord.defaultProps = {
  size: 18,
};

export default IconAExecutiveStationBookingRecord;
