/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDocumentText24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M373.333333 490.666667a32 32 0 0 0 0 64h277.333334a32 32 0 0 0 0-64h-277.333334z m0 117.333333a32 32 0 0 0 0 64h277.333334a32 32 0 0 0 0-64h-277.333334z m0 117.333333a32 32 0 0 0 0 64h277.333334a32 32 0 0 0 0-64h-277.333334z m206.293334-614.997333l248.704 248.661333A85.333333 85.333333 0 0 1 853.333333 419.328V853.333333a85.333333 85.333333 0 0 1-85.333333 85.333334H256a85.333333 85.333333 0 0 1-85.333333-85.333334V170.666667a85.333333 85.333333 0 0 1 85.333333-85.333334h263.338667c1.194667 0 2.346667 0.170667 3.498666 0.298667a26.88 26.88 0 0 0 2.517334 0.298667c9.173333 0.64 18.218667 2.389333 26.624 5.888 2.432 1.024 4.778667 2.389333 7.082666 3.712l2.133334 1.237333 2.005333 1.024a27.818667 27.818667 0 0 1 3.456 1.877333c3.328 2.261333 6.314667 4.949333 9.344 7.68a26.88 26.88 0 0 0 1.536 1.28 20.949333 20.949333 0 0 1 2.090667 1.706667zM768 874.666667a21.333333 21.333333 0 0 0 21.333333-21.333334V426.666667H597.333333a85.333333 85.333333 0 0 1-85.333333-85.333334V149.333333H256a21.333333 21.333333 0 0 0-21.333333 21.333334v682.666666a21.333333 21.333333 0 0 0 21.333333 21.333334h512z m-26.538667-512L576 197.162667V341.333333a21.333333 21.333333 0 0 0 21.333333 21.333334h144.128z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentDocumentText24Regular;
