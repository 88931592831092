/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWenshushenhe: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M679.552 1024H139.648C62.5408 1024 0 950.8608 0 860.672V163.328C0 73.1392 62.5408 0 139.648 0h539.904C756.6592 0 819.2 73.1392 819.2 163.328v697.344c0 90.1888-62.5408 163.328-139.648 163.328z"
        fill={getIconColor(color, 0, '#80B7F9')}
      />

      <path
        d="M666.0352 224.768H153.216C125.1328 224.768 102.4 203.1616 102.4 176.384 102.4 149.6576 125.1328 128 153.216 128h512.768C694.0672 128 716.8 149.6576 716.8 176.384c0 26.752-22.6816 48.384-50.7648 48.384z m-147.3024 177.664H153.216C125.1328 402.432 102.4 380.7744 102.4 354.048s22.7328-48.384 50.816-48.384h365.4656c28.0576 0 50.816 21.6576 50.816 48.384s-22.6816 48.384-50.7648 48.384zM452.7872 588.8H153.216C125.1328 588.8 102.4 567.1424 102.4 540.416c0-26.752 22.7328-48.384 50.816-48.384h299.52c28.0832 0 50.816 21.632 50.816 48.384 0 26.7264-22.6816 48.384-50.7648 48.384z"
        fill={getIconColor(color, 1, '#FFFFFF')}
      />

      <path
        d="M641.792 958.2592l-119.9616 12.8c-15.0272 1.6128-26.368-10.368-24.832-26.1632l12.1344-126.464a66.816 66.816 0 0 1 18.0992-39.04L849.2032 440.064c22.2208-23.424 55.9104-25.9328 75.1872-5.632l81.7408 86.144c19.3024 20.352 16.9216 55.808-5.376 79.2064L678.8096 939.1104c-10.24 10.8544-23.4496 17.7408-36.992 19.1488z"
        fill={getIconColor(color, 2, '#80B7F9')}
      />

      <path
        d="M819.2 471.68v319.5648l-140.3392 147.9424a61.824 61.824 0 0 1-32.5632 18.3808l-4.5056 0.6912-119.9616 12.8c-15.0272 1.6128-26.368-10.368-24.832-26.1632l12.1344-126.464a66.816 66.816 0 0 1 18.0992-39.04L819.2 471.68z"
        fill={getIconColor(color, 3, '#3E8BF8')}
      />
    </svg>
  );
};

IconWenshushenhe.defaultProps = {
  size: 18,
};

export default IconWenshushenhe;
