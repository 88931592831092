/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTuodong: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M307.2 0h125.15328v126.54592H307.2V0z m284.44672 0H716.8v126.54592H591.64672V0zM307.2 299.17184h125.15328v126.54592H307.2V299.17184z m284.44672 0H716.8v126.54592H591.64672V299.17184zM307.2 598.2208h125.15328v126.54592H307.2V598.23104z m284.44672 0H716.8v126.54592H591.64672V598.23104zM307.2 897.4336h125.15328V1024H307.2V897.45408z m284.44672 0H716.8V1024H591.64672V897.45408z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconTuodong.defaultProps = {
  size: 18,
};

export default IconTuodong;
