/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksTaskProject: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M367.59552 666.7264a34.75456 34.75456 0 0 1 33.54624 24.86272c13.6192 47.45216 57.50784 80.19968 107.47904 80.19968 49.9712 0 93.88032-32.768 107.52-80.19968 4.25984-14.7456 17.96096-24.90368 33.52576-24.86272h284.95872c38.05184 0 68.89472 30.43328 68.89472 67.9936v200.82688C1003.52 973.08672 972.67712 1003.52 934.62528 1003.52H89.37472C51.32288 1003.52 20.48 973.08672 20.48 935.54688v-200.84736c0-37.53984 30.84288-67.97312 68.89472-67.97312zM884.92032 20.48c20.7872 0 37.84704 18.28864 37.84704 41.71776V613.376H582.2464c0 46.83776-33.3824 84.8896-75.6736 84.8896-41.53344 0-75.6736-38.05184-75.6736-84.8896H280.1664V62.19776c0-22.69184 17.12128-41.71776 37.888-41.71776zM204.5952 105.39008V613.376H91.09504V171.35616c0-36.4544 29.94176-65.96608 66.8672-65.96608H204.5952z m558.63296 290.11968H440.58624c-14.41792 0-28.22144 12.45184-27.56608 27.17696 0.67584 14.7456 12.10368 27.17696 27.56608 27.17696h322.62144c14.41792 0 28.22144-12.45184 27.5456-27.17696-0.65536-14.72512-12.0832-27.17696-27.5456-27.17696z m-0.32768-189.91104H440.23808c-14.39744 0-28.22144 12.45184-27.5456 27.17696 0.67584 14.7456 12.10368 27.19744 27.5456 27.19744H762.88c14.37696 0 28.22144-12.47232 27.5456-27.19744-0.67584-14.72512-12.0832-27.17696-27.5456-27.17696z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksTaskProject.defaultProps = {
  size: 18,
};

export default IconAWorksTaskProject;
