/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconNaviconLyfltj: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M900.608 962.56H121.856c-14.336 0-25.6-11.264-25.6-25.6V308.736c0-14.336 11.264-25.6 25.6-25.6h778.752c14.336 0 25.6 11.264 25.6 25.6V936.96c0 14.336-11.264 25.6-25.6 25.6zM147.456 911.36h727.552V334.336H147.456V911.36z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M985.6 334.336H37.888c-14.336 0-25.6-11.264-25.6-25.6V107.52c0-14.336 11.264-25.6 25.6-25.6h947.712c14.336 0 25.6 11.264 25.6 25.6v201.216c0 14.336-11.264 25.6-25.6 25.6z m-922.112-51.2h896.512V133.12H63.488v150.016zM720.384 506.368H301.056c-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6h419.328c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6z"
        fill={getIconColor(color, 1, '#333333')}
      />

      <path
        d="M317.44 733.696m-43.008 0a43.008 43.008 0 1 0 86.016 0 43.008 43.008 0 1 0-86.016 0Z"
        fill={getIconColor(color, 2, '#333333')}
      />

      <path
        d="M512.512 733.696m-43.008 0a43.008 43.008 0 1 0 86.016 0 43.008 43.008 0 1 0-86.016 0Z"
        fill={getIconColor(color, 3, '#333333')}
      />

      <path
        d="M708.096 733.696m-43.008 0a43.008 43.008 0 1 0 86.016 0 43.008 43.008 0 1 0-86.016 0Z"
        fill={getIconColor(color, 4, '#333333')}
      />
    </svg>
  );
};

IconNaviconLyfltj.defaultProps = {
  size: 18,
};

export default IconNaviconLyfltj;
