/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceAttendanceAnnualLeave: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M829.09184 68.89472c2.3552-2.31424 64.14336-61.72672 125.952 0.06144 62.99648 63.01696 0 126.01344 0 126.01344L576.8192 573.19424l-176.5376 176.51712 18.88256 170.22976-62.99648 62.99648-94.55616-220.5696L40.96 667.77088l62.99648-63.01696 170.25024 18.88256 176.51712-176.5376L72.4992 257.98656 135.51616 194.9696l472.86272 94.63808z m-85.64736 392.06912l85.58592 427.52-63.01696 63.01696-171.04896-342.05696 148.48-148.48z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceAttendanceAnnualLeave.defaultProps = {
  size: 18,
};

export default IconAHumanResourceAttendanceAnnualLeave;
