/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconBuildingFill: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M884.224 834.048h-20.992V381.952c0-17.408-14.336-32.256-32.256-32.256h-157.696c-4.608 0-7.68 3.584-7.68 7.68v476.16h-34.304V172.544c0-24.576-20.48-45.056-45.056-45.056h-373.76c-24.576 0-45.056 20.48-45.056 45.056v661.504h-19.456c-15.872 0-28.672 12.8-28.672 28.672s12.8 28.672 28.672 28.672h736.768c15.872 0 28.672-12.8 28.672-28.672s-13.312-28.672-29.184-28.672zM312.32 310.272h174.592c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6H312.32c-14.336 0-25.6-11.264-25.6-25.6 0.512-14.336 11.776-25.6 25.6-25.6zM312.32 450.56h174.592c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6H312.32c-14.336 0-25.6-11.264-25.6-25.6s11.776-25.6 25.6-25.6z m0 141.312h174.592c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6H312.32c-14.336 0-25.6-11.264-25.6-25.6s11.776-25.6 25.6-25.6z"
        fill={getIconColor(color, 0, '#2C2C2C')}
      />
    </svg>
  );
};

IconBuildingFill.defaultProps = {
  size: 18,
};

export default IconBuildingFill;
