/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveStationBooking: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M136.4992 348.20096c37.6832 0 68.23936 32.54272 68.23936 72.84736v291.30752h314.88A287.5392 287.5392 0 0 0 512 778.24c0 56.05376 16.0768 108.3392 43.88864 152.53504h-248.6272v18.16576c0 30.208-22.95808 54.5792-51.2 54.5792-28.2624 0-51.2-24.4736-51.2-54.5792v-18.16576H102.4C64.73728 930.77504 0 861.7984 0 821.51424V421.0688c0-40.16128 30.5152-72.84736 68.23936-72.84736h68.25984zM785.2032 20.48c56.58624 0 102.42048 48.9472 102.42048 109.28128v145.69472c-10.752-4.1984-22.05696-0.1024-34.07872-0.1024-56.56576 0-102.4 48.98816-102.4 109.30176v110.7968a287.00672 287.00672 0 0 0-203.42784 144.05632H273.05984V384.63488c0-60.33408-45.85472-109.28128-102.4-109.28128-12.00128 0-23.47008-4.096-34.2016 0V129.78176C136.47872 69.44768 182.31296 20.48 238.91968 20.48h546.28352z m170.53696 327.72096c37.70368 0 68.23936 32.54272 68.23936 72.84736v179.8144a286.26944 286.26944 0 0 0-204.71808-108.62592V421.0688c0-40.16128 30.5152-72.84736 68.21888-72.84736h68.25984zM798.72 1003.52c-124.416 0-225.28-100.864-225.28-225.28s100.864-225.28 225.28-225.28 225.28 100.864 225.28 225.28-100.864 225.28-225.28 225.28z m-32.21504-356.90496a33.792 33.792 0 0 0-33.6896 33.5872l-0.6144 130.70336c-0.08192 9.0112 3.4816 17.65376 9.85088 24.00256 6.32832 6.28736 14.86848 9.8304 23.77728 9.8304l130.88768-0.55296a33.792 33.792 0 0 0 0-67.584l-96.8704 0.4096 0.4096-96.6656a33.792 33.792 0 0 0-33.52576-33.792l-0.22528 0.06144z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveStationBooking.defaultProps = {
  size: 18,
};

export default IconAExecutiveStationBooking;
