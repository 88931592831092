/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAboutUs: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 950.30303066c-218.763636 0-395.636364-176.872727-395.636364-395.636364S293.236364 159.03030266 512 159.03030266s395.636364 176.872727 395.636364 395.636364-176.872727 395.636364-395.636364 395.636364z m0-744.727273C318.836364 205.57575766 162.909091 361.50303066 162.909091 554.66666666s155.927273 349.090909 349.090909 349.090909 349.090909-155.927273 349.090909-349.090909S705.163636 205.57575766 512 205.57575766z"
        fill={getIconColor(color, 0, '#515151')}
      />

      <path
        d="M512 354.52121166m-39.563636 0a39.563636 39.563636 0 1 0 79.127272 0 39.563636 39.563636 0 1 0-79.127272 0Z"
        fill={getIconColor(color, 1, '#515151')}
      />

      <path
        d="M474.763636 815.32121166c-4.654545 0-9.309091 0-13.963636-2.327272-25.6-4.654545-39.563636-25.6-46.545455-39.563637l-4.654545-13.963636 6.98181799-11.636364c69.81818199-141.963636 79.127273-195.490909 81.45454601-216.436363-16.290909 4.654545-41.890909 20.945455-62.836364 39.563636l-44.21818201-53.527273c37.236364-32.58181799 109.38181799-79.127273 153.60000002-44.218181 34.909091 27.927273 39.563636 83.78181801-41.89090901 258.327272 11.636364-6.98181799 25.6-20.945455 41.89090899-39.563636l51.2 46.545454c-46.545455 51.2-86.109091 76.8-121.01818199 76.8z"
        fill={getIconColor(color, 2, '#515151')}
      />
    </svg>
  );
};

IconAboutUs.defaultProps = {
  size: 18,
};

export default IconAboutUs;
