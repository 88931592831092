/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentSearch24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M426.666667 117.333333a309.333333 309.333333 0 0 1 240.213333 504.277334l209.066667 209.066666a32 32 0 0 1-41.642667 48.384l-3.584-3.114666-209.109333-209.066667A309.333333 309.333333 0 1 1 426.666667 117.333333z m0 64a245.333333 245.333333 0 1 0 0 490.666667 245.333333 245.333333 0 0 0 0-490.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentSearch24Regular;
