/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveScheduleStatus: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M989.83936 20.48H354.0992c-21.31968 0-38.37952 16.7936-38.37952 33.5872v285.696h72.51968V91.91424h460.8L661.38112 192.7168c-12.84096 4.1984-17.05984 16.83456-17.05984 29.42976v541.9008H388.3008v-193.2288h-72.56064v226.85696c0 20.97152 17.08032 33.5872 34.14016 33.5872H644.3008v134.43072c0 12.53376 8.54016 25.1904 17.05984 29.40928 8.56064 8.41728 12.75904 8.41728 21.2992 8.41728 4.3008 0 12.8 0 17.08032-4.21888l298.63936-163.79904c12.82048-4.21888 25.62048-16.81408 25.62048-33.60768V54.04672C1024 37.2736 1006.91968 20.48 989.83936 20.48zM435.2 503.58272c29.88032 0 51.2-21.01248 51.2-50.42176 0-25.1904-21.31968-50.3808-51.2-50.3808h-192v-88.2688c0-8.37632-4.3008-16.7936-12.82048-16.7936-8.54016-4.21888-17.05984-4.21888-21.2992 0L8.54016 436.3264c-4.3008 8.45824-8.54016 12.61568-8.54016 21.01248 0 8.3968 4.25984 12.5952 8.54016 16.7936l200.54016 138.60864c4.23936 4.21888 8.54016 4.21888 12.75904 4.21888 4.3008 0 8.54016 0 8.54016-4.21888 8.54016-4.1984 12.82048-12.57472 12.82048-16.7936v-92.40576h192v0.04096z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveScheduleStatus.defaultProps = {
  size: 18,
};

export default IconAExecutiveScheduleStatus;
