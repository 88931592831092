/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const PeopleMoney24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M576 533.376a64 64 0 0 1 63.701333 57.856L640 597.333333v42.666667h-64a106.666667 106.666667 0 0 0-106.666667 106.666667v137.429333A471.893333 471.893333 0 0 1 362.666667 896c-116.138667 0-270.293333-40.789333-277.12-182.186667L85.333333 704V597.333333a64 64 0 0 1 57.856-63.701333L149.333333 533.333333l426.666667 0.042667z m341.333333 106.624c7.296 0 14.421333 0.725333 21.333334 2.133333V597.333333l-0.298667-6.186666A64 64 0 0 0 874.666667 533.333333h-213.845334l4.821334 6.912c10.666667 16.512 17.024 36.010667 17.024 57.088v42.666667h234.666666zM362.666667 85.333333a192 192 0 1 1 0 384 192 192 0 0 1 0-384z m384 85.333334a149.333333 149.333333 0 1 1 0 298.666666 149.333333 149.333333 0 0 1 0-298.666666zM512 746.666667a64 64 0 0 1 64-64h341.333333a64 64 0 0 1 64 64v170.666666a64 64 0 0 1-64 64h-341.333333a64 64 0 0 1-64-64v-170.666666z m426.666667 21.333333a42.666667 42.666667 0 0 1-42.666667-42.666667h-42.666667a85.333333 85.333333 0 0 0 85.333334 85.333334v-42.666667z m0 85.333333a85.333333 85.333333 0 0 0-85.333334 85.333334h42.666667a42.666667 42.666667 0 0 1 42.666667-42.666667v-42.666667z m-341.333334-128a42.666667 42.666667 0 0 1-42.666666 42.666667v42.666667a85.333333 85.333333 0 0 0 85.333333-85.333334h-42.666667z m42.666667 213.333334a85.333333 85.333333 0 0 0-85.333333-85.333334v42.666667a42.666667 42.666667 0 0 1 42.666666 42.666667h42.666667z m181.333333-106.666667a74.666667 74.666667 0 1 0-149.333333 0 74.666667 74.666667 0 0 0 149.333333 0z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default PeopleMoney24Filled;
