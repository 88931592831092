/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Icon24GlUnlock2: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M842.666667 384h-74.666667V277.333333a234.666667 234.666667 0 1 0-469.333333 0 21.333333 21.333333 0 0 0 42.666666 0c0-105.866667 86.133333-192 192-192s192 86.133333 192 192v106.666667H224a53.393333 53.393333 0 0 0-53.333333 53.333333v490.666667a53.393333 53.393333 0 0 0 53.333333 53.333333h618.666667a53.393333 53.393333 0 0 0 53.333333-53.333333V437.333333a53.393333 53.393333 0 0 0-53.333333-53.333333z m10.666666 544a10.666667 10.666667 0 0 1-10.666666 10.666667H224a10.666667 10.666667 0 0 1-10.666667-10.666667V437.333333a10.666667 10.666667 0 0 1 10.666667-10.666666h618.666667a10.666667 10.666667 0 0 1 10.666666 10.666666zM533.333333 554.666667a64 64 0 0 0-21.333333 124.34V789.333333a21.333333 21.333333 0 0 0 42.666667 0v-110.326666A64 64 0 0 0 533.333333 554.666667z m0 85.333333a21.333333 21.333333 0 1 1 21.333334-21.333333 21.333333 21.333333 0 0 1-21.333334 21.333333z"
        fill={getIconColor(color, 0, '#5C5C66')}
      />
    </svg>
  );
};

Icon24GlUnlock2.defaultProps = {
  size: 18,
};

export default Icon24GlUnlock2;
