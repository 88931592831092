/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXinxitishi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 198.485c54.811 0 99.239-44.442 99.239-99.239 0-54.798-44.429-99.24-99.239-99.24-54.812 0-99.24 44.442-99.24 99.24 0 54.797 44.428 99.239 99.24 99.239zM512 315.127c-46.976 0-85.063 38.087-85.063 85.063v538.742c0 46.976 38.087 85.063 85.063 85.063 46.975 0 85.063-38.087 85.063-85.063V400.19c0-46.976-38.088-85.063-85.063-85.063z"
        fill={getIconColor(color, 0, '#040000')}
      />
    </svg>
  );
};

IconXinxitishi.defaultProps = {
  size: 18,
};

export default IconXinxitishi;
