/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconLiebiao1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M380.371457 480.469208H100.097752A100.097752 100.097752 0 0 1 0 380.371457V100.097752A100.097752 100.097752 0 0 1 100.097752 0h280.273705a100.097752 100.097752 0 0 1 100.097751 100.097752v280.273705a100.097752 100.097752 0 0 1-100.097751 100.097751zM100.097752 80.078201a20.01955 20.01955 0 0 0-20.019551 20.019551v280.273705a20.01955 20.01955 0 0 0 20.019551 20.01955h280.273705a20.01955 20.01955 0 0 0 20.01955-20.01955V100.097752a20.01955 20.01955 0 0 0-20.01955-20.019551zM380.371457 1024H100.097752A100.097752 100.097752 0 0 1 0 923.902248V643.628543a100.097752 100.097752 0 0 1 100.097752-100.097751h280.273705a100.097752 100.097752 0 0 1 100.097751 100.097751v280.273705a100.097752 100.097752 0 0 1-100.097751 100.097752zM100.097752 623.608993a20.01955 20.01955 0 0 0-20.019551 20.01955v280.273705a20.01955 20.01955 0 0 0 20.019551 20.019551h280.273705a20.01955 20.01955 0 0 0 20.01955-20.019551V643.628543a20.01955 20.01955 0 0 0-20.01955-20.01955zM983.960899 178.173998H622.608016a40.039101 40.039101 0 0 1 0-80.078201h361.352883a40.039101 40.039101 0 0 1 0 80.078201zM983.960899 382.373412H622.608016a40.039101 40.039101 0 0 1 0-80.078202h361.352883a40.039101 40.039101 0 0 1 0 80.078202zM983.960899 722.705767H622.608016a40.039101 40.039101 0 0 1 0-80.078201h361.352883a40.039101 40.039101 0 0 1 0 80.078201zM983.960899 926.905181H622.608016a40.039101 40.039101 0 0 1 0-80.078202h361.352883a40.039101 40.039101 0 0 1 0 80.078202z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconLiebiao1.defaultProps = {
  size: 18,
};

export default IconLiebiao1;
