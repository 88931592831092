/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAdministrationOrganizationUnits: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M290.882335 950.580746a48.93252 48.93252 0 0 1-49.137259-48.748256V292.489533H100.475457c-42.585624 0-77.391183-30.506048-79.725202-68.996901L20.627412 219.377387V73.112146C20.627412 32.758173 56.374768 0 100.475457 0h479.088274c44.10069 0 79.848046 32.737699 79.848045 73.112146v146.244767c0 40.394922-35.747356 73.13262-79.848045 73.13262H340.019594v194.993022h270.254924v-17.075197c0-42.892732 30.751734-77.943977 69.529221-80.298471l4.176667-0.122843h245.686294c40.702029 0 73.705888 35.993042 73.705888 80.421314v107.242067c0 44.428272-33.003859 80.441787-73.705888 80.441788h-245.686294c-38.449905 0-70.020594-32.143957-73.419254-73.132621H340.019594v268.125643h270.254924v-17.075198c0-42.892732 30.751734-77.943977 69.529221-80.29847l4.176667-0.122843h245.686294c40.702029 0 73.705888 35.993042 73.705888 80.421313v107.242068C1003.372588 987.679376 970.368729 1023.692892 929.6667 1023.692892h-245.686294c-38.449905 0-70.020594-32.123483-73.419254-73.112146H290.882335z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAdministrationOrganizationUnits.defaultProps = {
  size: 18,
};

export default IconAAdministrationOrganizationUnits;
