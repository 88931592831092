/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWenshugaizhang: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M277.92 60.992c-3.104 6.4-3.104 12.832-3.104 19.264 0 43.136 28.576 86.272 66.528 89.664l4.8 0.224H671.68c37.184 0 68.192-44.96 68.192-89.92 0-4.8-1.728-9.6-2.624-14.4l-0.48-4.832h105.408c78.08 0 115.456 45.184 117.728 115.136l0.096 6.848 0.032 321.088A272 272 0 1 0 848 1024H175.616c-78.08 0-109.632-42.176-111.52-114.88L64 902.048V182.976C64 114.432 81.536 64 161.92 61.12l7.488-0.128h108.512z m143.808 428.992H311.168c-21.024 0-38.08 21.504-38.08 48 0 24.96 15.104 45.44 34.432 47.776l3.648 0.224h110.56c21.056 0 38.08-21.504 38.08-48s-17.024-48-38.08-48zM539.84 320H311.168c-21.024 0-38.08 21.504-38.08 48 0 24.96 15.104 45.44 34.432 47.776l3.648 0.224h228.704c21.056 0 38.112-21.504 38.112-48s-17.056-48-38.08-48z m85.28-320c24.8 0 46.528 25.696 46.528 54.56 0 30.336-19.36 52.064-42.4 54.4l-4.128 0.192H392.64c-24.8 0-46.496-25.696-46.496-54.592 0-30.304 19.36-52.032 42.4-54.368L392.64 0h232.512z m228.928 590.336l30.336 64.128c3.616 7.584 10.56 12.864 18.592 14.08l67.84 10.272c20.224 3.072 28.32 29.024 13.664 43.872l-49.088 49.92a26.464 26.464 0 0 0-7.104 22.784l11.584 70.464c3.456 21.024-17.696 37.056-35.776 27.136l-60.64-33.28a23.872 23.872 0 0 0-22.976 0l-60.672 33.28c-18.08 9.92-39.232-6.112-35.776-27.136l11.616-70.464a26.496 26.496 0 0 0-7.104-22.784l-49.12-49.92c-14.624-14.88-6.528-40.832 13.664-43.872l67.84-10.272a24.8 24.8 0 0 0 18.592-14.08l30.368-64.128a24.192 24.192 0 0 1 44.16 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconWenshugaizhang.defaultProps = {
  size: 18,
};

export default IconWenshugaizhang;
