/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Guanlianzhangdan4: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M636.970667 725.333333c13.44 0 24.362667 11.477333 24.362666 25.6 0 14.122667-10.922667 25.6-24.362666 25.6h-24.405334c-40.405333 0-73.130667 34.389333-73.130666 76.8s32.725333 76.8 73.130666 76.8h24.405334c13.44 0 24.362667 11.477333 24.362666 25.6 0 14.122667-10.922667 25.6-24.362666 25.6h-24.405334c-67.328 0-121.898667-57.301333-121.898666-128s54.613333-128 121.898666-128z m243.797333 0c67.328 0 121.898667 57.301333 121.898667 128s-54.613333 128-121.898667 128h-24.405333c-13.44 0-24.362667-11.477333-24.362667-25.6 0-14.122667 10.922667-25.6 24.362667-25.6h24.405333c40.405333 0 73.130667-34.389333 73.130667-76.8s-32.725333-76.8-73.130667-76.8h-24.405333c-13.44 0-24.362667-11.477333-24.362667-25.6 0-14.122667 10.922667-25.6 24.362667-25.6z m-31.701333 85.333334c14.122667 0 25.6 19.114667 25.6 42.666666s-11.477333 42.666667-25.6 42.666667h-204.8c-14.122667 0-25.6-19.114667-25.6-42.666667s11.477333-42.666667 25.6-42.666666h204.8zM448 42.666667c235.648 0 426.666667 191.018667 426.666667 426.666666 0 77.738667-20.778667 150.613333-57.088 213.376L618.666667 682.666667a170.666667 170.666667 0 0 0-165.290667 213.290666L448 896c-235.648 0-426.666667-191.018667-426.666667-426.666667s191.018667-426.666667 426.666667-426.666666z m6.272 254.549333l-129.706667 229.12a36.266667 36.266667 0 0 0-0.682666 34.005333 30.293333 30.293333 0 0 0 26.709333 17.194667h123.434667v55.893333c0 15.36 11.392 27.904 25.472 27.904 14.08 0 25.514667-12.501333 25.514666-27.946666v-55.850667h25.472c14.08 0 25.514667-12.501333 25.514667-27.946667 0-15.402667-11.434667-27.904-25.514667-27.904h-25.472V319.317333c0-42.154667-50.517333-57.941333-70.741333-22.101333z m19.754667 71.082667v153.386666H387.328l86.698667-153.386666z"
        fill={getIconColor(color, 0, '#636df1')}
      />
    </svg>
  );
};

export default Guanlianzhangdan4;
