/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentSend24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M242.944 512L98.090667 139.605333c-10.069333-25.898667 15.189333-50.688 40.192-41.898666l3.968 1.706666 768 384a32 32 0 0 1 4.138666 54.741334l-4.138666 2.474666-768 384a32 32 0 0 1-45.44-36.138666l1.28-4.096L242.944 512 98.090667 139.605333 242.944 512zM187.818667 193.706667l111.36 286.293333h282.752a32 32 0 0 1 31.701333 27.648l0.298667 4.352a32 32 0 0 1-27.690667 31.701333l-4.309333 0.298667H299.093333l-111.317333 286.293333L824.405333 512 187.776 193.706667z"
        fill={getIconColor(color, 0, '#515151')}
      />
    </svg>
  );
};

export default IcFluentSend24Regular;
