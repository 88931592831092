/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentWarning24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M465.493333 118.698667a96 96 0 0 1 126.933334 31.573333l3.541333 5.888 331.050667 597.717333a96 96 0 0 1-77.397334 142.250667l-6.570666 0.256H181.034667a96 96 0 0 1-87.082667-136.405333l3.072-6.101334L427.989333 156.16a96 96 0 0 1 37.461334-37.461333z m405.546667 666.154666l-331.093333-597.674666a32 32 0 0 0-53.504-3.754667l-2.432 3.754667-330.965334 597.674666a32 32 0 0 0 23.936 47.274667l4.053334 0.256h662.016a32 32 0 0 0 29.696-43.861333l-1.706667-3.669334-331.093333-597.674666 331.093333 597.674666zM512 682.752a42.624 42.624 0 1 1 0 85.205333 42.624 42.624 0 0 1 0-85.205333zM511.786667 362.666667a32 32 0 0 1 31.744 27.605333l0.298666 4.352 0.170667 192.085333a32 32 0 0 1-63.744 4.394667l-0.256-4.352-0.170667-192.085333a32 32 0 0 1 32-32z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentWarning24Regular;
