/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksLogSumquery: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M740.78208 161.6896a80.7936 80.7936 0 0 1 80.75264 80.83456v700.6208A80.7936 80.7936 0 0 1 740.78208 1024H121.71264A80.7936 80.7936 0 0 1 40.96 943.16544v-700.6208a80.7936 80.7936 0 0 1 80.75264-80.85504H740.7616zM902.28736 0A80.7936 80.7936 0 0 1 983.04 80.83456v727.59296a53.8624 53.8624 0 0 1-53.84192 53.88288H888.832V121.26208c0-14.88896-12.04224-26.95168-26.91072-26.95168H162.07872V53.90336A53.8624 53.8624 0 0 1 215.92064 0h686.36672zM592.75264 754.52416H269.7216l-2.3552 0.06144a40.40704 40.40704 0 0 0 0 80.71168l2.3552 0.06144h323.01056l2.37568-0.06144a40.40704 40.40704 0 0 0 0-80.6912l-2.37568-0.08192z m-134.59456-202.09664h-188.416l-2.3552 0.06144a40.40704 40.40704 0 0 0 2.3552 80.77312h188.416l2.37568-0.06144a40.40704 40.40704 0 0 0-2.37568-80.77312z m134.59456-202.11712H269.7216l-2.3552 0.08192a40.40704 40.40704 0 0 0 0 80.6912l2.3552 0.08192h323.01056l2.37568-0.08192a40.40704 40.40704 0 0 0 0-80.6912l-2.37568-0.08192z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksLogSumquery.defaultProps = {
  size: 18,
};

export default IconAWorksLogSumquery;
