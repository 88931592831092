/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessStampFilesStamp: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M907.468721 0.000205A54.968309 54.968309 0 0 1 962.55991 54.845634v914.308937A54.968309 54.968309 0 0 1 907.468721 1024H116.531279A54.968309 54.968309 0 0 1 61.44009 969.154571V54.845634A54.968309 54.968309 0 0 1 116.531279 0.000205h790.937442zM696.934363 733.859898H327.065637l-2.1504 0.14336c-9.953278 1.20832-17.715196 10.608638-17.715196 22.056956 0 12.247038 8.908798 22.179836 19.865596 22.179835h369.868726l2.1504-0.12288c9.953278-1.2288 17.715196-10.629118 17.715196-22.056955 0-12.267518-8.908798-22.200316-19.865596-22.200316zM514.41664 245.760156c-65.945587 0-119.603176 50.89279-119.603177 113.459177 0 40.181752 22.527995 77.209585 58.408949 97.50526l-11.673598 89.395183H344.064034l-3.01056 0.12288c-18.903036 1.47456-33.832953 16.588797-33.832953 34.938873v73.256945l0.12288 2.867199c1.536 18.001916 17.407997 32.194554 36.720633 32.194554H679.935966l3.03104-0.12288c18.903036-1.47456 33.832953-16.588797 33.832953-34.938873v-73.256945l-0.12288-2.8672c-1.536-18.001916-17.428477-32.194554-36.741113-32.194553h-92.897261l-11.632638-89.251823 4.423679-2.621439c33.423353-20.746236 54.210549-56.401909 54.21055-95.027181 0-62.566387-53.657589-113.459177-119.603176-113.459177z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessStampFilesStamp.defaultProps = {
  size: 18,
};

export default IconABusinessStampFilesStamp;
