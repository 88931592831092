/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Bianmaguize: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M749.738667 544.725333a33.450667 33.450667 0 0 1 7.936 32.64l-112.298667 379.904a33.834667 33.834667 0 0 1-56.746667 13.653334 33.450667 33.450667 0 0 1-7.936-32.597334l112.298667-379.946666a33.834667 33.834667 0 0 1 56.746667-13.653334zM523.221333 42.666667c51.456 0 94.72 38.485333 100.437334 89.386666h79.232c26.794667 0 52.48 10.624 71.424 29.44 18.944 18.901333 29.610667 44.458667 29.610666 71.125334v333.482666a78.378667 78.378667 0 0 0-68.266666-75.861333 78.592 78.592 0 0 0-85.76 55.552l-23.04 77.952h-32.597334a78.805333 78.805333 0 0 0-129.792-18.474667l-89.813333 100.608a77.952 77.952 0 0 0 0 103.936l89.813333 100.565334a78.506667 78.506667 0 0 0 58.24 26.24H186.368A100.821333 100.821333 0 0 1 85.333333 836.053333V232.618667a100.821333 100.821333 0 0 1 101.034667-100.565334h79.232A100.864 100.864 0 0 1 366.08 42.666667h157.184z m317.141334 592.341333L930.133333 735.573333a33.408 33.408 0 0 1 0 44.544l-89.813333 100.565334a33.706667 33.706667 0 0 1-47.616 2.816v-0.042667a33.408 33.408 0 0 1-2.773333-47.317333l69.973333-78.293334-69.973333-78.336a33.408 33.408 0 0 1 2.816-47.317333 33.792 33.792 0 0 1 47.573333 2.816z m-342.314667 0a33.792 33.792 0 0 1 47.530667-2.816 33.408 33.408 0 0 1 2.858666 47.36l-69.973333 78.293333 69.973333 78.250667a33.408 33.408 0 0 1-2.858666 47.36 33.706667 33.706667 0 0 1-47.488-2.773333l-89.856-100.565334a33.365333 33.365333 0 0 1 0-44.544z m25.173333-525.312H366.037333a33.621333 33.621333 0 0 0-33.706666 33.536c0 18.517333 15.104 33.536 33.706666 33.536h157.184c18.602667 0 33.706667-15.018667 33.706667-33.536a33.621333 33.621333 0 0 0-33.706667-33.536z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Bianmaguize;
