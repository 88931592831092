/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZhuye: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M832 968.32H192a98.56 98.56 0 0 1-101.76-97.92V523.52a32.64 32.64 0 0 1 32.64-32.64 32.64 32.64 0 0 1 32.64 32.64v346.88a32.64 32.64 0 0 0 32.64 32H832a32.64 32.64 0 0 0 32.64-32V520.96a32.64 32.64 0 1 1 64 0v349.44A97.92 97.92 0 0 1 832 968.32z"
        fill={getIconColor(color, 0, '#323333')}
      />

      <path
        d="M376.32 968.32a32.64 32.64 0 0 1-32.64-33.28V586.24A97.92 97.92 0 0 1 441.6 488.32H576a98.56 98.56 0 0 1 97.92 97.92v346.24a33.28 33.28 0 1 1-64 0V586.24A32 32 0 0 0 576 553.6H441.6a32.64 32.64 0 0 0-32.64 32.64v348.8a32.64 32.64 0 0 1-32.64 33.28zM39.04 488.32a33.92 33.92 0 0 1-25.6-12.16 32 32 0 0 1 5.76-46.08l403.84-320a128 128 0 0 1 158.72-3.84l423.04 320a32.64 32.64 0 0 1-39.68 52.48l-424.32-320a64 64 0 0 0-75.52 0l-405.76 320a32 32 0 0 1-20.48 9.6z"
        fill={getIconColor(color, 1, '#323333')}
      />
    </svg>
  );
};

IconZhuye.defaultProps = {
  size: 18,
};

export default IconZhuye;
