/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksMeetingConferenceBooking: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M190.17728 708.89472C119.3984 708.89472 61.44 650.91584 61.44 579.9936V128.90112C61.44 58.04032 119.3984 0 190.17728 0h643.62496C904.6016 0 962.56 58.01984 962.56 128.90112V579.9936c0 70.90176-57.93792 128.9216-128.73728 128.9216H190.17728v-0.02048z m337.98144-216.576c8.45824 0.04096 16.60928-3.1744 22.79424-8.97024L776.8064 268.94336a29.4912 29.4912 0 0 0 0-43.33568 33.46432 33.46432 0 0 0-45.62944 0l-203.01824 192.7168-106.25024-100.80256a33.46432 33.46432 0 0 0-45.60896 0l-129.10592 122.4704a29.4912 29.4912 0 0 0 0 43.29472c12.9024 12.00128 32.768 12.00128 45.6704 0l106.25024-100.82304 106.25024 100.84352c6.18496 5.81632 14.336 9.0112 22.79424 8.97024v0.04096z m386.1504 295.38304C940.8512 787.70176 962.56 808.96 962.56 834.94912c0 26.0096-21.7088 47.26784-48.25088 47.26784h-156.91776l50.688 66.1504a46.65344 46.65344 0 0 1-9.6256 66.19136 48.78336 48.78336 0 0 1-28.95872 9.44128 48.59904 48.59904 0 0 1-38.62528-18.90304l-94.14656-122.88H387.2768l-94.12608 122.88a48.59904 48.59904 0 0 1-38.62528 18.90304 48.8448 48.8448 0 0 1-28.95872-9.44128 46.73536 46.73536 0 0 1-9.64608-66.17088l50.688-66.1504H109.69088C83.1488 882.21696 61.44 860.91776 61.44 834.90816c0-25.96864 21.7088-47.22688 48.25088-47.22688h804.59776z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksMeetingConferenceBooking.defaultProps = {
  size: 18,
};

export default IconAWorksMeetingConferenceBooking;
