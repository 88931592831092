/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFapiaoguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M895.99664 474.62222a319.9988 319.9988 0 0 0-327.678771 543.485962 51.967805 51.967805 0 0 1-53.759799-7.167973L441.598344 952.828427l-65.343755 58.111782a50.431811 50.431811 0 0 1-33.663874 12.415953 50.111812 50.111812 0 0 1-33.343875-12.287954l-65.215755-58.111782-65.983753 58.623781a50.815809 50.815809 0 0 1-33.599874 12.415953 49.919813 49.919813 0 0 1-33.407874-12.287954L34.495871 943.612461a36.607863 36.607863 0 0 1-6.271977-7.039973A67.327748 67.327748 0 0 1 0 882.74869V51.391807C0 23.039914 23.039914 0 50.75181 0h794.49302c28.031895 0 50.75181 22.847914 50.75181 51.391807V474.62222zM102.399616 429.502389c0 13.503949 11.199958 25.215905 24.959906 25.215906h231.679132c14.271946 0 24.959906-11.263958 24.959906-25.215906a25.407905 25.407905 0 0 0-24.959906-25.279905H127.359522a24.831907 24.831907 0 0 0-24.959906 25.279905z m0 202.047243c0 13.567949 11.007959 25.279905 24.639908 25.279905h181.11932a24.959906 24.959906 0 0 0 24.639908-25.279905 25.215905 25.215905 0 0 0-24.639908-25.215906h-181.11932a24.959906 24.959906 0 0 0-24.639908 25.215906z m0-429.43839c0 13.567949 11.263958 25.279905 25.215905 25.279905h333.56675c14.079947 0 25.215905-11.327958 25.215905-25.279905a25.471904 25.471904 0 0 0-25.215905-25.279905H127.615521a25.087906 25.087906 0 0 0-25.215905 25.279905z"
        fill={getIconColor(color, 0, '#666666')}
      />

      <path
        d="M767.99712 1023.99616a255.99904 255.99904 0 1 1 0-511.99808 255.99904 255.99904 0 0 1 0 511.99808z m120.767547-259.455027a21.24792 21.24792 0 1 0 0-42.367841h-55.167793l55.039794-54.911794a21.119921 21.119921 0 0 0 0-30.079887 21.43992 21.43992 0 0 0-30.079888 0l-78.271706 77.951707-78.207707-77.887708a21.43992 21.43992 0 0 0-30.207887 0 21.119921 21.119921 0 0 0 0 30.079888l55.167794 54.847794h-51.583807a21.24792 21.24792 0 0 0 0 42.431841h85.31168v42.431841h-85.37568a21.24792 21.24792 0 1 0 0 42.49584h85.37568v49.471815a21.31192 21.31192 0 1 0 42.68784 0v-49.471815h85.31168a21.24792 21.24792 0 0 0 0-42.49584h-85.31168v-42.495841h85.31168z"
        fill={getIconColor(color, 1, '#666666')}
      />
    </svg>
  );
};

IconFapiaoguanli.defaultProps = {
  size: 18,
};

export default IconFapiaoguanli;
