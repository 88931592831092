/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Yewuanjian: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 307.2V0H192C133.12 0 85.333333 45.866667 85.333333 102.4v819.2C85.333333 978.133333 133.12 1024 192 1024h640c58.88 0 106.666667-45.866667 106.666667-102.4v-512h-320c-58.88 0-106.666667-45.866667-106.666667-102.4m-200.021333 218.837333h400c22.101333 0 40.021333 17.749333 40.021333 39.68 0 21.845333-17.92 39.594667-40.021333 39.594667H312.021333a39.808 39.808 0 0 1-39.978666-39.637333c0-21.888 17.92-39.637333 40.021333-39.637334m0 140.8h400c22.058667 0 39.978667 17.749333 39.978667 39.68 0 21.845333-17.92 39.594667-40.021334 39.594667H312.021333a39.808 39.808 0 0 1-39.978666-39.637333c0-21.888 17.92-39.637333 40.021333-39.637334m0 140.8h400c22.058667 0 39.978667 17.749333 39.978667 39.68 0 21.845333-17.92 39.594667-40.021334 39.594667H312.021333a39.808 39.808 0 0 1-39.978666-39.637333c0-21.888 17.92-39.637333 40.021333-39.637334M592 307.2V25.6l320 307.2H618.666667a26.154667 26.154667 0 0 1-26.666667-25.6"
        fill={getIconColor(color, 0, '#636df1')}
      />
    </svg>
  );
};

export default Yewuanjian;
