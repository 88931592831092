/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShoukuantongji: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M175.542857 141.409524h599.771429v448.609524h97.523809V43.885714H78.019048v936.228572H585.142857v-97.52381H175.542857z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M945.980952 736.304762h-204.8l43.885715-43.885714-58.514286-53.638096-146.285714 146.285715 146.285714 146.285714 58.514286-53.638095-43.885715-43.885715h204.8zM253.561905 448.609524h97.523809v170.666666h-97.523809zM429.104762 336.457143h97.523809v277.942857h-97.523809zM599.771429 229.180952h97.523809v390.095238h-97.523809z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconShoukuantongji.defaultProps = {
  size: 18,
};

export default IconShoukuantongji;
