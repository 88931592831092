/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconKapianmoshi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M844.8 844.8V623.2H623.2v221.6h221.6m-444 0V623.2H179.2v221.6h221.6m444-444V179.2H623.2v221.6h221.6m-444 0V179.2H179.2v221.6h221.6m472 527.2H595.2c-30.4 0-55.2-24.8-55.2-55.2V595.2c0-30.4 24.8-55.2 55.2-55.2h277.6c30.4 0 55.2 24.8 55.2 55.2v277.6c0 30.4-24.8 55.2-55.2 55.2z m-444 0H151.2c-30.4 0-55.2-24.8-55.2-55.2V595.2c0-30.4 24.8-55.2 55.2-55.2h277.6c30.4 0 55.2 24.8 55.2 55.2v277.6c0 30.4-24.8 55.2-55.2 55.2z m444-444H595.2c-30.4 0-55.2-24.8-55.2-55.2V151.2c0-30.4 24.8-55.2 55.2-55.2h277.6c30.4 0 55.2 24.8 55.2 55.2v277.6c0 30.4-24.8 55.2-55.2 55.2z m-444 0H151.2c-30.4 0-55.2-24.8-55.2-55.2V151.2c0-30.4 24.8-55.2 55.2-55.2h277.6c30.4 0 55.2 24.8 55.2 55.2v277.6c0 30.4-24.8 55.2-55.2 55.2z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconKapianmoshi.defaultProps = {
  size: 18,
};

export default IconKapianmoshi;
