/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTishi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 145.5c49.5 0 97.5 9.7 142.6 28.8 43.6 18.5 82.8 44.9 116.5 78.6 33.7 33.7 60.1 72.9 78.6 116.5 19.1 45.1 28.8 93.1 28.8 142.6s-9.7 97.5-28.8 142.6c-18.5 43.6-44.9 82.8-78.6 116.5-33.7 33.7-72.9 60.1-116.5 78.6-45.1 19.1-93.1 28.8-142.6 28.8s-97.5-9.7-142.6-28.8c-43.6-18.5-82.8-44.9-116.5-78.6-33.7-33.7-60.1-72.9-78.6-116.5-19.1-45.1-28.8-93.1-28.8-142.6s9.7-97.5 28.8-142.6c18.5-43.6 44.9-82.8 78.6-116.5 33.7-33.7 72.9-60.1 116.5-78.6 45.1-19.1 93.1-28.8 142.6-28.8m0-60c-235.5 0-426.5 191-426.5 426.5s191 426.5 426.5 426.5 426.5-191 426.5-426.5c0-235.6-190.9-426.5-426.5-426.5z m0 554.2c-16.6 0-30-13.4-30-30V299.1c0-16.6 13.4-30 30-30s30 13.4 30 30v310.6c0 16.5-13.4 30-30 30z m-34 85.6a34 34 0 1 0 68 0 34 34 0 1 0-68 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconTishi.defaultProps = {
  size: 18,
};

export default IconTishi;
