/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconLiebiao2: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M841.6 489.6h-214.4c-48 0-86.4-38.4-86.4-86.4V188.8c0-48 38.4-86.4 86.4-86.4h214.4c48 0 86.4 38.4 86.4 86.4v214.4c0 48-38.4 86.4-86.4 86.4z m-211.2-320c-12.8 0-22.4 9.6-22.4 22.4v214.4c0 12.8 9.6 22.4 22.4 22.4h214.4c12.8 0 22.4-9.6 22.4-22.4V188.8c0-12.8-9.6-22.4-22.4-22.4h-214.4zM393.6 489.6H182.4c-48 0-86.4-38.4-86.4-86.4V188.8c0-48 38.4-86.4 86.4-86.4h214.4c48 0 86.4 38.4 86.4 86.4v214.4c-3.2 48-41.6 86.4-89.6 86.4z m-211.2-320c-12.8 0-22.4 9.6-22.4 19.2v214.4c0 12.8 9.6 22.4 22.4 22.4h214.4c12.8 0 22.4-9.6 22.4-22.4V188.8c0-12.8-9.6-22.4-22.4-22.4H182.4zM841.6 937.6h-214.4c-48 0-86.4-38.4-86.4-86.4v-214.4c0-48 38.4-86.4 86.4-86.4h214.4c48 0 86.4 38.4 86.4 86.4v214.4c0 48-38.4 86.4-86.4 86.4z m-211.2-323.2c-12.8 0-22.4 9.6-22.4 22.4v214.4c0 12.8 9.6 22.4 22.4 22.4h214.4c12.8 0 22.4-9.6 22.4-22.4v-214.4c0-12.8-9.6-22.4-22.4-22.4h-214.4zM393.6 937.6H182.4c-48 0-86.4-38.4-86.4-86.4v-214.4c0-48 38.4-86.4 86.4-86.4h214.4c48 0 86.4 38.4 86.4 86.4v214.4c-3.2 48-41.6 86.4-89.6 86.4zM182.4 614.4c-12.8 0-22.4 9.6-22.4 22.4v214.4c0 12.8 9.6 22.4 22.4 22.4h214.4c12.8 0 22.4-9.6 22.4-22.4v-214.4c0-12.8-9.6-22.4-22.4-22.4H182.4z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconLiebiao2.defaultProps = {
  size: 18,
};

export default IconLiebiao2;
