/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialChargeManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M204.43136 102.4v88.2688h75.01824V102.4h707.03104C1007.12448 102.4 1024 120.87296 1024 143.4624v737.0752C1024 903.12704 1007.12448 921.6 986.48064 921.6H279.4496v-94.45376H204.43136V921.6h-166.912C16.87552 921.6 0 903.12704 0 880.5376V143.4624C0 120.87296 16.87552 102.4 37.51936 102.4h166.912z m75.01824 539.97568H204.43136v102.64576h75.01824v-102.64576zM694.41536 307.2h-78.21312c-12.41088 0-22.65088 8.78592-24.4736 20.48l-5.85728 42.41408c-7.31136 3.29728-14.25408 7.33184-21.56544 12.0832l-41.28768-16.09728a24.55552 24.55552 0 0 0-8.76544-1.45408c-8.78592 0-17.18272 4.38272-21.1968 12.06272l-39.1168 65.82272c-6.20544 10.24-3.64544 23.7568 5.85728 31.08864l34.7136 26.33728c-0.36864 4.38272-0.73728 8.3968-0.73728 12.06272s0 7.68 0.73728 12.0832l-34.7136 26.3168c-9.50272 7.31136-12.06272 20.11136-5.85728 31.08864l38.74816 65.45408a25.00608 25.00608 0 0 0 29.96224 10.97728l41.30816-16.09728c6.94272 4.75136 14.25408 8.78592 21.54496 12.0832l6.22592 42.04544c1.45408 11.71456 12.06272 20.84864 24.4736 20.84864h78.2336c12.41088 0 22.6304-8.78592 24.4736-20.48l6.20544-42.41408c7.31136-3.29728 14.25408-7.33184 21.56544-12.0832l41.28768 16.09728c2.92864 1.10592 5.85728 1.45408 8.78592 1.45408 8.76544 0 17.16224-4.38272 21.1968-12.06272l39.09632-66.19136a23.79776 23.79776 0 0 0-6.20544-30.72l-34.73408-26.33728a118.9888 118.9888 0 0 0 0.73728-12.06272c0-4.38272-0.36864-8.3968-0.73728-12.0832l34.73408-26.3168c9.48224-7.31136 12.04224-20.11136 5.8368-31.08864l-38.72768-65.45408a25.00608 25.00608 0 0 0-29.98272-10.97728l-41.28768 16.09728a140.12416 140.12416 0 0 0-21.56544-12.0832l-6.20544-42.04544A24.4736 24.4736 0 0 0 694.41536 307.2zM655.36 424.22272A88.02304 88.02304 0 0 1 743.13728 512 88.02304 88.02304 0 0 1 655.36 599.77728 88.02304 88.02304 0 0 1 567.58272 512 88.02304 88.02304 0 0 1 655.36 424.22272z m-375.9104 33.3824H204.43136v102.64576h75.01824v-102.66624z m0-184.79104H204.43136v102.64576h75.01824v-102.64576z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialChargeManage.defaultProps = {
  size: 18,
};

export default IconAFinancialChargeManage;
