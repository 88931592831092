/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFabu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M434.631111 903.395556c-18.887111 0-34.133333-15.246222-34.133333-34.133334V672.768c0-7.850667 2.730667-15.587556 7.736889-21.617778l410.510222-500.280889c9.671111-11.832889 26.055111-15.701333 40.049778-9.557333s22.186667 20.821333 20.138666 35.953778l-93.411555 664.917333c-1.479111 10.922667-8.305778 20.48-17.976889 25.486222-9.784889 5.12-21.390222 5.12-31.288889 0.113778l-213.105778-108.430222c-16.839111-8.533333-23.438222-29.127111-14.904889-45.852445 8.533333-16.839111 29.127111-23.438222 45.852445-14.904888l170.552889 86.812444 69.859555-497.208889L468.764444 685.056V869.262222c0 18.887111-15.36 34.133333-34.133333 34.133334z"
        fill={getIconColor(color, 0, '#592B0C')}
      />

      <path
        d="M331.776 659.911111c-5.233778 0-10.581333-1.251556-15.587556-3.754667l-174.08-89.884444c-11.264-5.802667-18.432-17.408-18.432-30.151111-0.113778-12.743111 6.940444-24.348444 18.204445-30.378667l687.331555-363.406222c16.611556-8.760889 37.319111-2.389333 46.08 14.222222 8.760889 16.611556 2.389333 37.319111-14.222222 46.08L231.424 535.552l116.053333 59.960889c16.725333 8.647111 23.324444 29.240889 14.677334 45.966222-6.030222 11.719111-17.976889 18.432-30.378667 18.432z"
        fill={getIconColor(color, 1, '#592B0C')}
      />
    </svg>
  );
};

IconFabu.defaultProps = {
  size: 18,
};

export default IconFabu;
