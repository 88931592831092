/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXitongpeizhi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M970.6752 204.6208l-453.0688 188.6464-454.2464-188.672L516.4288 25.6l454.2464 179.0208zM25.6 282.9568l451.8912 192.8704V998.4c-35.9936-16.2816-73.1648-33.152-112.1024-50.6368-33.024-15.0528-70.784-31.3344-112.6656-50.0224-41.8816-18.0736-83.7632-36.7616-126.2336-54.8352a161.1776 161.1776 0 0 1-41.3184-27.7248 192.9728 192.9728 0 0 1-31.8464-37.9904 175.5648 175.5648 0 0 1-20.6336-43.9808A157.44 157.44 0 0 1 25.6 687.36V282.9568z m972.8 0v401.408c0 18.0992-3.5328 36.1728-10.624 53.6576a242.3552 242.3552 0 0 1-26.5472 48.8192 232.6016 232.6016 0 0 1-35.968 40.3968c-12.9792 11.4432-25.9584 20.48-38.3488 27.1104a19048.832 19048.832 0 0 1-115.0464 48.8192c-40.1152 16.896-77.2864 32.5376-110.8992 46.4128-39.5264 16.896-77.8752 33.152-116.224 48.8192V475.8272L998.4 282.9568z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconXitongpeizhi.defaultProps = {
  size: 18,
};

export default IconXitongpeizhi;
