/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXitongguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 1024V576h512v448H512z m384-320h-256v192h256v-192z m-128-192a256 256 0 1 1 256-256 256 256 0 0 1-256 256z m0-384a128 128 0 1 0 128 128 128 128 0 0 0-128-128zM0 960V576h448v448H0v-64z m128-64h192v-192H128v192zM0 448V0h448v512H0V448z m128-64h192V128H128v256z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconXitongguanli.defaultProps = {
  size: 18,
};

export default IconXitongguanli;
