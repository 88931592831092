/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXiaoxifull: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M510.567168 1019.52a506.24 506.24 0 0 1-222.5408-51.0976l-0.4864-0.256-0.512 0.1024L28.416768 1018.5472a21.0944 21.0944 0 0 1-24.0384-27.5968l72.6784-212.5056 0.256-0.7424-0.4096-0.64a506.3168 506.3168 0 0 1-75.264-266.496A506.5216 506.5216 0 0 1 88.551168 226.0224a512.6912 512.6912 0 0 1 137.472-137.472A506.24 506.24 0 0 1 510.567168 1.6384 506.24 506.24 0 0 1 795.136768 88.5248a512.512 512.512 0 0 1 137.472 137.472 506.2912 506.2912 0 0 1 86.912 284.544 506.496 506.496 0 0 1-86.912 284.544 512.6912 512.6912 0 0 1-137.472 137.472 506.24 506.24 0 0 1-284.5696 86.912h0.0256z"
        fill={getIconColor(color, 0, '#5D73FA')}
      />

      <path
        d="M510.567168 1017.8816a504.7296 504.7296 0 0 0 283.648-86.656 510.7456 510.7456 0 0 0 137.0368-137.0112 505.1648 505.1648 0 0 0 86.6304-283.648 504.832 504.832 0 0 0-86.6304-283.648 510.7712 510.7712 0 0 0-137.0368-137.0368A504.8064 504.8064 0 0 0 510.567168 3.2512a504.7808 504.7808 0 0 0-283.648 86.6304 510.9504 510.9504 0 0 0-137.0112 137.0368 505.088 505.088 0 0 0-86.6304 283.648 504.7552 504.7552 0 0 0 75.008 265.6256l0.7936 1.3056-0.512 1.4592L5.939968 991.488a19.4816 19.4816 0 0 0 22.1952 25.472l258.5856-50.2784 1.0752-0.2048 0.9728 0.4864a504.576 504.576 0 0 0 221.824 50.944m0 3.2256c-80.0512 0-155.8016-18.432-223.232-51.2512L28.698368 1020.1344a22.784 22.784 0 0 1-25.8816-29.696l72.6528-212.5312A508.16 508.16 0 0 1 0.000768 510.5664C0.000768 228.5824 228.608768 0 510.567168 0c281.984 0 510.5664 228.608 510.5664 510.5664 0 281.984-228.5824 510.5664-510.5664 510.5664z"
        fill={getIconColor(color, 1, '#4988FD')}
      />

      <path
        d="M500.813568 471.552H240.640768a48.7936 48.7936 0 0 1 0-97.5616h260.1728a48.7936 48.7936 0 0 1 0 97.536z m-123.5712 169.088H240.640768a48.7936 48.7936 0 0 1 0-97.536h136.6016a48.7936 48.7936 0 0 1 0 97.536z"
        fill={getIconColor(color, 2, '#DEE3FE')}
      />
    </svg>
  );
};

IconXiaoxifull.defaultProps = {
  size: 18,
};

export default IconXiaoxifull;
