/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessPreFileCasesManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M859.52512 0C915.72224 0 962.56 43.97056 962.56 98.9184v826.1632c0 54.94784-44.9536 98.9184-101.1712 98.9184H162.6112C106.3936 1024 61.44 980.02944 61.44 925.0816V98.9184C61.44 43.97056 106.3936 0 162.6112 0zM484.84352 767.54944h-185.48736c-20.60288 0-37.4784 16.4864-37.4784 36.63872 0 20.13184 16.87552 36.61824 37.4784 36.61824h185.48736c20.60288 0 37.4784-16.4864 37.4784-36.61824 0-20.15232-16.87552-36.6592-37.4784-36.6592z m185.46688-144.73216H299.35616c-20.60288 0-37.4784 16.4864-37.4784 36.6592 0 20.13184 16.87552 36.61824 37.4784 36.61824H670.3104c20.60288 0 37.4784-16.4864 37.4784-36.63872 0-20.15232-16.87552-36.63872-37.4784-36.63872zM582.5536 177.88928A184.32 184.32 0 1 0 512.04096 532.48 184.5248 184.5248 0 0 0 696.32 348.20096a184.32 184.32 0 0 0-113.7664-170.31168z m-89.86624 83.49696c11.6736 0 21.11488 9.44128 21.11488 21.0944v80.2816h83.3536a21.0944 21.0944 0 0 1 0 42.1888h-96.29696c-1.35168 0-2.68288-0.12288-4.01408-0.38912a21.7088 21.7088 0 0 1-17.5104-4.54656 21.21728 21.21728 0 0 1-7.74144-16.32256v-101.21216c0-11.65312 9.44128-21.0944 21.0944-21.0944z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessPreFileCasesManage.defaultProps = {
  size: 18,
};

export default IconABusinessPreFileCasesManage;
