/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCasesMyCases: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M907.468721 0.000205A54.968309 54.968309 0 0 1 962.55991 54.845634v914.308937A54.968309 54.968309 0 0 1 907.468721 1024H116.531279A54.968309 54.968309 0 0 1 61.44009 969.154571V54.845634A54.968309 54.968309 0 0 1 116.531279 0.000205h790.937442zM700.293082 552.960094c-49.33631 0-89.313262 40.202232-89.313262 89.784302a89.599982 89.599982 0 0 0 64.307187 86.179823C583.475186 741.314617 512 820.899881 512 916.500501v1.16736c0 15.441917 1.86368 30.474234 5.386239 44.892151h365.670327a189.767642 189.767642 0 0 0 5.386239-44.892151v-1.16736c0.06144-95.600621-71.311346-175.185885-163.123168-187.576282a89.743342 89.743342 0 0 0 64.307187-86.179823c0-49.58207-39.997432-89.784302-89.333742-89.784302zM202.608702 519.106661a47.329271 47.329271 0 0 0-47.329271 47.165431 47.267831 47.267831 0 0 0 47.267831 47.10399h165.314527a47.329271 47.329271 0 0 0 47.30879-47.16543 47.329271 47.329271 0 0 0-47.30879-47.165431l-165.253087 0.06144z m0-211.701718a47.329271 47.329271 0 0 0-47.329271 47.165431 47.329271 47.329271 0 0 0 47.329271 47.10399h344.166331a47.329271 47.329271 0 0 0 47.308791-47.10399c0-26.009595-21.135356-47.103991-47.267831-47.165431H202.608702z m0-207.646678a47.329271 47.329271 0 0 0-47.329271 47.16543 47.329271 47.329271 0 0 0 47.329271 47.165431h489.512862a47.329271 47.329271 0 0 0 47.329271-47.165431 47.329271 47.329271 0 0 0-47.308791-47.16543H202.588222z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCasesMyCases.defaultProps = {
  size: 18,
};

export default IconABusinessCasesMyCases;
