/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconRefuse: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 85.333333C276.352 85.333333 85.333333 276.352 85.333333 512s191.018667 426.666667 426.666667 426.666667 426.666667-191.018667 426.666667-426.666667S747.648 85.333333 512 85.333333z m128.426667 606.72l-129.749334-129.749333-129.066666 129.024a35.968 35.968 0 1 1-50.901334-50.901333l129.024-129.066667-130.432-130.432A35.968 35.968 0 1 1 380.16 329.984l130.474667 130.474667 129.706666-129.749334a35.968 35.968 0 1 1 50.944 50.901334l-129.749333 129.749333 129.749333 129.749333a35.968 35.968 0 1 1-50.901333 50.944z"
        fill={getIconColor(color, 0, '#FF3838')}
      />
    </svg>
  );
};

IconRefuse.defaultProps = {
  size: 18,
};

export default IconRefuse;
