/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconChezix: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1302 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M205.406962 309.674295H76.644389a15.328878 15.328878 0 1 1 0-30.657755H214.604289l61.315511-232.998942A61.315511 61.315511 0 0 1 337.235311 0.030964h695.931051a61.315511 61.315511 0 0 1 58.249736 45.986634l58.249735 232.998942H1287.625733a15.328878 15.328878 0 1 1 0 30.657755h-128.762573l15.328878 61.315512h67.447062a61.315511 61.315511 0 0 1 61.315511 61.315511v343.366862a61.315511 61.315511 0 0 1-61.315511 61.315511h-61.315511v91.973267a93.506154 93.506154 0 1 1-187.012309 0v-91.973267H370.958842v91.973267a93.506154 93.506154 0 1 1-187.012309 0v-91.973267H122.631022a61.315511 61.315511 0 0 1-61.315511-61.315511V432.305318a61.315511 61.315511 0 0 1 61.315511-61.315511h67.447062z m104.236369 370.958843a122.631022 122.631022 0 1 0-122.631022-122.631022 122.631022 122.631022 0 0 0 122.631022 122.631022z m744.98346 0a122.631022 122.631022 0 1 0-122.631022-122.631022 122.631022 122.631022 0 0 0 122.631022 122.631022z"
        fill={getIconColor(color, 0, '#FFFD00')}
      />

      <path
        d="M144.091451 309.674295H15.328878a15.328878 15.328878 0 1 1 0-30.657755H153.288778l61.315511-232.998942A61.315511 61.315511 0 0 1 272.854024 0.030964h698.996827a61.315511 61.315511 0 0 1 58.249736 45.986634l58.249735 232.998942h134.894125a15.328878 15.328878 0 1 1 0 30.657755h-125.696798l15.328878 61.315512h67.447062a61.315511 61.315511 0 0 1 61.315511 61.315511v343.366862a61.315511 61.315511 0 0 1-61.315511 61.315511h-61.315511v91.973267a93.506154 93.506154 0 1 1-187.012309 0v-91.973267H309.643331v91.973267a93.506154 93.506154 0 1 1-187.012309 0v-91.973267H61.315511a61.315511 61.315511 0 0 1-61.315511-61.315511V432.305318a61.315511 61.315511 0 0 1 61.315511-61.315511h67.447062z m104.236369 370.958843a122.631022 122.631022 0 1 0-122.631022-122.631022 122.631022 122.631022 0 0 0 122.631022 122.631022z m744.98346 0a122.631022 122.631022 0 1 0-122.631022-122.631022 122.631022 122.631022 0 0 0 122.631022 122.631022z"
        fill={getIconColor(color, 1, '#FF5F25')}
      />

      <path
        d="M174.749207 309.674295H45.986633a15.328878 15.328878 0 1 1 0-30.657755H183.946533l61.315511-232.998942A61.315511 61.315511 0 0 1 303.51178 0.030964h698.996827a61.315511 61.315511 0 0 1 58.249735 45.986634l58.249736 232.998942h134.894124a15.328878 15.328878 0 1 1 0 30.657755h-125.696797l15.328877 61.315512h67.447063a61.315511 61.315511 0 0 1 61.315511 61.315511v343.366862a61.315511 61.315511 0 0 1-61.315511 61.315511h-61.315512v91.973267a93.506154 93.506154 0 1 1-187.012308 0v-91.973267H340.301087v91.973267a93.506154 93.506154 0 1 1-187.012309 0v-91.973267H91.973267a61.315511 61.315511 0 0 1-61.315511-61.315511V432.305318a61.315511 61.315511 0 0 1 61.315511-61.315511h67.447062z m104.236369 370.958843a122.631022 122.631022 0 1 0-122.631023-122.631022 122.631022 122.631022 0 0 0 122.631023 122.631022z m744.98346 0a122.631022 122.631022 0 1 0-122.631023-122.631022 122.631022 122.631022 0 0 0 122.631023 122.631022z"
        fill={getIconColor(color, 2, '#3D5AFE')}
      />

      <path
        d="M671.404847 836.987691A870.680258 870.680258 0 0 1 944.258871 0.030964h58.249736a61.315511 61.315511 0 0 1 58.249735 45.986634l58.249736 232.998942h134.894124a15.328878 15.328878 0 1 1 0 30.657755h-125.696797l15.328877 61.315512h67.447063a61.315511 61.315511 0 0 1 61.315511 61.315511v343.366862a61.315511 61.315511 0 0 1-61.315511 61.315511h-61.315512v91.973267a93.506154 93.506154 0 1 1-187.012308 0v-91.973267z m352.564189-156.354553a122.631022 122.631022 0 1 0-122.631023-122.631022 122.631022 122.631022 0 0 0 122.631023 122.631022z"
        fill={getIconColor(color, 3, '#0023E8')}
      />
    </svg>
  );
};

IconChezix.defaultProps = {
  size: 18,
};

export default IconChezix;
