/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Txt: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M756.7872 10.24H217.68192c-41.00096 0.128-74.20416 33.53088-74.33216 74.77248v853.97504c0.128 41.2416 33.3312 74.6496 74.33216 74.77248h706.39104c40.99584-0.128 74.19904-33.53088 74.32704-74.77248V253.34784L756.7872 10.24z"
        fill={getIconColor(color, 0, '#EFEFEF')}
      />

      <path
        d="M756.7872 178.4576c0.12288 41.25184 33.32096 74.67008 74.32704 74.83392h167.22944L756.78208 10.24v168.2176z"
        fill={getIconColor(color, 1, '#D8D8D8')}
      />

      <path
        d="M94.7968 625.11104h567.99744c46.1312 0 69.1968 23.20384 69.1968 69.61152v185.62048c0 46.40768-23.0656 69.61152-69.1968 69.61152H94.7968C48.6656 949.95456 25.6 926.75072 25.6 880.34304v-185.62048c0-46.40768 23.0656-69.61152 69.1968-69.61152z"
        fill={getIconColor(color, 2, '#A3A3A3')}
      />

      <path
        d="M285.83936 184.7808h353.19296v49.88928H285.83936V184.7808z m0 110.10048h353.19296v49.88416H285.83936v-49.88416z m0 110.09536h470.8864v49.88416h-470.8864v-49.88416z m0 110.1568h576.24064v49.88416H285.83936v-49.88416z"
        fill={getIconColor(color, 3, '#D8D8D8')}
      />

      <path
        d="M214.10304 729.52832h79.34976v21.10976H214.10304v-21.10976z m28.0832 166.016c-6.6304-7.95136-9.91744-19.6096-9.91744-35.0976V677.7856h24.39168v182.77888a27.0336 27.0336 0 0 0 5.07392 17.40288 18.39616 18.39616 0 0 0 15.10912 6.144h16.60928v22.85568h-19.89632c-14.30016 0.11776-24.7552-3.76832-31.37024-11.65824v0.23552z m135.168-86.95296l13.55264 20.3008-56.97536 77.37856h-27.33056l70.75328-97.67936z m1.03936 14.15168l-70.7584-93.21472h27.44832l57.66656 79.4624 72.25344 97.51552h-27.392l-59.21792-83.7632z m4.5568-16.3584l52.0704-76.85632h27.50464L395.5712 824.77568l-12.63104-18.39104z m89.20576-76.85632h79.34464v21.10976H472.15616v-21.10976z m28.07808 166.016c-6.6304-7.95136-9.91744-19.6096-9.91744-35.0976V677.7856h24.39168v182.77888a26.6752 26.6752 0 0 0 5.07392 17.40288 18.39616 18.39616 0 0 0 15.10912 6.144h16.60928v22.85568h-20.00896c-14.336 0.11776-24.76032-3.76832-31.2576-11.65824v0.23552z"
        fill={getIconColor(color, 4, '#FFFFFF')}
      />
    </svg>
  );
};

export default Txt;
