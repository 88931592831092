/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconKaiting: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M522.661647 30.328471c19.757176 0 38.4 4.457412 57.042824 11.143529a140.8 140.8 0 0 1 49.332705 30.117647 5929.351529 5929.351529 0 0 1 157.906824 149.383529c54.844235 53.549176 112.941176 111.525647 173.296941 172.845177 14.245647 14.486588 23.009882 28.973176 28.491294 43.489882 5.481412 14.456471 6.595765 26.744471 3.312941 37.888-3.312941 11.143529-10.992941 20.088471-21.955764 27.888941-12.047059 5.571765-26.322824 8.914824-43.851294 8.914824H873.411765v361.411765h120.470588c0 66.258824-67.162353 120.470588-149.263059 120.470588H179.380706C97.28 993.882353 30.117647 939.670588 30.117647 873.411765h120.470588V512H99.358118c-17.558588 0-31.804235-3.343059-42.767059-10.029176a54.091294 54.091294 0 0 1-23.04-26.774589c-4.367059-11.143529-4.367059-22.287059-1.084235-35.659294 3.312941-13.402353 12.047059-25.660235 24.094117-39.03247 27.437176-25.660235 55.958588-52.404706 85.564235-80.293647 29.605647-27.858824 61.44-56.862118 92.129883-85.835295l92.099765-85.835294c34.032941-29.003294 63.608471-56.892235 92.129882-83.636706a116.495059 116.495059 0 0 1 47.164235-26.774588c18.642824-5.571765 37.285647-8.914824 57.012706-7.80047zM481.882353 512H240.941176v361.411765h240.941177V512z m301.176471 0h-210.82353v361.411765h210.82353V512zM511.698824 186.428235c-46.019765 0-83.335529 37.948235-83.33553 84.751059 0 46.772706 37.315765 84.720941 83.33553 84.720941 46.019765 0 83.335529-37.948235 83.335529-84.720941 0-46.802824-37.315765-84.751059-83.335529-84.751059z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconKaiting.defaultProps = {
  size: 18,
};

export default IconKaiting;
