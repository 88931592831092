/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconDiannao: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M57.6 89.6h915.2c19.2 0 38.4 19.2 38.4 38.4v659.2c0 19.2-19.2 38.4-38.4 38.4H550.4V876.8h211.2c12.8 0 25.6 12.8 25.6 25.6s-12.8 25.6-25.6 25.6H262.4c-12.8 0-25.6-12.8-25.6-25.6s12.8-25.6 25.6-25.6h211.2V825.6H57.6c-19.2 0-38.4-19.2-38.4-38.4V128c0-25.6 12.8-38.4 38.4-38.4zM864 640c-19.2 0-38.4 19.2-38.4 38.4s19.2 38.4 38.4 38.4 38.4-19.2 38.4-38.4c0-25.6-19.2-38.4-38.4-38.4z m70.4-473.6H96v582.4h838.4V166.4z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconDiannao.defaultProps = {
  size: 18,
};

export default IconDiannao;
