/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShoukuanguanliXian: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M901.485714 78.628571H254.171429c-20.114286 0-36.571429 16.457143-36.571429 36.571429v157.257143H122.514286c-20.114286 0-36.571429 16.457143-36.571429 36.571428v563.2c0 40.228571 32.914286 73.142857 73.142857 73.142858h742.4c20.114286 0 36.571429-16.457143 36.571429-36.571429V115.2c0-20.114286-16.457143-36.571429-36.571429-36.571429zM159.085714 345.6h58.514286v526.628571H159.085714V345.6z m705.828572 526.628571H290.742857V151.771429h574.171429v720.457142z"
        fill={getIconColor(color, 0, '#4C4C4C')}
      />

      <path
        d="M541.257143 735.085714h73.142857v-102.4h144.457143v-73.142857h-144.457143v-43.885714h144.457143v-73.142857h-85.942857l60.342857-60.342857-51.2-53.028572-104.228572 106.057143-106.057142-106.057143-51.2 53.028572 62.171428 60.342857h-85.942857v73.142857h144.457143v43.885714h-144.457143v73.142857h144.457143z"
        fill={getIconColor(color, 1, '#4C4C4C')}
      />
    </svg>
  );
};

IconShoukuanguanliXian.defaultProps = {
  size: 18,
};

export default IconShoukuanguanliXian;
