/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Gongshibaobiao: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M742.4 460.8C897.914435 460.8 1024 586.885565 1024 742.4 1024 897.914435 897.914435 1024 742.4 1024c-155.514435 0-281.6-126.085565-281.6-281.6 0-155.514435 126.085565-281.6 281.6-281.6z m179.2-179.2l0.044522 180.379826a332.8 332.8 0 0 0-459.709218 459.731478L166.4 921.6A166.4 166.4 0 0 1 0 755.2V281.6h921.6z m-205.623652 283.959652l-2.048 5.008696-28.471652 91.492174h-92.315826c-27.225043 0-39.513043 34.214957-21.348174 53.047652l3.784347 3.33913 74.662957 56.56487-28.516174 91.492174c-8.45913 27.158261 19.18887 49.93113 42.028522 37.398261l4.006956-2.582261 74.640696-56.520348 74.640696 56.542609c22.171826 16.784696 51.511652-3.606261 47.104-30.118957l-1.068522-4.719304-28.516174-91.492174 74.640696-56.56487c22.038261-16.695652 12.688696-51.934609-12.644174-55.963826l-4.897392-0.422956h-92.315826l-28.538434-91.492174a29.495652 29.495652 0 0 0-54.828522-5.008696zM755.2 0A166.4 166.4 0 0 1 921.6 166.4V204.8H0V166.4A166.4 166.4 0 0 1 166.4 0h588.8z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Gongshibaobiao;
