/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Icon02TpmBumenlingyong: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M769.706667 555.235556l-237.226667 199.111111H392.533333l84.195556-104.675556a62.008889 62.008889 0 0 0 0-82.488889 63.146667 63.146667 0 0 0-82.488889-10.24l-261.688889 174.648889a113.777778 113.777778 0 0 0-50.062222 93.297778V910.222222a22.755556 22.755556 0 0 0 22.755555 22.755556h485.831112a24.462222 24.462222 0 0 0 15.36-5.688889L967.111111 604.16a22.186667 22.186667 0 0 0 0-31.288889 130.844444 130.844444 0 0 0-197.404444-17.635555z m-189.44 329.386666h-455.111111V824.888889a66.56 66.56 0 0 1 30.151111-56.888889l261.12-174.648889a18.204444 18.204444 0 0 1 24.462222 26.168889l-113.777778 141.653333a22.755556 22.755556 0 0 0 17.635556 36.977778H540.444444a22.186667 22.186667 0 0 0 14.791112-1.706667L796.444444 590.506667a85.333333 85.333333 0 0 1 117.191112-4.551111z"
        fill={getIconColor(color, 0, '#151515')}
      />

      <path
        d="M821.475556 471.608889a22.755556 22.755556 0 0 0 22.755555-22.755556V139.377778a22.755556 22.755556 0 0 0-22.755555-22.755556H202.524444a22.755556 22.755556 0 0 0-22.755555 22.755556v438.613333a22.755556 22.755556 0 1 0 45.511111 0V162.133333H796.444444v284.444445a22.755556 22.755556 0 0 0 25.031112 25.031111z"
        fill={getIconColor(color, 1, '#151515')}
      />

      <path
        d="M698.026667 244.622222H482.417778a22.755556 22.755556 0 0 0 0 45.511111h215.608889a22.755556 22.755556 0 1 0 0-45.511111zM698.026667 489.244444H544.995556a22.755556 22.755556 0 1 0 0 45.511112h153.031111a22.755556 22.755556 0 0 0 0-45.511112zM698.026667 366.933333H325.973333a22.755556 22.755556 0 1 0 0 45.511111h372.053334a22.755556 22.755556 0 0 0 0-45.511111z"
        fill={getIconColor(color, 2, '#151515')}
      />
    </svg>
  );
};

Icon02TpmBumenlingyong.defaultProps = {
  size: 18,
};

export default Icon02TpmBumenlingyong;
