/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABook: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M244.44553 1023.810083a317.381126 317.381126 0 0 1-115.178634-17.404771A119.78578 119.78578 0 0 1 81.659727 972.619579 87.535762 87.535762 0 0 1 58.624 916.82193a91.119097 91.119097 0 0 1 28.154777-65.523846 175.583429 175.583429 0 0 1 78.321472-42.488118 336.321612 336.321612 0 0 1 88.559572-12.285721v56.821459a198.107251 198.107251 0 0 0-126.95245 37.880973 36.345258 36.345258 0 0 0-11.261911 25.595253c0 11.261911 3.583335 25.595252 32.761922 36.857163a272.845387 272.845387 0 0 0 94.190528 13.309531 554.39316 554.39316 0 0 0 129.511975-28.666683l24.059537 51.190504a579.476507 579.476507 0 0 1-153.571512 34.297638zM91.897828 102.381008H82.171632a29.178587 29.178587 0 0 1-16.892866-36.345258A84.976237 84.976237 0 0 1 140.528807 12.285721a227.285838 227.285838 0 0 1 106.476248 13.821436 28.666682 28.666682 0 0 1-19.452391 53.750029c-58.86908-21.500012-102.381008-10.238101-109.035774 3.071431A28.154777 28.154777 0 0 1 91.897828 102.381008z"
        fill={getIconColor(color, 0, '#8a8a8a')}
      />

      <path
        d="M86.778777 940.369561a28.666682 28.666682 0 0 1-28.154777-28.666682Q58.624 495.012175 61.183525 286.666823V78.321471A28.666682 28.666682 0 0 1 90.362113 51.190504a28.154777 28.154777 0 0 1 28.154777 28.154777c0 4.09524 0 276.940628-3.07143 833.893313a28.666682 28.666682 0 0 1-28.666683 28.154778z m255.952521-73.202421h55.79765v122.85721H341.707488z m533.405053 0H308.43366a88.047667 88.047667 0 0 1-88.047667-88.047667V87.535762A88.047667 88.047667 0 0 1 308.43366 0H877.672067a88.047667 88.047667 0 0 1 87.535762 87.535762v691.583711A88.047667 88.047667 0 0 1 877.672067 867.16714zM308.43366 56.82146a30.714302 30.714302 0 0 0-31.226207 30.714302v691.583711a31.226208 31.226208 0 0 0 31.226207 31.226208H877.672067a30.714302 30.714302 0 0 0 30.714302-31.226208V87.535762a30.714302 30.714302 0 0 0-30.714302-30.714302z"
        fill={getIconColor(color, 1, '#8a8a8a')}
      />

      <path
        d="M416.957529 119.78578h233.428699a39.416688 39.416688 0 0 1 0 78.833376H416.957529a39.416688 39.416688 0 0 1 0-78.833376z m-19.964296 159.202468h413.619273a28.666682 28.666682 0 1 1 0 56.821459H396.993233a28.666682 28.666682 0 0 1 0-56.821459z m0 137.190551h413.619273a28.666682 28.666682 0 1 1 0 56.821459H396.993233a28.666682 28.666682 0 0 1 0-56.821459z m0 137.190551h413.619273a28.666682 28.666682 0 1 1 0 56.82146H396.993233a28.666682 28.666682 0 0 1 0-56.82146z m6.14286 137.190551h414.131179a28.154777 28.154777 0 0 1 28.154777 28.154777 28.666682 28.666682 0 0 1-28.154777 28.666683H403.136093a28.666682 28.666682 0 0 1-28.154777-30.714303 28.154777 28.154777 0 0 1 28.154777-28.154777z"
        fill={getIconColor(color, 2, '#8a8a8a')}
      />
    </svg>
  );
};

IconABook.defaultProps = {
  size: 18,
};

export default IconABook;
