/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconRicheng1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M987.761071 1017.993548H94.3013a88.094633 88.094633 0 0 1-88.094633-88.094633V178.191417a88.094633 88.094633 0 0 1 88.094633-88.094633h835.297292a88.094633 88.094633 0 0 1 88.194741 88.094633v809.76987a30.032261 30.032261 0 0 1-30.032262 30.032261z m-863.42751-60.064523h775.23277a58.062372 58.062372 0 0 0 58.162479-58.062371V208.223678a58.162479 58.162479 0 0 0-58.162479-58.062372H124.333561a58.062372 58.062372 0 0 0-58.062371 58.062372v691.642976a57.962264 57.962264 0 0 0 58.062371 58.062371z m-58.062371 0"
        fill={getIconColor(color, 0, '#323333')}
      />

      <path
        d="M972.544726 375.303158h-918.987193c-16.117314 0-29.131293-13.614625-29.131294-30.332584s13.01398-30.432691 29.131294-30.432692h918.987193c16.117314 0 29.231401 13.614625 29.231401 30.432692s-13.114087 30.332584-29.231401 30.332584z m0 0M196.911526 234.251638c-16.818066 0-30.432691-10.611399-30.432691-23.625379V29.631831c0-13.01398 13.614625-23.625379 30.432691-23.625379s30.332584 10.611399 30.332584 23.625379v180.994428c0 13.01398-13.614625 23.625379-30.332584 23.625379z m0 0M834.696647 234.251638c-16.717959 0-30.332584-10.611399-30.332584-23.625379V29.631831c0-13.01398 13.614625-23.625379 30.332584-23.625379s30.332584 10.611399 30.332584 23.625379v180.994428c0 13.01398-13.514518 23.625379-30.332584 23.625379z m0 0M457.591553 802.762342a30.332584 30.332584 0 0 1-21.52312-8.90957L332.156809 689.84104a30.367621 30.367621 0 1 1 42.946134-42.946133l82.48861 82.48861 186.400235-186.400234a30.367621 30.367621 0 0 1 42.946134 42.946133L479.014566 793.852772a30.332584 30.332584 0 0 1-21.423013 8.90957z m0 0"
        fill={getIconColor(color, 1, '#323333')}
      />
    </svg>
  );
};

IconRicheng1.defaultProps = {
  size: 18,
};

export default IconRicheng1;
