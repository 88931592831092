/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZijianju: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M192 896H149.333333c-12.8 0-21.333333-8.533333-21.333333-21.333333V149.333333c0-12.8 8.533333-21.333333 21.333333-21.333333h42.666667c12.8 0 21.333333 8.533333 21.333333 21.333333v725.333334c0 12.8-8.533333 21.333333-21.333333 21.333333zM874.666667 896h-42.666667c-12.8 0-21.333333-8.533333-21.333333-21.333333V149.333333c0-12.8 8.533333-21.333333 21.333333-21.333333h42.666667c12.8 0 21.333333 8.533333 21.333333 21.333333v725.333334c0 12.8-8.533333 21.333333-21.333333 21.333333zM467.2 168.533333h93.866667l121.6 426.666667h-96l-21.333334-87.466667H448l-21.333333 87.466667h-85.333334l125.866667-426.666667z m83.2 264.533334l-42.666667-174.933334-40.533333 174.933334h83.2zM782.933333 774.4l-87.466666-87.466667c-6.4-6.4-17.066667-6.4-23.466667 0l-36.266667 36.266667c-6.4 6.4-6.4 17.066667 0 23.466667l10.666667 10.666666h-256l10.666667-10.666666c6.4-6.4 6.4-17.066667 0-23.466667l-36.266667-36.266667c-6.4-6.4-17.066667-6.4-23.466667 0l-87.466666 87.466667c-8.533333 8.533333-8.533333 21.333333 0 29.866667l87.466666 87.466666c6.4 6.4 17.066667 6.4 23.466667 0l36.266667-36.266666c6.4-6.4 6.4-17.066667 0-23.466667l-10.666667-10.666667h253.866667l-10.666667 10.666667c-6.4 6.4-6.4 17.066667 0 23.466667l36.266667 36.266666c6.4 6.4 17.066667 6.4 23.466666 0l87.466667-87.466666c10.666667-8.533333 10.666667-21.333333 2.133333-29.866667z"
        fill={getIconColor(color, 0, '#297AFF')}
      />
    </svg>
  );
};

IconZijianju.defaultProps = {
  size: 18,
};

export default IconZijianju;
