/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Bianji: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M0 512C0 229.248 229.248 0 512 0s512 229.248 512 512-229.248 512-512 512S0 794.752 0 512z"
        fill={getIconColor(color, 0, '#FFFFFF')}
      />

      <path
        d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
        fill={getIconColor(color, 1, '#FFFFFF')}
      />

      <path
        d="M663.68 233.216a38.229333 38.229333 0 0 1 54.058667 0l58.581333 58.538667c14.933333 14.933333 14.933333 39.168 0 54.058666l-57.429333 57.472-112.64-112.64 57.429333-57.429333zM578.133333 318.805333l112.597334 112.64-253.44 253.44-112.64-112.64 253.44-253.44z"
        fill={getIconColor(color, 2, '#3A7FCC')}
      />

      <path
        d="M240.213333 769.322667l168.96-56.32-112.64-112.64-56.32 168.96z"
        fill={getIconColor(color, 3, '#3A7FCC')}
      />

      <path
        d="M0 0h1024v1024H0z"
        fill={getIconColor(color, 4, '#FFFFFF')}
        fillOpacity=".01"
      />
    </svg>
  );
};

export default Bianji;
