/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Youjian01: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M853.333333 396.373333v277.76a111.36 111.36 0 0 1-104.533333 110.933334H281.6A111.36 111.36 0 0 1 170.666667 682.666667V396.373333l329.386666 170.666667a26.026667 26.026667 0 0 0 23.893334 0zM281.6 238.933333h460.8A110.933333 110.933333 0 0 1 853.333333 338.773333l-341.333333 178.346667-341.333333-178.346667A111.36 111.36 0 0 1 275.2 238.933333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Youjian01;
