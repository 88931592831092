/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXiala2: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M593.13664 838.217143l410.038857-530.285714a99.474286 99.474286 0 0 0 10.313143-105.691429A102.107429 102.107429 0 0 0 922.060069 146.285714H102.12864c-38.765714 0-74.24 21.723429-91.428571 56.027429a99.474286 99.474286 0 0 0 10.24 105.691428l410.112 530.139429A102.4 102.4 0 0 0 512.094354 877.714286a102.4 102.4 0 0 0 81.042286-39.497143z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconXiala2.defaultProps = {
  size: 18,
};

export default IconXiala2;
