/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWodefull: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M256 256c0 141.3888 114.6112 256 256 256s256-114.6112 256-256S653.3888 0 512 0 256 114.6112 256 256z"
        fill={getIconColor(color, 0, '#5D73FA')}
      />

      <path
        d="M512 512c-141.1584 0-256-114.8416-256-256S370.8416 0 512 0s256 114.8416 256 256-114.8416 256-256 256z m0-416A160.1792 160.1792 0 0 0 352 256 160.1792 160.1792 0 0 0 512 416 160.1792 160.1792 0 0 0 672 256 160.1792 160.1792 0 0 0 512 96zM214.4 595.2h595.2a214.4 214.4 0 0 1 0 428.8H214.4a214.4 214.4 0 0 1 0-428.8z"
        fill={getIconColor(color, 1, '#5D73FA')}
      />

      <path
        d="M809.6 1024H214.4C96.1792 1024 0 927.8208 0 809.6s96.1792-214.4 214.4-214.4h595.2c118.2208 0 214.4 96.1792 214.4 214.4S927.8208 1024 809.6 1024zM214.4 691.2a118.528 118.528 0 0 0-118.4 118.4 118.528 118.528 0 0 0 118.4 118.4h595.2a118.528 118.528 0 0 0 118.4-118.4A118.528 118.528 0 0 0 809.6 691.2H214.4z"
        fill={getIconColor(color, 2, '#5D73FA')}
      />

      <path
        d="M839.7568 856.3968h-255.744a48 48 0 0 1 0-96h255.744a48 48 0 0 1 0 96z"
        fill={getIconColor(color, 3, '#DEE3FE')}
      />
    </svg>
  );
};

IconWodefull.defaultProps = {
  size: 18,
};

export default IconWodefull;
