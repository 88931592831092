/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconADataviewsManageDefault: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M638.42304 368.78336H385.76128v130.41664h252.66176v-130.41664z m0-169.53344H385.76128v130.39616h252.66176V199.24992z m292.53632 339.0464H678.2976v130.41664h252.66176v-130.41664z m-65.55648 327.43424c-8.72448-1.31072-17.63328-1.2288-26.50112-1.2288-217.4976-0.08192-435.01568-0.08192-652.55424 0-9.8304 0-22.24128-2.60096-28.81536 2.33472-12.1856 9.1136-28.52864 22.58944-28.99968 34.83648-0.45056 12.288 14.7456 27.136 26.2144 37.08928 5.98016 5.16096 18.90304 2.7648 28.61056 2.7648 110.26432 0.14336 220.4672 0.08192 330.752 0.08192 109.17888 0 218.4192 0.06144 327.72096 0 7.84384 0 15.99488 0.94208 23.53152-0.69632 20.64384-4.48512 33.4848-17.12128 32.62464-39.3216-0.8192-20.3776-12.30848-32.91136-32.58368-35.86048z m65.55648-666.48064H678.2976v130.39616h252.66176V199.24992z m0 169.53344H678.2976v130.41664h252.66176v-130.41664zM971.776 81.94048c-153.55904-0.04096-307.05664 0-460.5952 0-151.53152 0-303.08352-0.04096-454.61504 0-46.83776 0-56.40192 9.6256-56.44288 57.69216-0.1024 198.8608-0.16384 397.74208 0 596.62336 0.04096 44.40064 12.16512 56.81152 55.9104 56.81152 305.07008 0.12288 610.0992 0.12288 915.18976 0 40.69376-0.06144 52.67456-12.36992 52.67456-53.16608 0.14336-201.8304 0.14336-403.68128 0.08192-605.57312-0.08192-42.55744-9.95328-52.36736-52.20352-52.38784z m-0.90112 651.96032h-917.504V134.0416h917.504v599.8592z m-624.96768-195.60448H93.24544v130.41664H345.9072v-130.41664h-0.02048z m292.53632 0H385.76128v130.41664h252.66176v-130.41664z m-292.53632-169.51296H93.24544v130.41664H345.9072v-130.41664h-0.02048z m0-169.53344H93.24544v130.39616H345.9072V199.24992h-0.02048z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconADataviewsManageDefault.defaultProps = {
  size: 18,
};

export default IconADataviewsManageDefault;
