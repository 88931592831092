/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconLianshenqing: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M811.052522 0c68.474435 0 123.993043 53.737739 123.993043 119.986087V525.133913A284.093217 284.093217 0 0 0 849.408 512c-152.397913 0-275.945739 119.607652-275.945739 267.130435 0 109.523478 68.073739 203.642435 165.509565 244.869565H168.492522C100.018087 1024 44.521739 970.284522 44.521739 904.013913V119.986087C44.521739 53.76 100.018087 0 168.492522 0h642.56z m26.267826 630.205217c22.839652 0.044522 41.360696 18.031304 41.293913 40.158609v56.030609h59.547826c22.817391 0 41.316174 17.92 41.316174 40.002782 0 22.082783-18.498783 40.002783-41.316174 40.002783l-59.547826-0.022261v58.835478c0.066783 21.036522-16.62887 38.333217-37.910261 40.047305l-3.405913 0.133565c-22.861913-0.044522-41.382957-18.031304-41.316174-40.158609l-0.022261-58.857739-59.102609 0.022261c-22.817391 0-41.316174-17.92-41.316173-40.002783 0-22.082783 18.476522-40.002783 41.316173-40.002782h59.102609v-56.030609c-0.044522-22.127304 18.476522-40.114087 41.338435-40.158609zM346.156522 390.010435h-119.830261c-22.817391 0-41.338435 17.897739-41.338435 39.980522 0 20.992 16.695652 38.199652 37.932522 39.869217l3.405913 0.133565h119.830261c22.817391 0 41.316174-17.897739 41.316174-40.002782 0-22.082783-18.476522-39.980522-41.316174-39.980522z m128.089043-172.009739H226.348522c-22.817391 0-41.338435 17.92-41.338435 40.002782 0 20.992 16.695652 38.199652 37.932522 39.846957l3.405913 0.155826h247.919304c22.839652 0 41.338435-17.92 41.338435-40.002783 0-22.082783-18.498783-40.002783-41.338435-40.002782z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconLianshenqing.defaultProps = {
  size: 18,
};

export default IconLianshenqing;
