/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWodejiean: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M811.78713 0C916.48 0 1001.73913 88.375652 1001.73913 197.053217v629.893566C1001.73913 935.602087 916.569043 1024 811.853913 1024H212.146087C107.430957 1024 22.26087 935.624348 22.26087 826.946783V197.053217C22.26087 88.397913 107.430957 0 212.146087 0z m-13.044869 768H225.257739c-21.926957 0-39.757913 17.764174-39.757913 39.624348 0 21.882435 17.830957 39.646609 39.757913 39.646609h573.484522c21.993739 0 39.757913-17.764174 39.757913-39.624348 0-21.882435-17.830957-39.646609-39.757913-39.646609zM512 192a35.84 35.84 0 0 0-32.233739 20.435478l-51.133218 105.961739-114.420869 17.007305a36.173913 36.173913 0 0 0-29.005913 24.976695 37.153391 37.153391 0 0 0 9.104696 37.643131l82.587826 82.521043-19.522783 116.46887a37.175652 37.175652 0 0 0 14.291478 35.929043 35.350261 35.350261 0 0 0 37.843479 2.827131l102.355478-54.984348 102.333217 54.984348a35.172174 35.172174 0 0 0 37.843478-2.827131c11.085913-8.192 16.65113-22.149565 14.31374-35.929043l-19.522783-116.46887 82.810435-82.521043a37.175652 37.175652 0 0 0 9.104695-37.62087 36.08487 36.08487 0 0 0-28.983652-24.998956l-114.420869-17.007305-51.155479-105.961739A35.929043 35.929043 0 0 0 512 192z m0.044522 119.718957l27.291826 56.542608c5.253565 10.863304 15.36 18.365217 27.069217 20.101565l61.083826 9.060174-44.187826 44.009739a37.175652 37.175652 0 0 0-10.373565 32.50087l10.440348 62.174609-54.628174-29.317565a35.172174 35.172174 0 0 0-33.391304 0l-54.628174 29.317565 10.418087-62.174609a37.353739 37.353739 0 0 0-10.351305-32.50087l-44.187826-44.009739 61.061565-9.060174a35.84 35.84 0 0 0 27.069218-20.079304l27.314087-56.542609z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconWodejiean.defaultProps = {
  size: 18,
};

export default IconWodejiean;
