/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const GongshiliebiaoXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M938.666667 784.597333A154.069333 154.069333 0 0 1 784.597333 938.666667H239.36A154.069333 154.069333 0 0 1 85.333333 784.597333V239.36A154.069333 154.069333 0 0 1 239.402667 85.333333H784.64A154.069333 154.069333 0 0 1 938.666667 239.402667V784.64z m-71.125334 0V239.36c0-45.824-37.12-82.944-82.944-82.944H239.36c-45.824 0-82.944 37.12-82.944 82.944V784.64c0 45.781333 37.12 82.944 82.944 82.944H784.64c45.824 0 82.944-37.12 82.944-82.944zM749.056 310.528a35.541333 35.541333 0 0 1-30.72 35.2l-4.864 0.341333H310.528a35.541333 35.541333 0 0 1-4.864-70.784l4.864-0.341333h402.944c19.626667 0 35.584 15.957333 35.584 35.584z m0 402.944a35.541333 35.541333 0 0 1-30.72 35.242667l-4.864 0.341333H310.528a35.541333 35.541333 0 0 1-4.864-70.826667l4.864-0.298666h402.944c19.626667 0 35.584 15.914667 35.584 35.541333z m0-201.472a35.541333 35.541333 0 0 1-30.72 35.242667l-4.864 0.298666H310.528a35.541333 35.541333 0 0 1-4.864-70.784l4.864-0.298666h402.944c19.626667 0 35.584 15.914667 35.584 35.541333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default GongshiliebiaoXianxing;
