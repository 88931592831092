/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconHuiyiguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M857.7 89.9H170.2C122.1 89.9 83 129 83 177.1v124.3h70V177.1c0-9.5 7.7-17.2 17.2-17.2h687.5c9.5 0 17.2 7.7 17.2 17.2v666.6c0 9.5-7.7 17.2-17.2 17.2H698.5v70h159.2c48.1 0 87.2-39.1 87.2-87.2V177.1c0.1-48.1-39.1-87.2-87.2-87.2z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M481.2 588.1c29.1-30.5 47-71.7 47-117.1 0-93.6-76.1-169.7-169.7-169.7S188.8 377.4 188.8 471c0 45.4 17.9 86.6 47 117.1-92.4 19.6-162 101.9-162 200.1v85.6c0 44.9 36.5 81.5 81.5 81.5h406.5c44.9 0 81.5-36.5 81.5-81.5v-85.6c-0.1-98.3-69.7-180.5-162.1-200.1zM358.5 371.2c55 0 99.7 44.7 99.7 99.7s-44.7 99.7-99.7 99.7-99.7-44.7-99.7-99.7 44.7-99.7 99.7-99.7z m214.7 502.5c0 6.3-5.1 11.5-11.5 11.5H155.3c-6.3 0-11.5-5.1-11.5-11.5v-85.6c0-74.2 60.4-134.5 134.5-134.5h160.3c74.2 0 134.5 60.4 134.5 134.5v85.6zM778.3 239.5c-18.2-6.6-38.2 2.8-44.9 20.9L628.9 547.6c-6.6 18.2 2.8 38.2 20.9 44.9 18.2 6.6 38.2-2.8 44.9-20.9l104.6-287.3c6.5-18.2-2.8-38.2-21-44.8z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconHuiyiguanli.defaultProps = {
  size: 18,
};

export default IconHuiyiguanli;
