/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconChuangjianricheng: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M1024 451.552V892.48C1024 949.344 980.416 992 922.336 992H101.664C43.584 992 0 949.344 0 892.448V451.52h1024z m-232.384 334.24H232.384c-21.76 0-36.288 21.312-36.288 35.52 0 21.344 14.528 35.584 36.288 35.584h559.232c14.496 0 29.024-14.24 36.288-35.552 0-21.344-14.528-35.552-36.288-35.552z m0-206.24H232.384c-21.76 0-36.288 14.24-36.288 35.552 0 21.344 14.528 35.552 36.288 35.552h551.968c21.76 0 36.288-14.208 36.288-35.52 0-14.24-14.528-28.48-29.024-35.584zM196.096 117.344v128c0 35.52 36.288 71.104 72.64 71.104 36.288 0 72.608-28.448 72.608-71.104v-128h341.312v128a72 72 0 0 0 72.64 71.104c36.32 0 72.64-28.448 72.64-71.104v-128h94.4C980.416 117.344 1024 160 1024 216.864v177.792H0V216.896C0 160 43.584 117.344 101.664 117.344h94.4zM268.736 32c29.024 0 58.08 21.344 58.08 56.896v156.448c-7.264 28.448-29.056 56.864-65.376 56.864-29.056 0-50.816-28.416-50.816-56.864v-156.48C210.624 60.48 232.384 32 268.704 32z m486.56 0c29.056 0 58.08 21.344 58.08 56.896v156.448c0 28.448-29.024 56.864-58.08 56.864-29.056 0-58.112-21.312-58.112-56.864v-156.48c0-28.416 21.792-56.864 58.112-56.864z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconChuangjianricheng.defaultProps = {
  size: 18,
};

export default IconChuangjianricheng;
