/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconGengduo: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M128 384a128 128 0 1 1 0 256 128 128 0 0 1 0-256z m384 0a128 128 0 1 1 0 256 128 128 0 0 1 0-256z m384 0a128 128 0 1 1 0 256 128 128 0 0 1 0-256z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconGengduo.defaultProps = {
  size: 18,
};

export default IconGengduo;
