/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconChenggong: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512.723 21.149c-271.456 0-491.52 220.064-491.52 491.52s220.064 491.52 491.52 491.52 491.52-220.064 491.52-491.52-220.064-491.52-491.52-491.52zM798.679 358.605c0 0-359.601 337.36-359.711 337.456-8.069 7.865-21.094 7.7-28.959-0.369l-183.637-181.22c-7.865-8.069-7.7-21.094 0.369-28.959s21.094-7.7 28.959 0.369l169.097 166.53 345.279-323.134c8.069-7.865 21.094-7.7 28.959 0.369 7.878 8.069 7.714 21.094-0.355 28.959z"
        fill={getIconColor(color, 0, '#272636')}
      />
    </svg>
  );
};

IconChenggong.defaultProps = {
  size: 18,
};

export default IconChenggong;
