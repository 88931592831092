/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconSee: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1434 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M713.30034183 62C263.30034182 62 83.33333333 512 83.33333333 512s179.9670085 450 629.9670085 450c450 0 629.96700849-450 629.9670085-450S1163.13538604 62 713.30034183 62L713.30034183 62zM713.30034183 781.86803487c-149.12023447 0-270.03299151-120.91275703-270.0329915-270.0329915 0-149.12023447 120.91275703-270.03299151 270.03299147-270.03299152 149.12023447 0 270.03299151 120.91275703 270.03299153 270.0329915C983.16837758 661.12023447 862.25562054 781.86803487 713.30034183 781.86803487L713.30034183 781.86803487zM713.30034183 781.86803487M848.23435969 512c0 74.56011766-60.53885596 134.93401787-134.93401789 134.93401787S578.2013682 586.39516103 578.2013682 512c0-74.56011766 60.53885596-134.93401787 134.93401784-134.93401787C787.86045948 376.90102636 848.23435969 437.43988234 848.23435969 512L848.23435969 512zM848.23435969 512"
        fill={getIconColor(color, 0, '#707070')}
      />
    </svg>
  );
};

IconSee.defaultProps = {
  size: 18,
};

export default IconSee;
