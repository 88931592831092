/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconHaocailingyongXian: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M930.742857 650.971429c-7.314286-27.428571-25.6-47.542857-51.2-58.514286-42.057143-18.285714-190.171429 16.457143-312.685714 49.371428 9.142857-21.942857 12.8-45.714286 7.314286-67.657142-5.485714-23.771429-23.771429-40.228571-47.542858-49.371429-80.457143-29.257143-221.257143 104.228571-287.085714 171.885714l-120.685714 23.771429c-18.285714 3.657143-29.257143 18.285714-29.257143 36.571428l1.828571 133.485715c0 20.114286 16.457143 36.571429 36.571429 36.571428h128c27.428571 3.657143 153.6 20.114286 268.8 20.114286 69.485714 0 133.485714-5.485714 170.057143-25.6 98.742857-51.2 197.485714-140.8 217.6-170.057143 14.628571-20.114286 29.257143-62.171429 18.285714-100.571428z m-78.628571 58.514285c-14.628571 20.114286-106.057143 102.4-192 148.114286-58.514286 31.085714-274.285714 14.628571-396.8-3.657143H162.742857v-65.828571l102.4-20.114286c7.314286-1.828571 14.628571-5.485714 20.114286-10.971429 85.942857-91.428571 190.171429-170.057143 215.771428-162.742857h1.828572c1.828571 12.8-18.285714 54.857143-42.057143 84.114286-10.971429 12.8-10.971429 29.257143-1.828571 43.885714 9.142857 12.8 25.6 20.114286 40.228571 14.628572 144.457143-42.057143 320-84.114286 352.914286-76.8 5.485714 1.828571 7.314286 5.485714 9.142857 10.971428 3.657143 12.8-3.657143 31.085714-9.142857 38.4zM409.6 532.114286v-60.342857l-170.057143-65.828572v60.342857z"
        fill={getIconColor(color, 0, '#4C4C4C')}
      />

      <path
        d="M173.714286 309.028571l248.685714 95.085715 53.028571 20.114285v54.857143h73.142858v-54.857143l301.714285-115.2v234.057143h73.142857V256c0-3.657143 0-7.314286-1.828571-9.142857 0-1.828571 0-1.828571-1.828571-3.657143 0-1.828571-1.828571-3.657143-3.657143-5.485714 0-1.828571-1.828571-1.828571-1.828572-3.657143-1.828571-1.828571-3.657143-3.657143-3.657143-5.485714l-1.828571-1.828572-1.828571-1.828571c-1.828571-1.828571-5.485714-3.657143-7.314286-3.657143L524.8 78.628571c-9.142857-3.657143-18.285714-3.657143-25.6 0L124.342857 223.085714c-1.828571 1.828571-5.485714 1.828571-7.314286 3.657143l-1.828571 1.828572c-1.828571 0-1.828571 1.828571-1.828571 1.828571-1.828571 1.828571-3.657143 3.657143-3.657143 5.485714 0 1.828571-1.828571 1.828571-1.828572 3.657143-1.828571 1.828571-1.828571 3.657143-3.657143 5.485714 0 1.828571 0 1.828571-1.828571 3.657143-1.828571 3.657143-1.828571 7.314286-1.828571 9.142857v411.428572h73.142857V309.028571zM512 151.771429L784.457143 256 512 360.228571 239.542857 256 512 151.771429z"
        fill={getIconColor(color, 1, '#4C4C4C')}
      />
    </svg>
  );
};

IconHaocailingyongXian.defaultProps = {
  size: 18,
};

export default IconHaocailingyongXian;
