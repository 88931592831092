/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconGouxuankuangYigouxuan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M959.9 971.9H64.1c-6.6 0-12-5.4-12-12V64.1c0-6.6 5.4-12 12-12H960c6.6 0 12 5.4 12 12V960c-0.1 6.6-5.4 11.9-12.1 11.9z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M867.8 1024H156.2C70.1 1024 0 953.9 0 867.8V156.2C0 70.1 70.1 0 156.2 0h711.6C953.9 0 1024 70.1 1024 156.2v711.6c0 86.1-70.1 156.2-156.2 156.2zM156.2 104.1c-28.7 0-52.1 23.4-52.1 52.1v711.6c0 28.7 23.4 52.1 52.1 52.1h711.6c28.7 0 52.1-23.4 52.1-52.1V156.2c0-28.7-23.4-52.1-52.1-52.1H156.2z"
        fill={getIconColor(color, 1, '#333333')}
      />

      <path
        d="M420.2 805.6c-10.7 0-21.5-3.3-30.7-10L138.3 612c-23.2-17-28.3-49.5-11.3-72.8 17-23.2 49.6-28.3 72.8-11.3L413 683.7l403.3-448.1c19.2-21.4 52.2-23.1 73.5-3.9s23.1 52.2 3.9 73.5L459 788.2c-10.3 11.5-24.5 17.4-38.8 17.4z"
        fill={getIconColor(color, 2, '#FFFFFF')}
      />
    </svg>
  );
};

IconGouxuankuangYigouxuan.defaultProps = {
  size: 18,
};

export default IconGouxuankuangYigouxuan;
