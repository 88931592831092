/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACustomersStorage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M197.98016 40.96a52.34688 52.34688 0 0 1 0 104.67328c-26.17344 0-52.34688 23.552-52.34688 52.4288v418.5088c0 28.5696 23.61344 52.4288 52.34688 52.4288h104.6528c52.34688 0 52.34688 104.69376 209.36704 104.69376 157.02016 0 160.93184-104.67328 209.34656-104.67328h105.0624c28.672 0 52.4288-26.17344 51.95776-52.44928V198.08256c0-28.59008-26.17344-52.4288-52.34688-52.4288a52.34688 52.34688 0 0 1 0-104.69376h52.81792A104.16128 104.16128 0 0 1 983.04 145.12128v733.71648A104.16128 104.16128 0 0 1 878.87872 983.04H145.16224A104.16128 104.16128 0 0 1 40.96 878.87872V145.16224A104.16128 104.16128 0 0 1 145.16224 40.96z m480.4608 494.94016c9.87136 0 17.87904 8.3968 17.87904 18.75968 0 10.38336-8.00768 18.78016-17.89952 18.78016H345.57952c-9.89184 0-17.89952-8.3968-17.89952-18.78016 0-10.36288 8.00768-18.75968 17.89952-18.75968zM514.17088 122.88c59.35104 0 107.64288 43.06944 107.64288 96.01024 0 34.0992-20.35712 65.45408-52.77696 82.61632l10.46528 75.53024h83.61984c18.28864 0 33.1776 13.312 33.1776 29.65504v61.99296c0 16.36352-14.88896 29.65504-33.1776 29.65504H360.8576c-18.28864 0-33.1776-13.312-33.1776-29.65504v-61.99296c0-16.34304 14.88896-29.65504 33.1776-29.65504h87.77728l10.48576-75.63264c-32.29696-17.18272-52.57216-48.5376-52.57216-82.51392C406.528 165.94944 454.84032 122.88 514.19136 122.88z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconACustomersStorage.defaultProps = {
  size: 18,
};

export default IconACustomersStorage;
