/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Hangfengexian: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M89.796923 0A38.4 38.4 0 0 0 51.396923 38.4v51.2A166.4 166.4 0 0 0 217.796923 256h588.8A166.4 166.4 0 0 0 972.996923 89.6v-51.2a38.4 38.4 0 0 0-76.8 0v51.2a89.6 89.6 0 0 1-89.6 89.6H691.396923V38.4a38.4 38.4 0 0 0-76.8 0V179.2h-204.8V38.4a38.4 38.4 0 0 0-76.8 0V179.2H217.796923A89.6 89.6 0 0 1 128.196923 89.6v-51.2A38.4 38.4 0 0 0 89.796923 0z m0 1024a38.4 38.4 0 0 1-38.4-38.4v-51.2A166.4 166.4 0 0 1 217.796923 768h588.8a166.4 166.4 0 0 1 166.4 166.4v51.2a38.4 38.4 0 0 1-76.8 0v-51.2a89.6 89.6 0 0 0-89.6-89.6H691.396923v140.8a38.4 38.4 0 0 1-76.8 0V844.8h-204.8v140.8a38.4 38.4 0 0 1-76.8 0V844.8H217.796923a89.6 89.6 0 0 0-89.6 89.6v51.2a38.4 38.4 0 0 1-38.4 38.4z"
        fill={getIconColor(color, 0, '#3F51B5')}
        opacity=".504"
      />

      <path
        d="M275.889231 472.615385m39.384615 0l433.230769 0q39.384615 0 39.384616 39.384615l0 0q0 39.384615-39.384616 39.384615l-433.230769 0q-39.384615 0-39.384615-39.384615l0 0q0-39.384615 39.384615-39.384615Z"
        fill={getIconColor(color, 1, '#3F51B5')}
      />

      <path
        d="M344.615385 550.4a87.433846 87.433846 0 0 1 3.702153-3.899077L382.818462 512l-34.500924-34.500923a94.444308 94.444308 0 0 1-3.702153-3.899077H38.596923a38.4 38.4 0 0 0 0 76.8h306.018462z m347.963077 0h293.218461a38.4 38.4 0 0 0 0-76.8H692.578462c-1.181538 1.339077-2.441846 2.638769-3.702154 3.899077L654.375385 512l34.500923 34.500923 3.702154 3.899077z"
        fill={getIconColor(color, 2, '#3F51B5')}
      />
    </svg>
  );
};

export default Hangfengexian;
