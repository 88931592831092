/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Album24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M85.333333 256a85.333333 85.333333 0 0 1 85.333334-85.333333h42.666666v682.666666H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333333V256z m597.333334 106.666667h-170.666667a21.333333 21.333333 0 0 0-21.333333 21.333333v42.666667a21.333333 21.333333 0 0 0 21.333333 21.333333h170.666667a21.333333 21.333333 0 0 0 21.333333-21.333333V384a21.333333 21.333333 0 0 0-21.333333-21.333333z"
        fill={getIconColor(color, 0, '#212121')}
      />

      <path
        d="M277.333333 853.333333H853.333333a85.333333 85.333333 0 0 0 85.333334-85.333333V256a85.333333 85.333333 0 0 0-85.333334-85.333333H277.333333v682.666666zM512 298.666667h170.666667a85.333333 85.333333 0 0 1 85.333333 85.333333v42.666667a85.333333 85.333333 0 0 1-85.333333 85.333333h-170.666667a85.333333 85.333333 0 0 1-85.333333-85.333333V384a85.333333 85.333333 0 0 1 85.333333-85.333333z"
        fill={getIconColor(color, 1, '#212121')}
      />
    </svg>
  );
};

export default Album24Filled;
