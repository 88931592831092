/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconHangzhengyongzhang: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M717.3888 1024H101.888C45.568 1024 0 977.5104 0 920.2176V103.7824C0 46.4384 45.6448 0 101.888 0h615.424C773.632 0 819.2 46.4896 819.2 103.7824v816.4352c0.0512 57.344-45.568 103.7824-101.8112 103.7824z"
        fill={getIconColor(color, 0, '#A5ADF6')}
      />

      <path
        d="M565.6064 555.1104c0 81.3312 64.7424 147.2768 144.5888 147.2768 79.872 0 144.6144-65.9456 144.6144-147.2768 0-81.3568-64.768-147.2768-144.6144-147.2768-79.872 0-144.5888 65.92-144.5888 147.2768z"
        fill={getIconColor(color, 1, '#A5ADF6')}
      />

      <path
        d="M816.256 655.1552v-200.1152a143.1296 143.1296 0 0 0-106.0608-47.2064c-79.872 0-144.5888 65.92-144.5888 147.2768s64.7168 147.2768 144.5888 147.2768a142.9248 142.9248 0 0 0 106.0608-47.232z"
        fill={getIconColor(color, 2, '#635DF7')}
      />

      <path
        d="M895.2576 1019.264H523.1616c-70.4 0-127.488-58.1632-127.488-129.8432 0-71.68 57.088-129.8432 127.488-129.8432H895.232c70.4 0 127.488 58.1632 127.488 129.8432 0 71.7568-57.0368 129.8432-127.488 129.8432z"
        fill={getIconColor(color, 3, '#A5ADF6')}
      />

      <path
        d="M523.1616 759.5776c-70.4 0-127.488 58.1632-127.488 129.8432 0 71.68 57.088 129.8432 127.488 129.8432h203.1104c50.6368-5.8112 89.984-49.5104 89.984-102.6048v-157.0816h-293.12z"
        fill={getIconColor(color, 4, '#635DF7')}
      />

      <path
        d="M666.0352 224.768H153.216C125.1328 224.768 102.4 203.1616 102.4 176.384 102.4 149.6576 125.1328 128 153.216 128h512.768C694.0672 128 716.8 149.6576 716.8 176.384c0 26.752-22.6816 48.384-50.7648 48.384z m-147.3024 177.664H153.216C125.1328 402.432 102.4 380.7744 102.4 354.048s22.7328-48.384 50.816-48.384h365.4656c28.0576 0 50.816 21.6576 50.816 48.384s-22.6816 48.384-50.7648 48.384zM452.7872 588.8H153.216C125.1328 588.8 102.4 567.1424 102.4 540.416c0-26.752 22.7328-48.384 50.816-48.384h299.52c28.0832 0 50.816 21.632 50.816 48.384 0 26.7264-22.6816 48.384-50.7648 48.384z"
        fill={getIconColor(color, 5, '#FFFFFF')}
      />
    </svg>
  );
};

IconHangzhengyongzhang.defaultProps = {
  size: 18,
};

export default IconHangzhengyongzhang;
