/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACustomersCustomerStorageMyStorage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M197.98016 40.96a52.34688 52.34688 0 0 1 0 104.67328c-26.17344 0-52.34688 23.552-52.34688 52.4288v418.5088c0 28.5696 23.61344 52.4288 52.34688 52.4288h104.6528c52.34688 0 52.34688 104.69376 209.36704 104.69376 157.02016 0 160.93184-104.67328 209.34656-104.67328h105.0624c28.672 0 52.4288-26.17344 51.95776-52.44928V198.08256c0-28.59008-26.17344-52.4288-52.34688-52.4288a52.34688 52.34688 0 0 1 0-104.69376h52.81792A104.16128 104.16128 0 0 1 983.04 145.12128v733.71648A104.16128 104.16128 0 0 1 878.87872 983.04H145.16224A104.16128 104.16128 0 0 1 40.96 878.87872V145.16224A104.16128 104.16128 0 0 1 145.16224 40.96zM512 122.88c59.02336 0 106.86464 49.11104 106.86464 109.71136 0 50.31936-33.05472 92.7744-78.11072 105.63584 99.45088 14.336 175.96416 102.03136 176.04608 208.19968 0 9.15456-0.59392 18.2272-1.67936 27.01312H308.87936A220.85632 220.85632 0 0 1 307.2 546.4064c0-106.06592 76.5952-193.8432 176.04608-208.1792-45.056-12.86144-78.11072-55.31648-78.11072-105.63584C405.13536 171.99104 452.97664 122.88 512 122.88z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconACustomersCustomerStorageMyStorage.defaultProps = {
  size: 18,
};

export default IconACustomersCustomerStorageMyStorage;
