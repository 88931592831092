/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Hanggao: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M715.968 137.408a32 32 0 0 0-45.248 0L564.032 244.032a32 32 0 0 0 45.248 45.248l84.032-84.032 84.096 84.032a32 32 0 1 0 45.184-45.248l-106.624-106.624zM170.688 266.24a96 96 0 0 1 96-96h170.624a32 32 0 0 1 0 64H266.688a32 32 0 0 0-32 32v490.688a32 32 0 0 0 32 32h170.624a32 32 0 1 1 0 64H266.688a96 96 0 0 1-96-96V266.304z m545.28 620.288l106.624-106.624a32 32 0 1 0-45.184-45.248l-84.096 84.032L609.28 734.72a32 32 0 1 0-45.248 45.248l106.688 106.624a32 32 0 0 0 45.248 0z m-22.656-417.28a32 32 0 0 0-32 32v21.376a32 32 0 1 0 64 0v-21.376a32 32 0 0 0-32-32z m-32 181.376a32 32 0 1 1 64 0v42.624a32 32 0 1 1-64 0v-42.624z m32-352a32 32 0 0 0-32 32v42.624a32 32 0 1 0 64 0v-42.624a32 32 0 0 0-32-32z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default Hanggao;
