/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const ShoukuanguanliXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M764.757333 85.333333c29.141333 0 57.130667 11.221333 77.738667 31.232 20.608 20.053333 32.170667 47.146667 32.170667 75.434667v608c0 17.664-14.762667 32-32.981334 32H215.253333c0 23.552 19.712 42.666667 43.946667 42.666667h582.485333c18.218667 0 32.981333 14.336 32.981334 32s-14.762667 32-32.981334 32H259.242667C198.528 938.666667 149.333333 890.88 149.333333 832v-640C149.333333 133.12 198.528 85.333333 259.242667 85.333333z m0 64H259.242667c-24.32 0-43.946667 19.114667-43.946667 42.666667V768h593.450667V192c0-23.552-19.712-42.666667-43.946667-42.666667z m-88.149333 400.64c0.170667 1.578667 0.256 3.114667 0.256 4.693334 0 47.146667-68.906667 85.333333-153.856 85.333333-84.992 0-153.856-38.186667-153.856-85.333333 0-1.578667 0.085333-3.114667 0.213333-4.693334 36.266667 28.885333 91.648 47.36 153.6 47.36 61.994667 0 117.376-18.474667 153.642667-47.36z m0-85.333333c0.170667 1.578667 0.256 3.114667 0.256 4.693333 0 47.146667-68.906667 85.333333-153.856 85.333334-84.992 0-153.856-38.186667-153.856-85.333334 0-1.578667 0.085333-3.114667 0.213333-4.693333 36.266667 28.885333 91.648 47.36 153.6 47.36 61.994667 0 117.376-18.474667 153.642667-47.36zM523.008 298.666667c38.058667 0 73.898667 7.125333 101.205333 19.797333 25.301333 11.733333 52.650667 33.109333 52.650667 65.536s-27.306667 53.76-52.650667 65.536c-27.306667 12.672-63.146667 19.797333-101.205333 19.797333-38.101333 0-73.898667-7.125333-101.248-19.797333C396.458667 437.802667 369.152 416.426667 369.152 384s27.306667-53.76 52.608-65.536c27.392-12.672 63.146667-19.797333 101.248-19.797333z m0 53.333333c-31.744 0-59.093333 6.016-77.525333 14.592C425.045333 376.064 424.106667 384 424.106667 384s0.981333 7.936 21.418666 17.408c18.432 8.533333 45.781333 14.592 77.525334 14.592 31.701333 0 59.050667-6.016 77.482666-14.592 18.773333-8.704 21.12-16.085333 21.376-17.237333 0-0.170667-0.938667-8.106667-21.376-17.578667-18.432-8.533333-45.781333-14.592-77.482666-14.592z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default ShoukuanguanliXianxing;
