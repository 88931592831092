/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksLogCreate: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M780.96384 43.8272l-251.4944 241.72544a118.4768 118.4768 0 0 0-0.6144 171.33568 126.79168 126.79168 0 0 0 88.82176 35.84 126.89408 126.89408 0 0 0 88.12544-35.16416L935.40352 236.7488v680.26368c0 76.5952-11.81696 86.50752-90.95168 86.50752H131.93216C52.79744 1003.52 40.96 941.40416 40.96 864.82944V177.80736c0.512-74.38336 13.47584-133.98016 90.97216-133.98016h649.03168z m-114.93376 624.98816H213.56544a46.63296 46.63296 0 0 0-33.1776 13.1072c-8.86784 8.6016-13.94688 20.3776-14.1312 32.70656 0.18432 12.34944 5.26336 24.10496 14.1312 32.70656 8.88832 8.6016 20.82816 13.312 33.1776 13.1072h452.46464a46.44864 46.44864 0 0 0 47.3088-45.81376 46.44864 46.44864 0 0 0-14.1312-32.68608 46.63296 46.63296 0 0 0-33.1776-13.1072z m-276.2752-271.01184h-158.9248c-26.112 0-47.34976 20.48-47.24736 45.81376a46.53056 46.53056 0 0 0 47.26784 45.7728h158.88384a46.53056 46.53056 0 0 0 47.26784-45.7728 46.44864 46.44864 0 0 0-14.1312-32.68608 46.63296 46.63296 0 0 0-33.13664-13.1072zM935.81312 20.48c12.55424 0 24.59648 4.9152 33.4848 13.57824 8.8064 8.62208 13.78304 20.2752 13.7216 32.44032a45.09696 45.09696 0 0 1-14.0288 32.39936l-318.0544 305.27488c-8.94976 8.58112-20.8896 13.33248-33.28 13.27104l0.04096 0.06144a48.14848 48.14848 0 0 1-33.71008-13.57824 45.056 45.056 0 0 1-13.7216-32.50176c0-12.12416 5.09952-23.7568 13.98784-32.33792L902.3488 33.792A48.128 48.128 0 0 1 935.8336 20.48z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksLogCreate.defaultProps = {
  size: 18,
};

export default IconAWorksLogCreate;
