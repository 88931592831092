/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTongzhiguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M605.9008 43.4688c8.96 9.9328 13.9264 22.8352 13.9264 36.1984v864.64a54.0416 54.0416 0 0 1-90.2656 40.1408l-260.864-235.136H133.6832A108.0832 108.0832 0 0 1 25.6 641.2288V382.72a108.0832 108.0832 0 0 1 108.0832-108.0832h135.0144l260.8896-235.1616c22.1696-19.968 56.32-18.176 76.3136 3.9936z m146.432 146.304A337.664 337.664 0 0 1 998.4 514.7648c0 90.7008-35.9936 175.7952-98.8928 238.6688a337.408 337.408 0 0 1-145.408 85.8112 40.5248 40.5248 0 0 1-22.3488-77.8752 256.6656 256.6656 0 0 0-1.3568-493.568 40.4992 40.4992 0 1 1 21.9392-78.0288z m-16.6656 166.2464c55.5776 34.3808 90.1888 99.1744 90.1888 170.112 0 51.2768-18.0224 99.712-49.92 135.936-11.008 12.4928-23.5008 23.3472-37.2224 32.256a40.5248 40.5248 0 0 1-44.032-67.9936 99.84 99.84 0 0 0 20.4544-17.8176c18.7648-21.2992 29.696-50.6624 29.696-82.3808 0-43.392-20.5824-81.92-51.8144-101.2224a40.5248 40.5248 0 1 1 42.6496-68.9152v0.0256z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconTongzhiguanli.defaultProps = {
  size: 18,
};

export default IconTongzhiguanli;
