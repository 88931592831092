/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconRukuguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1124 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M1086.757302 345.637416a37.853073 37.853073 0 0 1-17.373103-4.210124L562.419317 80.843437 55.454436 341.427292a38.044558 38.044558 0 0 1-34.79693-67.666332L545.020853 4.21157a38.04329 38.04329 0 0 1 34.796929 0l524.363346 269.54939a38.04329 38.04329 0 0 1-17.423826 71.876456zM403.271555 453.135072l205.788836 183.381339-205.788836 183.39402-1.26811-111.111769h-323.367965v-144.564502h323.367965l1.26811-111.099088m0-76.08658a76.08658 76.08658 0 0 0-76.08658 75.224266l-0.405795 35.874822H78.63548a76.08658 76.08658 0 0 0-76.08658 76.08658v144.564502a76.08658 76.08658 0 0 0 76.08658 76.08658h248.1437l0.405795 35.887503a76.08658 76.08658 0 0 0 126.709517 55.936317l205.788837-183.368657a76.08658 76.08658 0 0 0 0-113.609945L453.894492 396.33644A76.08658 76.08658 0 0 0 403.258873 377.048492z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M181.986418 547.824821a38.04329 38.04329 0 0 1-38.04329-38.04329v-124.908802a38.04329 38.04329 0 0 1 76.08658 0V509.781531a38.04329 38.04329 0 0 1-38.04329 38.04329zM942.852217 1024h-760.865799a38.04329 38.04329 0 0 1-38.04329-38.04329V792.569986a38.04329 38.04329 0 0 1 76.08658 0v155.343434h684.779219v-563.040691a38.04329 38.04329 0 0 1 76.08658 0v601.083981a38.04329 38.04329 0 0 1-38.04329 38.04329z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconRukuguanli.defaultProps = {
  size: 18,
};

export default IconRukuguanli;
