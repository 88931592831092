/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Bencishoukuan: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M968.789333 588.885333l-373.632 320.938667A133.632 133.632 0 0 1 512 938.666667c-31.232 0-59.904-10.666667-82.602667-28.416l-373.461333-320.512A51.328 51.328 0 0 1 42.666667 555.52c0-23.936 16.512-43.946667 38.869333-50.090667 0 0 5.376-2.048 13.226667-2.048H204.8V169.002667C204.8 120.917333 241.706667 85.333333 290.602667 85.333333H733.44c48.896 0 85.76 35.584 85.76 83.626667v334.421333h107.989333l1.066667-0.085333 1.024 0.085333h1.28v0.128c28.245333 1.28 50.816 23.978667 50.816 52.053334 0 12.714667-4.821333 24.277333-12.544 33.322666z m-338.304-139.605333c19.413333 0 30.848-14.122667 30.848-32.426667 0-18.218667-11.434667-31.146667-30.848-31.146666h-74.666666l77.184-72.832a31.957333 31.957333 0 0 0 0-47.104 36.949333 36.949333 0 0 0-50.048 0l-71.552 67.370666-71.253334-67.114666a36.949333 36.949333 0 0 0-50.048 0 31.957333 31.957333 0 0 0 0 47.104l77.098667 72.618666H393.514667c-19.413333 0-30.848 12.885333-30.848 31.146667s11.434667 32.426667 30.848 32.426667h76.16v39.68H393.514667c-19.413333 0-30.848 11.861333-30.848 30.122666 0 18.261333 11.434667 33.450667 30.848 33.450667h76.16v47.701333c0 22.826667 18.048 39.722667 42.325333 39.722667 24.234667 0 42.325333-16.896 42.325333-39.722667V552.533333h78.378667c19.370667 0 28.629333-12.16 28.629333-30.464 0-23.765333-13.482667-33.109333-32.896-33.109333h-74.112v-39.68h76.16z"
        fill={getIconColor(color, 0, '#4173CA')}
      />
    </svg>
  );
};

export default Bencishoukuan;
