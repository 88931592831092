/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessConsultantCasesMature: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M837.2224 0C894.99648 0 942.08 47.18592 942.08 105.14432v813.71136C942.08 976.81408 895.01696 1024 837.2224 1024H186.7776A105.10336 105.10336 0 0 1 81.92 919.04V105.14432C81.92 47.18592 128.98304 0 186.7776 0zM597.15584 471.22432c-9.66656 0-18.59584 5.12-23.3472 13.35296L366.26432 844.06272a26.95168 26.95168 0 0 0 23.3472 40.40704h414.94528c9.66656 0 18.59584-5.12 23.3472-13.5168 4.73088-8.3968 4.73088-18.6368 0.18432-27.07456L620.52352 484.57728a27.15648 27.15648 0 0 0-23.3472-13.35296z m-17.87904 289.87392a35.67616 35.67616 0 0 1 35.75808 0 35.84 35.84 0 0 1 0 62.07488 35.67616 35.67616 0 0 1-35.75808 0 35.84 35.84 0 0 1 0-62.07488z m17.69472-161.87392c9.50272-0.04096 18.6368 3.70688 25.33376 10.4448 6.71744 6.73792 10.46528 15.872 10.42432 25.3952v67.11296a35.7376 35.7376 0 1 1-71.49568 0.18432v-67.11296a35.65568 35.65568 0 0 1 35.7376-36.0448z m-111.8208-226.75456H251.904c-19.70176 0-35.7376 15.91296-35.7376 35.84 0 19.7632 16.03584 35.84 35.92192 35.84h233.10336a35.84 35.84 0 0 0 0-71.68z m197.36576-164.00384H252.08832c-19.8656 0-35.7376 16.0768-35.7376 35.84 0 19.74272 15.85152 35.84 35.7376 35.84h430.44864a35.84 35.84 0 0 0 0-71.68z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessConsultantCasesMature.defaultProps = {
  size: 18,
};

export default IconABusinessConsultantCasesMature;
