/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconHelpAndFeedback: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M452.42403404 422.63605029a29.78798298 29.78798298 0 0 1-59.57596673 0 119.15193269 119.15193269 0 0 1 238.30386538 0 130.47594553 130.47594553 0 0 1-54.8090563 109.03525921l-9.5338201 8.32648075c-19.3590669 16.98602002-24.9794407 25.3125-24.97944071 39.03037563v22.33578383a29.78798298 29.78798298 0 1 1-59.57596596 0V579.02816588a102.47815604 102.47815604 0 0 1 45.2752362-84.01418591l9.5338201-8.32647997a74.48036579 74.48036579 0 0 0 34.55489264-64.05144971 59.57596596 59.57596596 0 0 0-119.15193268 0z m89.36394894 268.09184836A29.78798298 29.78798298 0 1 1 512 660.93991567a29.78798298 29.78798298 0 0 1 29.78798298 29.78798298"
        fill={getIconColor(color, 0, '#515151')}
      />

      <path
        d="M851.01264384 512A339.01264384 339.01264384 0 1 0 512 851.01264384 339.01264384 339.01264384 0 0 0 851.01264384 512zM907.5078125 512A395.5078125 395.5078125 0 1 1 512 116.4921875a395.5078125 395.5078125 0 0 1 395.5078125 395.5078125z"
        fill={getIconColor(color, 1, '#515151')}
      />
    </svg>
  );
};

IconHelpAndFeedback.defaultProps = {
  size: 18,
};

export default IconHelpAndFeedback;
