/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialCharge: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M204.43136 102.4v88.2688h75.01824V102.4h707.03104C1007.12448 102.4 1024 120.87296 1024 143.4624v737.0752C1024 903.12704 1007.12448 921.6 986.48064 921.6H279.4496v-94.45376H204.43136V921.6h-166.912C16.87552 921.6 0 903.12704 0 880.5376V143.4624C0 120.87296 16.87552 102.4 37.51936 102.4h166.912z m617.34912 614.4H488.93952c-9.89184 0-17.89952 9.17504-17.89952 20.48s8.00768 20.48 17.89952 20.48h332.84096c9.89184 0 17.89952-9.17504 17.89952-20.48s-8.00768-20.48-17.89952-20.48z m-542.33088-74.42432H204.43136v102.64576h75.01824v-102.64576zM657.55136 266.24c-59.35104 0-107.64288 46.98112-107.64288 104.73472 0 37.08928 20.2752 71.2704 52.57216 90.0096l-10.48576 82.5344h-87.79776c-18.26816 0-33.15712 14.49984-33.15712 32.33792v67.62496c0 17.83808 14.88896 32.3584 33.1776 32.3584h302.2848c18.28864 0 33.1776-14.52032 33.1776-32.3584v-67.62496c0-17.83808-14.88896-32.3584-33.1776-32.3584h-83.61984l-10.4448-82.39104c32.39936-18.71872 52.75648-52.9408 52.75648-90.112 0-57.7536-48.29184-104.7552-107.64288-104.7552z m-378.10176 191.34464H204.43136v102.66624h75.01824v-102.66624z m0-184.77056H204.43136v102.64576h75.01824v-102.64576z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialCharge.defaultProps = {
  size: 18,
};

export default IconAFinancialCharge;
