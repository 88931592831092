/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Tongbuliebiao: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M806.771963 0c33.991601 0 66.603059 13.489791 90.64427 37.486481A127.908148 127.908148 0 0 1 934.947235 127.99719v729.583981c0 21.214143-17.207274 38.399157-38.443677 38.399156H165.96238c0 28.270684 22.928192 51.198876 51.265657 51.198876h679.275521a38.421417 38.421417 0 1 1 0 76.798314H217.228037A128.086231 128.086231 0 0 1 89.052765 895.980327V127.99719A128.086231 128.086231 0 0 1 217.228037 0h589.543926zM449.092164 285.222259l-11.375055 46.546457h-54.916359l-5.23119 0.356166a38.421417 38.421417 0 0 0-33.212488 38.04299l0.356166 5.23119c2.626725 19.010365 18.876803 33.167967 38.087512 33.167967h36.061817l-19.900781 81.205869-40.803278 0.044521-5.231189 0.356166a38.421417 38.421417 0 0 0-33.234749 38.065251l0.356166 5.20893c2.626725 19.010365 18.876803 33.167967 38.109772 33.167967h21.993256l-6.321948 25.911083-0.912675 5.119888a38.399157 38.399157 0 0 0 29.116578 41.315267l5.119887 0.912675c19.143927 2.003434 36.81867-10.395598 41.382048-29.072057l10.751764-44.231377h78.91305l-6.054823 26.512114-0.823634 5.119887a38.399157 38.399157 0 0 0 29.739868 40.91458l5.119888 0.823634a38.443678 38.443678 0 0 0 40.959101-29.695348l9.95039-43.674867 48.193724 0.044521 5.23119-0.356166a38.421417 38.421417 0 0 0 33.279269-38.042991l-0.356166-5.231189a38.443678 38.443678 0 0 0-38.109772-33.167968h-30.608024l18.609679-81.25039h36.929971l5.23119-0.356166a38.421417 38.421417 0 0 0 33.212488-38.042991l-0.356166-5.164408a38.443678 38.443678 0 0 0-38.087512-33.234748h-19.277489l6.678114-28.871714 0.801374-5.119888a38.399157 38.399157 0 0 0-29.739869-40.870059l-5.119888-0.801374a38.443678 38.443678 0 0 0-40.91458 29.695348l-10.618201 45.967687h-75.240087l6.967499-28.315205 0.912675-5.119887a38.443678 38.443678 0 1 0-75.618513-13.111365z m125.348204 123.34477l-18.565157 81.205869H478.164221l19.900781-81.205869h76.375366z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Tongbuliebiao;
