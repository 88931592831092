/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconDadianhua: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M680.8 927c-28.9 0-50.5-4-58.7-5.8-37.4-2-87.7-26.9-123.9-47.7-51.6-29.8-108.6-71.1-160.6-116.5-59.1-51.5-111.3-107.8-151.1-162.7-47.4-65.5-77.7-129.2-89.9-189.3-19.2-94.3-8.8-168 30.9-219 46.8-60.1 116.6-63.9 119.5-64.1h2c3 0.1 30.2 1 62.7 13.5 45 17.3 78.6 48.6 96.9 90.5 14.2 32.5 15.7 62.8 4.4 92.8-9.2 24.5-25.4 44.4-41 63.7-13.8 17-26.8 33-33.7 50.5-0.1 1.4 0.2 9.6 11.2 28.5 9.5 16.3 24.5 35.9 43.3 56.8 34.3 38 80.3 79 123.1 109.6 52.3 37.4 81.6 46.8 90.8 46.5 51.5-2 112.7-49.4 130.3-63.8 13.4-11 30.3-16.5 47.6-15.4 69.2 4.5 116 27 139.1 66.8 14.2 24.4 18.4 54.7 12.3 87.5-4.3 23-12.2 39.6-13.1 41.4l-0.7 1.5-0.9 1.4C863.4 884.8 784.2 914.5 728 923.4c-17 2.6-33 3.6-47.2 3.6z m-47.4-63.9c1.6 0.4 39.7 9.5 87.7 1.5C783.7 854 834 820 870.4 763.5c5.9-13.2 16.2-48.1 2-72.1-15.8-26.9-58.5-35.1-91.6-37.3-2.3-0.1-4.4 0.5-6.2 2-33.7 27.8-100 74.7-165.6 77.3h-2.5c-42.8 0-104.6-40.9-149.3-75.7-58-45-111.8-98.8-143.9-143.8-30.5-42.7-40.4-76-30.4-102 10.2-26.4 27.7-48 43.2-67 29.9-36.9 44.8-57.9 28.4-95.4-12.1-27.5-33.6-47.3-64-59-20.1-7.7-37.2-9.2-41.6-9.5-7.4 0.7-47.7 6.1-75.2 41.9-28.2 36.8-34.6 94-19.1 170.1 19.8 97.2 100.7 213.5 221.9 319.3 49.2 42.9 102.9 81.9 151.3 109.8 50.5 29.1 84.1 39.7 98.7 39.9l3.7 0.1 3.2 1zM338.3 432.6z"
        fill={getIconColor(color, 0, '#020202')}
      />

      <path
        d="M744.1 554.3c-0.9 0-1.8 0-2.8-0.1-16.3-1.5-28.2-15.9-26.7-32.2 5.3-56.9-6.8-102.4-35.9-135.2-50.9-57.4-137.3-60.2-138.1-60.3-16.3-0.4-29.2-14-28.8-30.3 0.4-16.3 14-29.2 30.3-28.8 4.6 0.1 113.1 3.8 180.9 80.1 40.4 45.5 57.4 106.1 50.5 180-1.4 15.3-14.3 26.8-29.4 26.8z"
        fill={getIconColor(color, 1, '#020202')}
      />

      <path
        d="M900 513.7c-1.2 0-2.4-0.1-3.6-0.2-16.2-2-27.8-16.8-25.8-33C882 388 863.3 314 815.1 260.6c-92.4-102.5-263.5-100.5-265.3-100.5-16.4 0.3-29.8-12.7-30.1-29-0.3-16.3 12.7-29.8 29-30.1 2 0 50.6-0.8 112.5 13.2 36.6 8.3 70.5 20 100.7 34.9 38.5 19 71.2 43.2 97.1 71.9 60.2 66.8 83.8 156.5 70.3 266.8-1.8 14.9-14.6 25.9-29.3 25.9z"
        fill={getIconColor(color, 2, '#020202')}
      />
    </svg>
  );
};

IconDadianhua.defaultProps = {
  size: 18,
};

export default IconDadianhua;
