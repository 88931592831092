/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksLogMyWorklog: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M708.011917 379.902341c85.116634 0 154.119054 70.189998 154.119054 156.780869 0 71.90994-47.646478 132.599308-112.656175 150.965827 143.471796 20.475495 253.814241 145.785527 253.937093 297.508948 0 13.083842-0.839495 26.04483-2.416108 38.616785H415.028054a312.722241 312.722241 0 0 1-2.436584-38.616785c0-151.580092 110.485773-277.033453 253.937094-297.508948-64.989222-18.366519-112.656176-79.055888-112.656176-150.965827 0-86.611346 69.002419-156.780868 154.139529-156.780869zM861.700986 0c20.741677 0 37.59301 17.13799 37.593009 38.22775v326.256543c-45.967487-51.188738-112.062386-83.253364-185.303233-83.253364-138.475775 0-251.131951 114.580872-251.029573 255.329428 0 43.039491 10.565356 83.621923 29.116154 119.26976a395.586571 395.586571 0 0 0-55.550019 46.991262 395.218012 395.218012 0 0 0-83.90858 126.948072 403.142029 403.142029 0 0 0-29.709944 186.367959H58.18112C37.439443 1016.13741 20.588111 998.978945 20.588111 977.889184V38.22775C20.588111 17.13799 37.439443 0 58.18112 0zM311.033013 417.659155H191.210414c-26.802423 0-48.506449 22.072584-48.506449 49.345944 0 27.232409 21.704025 49.325468 48.506449 49.325468h119.822599c26.781948 0 48.506449-22.072584 48.506448-49.345944 0-27.252884-21.704025-49.345944-48.526924-49.345944zM433.988363 176.007358H191.087561c-26.802423 0-48.506449 22.09306-48.506449 49.345944 0 27.252884 21.704025 49.345944 48.526924 49.345944H433.988363c26.802423 0 48.506449-22.09306 48.506448-49.345944 0-27.252884-21.704025-49.345944-48.506448-49.345944z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksLogMyWorklog.defaultProps = {
  size: 18,
};

export default IconAWorksLogMyWorklog;
