/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconJingyingbaodian: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M303.995259 384l88.727704-51.693037 87.267556 51.693037V159.990519h256v704.018962H267.851852a75.851852 75.851852 0 0 1-75.851852-75.851851V235.823407a75.851852 75.851852 0 0 1 75.851852-75.851851h36.143407V384z"
        fill={getIconColor(color, 0, '#FD6112')}
      />

      <path
        d="M736.009481 160.009481h20.138667a75.851852 75.851852 0 0 1 75.851852 75.851852v552.277334a75.851852 75.851852 0 0 1-75.851852 75.851852h-20.157629V160.009481z"
        fill={getIconColor(color, 1, '#FD6112')}
        fillOpacity=".5"
      />
    </svg>
  );
};

IconJingyingbaodian.defaultProps = {
  size: 18,
};

export default IconJingyingbaodian;
