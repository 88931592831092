/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Close: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 896a384 384 0 1 1 384-384 384 384 0 0 1-384 384z m37.12-631.893333a37.12 37.12 0 1 0-74.24 0v223.146666a37.12 37.12 0 1 0 74.24 0z m188.16 73.813333c-37.12-48.213333-87.893333 0-50.346667 56.746667a157.013333 157.013333 0 0 1 35.84 117.333333 210.773333 210.773333 0 0 1-421.12 0 157.013333 157.013333 0 0 1 34.986667-116.48c37.973333-57.173333-13.226667-105.386667-50.346667-57.173333a285.013333 285.013333 0 1 0 512 173.653333 273.066667 273.066667 0 0 0-59.733333-174.08z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Close;
