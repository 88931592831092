/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Pingzhengguanli: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M234.368 128c35.242667 0 63.829333 28.672 63.829333 64v640c0 35.328-28.586667 64-63.829333 64H149.205333A63.914667 63.914667 0 0 1 85.333333 832v-640C85.333333 156.672 113.92 128 149.205333 128h85.162667z m255.445333 0c35.285333 0 63.872 28.672 63.872 64v640c0 35.328-28.586667 64-63.872 64H404.650667a63.914667 63.914667 0 0 1-63.872-64v-640c0-35.328 28.586667-64 63.872-64h85.162666z m310.016 133.290667l136.832 534.144a64.128 64.128 0 0 1-45.994666 77.909333l-79.872 20.48a63.744 63.744 0 0 1-77.738667-46.08L596.224 313.770667a64.128 64.128 0 0 1 45.952-77.909334l79.914667-20.608c34.133333-8.746667 68.906667 11.861333 77.738666 46.08v-0.042666z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Pingzhengguanli;
