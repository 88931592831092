/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZhankai: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M1009.864695 478.458617a46.54881 46.54881 0 0 1 0 65.876593L543.651273 1010.121969a47.231469 47.231469 0 0 1-34.474279 13.866511 47.231469 47.231469 0 0 1-33.834286-13.866511L11.134597 544.975202a46.54881 46.54881 0 0 1 2.858635-63.017957 47.316801 47.316801 0 0 1 63.444619-2.858635l431.781809 430.928486 434.341781-431.568479a47.956794 47.956794 0 0 1 66.303254 0zM1010.547354 13.31185a46.54881 46.54881 0 0 1 0 65.876592L543.651273 544.975202a47.231469 47.231469 0 0 1-34.474279 13.866511 47.231469 47.231469 0 0 1-33.834286-13.866511L11.134597 79.828435A46.54881 46.54881 0 0 1 13.993232 16.810478 47.316801 47.316801 0 0 1 77.437851 13.951843l431.781809 430.928485L944.201433 13.269184a47.956794 47.956794 0 0 1 66.303255 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconZhankai.defaultProps = {
  size: 18,
};

export default IconZhankai;
