/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const LiuchengShenpijiedian: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M919.4 960H98.2c-18.8 0-34.2-15.6-34.2-34.8 0-19.2 15.4-34.8 34.2-34.8h821c18.8 0 34.2 15.6 34.2 34.8 0.2 19-15.2 34.8-34 34.8z m6.4-133.8H104.6c-18.8 0-34.2-15.6-34.2-34.8v-99.6c0-57.6 46.2-104.6 102.6-104.6H392c-6.8-41.8-27.4-82-56.4-116.8-39.4-45.4-59.8-102.8-59.8-162.2 0-66.2 25.6-129 73.6-176s109.4-69.8 176.2-68c123.2 5.2 227.6 113.4 229.2 238.8 1.8 62.8-22.2 123.8-63.2 170.8-29 31.4-46.2 69.8-53 113.4h219c56.4 0 102.6 47 102.6 104.6v99.6c-0.2 19.2-15.6 34.8-34.4 34.8z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default LiuchengShenpijiedian;
