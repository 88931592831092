/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Ldap01: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M477.866667 327.68l2.56 2.986667 42.666666 70.4a436.48 436.48 0 0 0-26.88 36.266666l-29.44 49.92-59.306666-94.293333v-2.133333l35.84-60.16a29.44 29.44 0 0 1 4.693333-5.546667h3.413333a21.76 21.76 0 0 1 28.16 4.693333z m192 38.826667a22.613333 22.613333 0 0 1 31.146666 7.253333l148.906667 244.053333a22.186667 22.186667 0 0 1-7.253333 30.72 24.32 24.32 0 0 1-11.946667 3.413334h-91.306667a42.666667 42.666667 0 0 0-5.973333-12.8l-113.92-186.88-2.56-4.266667 45.653333-74.24a18.346667 18.346667 0 0 1 4.693334-5.12zM534.613333 413.866667L384 669.44a22.613333 22.613333 0 0 0 8.106667 30.72 21.333333 21.333333 0 0 0 11.52 3.413333h300.8A22.613333 22.613333 0 0 0 725.333333 682.666667a27.306667 27.306667 0 0 0-2.986666-11.52l-150.186667-256a22.186667 22.186667 0 0 0-30.72-7.68 23.466667 23.466667 0 0 0-6.826667 6.4zM331.52 362.666667a22.186667 22.186667 0 0 1 30.72 7.253333l85.333333 143.36-69.546666 121.173333a51.2 51.2 0 0 0-5.546667 13.226667H192.853333a22.186667 22.186667 0 0 1-22.186666-23.04 21.333333 21.333333 0 0 1 3.413333-11.52l149.76-243.2a24.746667 24.746667 0 0 1 7.68-7.253333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Ldap01;
