/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFenxiang: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M610.474667 42.666667c97.877333 0 177.237333 78.805333 177.237333 176s-79.36 176-177.237333 176a177.28 177.28 0 0 1-95.488-27.690667L311.466667 553.514667a174.592 174.592 0 0 1 42.794666 123.434666l322.090667 79.957334a177.152 177.152 0 0 1 170.410667-127.573334c97.877333 0 177.237333 78.805333 177.237333 176S944.64 981.333333 846.762667 981.333333a176.938667 176.938667 0 0 1-174.421334-144.725333l-339.285333-84.266667a177.365333 177.365333 0 0 1-155.818667 92.117334C79.36 844.458667 0 765.653333 0 668.458667c0-97.237333 79.36-176 177.237333-176 24.618667 0 48.042667 4.949333 69.376 13.994666l213.034667-195.285333a174.293333 174.293333 0 0 1-26.453333-92.501333c0-97.194667 79.36-176 177.28-176z m236.288 664.874666a98.133333 98.133333 0 0 0-98.474667 97.792l0.128-2.304a98.005333 98.005333 0 0 0 98.346667 100.096 98.133333 98.133333 0 0 0 98.474666-97.792 98.133333 98.133333 0 0 0-98.474666-97.792zM177.237333 570.666667a98.133333 98.133333 0 0 0-98.474666 97.792 98.133333 98.133333 0 0 0 98.474666 97.749333 98.133333 98.133333 0 0 0 98.474667-97.749333 98.133333 98.133333 0 0 0-98.474667-97.792zM610.474667 120.874667A98.133333 98.133333 0 0 0 512 218.666667a98.133333 98.133333 0 0 0 98.474667 97.792 98.56 98.56 0 0 0 85.248-48.896 97.194667 97.194667 0 0 0 0-97.792 98.56 98.56 0 0 0-85.248-48.896z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconFenxiang.defaultProps = {
  size: 18,
};

export default IconFenxiang;
