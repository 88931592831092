/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const BuildingRetailToolbox24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M320 490.666667v-85.333334h384v74.666667h-32c-17.493333 0-34.048 3.84-48.938667 10.666667H320zM768 373.333333v106.666667h53.333333c28.373333 0 54.357333 10.069333 74.666667 26.837333V287.232A85.333333 85.333333 0 0 0 853.333333 128H170.666667a85.333333 85.333333 0 0 0-42.666667 159.232V864c0 17.664 14.336 32 32 32H256v-224a32 32 0 0 1 32-32h170.666667c9.813333 0 18.645333 4.437333 24.490666 11.434667A106.794667 106.794667 0 0 1 554.666667 599.466667V597.333333c0-15.061333 2.816-29.44 8.021333-42.666666H288a32 32 0 0 1-32-32v-149.333334A32 32 0 0 1 288 341.333333h448a32 32 0 0 1 32 32zM149.333333 213.333333a21.333333 21.333333 0 0 1 21.333334-21.333333h682.666666a21.333333 21.333333 0 0 1 0 42.666667H170.666667a21.333333 21.333333 0 0 1-21.333334-21.333334zM426.666667 704V896H320v-192H426.666667z m170.666666-64h-21.333333a64 64 0 0 0-64 64V768h106.666667v-10.666667a32 32 0 0 1 64 0V768h128v-10.666667a32 32 0 0 1 64 0V768H981.333333v-64a64 64 0 0 0-64-64H896v-42.666667a74.666667 74.666667 0 0 0-74.666667-74.666666h-149.333333A74.666667 74.666667 0 0 0 597.333333 597.333333v42.666667z m64-42.666667a10.666667 10.666667 0 0 1 10.666667-10.666666h149.333333a10.666667 10.666667 0 0 1 10.666667 10.666666v42.666667h-170.666667v-42.666667zM512 917.333333v-85.333333h106.666667v32a32 32 0 0 0 64 0v-32h128v32a32 32 0 0 0 64 0v-32H981.333333v85.333333a64 64 0 0 1-64 64h-341.333333a64 64 0 0 1-64-64z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default BuildingRetailToolbox24Filled;
