/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconGongyilingyongshenqingliucheng: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M837.376 517.888l-5.418667-135.594667-85.248 3.413334 19.797334 495.36c-45.141333 6.058667-131.968 14.933333-254.506667 14.933333-122.026667 0-209.194667-8.874667-254.506667-14.976l19.797334-495.36-85.248-3.413333-5.418667 135.637333-53.248-15.232 44.672-282.794667a84.906667 84.906667 0 0 1 68.821333-70.613333c16.469333-2.944 36.522667-5.973333 59.306667-8.874667A213.546667 213.546667 0 0 0 512 298.666667a213.546667 213.546667 0 0 0 205.866667-158.293334c22.741333 2.901333 42.794667 5.973333 59.306666 8.874667a84.906667 84.906667 0 0 1 68.778667 70.613333l44.672 282.794667-53.248 15.232zM631.04 131.968A128.085333 128.085333 0 0 1 512 213.333333a128.085333 128.085333 0 0 1-119.04-81.365333A1745.706667 1745.706667 0 0 1 512 128c43.733333 0 83.456 1.536 119.04 3.968z m349.781333 394.666667l-50.56-320.085334a170.325333 170.325333 0 0 0-138.24-141.354666C733.781333 54.954667 637.952 42.666667 512 42.666667c-126.037333 0-221.824 12.245333-279.978667 22.528a170.325333 170.325333 0 0 0-138.24 141.354666l-50.602666 320.128a42.666667 42.666667 0 0 0 30.421333 47.658667l109.525333 31.274667-12.373333 310.016a42.666667 42.666667 0 0 0 34.218667 43.52c4.522667 0.938667 113.066667 22.186667 307.029333 22.186666 194.005333 0 302.506667-21.248 307.029333-22.186666A42.666667 42.666667 0 0 0 853.333333 915.626667l-12.373333-310.016 109.482667-31.274667a42.666667 42.666667 0 0 0 30.421333-47.658667z"
        fill={getIconColor(color, 0, '#1768E4')}
      />

      <path
        d="M469.333333 579.626667l-76.501333-76.501334-60.330667 60.330667 106.666667 106.666667a42.496 42.496 0 0 0 60.330667 0l213.333333-213.333334-60.330667-60.288L469.333333 579.626667z"
        fill={getIconColor(color, 1, '#8BB3F1')}
      />
    </svg>
  );
};

IconGongyilingyongshenqingliucheng.defaultProps = {
  size: 18,
};

export default IconGongyilingyongshenqingliucheng;
