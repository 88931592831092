/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveIntranetManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M108.72832 138.26048h270.2336l98.6112 122.10176a44.50304 44.50304 0 0 0 34.4064 16.67072h302.44864v92.59008H171.70432a44.99456 44.99456 0 0 0-43.47904 35.4304l-19.49696 83.6608V138.26048z m884.69504 272.5888a43.58144 43.58144 0 0 0-34.57024-17.34656h-55.95136v-94.9248c0-52.3264-40.07936-94.8224-89.2928-94.8224H532.93056l-98.304-125.27616A43.7248 43.7248 0 0 0 400.13824 61.44H109.7728C60.55936 61.44 20.48 104.01792 20.48 156.28288v758.8864c0 2.10944 0.75776 4.05504 1.06496 6.08256 0.28672 2.41664 0.512 4.5056 1.1264 6.7584 1.29024 4.8128 3.33824 9.0112 5.81632 13.14816 0.75776 1.04448 0.75776 2.33472 1.51552 3.3792 0.22528 0.3072 0.67584 0.36864 0.8192 0.75776 3.85024 4.9152 8.66304 9.0112 14.15168 12.00128 1.29024 0.75776 2.6624 0.8192 4.01408 1.35168 3.39968 1.51552 6.79936 3.15392 10.58816 3.60448 1.8432 0.2048 3.6864 0.3072 5.5296 0.3072h804.31104a45.48608 45.48608 0 0 0 43.84768-38.07232l89.37472-474.29632a49.52064 49.52064 0 0 0-9.29792-39.34208h0.08192z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveIntranetManage.defaultProps = {
  size: 18,
};

export default IconAExecutiveIntranetManage;
