/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconJieanshenhe: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M719.8208 1020.0064H162.56c-89.6 0-162.176-71.3728-162.176-159.4368V159.744C0.384 71.6544 72.96 0.2816 162.56 0.2816h557.2608c89.5744 0 162.176 71.3728 162.176 159.4368v700.928c0 87.9872-72.6016 159.36-162.176 159.36z"
        fill={getIconColor(color, 0, '#F85F69')}
      />

      <path
        d="M630.0928 289.7152H240.7168a49.0496 49.0496 0 1 1 0-98.0992h389.376a49.0496 49.0496 0 1 1 0 98.0992z m0 194.4832H240.7168a49.0496 49.0496 0 1 1 0-98.0992h389.376a49.0496 49.0496 0 1 1 0 98.0992z m-202.88 194.4576H240.7936a49.0496 49.0496 0 1 1 0-98.1248h186.4704a49.0496 49.0496 0 1 1-0.0512 98.1248z"
        fill={getIconColor(color, 1, '#FFFFFF')}
      />

      <path
        d="M802.4064 555.264l47.9488 97.152c5.6832 11.52 16.64 19.456 29.3632 21.3248l107.1872 15.5648c31.9488 4.6592 44.7744 43.9808 21.5808 66.4832l-77.568 75.5968c-9.1904 8.96-13.3888 21.8624-11.2128 34.5344l18.3296 106.752c5.4528 31.8208-27.9552 56.0896-56.5248 41.0624l-95.8464-50.3808a39.168 39.168 0 0 0-36.3008 0l-95.8464 50.3808c-28.5696 15.0272-61.9776-9.2416-56.5248-41.0624l18.3296-106.752a39.0656 39.0656 0 0 0-11.2128-34.5344l-77.568-75.5968c-23.1168-22.5792-10.368-61.8752 21.5808-66.4832l107.1872-15.5648a39.0144 39.0144 0 0 0 29.3632-21.3248l47.9488-97.152c14.1568-28.928 55.5008-28.928 69.7856 0z"
        fill={getIconColor(color, 2, '#F498A6')}
      />

      <path
        d="M881.9968 674.0992l-2.3296-0.3072a39.0144 39.0144 0 0 1-29.3632-21.3248l-47.9744-97.1264c-14.2848-28.928-55.6032-28.928-69.888 0l-47.872 97.0752c-5.7088 11.52-16.6912 19.456-29.3888 21.3248l-107.1872 15.5648c-31.9488 4.6592-44.7488 43.9808-21.5808 66.4832l77.568 75.5968c9.1904 8.96 13.3888 21.8624 11.2128 34.5344l-18.3296 106.752c-5.4528 31.8208 27.9552 56.0896 56.5504 41.0624l95.8208-50.3808a39.168 39.168 0 0 1 36.3008 0l37.8368 19.9168a158.1568 158.1568 0 0 0 58.5472-122.624v-186.5472h0.0768z"
        fill={getIconColor(color, 3, '#F33B3D')}
      />
    </svg>
  );
};

IconJieanshenhe.defaultProps = {
  size: 18,
};

export default IconJieanshenhe;
