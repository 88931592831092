/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksTaskStatistics: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M929.60768 660.97152l49.27488 25.8048c32.84992 16.44544 32.84992 65.69984 0 82.1248L544.84992 996.47488c-9.37984 4.7104-21.11488 7.04512-32.84992 7.04512-11.73504 0-23.47008-2.3552-32.84992-7.04512L45.11744 768.90112c-32.84992-16.42496-32.84992-65.67936 0-82.1248l49.27488-25.8048 351.90784 183.00928c21.11488 11.73504 42.22976 16.42496 65.69984 16.42496 23.47008 0 44.58496-4.7104 65.69984-16.42496l351.90784-183.00928z m0-215.83872l49.27488 25.8048c32.84992 18.78016 32.84992 65.69984-4.68992 82.1248l-49.27488 25.8048-75.07968 39.87456-307.34336 161.8944c-9.37984 4.7104-21.0944 7.04512-32.84992 7.04512-11.71456 0-23.4496-2.3552-32.82944-7.04512L169.45152 618.74176 94.4128 578.8672l-49.27488-25.8048c-32.84992-16.42496-32.84992-65.69984 0-82.1248l49.27488-25.8048 129.024 68.03456 77.4144 39.89504 145.46944 77.4144c21.11488 11.73504 42.22976 16.42496 65.69984 16.42496 23.47008 0 44.58496-7.04512 65.69984-16.42496l145.44896-77.4144 77.4144-39.89504 129.04448-68.03456zM515.072 20.50048c4.36224 0.08192 9.6256 0.45056 13.37344 2.33472 7.04512 2.33472 11.73504 4.68992 16.42496 7.02464L978.88256 257.4336c32.84992 16.42496 32.84992 65.69984-4.68992 82.1248l-49.27488 25.8048-75.07968 39.89504-129.024 68.03456-75.07968 39.87456-103.2192 53.9648c-9.40032 4.7104-21.13536 7.04512-32.8704 7.04512-11.71456 0-23.4496-2.3552-32.82944-7.04512l-103.2192-53.9648-75.10016-39.87456-129.024-68.03456-75.07968-39.89504-49.27488-25.8048c-32.84992-16.42496-32.84992-65.69984 0-82.1248L479.15008 29.85984c4.7104-4.68992 11.73504-4.68992 16.42496-7.02464C500.28544 20.48 507.31008 20.48 512 20.48z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksTaskStatistics.defaultProps = {
  size: 18,
};

export default IconAWorksTaskStatistics;
