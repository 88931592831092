/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinssBiddingManageBiddingHall: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M698.9824 0v222.72c0 32.01024 26.17344 57.9584 58.42944 57.9584H942.08v667.4432C942.08 989.98272 913.5104 1024 878.30528 1024H145.69472C110.46912 1024 81.92 990.0032 81.92 948.1216V75.8784C81.92 34.01728 110.30528 0 145.5104 0H698.9824z m-88.10496 781.90592H271.38048c-19.90656 0-35.9424 15.89248-35.9424 35.6352a35.84 35.84 0 0 0 35.9424 35.67616h339.49696a35.84 35.84 0 0 0 35.9424-35.65568 35.84 35.84 0 0 0-35.9424-35.65568z m-9.95328-471.77728a63.20128 63.20128 0 0 0-88.84224 0l-25.6 25.3952a62.01344 62.01344 0 0 0 0 88.1664l5.5296 5.46816-70.9632 72.6016-10.1376-10.05568a63.20128 63.20128 0 0 0-88.84224 0l-25.6 25.41568a62.01344 62.01344 0 0 0 0 88.14592l110.57152 109.71136c11.79648 11.8784 27.648 18.28864 44.42112 18.28864a62.8736 62.8736 0 0 0 44.42112-18.28864l25.6-25.41568c11.9808-11.71456 18.432-27.4432 18.432-44.07296 0-16.65024-6.63552-32.3584-18.432-44.07296l-2.94912-2.92864 10.69056-10.9568 198.69696 186.51136c7.00416 6.38976 15.85152 9.68704 24.69888 9.68704 9.40032 0 18.98496-3.85024 25.98912-11.1616a35.34848 35.34848 0 0 0-1.47456-50.2784l-197.95968-185.79456 10.32192-10.58816 7.5776 7.49568c11.776 11.8784 27.648 18.26816 44.40064 18.26816a62.8736 62.8736 0 0 0 44.42112-18.26816l25.6-25.41568c12.00128-11.71456 18.432-27.4432 18.432-44.07296 0-16.65024-6.61504-32.3584-18.432-44.07296z m176.0256-279.9616L925.2864 203.3664h-148.35712V30.16704z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinssBiddingManageBiddingHall.defaultProps = {
  size: 18,
};

export default IconABusinssBiddingManageBiddingHall;
