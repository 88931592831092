/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const AIcFluentInfo24Filled1: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 85.290667c235.690667 0 426.752 191.061333 426.752 426.752 0 235.648-191.061333 426.709333-426.752 426.709333-235.690667 0-426.752-191.061333-426.752-426.709333C85.248 276.352 276.309333 85.290667 512 85.290667z m-0.170667 352a42.666667 42.666667 0 0 0-42.325333 37.76l-0.298667 4.949333 0.128 234.752 0.298667 4.992a42.666667 42.666667 0 0 0 84.778667-0.085333L554.666667 714.666667l-0.128-234.709334-0.298667-4.992a42.666667 42.666667 0 0 0-42.410667-37.632zM512 277.333333a53.376 53.376 0 1 0 0 106.794667A53.376 53.376 0 0 0 512 277.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default AIcFluentInfo24Filled1;
