/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const GongshibaobiaoXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M714.666667 85.333333A138.666667 138.666667 0 0 1 853.333333 224l0.042667 246.314667a276.010667 276.010667 0 0 0-64-30.293334L789.333333 320h-640v394.666667c0 41.216 33.450667 74.666667 74.666667 74.666666h216.064c7.338667 22.784 17.578667 44.288 30.250667 64.042667L224 853.333333A138.666667 138.666667 0 0 1 85.333333 714.666667V224A138.666667 138.666667 0 0 1 224 85.333333h490.666667z m0 64H224A74.666667 74.666667 0 0 0 149.333333 224V256h640v-32a74.666667 74.666667 0 0 0-74.666666-74.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M704 469.333333a234.666667 234.666667 0 1 1 0 469.333334 234.666667 234.666667 0 0 1 0-469.333334z m0 64a170.666667 170.666667 0 1 0 0 341.333334 170.666667 170.666667 0 0 0 0-341.333334z"
        fill={getIconColor(color, 1, '#333333')}
      />

      <path
        d="M688.298667 607.146667l-1.237334 2.986666-16.938666 54.442667h-54.954667c-16.213333 0-23.552 20.394667-12.714667 31.573333l2.261334 2.005334 44.458666 33.706666-16.981333 54.442667c-5.034667 16.170667 11.392 29.738667 25.002667 22.272l2.389333-1.536 44.416-33.664 44.458667 33.664c13.226667 9.984 30.677333-2.133333 28.032-17.92l-0.64-2.816-16.981334-54.485333 44.458667-33.706667c13.098667-9.898667 7.552-30.890667-7.509333-33.28l-2.944-0.256h-54.954667l-16.981333-54.442667a17.578667 17.578667 0 0 0-32.64-2.986666z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

export default GongshibaobiaoXianxing;
