/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFemale: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M810.96021333 255.70645333c119.97184 119.97184 119.97184 314.4704 0 434.44224-112.64 112.64-290.95936 119.52128-411.648 20.66432l-44.52352 44.50304 72.41728 72.3968a30.72 30.72 0 0 1-43.43808 43.45856L311.33013333 798.75413333l-94.12608 94.12608a30.72 30.72 0 0 1-43.43808-43.43808l94.12608-94.12608-72.41728-72.41728a30.72 30.72 0 0 1 43.45856-43.43808l72.3968 72.41728 44.52352-44.52352c-98.87744-120.66816-91.99616-299.008 20.64384-411.648 119.97184-119.97184 314.4704-119.97184 434.44224 0z m-43.43808 43.43808c-95.96928-95.96928-251.57632-95.96928-347.5456 0-95.98976 95.96928-95.98976 251.57632 0 347.5456 95.96928 95.98976 251.57632 95.98976 347.5456 0 95.96928-95.96928 95.96928-251.57632 0-347.5456z"
        fill={getIconColor(color, 0, '#131415')}
      />
    </svg>
  );
};

IconFemale.defaultProps = {
  size: 18,
};

export default IconFemale;
