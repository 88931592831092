/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const BanbenfenfaXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M509.96093723 90.61718723c231.58928567 0 419.34375 187.75446433 419.34375 419.34375 0 231.58928567-187.75446433 419.34375-419.34375 419.34375-231.58928567 0-419.34375-187.75446433-419.34375-419.34375C90.61718723 278.37165155 278.37165155 90.61718723 509.96093723 90.61718723z m0 62.75892919c-196.92522345 0-356.58482163 159.65959817-356.5848208 356.58482081s159.65959817 356.58482163 356.5848208 356.58482163 356.58482163-159.65959817 356.58482163-356.58482163-159.65959817-356.58482163-356.58482163-356.58482081z m-223.68917368 188.88950831l184.77622787 391.37946487H547.859375l185.7053573-391.37946487H650.2142854l-140.2583703 315.61272372-140.2583703-315.61272372H286.27176355z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default BanbenfenfaXianxing;
