/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconBangongyongpinlingyong: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1398 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M841.416 1024H158.791c-22.625 0-44.375-9-60.375-25-16-16-25-37.75-25-60.375V85.375c0-47.125 38.25-85.375 85.375-85.375h682.625c22.625 0 44.375 9 60.375 25 16 16 25 37.75 25 60.375V938.75c0 47-38.25 85.25-85.375 85.25zM180.041 768c0 23.625 19.125 42.625 42.625 42.625h298.625c23.625 0 42.625-19.125 42.625-42.625v-42.625c0-23.625-19.125-42.625-42.625-42.625H222.791c-23.5 0-42.625 19.125-42.625 42.625v42.625z m0-234.625c0 23.625 19.125 42.625 42.625 42.625h298.625c23.625 0 42.625-19.125 42.625-42.625v-42.625c0-23.625-19.125-42.625-42.625-42.625H222.791c-23.5 0-42.625 19.125-42.625 42.625v42.625zM692.041 256c0-23.625-19.125-42.625-42.625-42.625H222.791C199.166 213.375 180.041 232.375 180.041 256v42.625c0 23.625 19.125 42.625 42.625 42.625h426.625c23.625 0 42.625-19.125 42.625-42.625V256z m192 0c0-23.625-19.125-42.625-42.625-42.625h-42.625c-23.625 0-42.625 19.125-42.625 42.625v42.625h128V256z m0 64H756.041v383.75h-0.25l64.125 128.25 64-128h0.125V320z m0 0"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconBangongyongpinlingyong.defaultProps = {
  size: 18,
};

export default IconBangongyongpinlingyong;
