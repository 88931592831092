/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Tianjiagongshimiaoshu: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M0 0h1024v1024H0z"
        fill={getIconColor(color, 0, '#FFFFFF')}
      />

      <path
        d="M725.333333 170.666667a42.666667 42.666667 0 0 1 42.666667 42.666666v410.709334a42.666667 42.666667 0 0 1-42.666667 42.666666H218.453333l-1.621333 1.578667-95.658667 95.658667a8.533333 8.533333 0 0 1-14.549333-6.016v-133.333334V213.333333a42.666667 42.666667 0 0 1 42.666667-42.666666H725.333333z"
        fill={getIconColor(color, 1, '#FFCA00')}
      />

      <path
        d="M789.333333 913.066667a234.666667 234.666667 0 1 0 0-469.333334 234.666667 234.666667 0 0 0 0 469.333334z"
        fill={getIconColor(color, 2, '#3A7FCC')}
      />

      <path
        d="M812.8 584.533333a23.466667 23.466667 0 0 0-46.933333 0v70.4H695.466667a23.466667 23.466667 0 1 0 0 46.933334h70.4v70.4a23.466667 23.466667 0 1 0 46.933333 0v-70.4h70.4a23.466667 23.466667 0 1 0 0-46.933334h-70.4V584.533333z"
        fill={getIconColor(color, 3, '#FFFFFF')}
      />
    </svg>
  );
};

export default Tianjiagongshimiaoshu;
