/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const ArrowSyncCircle24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 938.666667C276.352 938.666667 85.333333 747.648 85.333333 512S276.352 85.333333 512 85.333333s426.666667 191.018667 426.666667 426.666667-191.018667 426.666667-426.666667 426.666667z m139.52-480H597.333333a32 32 0 0 0 0 64h117.333334a32 32 0 0 0 32-32V352a32 32 0 0 0-64 0V384a212.949333 212.949333 0 0 0-170.666667-85.333333c-65.578667 0-123.904 28.16-163.285333 75.52a32 32 0 0 0 49.237333 40.96C425.088 382.421333 465.365333 362.666667 512 362.666667c63.658667 0 118.058667 39.850667 139.52 96z m-310.186667 213.333333V640a213.888 213.888 0 0 0 333.696 10.112 32 32 0 1 0-48.725333-41.472 149.888 149.888 0 0 1-249.258667-32.64H426.666667a32 32 0 0 0 0-64H309.333333a32 32 0 0 0-32 32v128a32 32 0 0 0 64 0z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default ArrowSyncCircle24Filled;
