/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWjZcjl: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M960 828.2v-0.2-0.2-1.4c0-0.3 0-0.5-0.1-0.8 0-0.2 0-0.5-0.1-0.7 0-0.3-0.1-0.7-0.1-1 0-0.2 0-0.4-0.1-0.6-0.1-0.4-0.1-0.7-0.2-1 0-0.2-0.1-0.3-0.1-0.5-0.1-0.4-0.1-0.7-0.2-1 0-0.2-0.1-0.3-0.1-0.5-0.1-0.3-0.2-0.7-0.3-1 0-0.2-0.1-0.3-0.1-0.5l-0.3-0.9c-0.1-0.2-0.1-0.4-0.2-0.6-0.1-0.3-0.2-0.5-0.3-0.8-0.1-0.2-0.2-0.4-0.3-0.7-0.1-0.2-0.2-0.5-0.3-0.7-0.1-0.2-0.2-0.5-0.3-0.7l-0.3-0.6c-0.1-0.3-0.3-0.5-0.4-0.8-0.1-0.2-0.2-0.3-0.3-0.5-0.2-0.3-0.3-0.6-0.5-0.8-0.1-0.2-0.2-0.3-0.3-0.5-0.2-0.3-0.4-0.6-0.5-0.8-0.1-0.2-0.2-0.3-0.3-0.5-0.2-0.3-0.4-0.5-0.6-0.8-0.1-0.2-0.3-0.3-0.4-0.5-0.2-0.2-0.4-0.5-0.6-0.7-0.2-0.2-0.4-0.5-0.6-0.7-0.1-0.2-0.3-0.3-0.4-0.5-0.4-0.4-0.7-0.8-1.1-1.2L879 733.7c-12.5-12.5-32.7-12.5-45.2 0s-12.5 32.8 0 45.3l17.1 17.1H703.6v-51h95.9c17.7 0 32-14.3 32-32s-14.3-32-32-32h-64.2l89.8-119.8c10.6-14.1 7.7-34.2-6.4-44.8-14.1-10.6-34.2-7.7-44.8 6.4L671.7 659.3 569.3 522.8c-10.6-14.1-30.6-17-44.8-6.4-14.1 10.6-17 30.7-6.4 44.8L607.9 681h-64.2c-17.7 0-32 14.3-32 32s14.3 32 32 32h95.9v51.3h-95.9c-17.7 0-32 14.3-32 32s14.3 32 32 32h95.9V925c0 17.7 14.3 32 32 32s32-14.3 32-32v-64.7h95.9c1.6 0 3.1-0.1 4.6-0.3h47.3l-17.7 17.7c-12.5 12.5-12.5 32.8 0 45.3s32.7 12.5 45.2 0l71.7-71.7c6.3-6.3 9.4-14.7 9.4-23v-0.1zM256 64.5h64v159.8h-64zM351.9 128.4h128v63.9h-128zM511.9 64.5h64v159.8h-64z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M128 863.6V224.3c0-17.7 14.3-32 32-32h64v-63.9h-64c-53 0-95.9 42.9-95.9 95.9v639.3c0 53 43 95.9 95.9 95.9h415.7v-63.9H159.9c-17.7 0-31.9-14.3-31.9-32z"
        fill={getIconColor(color, 1, '#333333')}
      />

      <path
        d="M415.3 703H223.7c-17.7 0-32 14.3-32 32s14.3 32 32 32h191.7c17.7 0 32-14.3 32-32-0.1-17.7-14.4-32-32.1-32zM479.3 543c0-17.7-14.3-32-32-32H223.7c-17.7 0-32 14.3-32 32s14.3 32 32 32h223.6c17.7 0 32-14.3 32-32zM192.7 351.2c0 17.7 14.3 32 32 32h383.5c17.7 0 32-14.3 32-32s-14.3-32-32-32H224.7c-17.7 0-32 14.3-32 32zM703.7 224.3V512h64V224.3c0-53-43-95.9-95.9-95.9h-64v63.9h64c17.6 0 31.9 14.3 31.9 32z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconWjZcjl.defaultProps = {
  size: 18,
};

export default IconWjZcjl;
