/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShanchu1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M692.37837369 347.43182222a8.34200462 8.34200462 0 0 0-8.31870294-8.31870293l-68.6584263 0.30292196L512 462.68197547l-103.29638685-123.14942578-68.7632839-0.3145728a8.27209955 8.27209955 0 0 0-8.31870294 8.31870293c0 1.98064355 0.72235235 3.84477867 1.98064356 5.40599183l135.33620906 161.2476871-135.34785991 161.13117867a8.34200462 8.34200462 0 0 0 6.34971023 13.7363456l68.7632839-0.3145728L512 565.477376l103.29638685 123.14942578 68.6584263 0.32622364a8.27209955 8.27209955 0 0 0 8.31870294-8.33035377c0-1.98064355-0.72235235-3.84477867-1.98064356-5.40599183l-135.12649386-161.13117867 135.33620906-161.2476871a8.34200462 8.34200462 0 0 0 1.87578596-5.40599183zM512 45.96622222c-257.36715378 0-466.03377778 208.666624-466.03377778 466.03377778s208.666624 466.03377778 466.03377778 466.03377778 466.03377778-208.666624 466.03377778-466.03377778-208.666624-466.03377778-466.03377778-466.03377778z m0 853.00492516C298.32351289 898.97114738 125.02885262 725.67648711 125.02885262 512c0-213.67648711 173.30631111-386.97114738 386.97114738-386.97114738 213.67648711 0 386.97114738 173.30631111 386.97114738 386.97114738 0 213.67648711-173.30631111 386.97114738-386.97114738 386.97114738z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconShanchu1.defaultProps = {
  size: 18,
};

export default IconShanchu1;
