/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCaseApplicationsCheckCustomers: FunctionComponent<Props> = ({
  size,
  color,
  style: _style,
  ...rest
}) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M768 435.07712L1024 698.81856 768 962.56v-131.87072H362.65984V566.94784H768v-131.8912zM256 61.44v131.87072h405.34016v263.74144H256v131.8912L0 325.16096 256 61.44z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCaseApplicationsCheckCustomers.defaultProps = {
  size: 18,
};

export default IconABusinessCaseApplicationsCheckCustomers;
