/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Biangengshenhe: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M734.608696 467.478261c147.522783 0 267.130435 119.607652 267.130434 267.130435a267.130435 267.130435 0 0 1-534.260869 0c0-147.522783 119.607652-267.130435 267.130435-267.130435z m17.318956-445.217391A160.768 160.768 0 0 1 912.695652 183.02887v282.334608a321.26887 321.26887 0 0 0-178.086956-53.537391c-178.265043 0-322.782609 144.517565-322.782609 322.782609 0 64.445217 19.21113 126.130087 53.581913 178.086956H183.02887A160.768 160.768 0 0 1 22.26087 751.927652V183.02887A160.768 160.768 0 0 1 183.02887 22.26087h568.898782z m59.948522 829.395478h-153.221565V890.434783h153.221565v-38.778435zM734.608696 578.782609c-41.894957 0-75.954087 34.771478-75.954087 77.57913a79.026087 79.026087 0 0 0 37.977043 66.871652v30.764522h-57.61113c-20.947478 0-37.977043 17.385739-37.977044 38.800696v38.778434h267.130435v-38.778434c0-21.414957-17.029565-38.800696-37.977043-38.800696h-57.611131V723.255652c22.26087-13.356522 37.977043-37.442783 37.977044-66.871652C810.562783 613.554087 776.503652 578.782609 734.608696 578.782609z m-479.899826 12.466087a37.10887 37.10887 0 0 0-34.571131 36.997565v49.463652l0.333913 5.053217a37.10887 37.10887 0 0 0 73.861565-5.053217v-49.463652l-0.333913-5.030957a37.10887 37.10887 0 0 0-39.290434-31.966608z m2.893913-189.462261a37.175652 37.175652 0 0 0-37.131131 37.153391l-0.333913 56.742957 0.333913 5.030956a37.10887 37.10887 0 0 0 73.861565-5.030956l0.356174-56.787479-0.356174-5.053217a37.10887 37.10887 0 0 0-36.730434-32.055652z m-2.893913-181.559652a37.10887 37.10887 0 0 0-34.571131 37.019826v49.463652l0.333913 5.030956a37.10887 37.10887 0 0 0 73.861565-5.030956v-49.463652l-0.333913-5.053218a37.10887 37.10887 0 0 0-39.290434-31.966608z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Biangengshenhe;
