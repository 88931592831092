/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessBorrowMyBorrow: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M723.33312 0c32.39936 0 59.12576 25.7024 61.00992 57.83552l0.1024 3.60448v266.24h137.48224c32.41984 0 59.14624 25.7024 61.00992 57.83552L983.04 389.12v573.44a61.50144 61.50144 0 0 1-57.5488 61.3376l-3.56352 0.1024H102.07232c-32.41984 0-59.14624-25.7024-61.00992-57.83552L40.96 962.56V61.44A61.50144 61.50144 0 0 1 98.5088 0.1024L102.05184 0h621.2608z m178.23744 409.6h-117.1456v532.48h117.1456V409.6z m-306.3808 196.8128A163.84 163.84 0 1 0 532.52096 921.6 164.02432 164.02432 0 0 0 696.32 757.80096a163.84 163.84 0 0 0-101.13024-151.38816z m-79.872 74.21952c10.36288 0 18.75968 8.3968 18.75968 18.75968v71.33184h74.09664a18.75968 18.75968 0 1 1 0 37.51936h-85.6064a18.26816 18.26816 0 0 1-3.54304-0.34816 19.31264 19.31264 0 0 1-15.5648-4.05504 18.86208 18.86208 0 0 1-6.90176-14.49984V699.392c0-10.36288 8.3968-18.75968 18.75968-18.75968zM285.3888 430.08H163.18464a40.96 40.96 0 0 0-3.03104 81.79712l3.03104 0.12288h122.20416a40.96 40.96 0 0 0 0-81.92z m183.82848-143.36H163.67616a40.96 40.96 0 0 0-3.01056 81.79712l3.01056 0.12288h305.5616a40.96 40.96 0 0 0 0-81.92z m162.95936-143.36H163.67616a40.96 40.96 0 0 0-3.01056 81.79712l3.01056 0.12288h468.50048a40.96 40.96 0 0 0 0-81.92z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessBorrowMyBorrow.defaultProps = {
  size: 18,
};

export default IconABusinessBorrowMyBorrow;
