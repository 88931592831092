/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialInvoicesApply: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M901.12 81.92a122.88 122.88 0 0 1 122.88 122.88v142.27456c-60.6208 30.18752-102.4 92.61056-102.4 164.94592 0 72.31488 41.7792 134.7584 102.4 164.92544V819.2a122.88 122.88 0 0 1-122.88 122.88H122.88a122.88 122.88 0 0 1-122.88-122.88v-142.25408C60.6208 646.7584 102.4 584.33536 102.4 512s-41.7792-134.7584-102.4-164.92544V204.8a122.88 122.88 0 0 1 122.88-122.88h778.24z m-194.56 675.84h-389.12a30.72 30.72 0 0 0-30.72 30.72v20.48a30.72 30.72 0 0 0 30.72 30.74048h389.12a30.72 30.72 0 0 0 30.72-30.72v-20.48c0-16.9984-13.76256-30.72-30.72-30.72z m0-143.36h-389.12a30.72 30.72 0 0 0-30.72 30.72v20.48a30.72 30.72 0 0 0 30.72 30.74048h389.12a30.72 30.72 0 0 0 30.72-30.72v-20.48c0-16.9984-13.76256-30.74048-30.72-30.74048zM578.8672 184.32c-10.8544 0-21.68832 4.64896-29.42976 12.43136l-188.88704 189.44c-7.74144 7.7824-12.3904 18.6368-12.3904 29.53216v55.9104C348.16 493.3632 366.73536 512 388.42368 512h57.28256c12.3904 0 21.66784-4.64896 29.42976-12.43136l188.88704-191.01696c15.48288-15.52384 15.48288-40.36608 1.55648-54.35392L608.256 196.75136A42.22976 42.22976 0 0 0 578.8672 184.32z m0 69.87776l26.3168 26.4192-167.2192 167.71072h-27.8528v-26.4192l168.7552-167.71072z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialInvoicesApply.defaultProps = {
  size: 18,
};

export default IconAFinancialInvoicesApply;
