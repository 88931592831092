/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Huishuai1: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M97.152 494.506667l-52.224 97.194666a37.162667 37.162667 0 0 0-2.986667 27.733334 36.053333 36.053333 0 0 0 30.421334 25.984 35.626667 35.626667 0 0 0 35.242666-18.602667l2.816-5.290667C154.453333 807.893333 320.554667 940.8 512 940.8c180.949333 0.256 341.589333-118.4 398.208-294.186667a36.693333 36.693333 0 0 0-22.741333-46.122666 34.986667 34.986667 0 0 0-27.306667 2.048 36.266667 36.266667 0 0 0-17.877333 21.205333c-47.104 145.962667-179.882667 244.053333-330.282667 244.053333-191.744 0-347.776-159.573333-347.776-355.754666a36.352 36.352 0 0 0-26.88-35.370667 35.413333 35.413333 0 0 0-40.192 17.834667z"
        fill={getIconColor(color, 0, '#30A750')}
      />

      <path
        d="M523.861333 83.370667l-11.904-0.170667c-184.32-0.725333-347.221333 122.325333-400.810666 302.677333a36.693333 36.693333 0 0 0 23.978666 44.373334c18.346667 5.76 37.930667-4.266667 44.288-22.826667 44.501333-149.76 179.712-251.818667 332.629334-251.178667 191.701333 0 347.733333 159.658667 347.733333 355.84 0.128 16.64 11.221333 31.146667 27.008 35.2a35.498667 35.498667 0 0 0 40.149333-17.834666l52.224-98.56a37.162667 37.162667 0 0 0 2.858667-27.733334 36.138667 36.138667 0 0 0-17.322667-21.674666 34.986667 34.986667 0 0 0-27.178666-2.901334 35.84 35.84 0 0 0-21.248 17.578667l-2.944 5.504c-43.434667-182.016-203.434667-312.917333-389.461334-318.293333z"
        fill={getIconColor(color, 1, '#2F6BBA')}
      />

      <path
        d="M682.666667 594.517333c0-36.394667-10.581333-53.248-27.989334-66.432-8.32-6.314667-18.901333-12.672-40.064-23.210666-23.466667-11.605333-46.890667-22.144-71.082666-33.194667-32.512-14.250667-58.24-25.344-65.024-35.328-2.261333-3.712-3.797333-9.514667-3.797334-18.986667 0-17.408-0.725333-36.906667 33.28-36.906666 15.872 0 34.048 1.578667 37.802667 14.762666 1.536 5.248 1.536 6.826667 1.536 15.274667v27.434667h122.453333c0.768-28.501333 3.84-69.12-23.424-92.8-24.96-22.698667-66.56-25.344-103.594666-27.434667V256h-62.72v61.696c-37.077333 2.133333-77.141333 6.314667-102.826667 27.946667-25.728 21.077333-28.757333 49.578667-28.757333 75.946666 0 37.973333 10.581333 54.272 28.714666 68.010667 15.872 12.117333 41.6 24.234667 46.122667 26.368 21.930667 10.538667 43.861333 21.589333 66.56 32.170667 31.744 15.274667 52.906667 24.234667 59.733333 36.352 3.754667 6.826667 3.754667 17.408 3.754667 18.986666 0 13.226667 2.304 32.682667-43.093333 32.682667-26.453333 0-35.541333-8.405333-38.528-16.853333-3.029333-6.826667-2.304-14.762667-2.304-16.896v-26.325334H341.632c-1.493333 53.76 1.536 78.037333 31.786667 98.56 28.714667 18.986667 65.024 22.186667 77.866666 23.210667 9.088 1.066667 18.901333 2.133333 28.714667 2.645333V768h62.762667v-67.498667c38.570667-3.157333 73.386667-6.826667 102.826666-25.813333 33.28-21.12 37.077333-50.645333 37.077334-80.170667z"
        fill={getIconColor(color, 2, '#306BB9')}
      />
    </svg>
  );
};

export default Huishuai1;
