/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconEyeDisable: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M93.408 840.096l118.592-118.624c-77.376-65.344-125.408-145.344-145.824-197.888l-4.992-12.8 5.888-12.48c75.84-160.096 186.432-237.408 279.168-274.048 46.048-18.24 87.36-26.272 117.28-29.76 15.008-1.792 27.2-2.4 35.84-2.56a248.224 248.224 0 0 1 12.384 0c44.48-2.368 112.544 8 186.112 43.712l131.52-131.52 45.28 45.216-249.504 249.536a160 160 0 0 1-226.272 226.272l-260.224 260.192-45.248-45.248z m269.6-269.632a160 160 0 0 1 207.456-207.456l79.04-79.04c-56.224-23.68-105.6-29.888-135.52-28.032l-1.92 0.128-1.856-0.128h-0.288l-1.696-0.064a312.16 312.16 0 0 0-37.248 2.144c-25.536 3.008-61.28 9.92-101.216 25.728-76.896 30.4-170.88 94.08-238.656 229.28 20.704 46.592 62.624 110.72 126.4 163.008l105.504-105.568z m81.12 9.408a96 96 0 0 0 135.776-135.776l-135.776 135.776z m73.216-163.712a96 96 0 0 0-101.216 101.216l101.216-101.216z"
        fill={getIconColor(color, 0, '#8997B2')}
      />

      <path
        d="M322.688 791.808c54.4 24.64 117.44 40.192 189.312 40.192 261.888 0 406.08-206.176 445.824-308.416l5.28-13.568-6.784-12.928c-43.456-82.56-95.424-144.32-149.088-189.824l-45.44 45.44c46.176 38.304 91.712 90.56 130.784 161.056C850.976 606.656 725.856 768 512 768c-51.936 0-98.656-9.504-140.224-25.28l-49.088 49.088z"
        fill={getIconColor(color, 1, '#8997B2')}
      />
    </svg>
  );
};

IconEyeDisable.defaultProps = {
  size: 18,
};

export default IconEyeDisable;
