/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconCaozuorizhi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M492.676 378.684c21.451-8.895 40.756-21.921 56.921-38.087C581.357 308.838 601 264.963 601 216.5 601 119.574 522.426 41 425.5 41S250 119.574 250 216.5c0 48.463 19.644 92.338 51.403 124.097 16.165 16.166 35.47 29.192 56.92 38.087V228.636c0-62.926 134.353-62.926 134.353 0v150.048z"
        fill={getIconColor(color, 0, '#A5E8D0')}
      />

      <path
        d="M813.641 414.808h-2.342c-17.306 0-31.51 4.965-45.19 13.42-11.97-34.873-42.422-59.993-81.64-59.993-17.308 0-33.838 4.965-47.516 13.42-11.973-34.87-42.437-59.993-81.659-59.993-15.297 0-29.512 3.828-42.095 10.552V242.49c0-48.871-37.011-88.489-86.297-88.489-49.289 0-89.242 39.618-89.242 88.49v343.532l-54.217-53.927c-34.852-34.557-96.536-29.636-126.209 0-29.674 29.638-49.135 89.29-6.643 131.73L399.82 911.114c5.141 5.097 10.794 9.35 16.739 12.944C461.999 961.082 514.068 983 624.175 983 875.706 983 899 847.466 899 680.276v-176.98c0.001-48.87-36.07-88.488-85.359-88.488zM624.173 936.423c-96.238 0-154.015-21.432-197.785-64.831L190.446 637.476c-20.874-20.849-15.624-47.506 1.465-64.574 17.087-17.068 48.453-17.658 64.96-1.29A549600421.066 549600421.066 0 0 0 384.63 698.458V251.804c0-23.15 18.927-41.916 42.274-41.916 23.345 0 39.327 18.767 39.327 41.916v284.094c0.165 1.505 0 3.061 0 4.657 0 12.859 10.514 23.287 23.484 23.287 12.97 0 23.484-10.426 23.484-23.287 0-1.596-0.164-3.152-0.474-4.657l0.474-116.433c0-23.15 16.89-41.916 40.238-41.916 0 0 41.362-0.559 41.362 41.916v153.689c0.166 1.507 0 3.063 0 4.658 0 12.86 10.515 23.287 23.484 23.287 12.97 0 23.485-10.425 23.485-23.287 0-1.595-0.164-3.152-0.474-4.658l0.474-107.115c0-23.15 16.62-41.918 39.968-41.918 0 0 42.223 2.626 42.223 41.918V601.1c0.165 1.505 0 3.063 0 4.658 0 12.86 10.514 23.286 23.483 23.286 12.971 0 22.896-10.424 22.896-23.286 0-1.595-0.155-3.153-0.447-4.658l0.447-92.213c0-23.148 17.416-41.916 40.762-41.916 0 0 40.928-1.654 40.928 41.916v171.386c0 141.47-0.676 256.15-227.854 256.15z"
        fill={getIconColor(color, 1, '#4CA7FF')}
      />
    </svg>
  );
};

IconCaozuorizhi.defaultProps = {
  size: 18,
};

export default IconCaozuorizhi;
