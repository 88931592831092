/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconYingshoukuanbaobiao: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 282.819048l-229.180952-4.876191-4.876191 92.647619h229.180953z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M185.295238 121.904762l409.6 4.87619-4.87619 243.809524 253.561904 4.876191v195.047619h92.647619V282.819048L682.666667 34.133333 92.647619 29.257143v965.485714h711.92381v-92.647619H185.295238v-780.190476z m497.371429 39.009524l112.152381 121.904762-112.152381-4.876191V160.914286z"
        fill={getIconColor(color, 1, '#333333')}
      />

      <path
        d="M658.285714 482.742857l-375.466666-4.87619v97.523809h375.466666zM512 770.438095V682.666667l-229.180952-4.876191v92.647619zM731.428571 682.666667l39.009524-43.885715-53.638095-53.638095-146.285714 146.285714 146.285714 146.285715 53.638095-53.638096-39.009524-43.885714h199.92381v-97.523809z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconYingshoukuanbaobiao.defaultProps = {
  size: 18,
};

export default IconYingshoukuanbaobiao;
