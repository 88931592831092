/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const PingzhengguanliXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M170.709333 128h42.666667c45.013333 0 81.92 34.816 85.205333 78.933333l0.213334 6.4v597.333334a85.376 85.376 0 0 1-78.976 85.077333l-6.4 0.256h-42.666667a85.418667 85.418667 0 0 1-85.205333-78.933333L85.333333 810.666667V213.333333c0-44.970667 34.816-81.834667 78.976-85.12L170.709333 128h42.666667-42.666667z m256.128 0h42.666667c45.013333 0 81.92 34.816 85.205333 78.933333l0.213334 6.4v597.333334a85.376 85.376 0 0 1-78.976 85.077333l-6.4 0.256h-42.666667a85.418667 85.418667 0 0 1-85.205333-78.933333L341.461333 810.666667V213.333333c0-44.970667 34.858667-81.834667 78.976-85.12l6.4-0.213333h42.666667-42.666667z m297.728 85.333333c35.84 0 68.650667 22.656 80.682667 57.429334l2.005333 6.698666 128.725334 501.12a85.333333 85.333333 0 0 1-55.338667 102.058667l-6.144 1.834667-41.386667 10.581333a85.461333 85.461333 0 0 1-101.888-54.784l-2.005333-6.613333-128.768-501.12a85.333333 85.333333 0 0 1 55.466667-102.058667l6.058666-1.834667 41.344-10.581333c7.082667-1.834667 14.250667-2.730667 21.248-2.730667z m-511.146666-21.333333h-42.666667a21.376 21.376 0 0 0-21.034667 17.493333L149.333333 213.333333v597.333334c0 10.410667 7.552 19.2 17.493334 20.992l3.84 0.341333h42.666666c10.496 0 19.2-7.594667 21.034667-17.493333l0.341333-3.84V213.333333a21.376 21.376 0 0 0-17.493333-20.992l-3.84-0.341333z m256.128 0h-42.666667a21.376 21.376 0 0 0-21.034667 17.493333L405.504 213.333333v597.333334c0 10.410667 7.552 19.2 17.493333 20.992l3.84 0.341333h42.666667c10.496 0 19.242667-7.594667 21.034667-17.493333l0.341333-3.84V213.333333a21.376 21.376 0 0 0-17.493333-20.992l-3.84-0.341333z m255.061333 85.333333l-2.688 0.170667-2.688 0.554667-41.344 10.538666a21.248 21.248 0 0 0-16.042667 21.76l0.64 4.266667 128.768 501.12a21.333333 21.333333 0 0 0 20.608 16l2.688-0.128 2.645334-0.512 41.386666-10.666667a21.333333 21.333333 0 0 0 16-22.144l-0.64-3.754666-128.725333-501.205334a21.376 21.376 0 0 0-20.608-16z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default PingzhengguanliXianxing;
