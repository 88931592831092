/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const ALtr16Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M896 736V384H128v352A160 160 0 0 0 288 896h448a160 160 0 0 0 160-160zM383.808 527.872a47.872 47.872 0 1 1-95.808 0 47.872 47.872 0 0 1 95.808 0z m0 160a47.872 47.872 0 1 1-95.808 0 47.872 47.872 0 0 1 95.808 0z m176.128-160a47.872 47.872 0 1 1-95.808 0 47.872 47.872 0 0 1 95.808 0z m0 160a47.872 47.872 0 1 1-95.808 0 47.872 47.872 0 0 1 95.808 0z m175.872-160a47.872 47.872 0 1 1-95.808 0 47.872 47.872 0 0 1 95.808 0zM896 288A160 160 0 0 0 736 128h-448A160 160 0 0 0 128 288V320h768v-32z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default ALtr16Filled;
