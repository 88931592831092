/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentWhiteboard24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M682.197333 170.666667l-130.858666 130.688a149.333333 149.333333 0 0 0-33.194667 50.346666l-3.413333 9.557334-31.573334 97.92a96 96 0 0 0 112.341334 123.093333l6.656-1.706667 99.328-29.866666a149.418667 149.418667 0 0 0 54.826666-30.122667l7.722667-7.210667L938.666667 339.029333v375.850667a138.666667 138.666667 0 0 1-138.666667 138.666667H224A138.666667 138.666667 0 0 1 85.333333 714.88v-179.2l3.242667-1.322667 3.114667-1.749333 160.426666-100.821333 4.181334-2.218667a32 32 0 0 1 43.093333 39.808l-1.962667 4.437333-51.541333 97.536-2.986667 6.186667a96 96 0 0 0 127.701334 125.994667l7.253333-3.669334 75.306667-42.496 3.626666-2.432a32 32 0 0 0-31.36-55.125333l-3.925333 1.92-75.050667 42.368-4.181333 1.962667a32 32 0 0 1-41.557333-40.832l1.749333-3.968 51.541333-97.536 3.413334-7.082667a96 96 0 0 0-133.162667-122.624l-6.229333 3.584L85.333333 461.013333V309.333333a138.666667 138.666667 0 0 1 130.816-138.453333L224 170.666667h458.24z m221.312-19.413334l5.546667 5.12 5.12 5.546667a96.810667 96.810667 0 0 1-5.162667 131.413333l-182.656 182.314667a96 96 0 0 1-40.234666 23.978667l-99.285334 29.866666a42.666667 42.666667 0 0 1-52.906666-53.973333l31.573333-97.92c4.650667-14.506667 12.714667-27.733333 23.509333-38.528l183.04-182.741333a96.853333 96.853333 0 0 1 131.413334-5.12z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentWhiteboard24Filled;
