/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconGongsi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M0 102.4v921.6h665.6V102.4l-153.6-102.4z m384 808.96h-307.2V819.2l307.2-5.12z m0-204.8l-307.2 5.12v-92.16l307.2-20.48z m0-220.16l-307.2 20.48V414.72l307.2-35.84z m0-225.28l-307.2 40.96V204.8l307.2-61.44zM921.6 921.6V102.4l-153.6-102.4h-76.8L768 51.2v972.8h256v-102.4h-102.4z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconGongsi.defaultProps = {
  size: 18,
};

export default IconGongsi;
