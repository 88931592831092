/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFeiyongbaoxiao: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M620.48 571.968s50.688 3.904 49.984 49.6c-0.608 28.864-18.816 54.912-49.28 61.44-27.264 4.576-91.648 9.792-137.696 13.024-16.96 1.376 0 12.416 0 12.416l44.096 31.52c10.56 7.552 23.36 11.648 36.416 11.648h169.216c13.312 0 26.24-4.224 36.896-12 42.144-30.464 83.616-61.76 124.48-93.824 90.24-65.28 124.64 0 124.64 0s11.104 23.52-0.608 36.608c-31.84 29.024-106.4 96.416-223.68 202.048a62.08 62.08 0 0 1-22.944 13.024l-4.96 1.312-6.08 1.216c-7.36 1.184-14.816 1.76-22.24 1.76l-96.512-0.128-25.184-0.032-35.84-0.032h-22.592l-10.88-0.032h-40.992l-9.6-0.032h-106.624l-5.632 0.032-15.36 0.032-13.024 0.032h-7.36l-20.128 0.16c-24.192 0-42.048 13.824-46.4 17.504l-0.992 0.896-70.08 61.376c-28.64 23.52-44.256 0-44.256 0L6.208 805.152c0.64 0-14.304-16.96 0-28.096 8.864-7.2 76.48-68.32 202.912-183.392 6.72-6.144 14.272-11.36 22.432-15.456 8.128-4.096 17.088-6.24 26.24-6.24H620.48zM544 0c194.4 0 352 157.6 352 352 0 104.32-45.376 198.016-117.44 262.464 1.92-9.696 2.976-18.848 3.136-26.88 1.024-67.296-69.984-75.104-74.72-75.552L576.416 512l0.064-0.64v-58.976h95.072c17.92 0 32.448-14.624 32.448-32.64 0-16.864-12.64-30.72-28.896-32.48l-3.52-0.192v-0.064h-95.104v-39.904h95.072c17.92 0 32.448-14.624 32.448-32.64 0-16.832-12.64-30.72-28.896-32.48l-3.52-0.192H606.4l85.12-135.648c9.6-15.232 5.024-35.456-10.112-45.12a32.352 32.352 0 0 0-42.56 7.104l-2.24 3.104-93.12 148.608-92.096-146.912c-9.6-15.264-29.632-19.84-44.8-10.208-14.08 8.96-18.976 27.008-11.968 41.76l1.824 3.36 84.032 134.08h-64.032c-11.584 0-22.304 6.208-28.096 16.32a32.864 32.864 0 0 0 0 32.64c5.12 8.992 14.176 14.912 24.256 16.128l3.84 0.224h95.072v39.904h-95.072c-17.92 0-32.448 14.624-32.448 32.64 0 16.832 12.64 30.72 28.896 32.48l3.52 0.192h95.168v59.584H230.4C205.856 464 192 409.6 192 352 192 157.6 349.6 0 544 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconFeiyongbaoxiao.defaultProps = {
  size: 18,
};

export default IconFeiyongbaoxiao;
