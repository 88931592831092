/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPeopleSwap24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M637.312 661.333333A85.376 85.376 0 0 0 554.666667 597.333333l-384-0.042666-6.4 0.213333A85.376 85.376 0 0 0 85.333333 682.666667v64l0.213334 9.813333C92.373333 897.877333 246.528 938.666667 362.666667 938.666667c36.48 0 76.714667-4.010667 114.944-13.909334l-29.056-29.098666a74.666667 74.666667 0 0 1-17.109334-26.538667 414.634667 414.634667 0 0 1-55.253333 5.333333L362.666667 874.666667l-13.525334-0.213334c-53.888-1.664-104.106667-13.141333-139.349333-32.128C168.533333 820.138667 149.333333 789.717333 149.333333 746.666667v-64.042667l0.426667-4.309333a21.589333 21.589333 0 0 1 5.802667-10.794667 21.76 21.76 0 0 1 10.794666-5.802667l4.309334-0.426666 466.645333 0.042666z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M630.613333 926.848a32 32 0 0 1-45.226666 45.226667l-106.666667-106.581334a32 32 0 0 1 0-45.269333l106.666667-106.709333a32 32 0 1 1 45.226666 45.226666l-52.053333 52.053334h293.461333l-52.010666-52.053334a32 32 0 1 1 45.269333-45.226666l106.666667 106.752a32 32 0 0 1 0 45.226666l-106.666667 106.624a32 32 0 1 1-45.226667-45.269333l52.053334-52.053333h-293.546667l52.053333 52.053333z"
        fill={getIconColor(color, 1, '#333333')}
      />

      <path
        d="M938.666667 725.333333v1.28l-43.178667-43.264a74.325333 74.325333 0 0 0-30.293333-18.432 21.205333 21.205333 0 0 0-8.021334-3.242666L853.333333 661.333333h-172.842666a126.165333 126.165333 0 0 0-25.386667-57.173333L649.386667 597.333333H853.333333a85.333333 85.333333 0 0 1 85.077334 78.933334L938.666667 682.666667v42.666666z m-384-405.333333a192 192 0 1 0-384 0 192 192 0 0 0 384 0z m-320 0c0-70.570667 57.429333-128 128-128s128 57.429333 128 128-57.429333 128-128 128-128-57.429333-128-128z m661.333333 42.666667a149.333333 149.333333 0 1 0-298.666667 0 149.333333 149.333333 0 0 0 298.666667 0z m-234.666667 0c0-47.061333 38.272-85.333333 85.333334-85.333334s85.333333 38.272 85.333333 85.333334-38.272 85.333333-85.333333 85.333333-85.333333-38.272-85.333334-85.333333z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

export default IcFluentPeopleSwap24Regular;
