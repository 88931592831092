/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const A100Xianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M182.857143 73.142857a36.571429 36.571429 0 0 1 36.571428 36.571429v804.571428a36.571429 36.571429 0 0 1-59.172571 28.745143C152.210286 921.892571 146.285714 860.233143 146.285714 787.456V163.401143c0-22.674286 0.585143-44.324571 1.609143-64A36.352 36.352 0 0 1 182.857143 73.142857z"
        fill={getIconColor(color, 0, '#999999')}
      />

      <path
        d="M883.2 73.142857C960.950857 73.142857 1024 140.653714 1024 224.036571v575.926858a156.525714 156.525714 0 0 1-41.252571 106.715428c-26.331429 28.306286-62.171429 44.178286-99.547429 44.178286H140.8C63.049143 950.857143 0 883.346286 0 800.036571V223.963429C0 140.653714 63.049143 73.142857 140.8 73.142857z m-16.969143 795.428572c44.690286 0 80.969143-30.72 80.969143-68.608V224.109714c0-37.888-36.278857-68.608-80.969143-68.608H140.8c-35.328 0-64 30.72-64 68.534857v576.073143c0 37.888 28.672 68.608 64 68.534857z"
        fill={getIconColor(color, 1, '#999999')}
      />
    </svg>
  );
};

export default A100Xianxing;
