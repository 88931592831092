/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPositionToFront24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M42.666667 160A117.333333 117.333333 0 0 1 160 42.666667h149.333333A117.333333 117.333333 0 0 1 426.666667 160V213.333333h-32c-10.922667 0-21.632 0.981333-32 2.816V160c0-29.44-23.893333-53.333333-53.333334-53.333333h-149.333333c-29.44 0-53.333333 23.893333-53.333333 53.333333v149.333333c0 29.44 23.893333 53.333333 53.333333 53.333334h56.149333A182.442667 182.442667 0 0 0 213.333333 394.666667V426.666667H160A117.333333 117.333333 0 0 1 42.666667 309.333333v-149.333333zM597.333333 810.666667v53.333333A117.333333 117.333333 0 0 0 714.666667 981.333333h149.333333A117.333333 117.333333 0 0 0 981.333333 864v-149.333333A117.333333 117.333333 0 0 0 864 597.333333H810.666667v32c0 10.922667-0.981333 21.632-2.816 32h56.149333c29.44 0 53.333333 23.893333 53.333333 53.333334v149.333333c0 29.44-23.893333 53.333333-53.333333 53.333333h-149.333333c-29.44 0-53.333333-23.893333-53.333334-53.333333v-56.149333a182.229333 182.229333 0 0 1-32 2.816H597.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M256 394.666667A138.666667 138.666667 0 0 1 394.666667 256h234.666666A138.666667 138.666667 0 0 1 768 394.666667v234.666666A138.666667 138.666667 0 0 1 629.333333 768h-234.666666A138.666667 138.666667 0 0 1 256 629.333333v-234.666666z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

export default IcFluentPositionToFront24Filled;
