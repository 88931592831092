/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Zhangdanliebiao: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M809.026783 22.26087a30.586435 30.586435 0 0 1 0 61.217391H162.281739c-32.50087 0-58.902261 27.40313-58.902261 61.217391s26.401391 61.217391 58.902261 61.217391h646.722783a30.47513 30.47513 0 0 1 26.646261 15.515827 31.098435 31.098435 0 0 1 3.940174 15.092869v673.391304c0 50.710261-39.557565 91.826087-88.353392 91.826087H132.85287C84.057043 1001.73913 44.521739 960.623304 44.521739 909.913043V114.086957C44.521739 63.376696 84.057043 22.26087 132.85287 22.26087h676.173913z m167.913739 748.544c25.978435 0 47.059478 21.103304 47.059478 47.104v71.412869a47.081739 47.081739 0 0 1-47.059478 47.104h-86.77287a11.642435 11.642435 0 0 1-8.303304-3.428174 11.887304 11.887304 0 0 1-3.472696-8.347826v-142.068869c0-3.116522 1.26887-6.144 3.472696-8.347827 2.203826-2.226087 5.186783-3.450435 8.303304-3.428173z m-544.545392 0.890434h-155.15826a46.525217 46.525217 0 0 0 0 93.094957h155.15826a46.525217 46.525217 0 1 0 0-93.072696z m544.545392-252.616347c26.000696 0 47.059478 21.103304 47.059478 47.12626v71.390609a47.081739 47.081739 0 0 1-47.059478 47.104h-86.77287a11.776 11.776 0 0 1-11.776-11.776v-142.046609c0-3.161043 1.26887-6.166261 3.472696-8.370087 2.226087-2.203826 5.186783-3.428174 8.303304-3.428173h86.77287zM618.585043 554.518261H277.25913a46.525217 46.525217 0 0 0 0 93.094956h341.325913a46.525217 46.525217 0 0 0 0-93.094956z m358.355479-287.165218c25.978435 0 47.059478 21.125565 47.059478 47.126261v71.41287a47.081739 47.081739 0 0 1-47.059478 47.104h-86.77287a11.642435 11.642435 0 0 1-8.303304-3.428174 11.887304 11.887304 0 0 1-3.472696-8.347826v-142.06887c0-3.116522 1.26887-6.144 3.472696-8.347826 2.203826-2.226087 5.186783-3.450435 8.303304-3.428174z m-358.355479 69.965914H277.25913a46.525217 46.525217 0 1 0 0 93.072695h341.325913a46.525217 46.525217 0 0 0 0-93.072695z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Zhangdanliebiao;
