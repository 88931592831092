/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconRizhishenpi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M810.674087 0c80.606609 0 146.521043 66.582261 149.23687 149.481739l0.089043 5.320348 0.022261 458.351304A258.092522 258.092522 0 0 0 773.787826 534.26087c-142.469565 0-257.936696 114.643478-257.936696 256 0 104.158609 62.664348 193.758609 152.598261 233.73913L213.325913 1024c-80.62887 0-146.521043-66.604522-149.23687-149.481739l-0.089043-5.320348V154.802087C64 71.234783 128.222609 2.893913 208.205913 0.089043L213.325913 0h597.348174zM275.478261 96.01113h-6.967652a44.521739 44.521739 0 0 0-44.388174 41.182609l-0.111305 3.339131v742.93426a44.521739 44.521739 0 0 0 41.182609 44.410435l3.339131 0.111305h6.945391a44.521739 44.521739 0 0 0 44.410435-41.182609l0.111304-3.339131V140.53287a44.521739 44.521739 0 0 0-44.521739-44.52174z m490.073043 0h-189.551304v192l96.01113-64.556521 93.540174 62.909217V95.98887z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M679.245913 710.210783c0-51.244522 44.855652-92.16 97.769739-86.372174 40.915478 4.518957 73.572174 37.531826 77.378783 78.135652a86.550261 86.550261 0 0 1-34.726957 77.779478c-5.832348 23.329391 6.166261 34.637913 17.942261 40.047304h40.670609c19.433739 0 35.172174 15.582609 35.172174 34.79374v53.871304c0 19.233391-15.738435 34.838261-35.172174 34.838261H658.476522c-19.389217 0-35.149913-15.582609-35.149913-34.793739v-53.871305c0-19.21113 15.716174-34.816 35.149913-34.816h34.415304c20.079304-9.060174 23.707826-25.577739 22.572522-39.290434a86.550261 86.550261 0 0 1-36.196174-70.322087z m160.411826 152.442434h-142.580869c-14.981565 0-27.113739 12.02087-27.11374 26.846609 0 14.848 12.132174 26.846609 27.11374 26.846609h142.580869c14.981565 0 27.113739-12.02087 27.113739-26.846609 0-14.825739-12.132174-26.846609-27.113739-26.846609z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconRizhishenpi.defaultProps = {
  size: 18,
};

export default IconRizhishenpi;
