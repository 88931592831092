/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShare: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M831.99999969 510.66666636a30 30 0 0 1 60 0v250.00000031c0 60.75-49.24999969 109.99999969-109.99999969 109.99999969H242c-60.75 0-109.99999969-49.24999969-109.99999969-109.99999969V250.66666667c0-60.75 49.24999969-109.99999969 109.99999969-109.99999969h259.99999969a30 30 0 0 1 0 60H242a49.99999969 49.99999969 0 0 0-49.99999969 49.99999969v510a49.99999969 49.99999969 0 0 0 49.99999969 49.99999969h540a49.99999969 49.99999969 0 0 0 49.99999969-49.99999969V510.66666636z m-39.42999938-259.99999969l-84.18-89.44000031a30 30 0 0 1 43.69999969-41.11999969l131.76 139.99999969C901.86999969 279.24666636 888.29 310.66666667 861.99999969 310.66666667H711.41C634.83999969 310.66666667 572 377.42666698 572 460.66666667v180a30 30 0 1 1-60 0V460.66666667c0-115.59 88.86-210 199.41-210h81.16000031z"
        fill={getIconColor(color, 0, '#5d73fa')}
      />
    </svg>
  );
};

IconShare.defaultProps = {
  size: 18,
};

export default IconShare;
