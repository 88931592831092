/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Renliziyuan: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M579.33217223 95.72503666l308.99010332 205.97722112a48.54518557 48.54518557 0 0 1 0 80.77918777l-308.99010332 206.02576668a121.36296334 121.36296334 0 0 1-134.66434446 0L135.67772445 382.48144555a48.54518557 48.54518557 0 0 1 0-80.77918777l308.99010332-205.97722112a121.36296334 121.36296334 0 0 1 134.66434446 0z m322.29148444 425.59563889a97.18746112 97.18746112 0 0 1-28.01057222 29.03002112l-293.69837 198.25853554a121.36296334 121.36296334 0 0 1-135.8294289 0l-293.69837-198.25853554a97.09036999 97.09036999 0 0 1-37.81669888-111.16847445l332.0976111 221.36604445a121.36296334 121.36296334 0 0 0 126.84856889 4.75742776l7.76723001-4.75742776 332.09761223-221.36604445a96.89619001 96.89619001 0 0 1-9.75758223 82.13845333z m0 157.77185224a97.18746112 97.18746112 0 0 1-28.01057222 29.03001998l-293.69837 198.25853668a121.36296334 121.36296334 0 0 1-135.8294289 0l-293.69837-198.25853668a97.09036999 97.09036999 0 0 1-37.81669888-111.16847332l332.0976111 221.36604445a121.36296334 121.36296334 0 0 0 126.84856889 4.75742777l7.76723001-4.75742777 332.09761223-221.36604445a96.89619001 96.89619001 0 0 1-9.75758223 82.13845334z"
        fill={getIconColor(color, 0, '#707070')}
      />
    </svg>
  );
};

export default Renliziyuan;
