/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentMoney24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M874.666667 279.936c38.272 23.637333 64 67.754667 64 118.272v238.933333c0 57.344-21.333333 112.341333-59.349334 152.874667-38.016 40.533333-89.6 63.317333-143.317333 63.317333H298.666667c-45.738667 0-88.021333-26.026667-110.890667-68.266666H736c76.586667 0 138.666667-66.218667 138.666667-147.925334zM736 170.666667c41.216 0 74.666667 35.669333 74.666667 79.658666v386.816c0 43.989333-33.450667 79.658667-74.666667 79.658667H160c-41.216 0-74.666667-35.669333-74.666667-79.658667V250.325333C85.333333 206.378667 118.784 170.666667 160 170.666667h576z m-554.666667 341.333333h-42.666666v68.266667h42.666666c17.664 0 32 15.274667 32 34.133333v45.525333h64V614.4c0-56.533333-42.965333-102.4-96-102.4z m576 0h-42.666666c-53.034667 0-96 45.866667-96 102.4v45.525333H682.666667V614.4c0-18.858667 14.336-34.133333 32-34.133333h42.666666V512zM448 352.725333c-53.034667 0-96 45.824-96 102.4 0 56.533333 42.965333 102.4 96 102.4s96-45.866667 96-102.4c0-56.576-42.965333-102.4-96-102.4z m-170.666667-125.184H213.333333V273.066667c0 18.858667-14.336 34.133333-32 34.133333h-42.666666v68.266667h42.666666c25.472 0 49.877333-10.794667 67.882667-29.994667 18.005333-19.2 28.117333-45.226667 28.117333-72.405333V227.541333z m405.333334 0h-64V273.066667c0 56.533333 42.965333 102.4 96 102.4h42.666666V307.2h-42.666666c-17.664 0-32-15.274667-32-34.133333V227.541333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentMoney24Filled;
