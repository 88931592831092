/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconBack: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M671.5 480H428.7l90.3-90.3c12.5-12.5 12.5-32.8 0-45.3-6.2-6.2-14.4-9.4-22.6-9.4-8.2 0-16.4 3.1-22.6 9.4l-145 145-1.1 1.1c-0.2 0.2-0.3 0.4-0.5 0.6-0.2 0.2-0.4 0.4-0.5 0.6-0.2 0.2-0.4 0.5-0.6 0.7-0.1 0.2-0.3 0.3-0.4 0.5-0.2 0.3-0.4 0.6-0.6 0.8-0.1 0.1-0.2 0.3-0.3 0.4l-0.6 0.9c-0.1 0.1-0.2 0.3-0.3 0.4-0.2 0.3-0.3 0.6-0.5 0.9-0.1 0.2-0.2 0.3-0.3 0.5-0.1 0.3-0.3 0.6-0.4 0.8l-0.3 0.6c-0.1 0.3-0.2 0.5-0.3 0.8-0.1 0.2-0.2 0.5-0.3 0.7-0.1 0.2-0.2 0.5-0.3 0.7-0.1 0.3-0.2 0.5-0.3 0.8-0.1 0.2-0.1 0.4-0.2 0.6l-0.3 0.9c-0.1 0.2-0.1 0.4-0.2 0.5-0.1 0.3-0.2 0.6-0.3 1 0 0.2-0.1 0.3-0.1 0.5-0.1 0.3-0.2 0.7-0.2 1 0 0.2-0.1 0.3-0.1 0.5-0.1 0.3-0.1 0.7-0.2 1 0 0.2-0.1 0.4-0.1 0.6 0 0.3-0.1 0.6-0.1 0.9 0 0.3 0 0.5-0.1 0.8 0 0.3 0 0.5-0.1 0.8v3.2c0 0.3 0 0.5 0.1 0.8 0 0.3 0 0.5 0.1 0.8 0 0.3 0.1 0.6 0.1 0.9 0 0.2 0 0.4 0.1 0.6 0 0.3 0.1 0.7 0.2 1 0 0.2 0.1 0.3 0.1 0.5 0.1 0.3 0.1 0.7 0.2 1 0 0.2 0.1 0.3 0.1 0.5 0.1 0.3 0.2 0.6 0.3 1 0.1 0.2 0.1 0.4 0.2 0.5l0.3 0.9c0.1 0.2 0.1 0.4 0.2 0.6 0.1 0.3 0.2 0.5 0.3 0.8 0.1 0.2 0.2 0.5 0.3 0.7 0.1 0.2 0.2 0.5 0.3 0.7 0.1 0.3 0.2 0.5 0.3 0.8l0.3 0.6c0.1 0.3 0.3 0.6 0.4 0.8 0.1 0.2 0.2 0.3 0.3 0.5 0.2 0.3 0.3 0.6 0.5 0.9 0.1 0.1 0.2 0.3 0.3 0.4l0.6 0.9c0.1 0.1 0.2 0.3 0.3 0.4 0.2 0.3 0.4 0.6 0.6 0.8 0.1 0.2 0.3 0.3 0.4 0.5 0.2 0.2 0.4 0.5 0.6 0.7 0.2 0.2 0.4 0.4 0.5 0.6 0.2 0.2 0.3 0.4 0.5 0.6 0.4 0.4 0.7 0.8 1.1 1.1l144.9 144.9c6.2 6.2 14.4 9.4 22.6 9.4 8.2 0 16.4-3.1 22.6-9.4 12.5-12.5 12.5-32.8 0-45.3l-90.2-90h242.7c17.7 0 32-14.3 32-32 0.1-17.7-14.3-32-31.9-32z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M512 118.86523437C294.82226562 118.86523437 118.86523437 294.82226562 118.86523437 512S294.82226562 905.13476563 512 905.13476563 905.13476563 729.17773438 905.13476563 512 729.17773438 118.86523437 512 118.86523437z m238.27148438 631.40625c-30.9375 30.9375-66.97265625 55.28320313-107.13867188 72.24609375-41.484375 17.578125-85.60546875 26.45507813-131.1328125 26.45507813s-89.6484375-8.87695313-131.1328125-26.45507813c-40.078125-16.96289063-76.11328125-41.30859375-107.13867188-72.24609375-30.9375-30.9375-55.28320313-66.97265625-72.24609374-107.13867187-17.578125-41.484375-26.45507813-85.60546875-26.45507813-131.1328125s8.87695313-89.6484375 26.45507813-131.1328125c16.96289063-40.078125 41.30859375-76.11328125 72.24609375-107.13867188 30.9375-30.9375 66.97265625-55.28320313 107.13867187-72.24609375 41.484375-17.578125 85.60546875-26.45507813 131.1328125-26.45507812s89.6484375 8.87695313 131.1328125 26.45507812c40.078125 16.96289063 76.11328125 41.30859375 107.13867188 72.24609375 30.9375 30.9375 55.28320313 66.97265625 72.24609374 107.13867188 17.578125 41.484375 26.45507813 85.60546875 26.45507813 131.1328125s-8.87695313 89.6484375-26.45507813 131.1328125c-17.05078125 40.078125-41.30859375 76.11328125-72.24609375 107.13867187z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconBack.defaultProps = {
  size: 18,
};

export default IconBack;
