/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const BianmaguizeXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M730.154667 534.613333a33.578667 33.578667 0 0 1 27.52 42.752l-112.298667 379.904a33.834667 33.834667 0 0 1-56.746667 13.653334 33.450667 33.450667 0 0 1-7.936-32.597334l112.298667-379.946666a33.706667 33.706667 0 0 1 37.12-23.765334zM523.221333 42.666667c49.152 0 90.794667 35.114667 99.413334 82.602666l1.024 6.826667h79.232c26.794667 0 52.48 10.581333 71.424 29.44 18.944 18.858667 29.610667 44.416 29.610666 71.082667v333.482666a78.336 78.336 0 0 0-67.370666-75.648V232.618667a33.621333 33.621333 0 0 0-33.706667-33.493334h-95.573333a101.12 101.12 0 0 1-84.053334 44.672H366.037333a101.12 101.12 0 0 1-84.053333-44.672H186.453333a33.621333 33.621333 0 0 0-33.706666 33.493334v603.434666c0 18.517333 15.104 33.536 33.706666 33.536h241.578667l36.48 40.789334a78.506667 78.506667 0 0 0 58.24 26.24H186.368A100.821333 100.821333 0 0 1 85.333333 836.053333V232.618667a100.821333 100.821333 0 0 1 101.034667-100.565334h79.232A100.864 100.864 0 0 1 366.08 42.666667h157.184z m21.504 590.549333c13.482667 11.946667 15.104 32.426667 3.669334 46.293333l-69.845334 78.336 69.930667 78.293334c12.373333 13.866667 11.093333 35.072-2.858667 47.36a33.792 33.792 0 0 1-47.530666-2.816l-89.856-100.565334a33.408 33.408 0 0 1 0-44.544l89.856-100.565333a33.792 33.792 0 0 1 46.634666-1.792z m295.637334 1.792L930.133333 735.573333a33.408 33.408 0 0 1 0 44.544l-89.813333 100.565334a33.706667 33.706667 0 0 1-47.616 2.816v-0.042667a33.408 33.408 0 0 1-2.773333-47.317333l69.973333-78.293334-69.973333-78.336a33.408 33.408 0 0 1 2.816-47.317333 33.792 33.792 0 0 1 47.573333 2.816zM523.221333 109.696H366.037333a33.621333 33.621333 0 0 0-33.706666 33.536c0 18.517333 15.104 33.536 33.706666 33.536h157.184c18.602667 0 33.706667-15.018667 33.706667-33.536a33.621333 33.621333 0 0 0-33.706667-33.536z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default BianmaguizeXianxing;
