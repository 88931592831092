/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconQingjiashenpiliucheng: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1072 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M213.333333 384V298.666667h426.666667v85.333333H213.333333z m0 170.666667v-85.333334h213.333334v85.333334H213.333333zM128 170.666667v640h341.333333v85.333333H85.333333a42.666667 42.666667 0 0 1-42.666666-42.666667V128a42.666667 42.666667 0 0 1 42.666666-42.666667h682.666667a42.666667 42.666667 0 0 1 42.666667 42.666667v298.666667h-85.333334V170.666667H128z"
        fill={getIconColor(color, 0, '#1768E4')}
      />

      <path
        d="M903.125333 742.4L981.333333 708.266667a256.170667 256.170667 0 0 0-177.792-147.2 106.666667 106.666667 0 0 1 71.125334-27.733334c10.368 0 20.650667 1.493333 30.506666 4.394667l24.32-81.792A192.682667 192.682667 0 0 0 874.666667 448a193.28 193.28 0 0 0-160.981334 87.722667 232.96 232.96 0 0 0-189.781333-39.253334l18.816 83.2a148.266667 148.266667 0 0 1 83.797333 5.290667 256 256 0 0 0-130.730666 174.506667l83.626666 17.024a171.178667 171.178667 0 0 1 122.837334-130.56c14.506667 23.04 23.04 50.218667 23.04 79.36 0 57.173333-31.744 108.416-82.944 133.845333a42.666667 42.666667 0 0 0 35.285333 77.653333c0.853333-0.384 90.453333-35.754667 156.586667-2.645333l38.186666-76.330667a226.56 226.56 0 0 0-88.832-22.528 234.666667 234.666667 0 0 0 13.653334-187.52 170.581333 170.581333 0 0 1 105.898666 94.634667"
        fill={getIconColor(color, 1, '#8BB3F1')}
      />
    </svg>
  );
};

IconQingjiashenpiliucheng.defaultProps = {
  size: 18,
};

export default IconQingjiashenpiliucheng;
