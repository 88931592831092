/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTxt: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M147.2 0C102.4 0 65.6 35.2 65.6 80v862.4c0 44.8 35.2 81.6 81.6 81.6h731.2c44.8 0 81.6-36.8 81.6-81.6V324.8L657.6 0H147.2z"
        fill={getIconColor(color, 0, '#85BCFF')}
      />

      <path
        d="M960 324.8v16H755.2s-100.8-19.2-97.6-107.2c0 0 3.2 91.2 96 91.2H960z"
        fill={getIconColor(color, 1, '#529EE0')}
      />

      <path
        d="M657.6 0v232c0 27.2 17.6 92.8 97.6 92.8H960L657.6 0z"
        fill={getIconColor(color, 2, '#529EE0')}
      />

      <path
        d="M333.35371852 597.05362964h-64.07964444v196.12254816c0 7.76722963-5.82542222 13.59265185-15.53445926 13.59265184-7.76722963 0-13.59265185-5.82542222-13.59265185-13.59265184V597.05362964h-64.07964445c-5.82542222 0-11.65084445-3.88361482-11.65084444-11.65084444 0-7.76722963 5.82542222-13.59265185 11.65084444-13.59265184h157.2864c5.82542222 0 11.65084445 5.82542222 11.65084445 13.59265184 0 7.76722963-5.82542222 11.65084445-11.65084445 11.65084444z m227.19146666 209.7152c-5.82542222 0-9.70903703 0-11.65084443-3.8836148l-71.84687408-97.09037037-73.78868149 97.09037037c-1.94180741 3.88361482-5.82542222 3.88361482-9.70903703 3.8836148-7.76722963 0-13.59265185-5.82542222-13.59265185-13.59265184 0-1.94180741 0-5.82542222 1.9418074-7.76722963l75.73048889-99.03217778-69.90506667-95.14856296c-1.94180741-1.94180741-1.94180741-3.88361482-1.9418074-7.76722963 0-5.82542222 3.88361482-13.59265185 13.59265185-13.59265186 3.88361482 0 7.76722963 1.94180741 11.65084445 5.82542223l66.02145185 89.32314074 66.02145185-89.32314074c3.88361482-3.88361482 7.76722963-5.82542222 11.65084445-5.82542223 7.76722963 0 13.59265185 5.82542222 13.59265185 13.59265186 0 3.88361482 0 5.82542222-1.94180741 7.76722963l-69.90506666 93.20675555 75.73048888 100.97398519c0 1.94180741 1.94180741 5.82542222 1.9418074 7.76722963 0 7.76722963-5.82542222 13.59265185-13.59265185 13.59265184zM778.02761482 597.05362964h-64.07964445v196.12254816c0 7.76722963-5.82542222 13.59265185-15.53445926 13.59265184-7.76722963 0-13.59265185-5.82542222-13.59265186-13.59265184V597.05362964h-64.07964443c-5.82542222 0-11.65084445-3.88361482-11.65084445-11.65084444 0-7.76722963 5.82542222-13.59265185 11.65084445-13.59265184h157.2864c5.82542222 0 11.65084445 5.82542222 11.65084443 13.59265184 0 7.76722963-5.82542222 11.65084445-11.65084443 11.65084444z"
        fill={getIconColor(color, 3, '#FFFFFF')}
      />
    </svg>
  );
};

IconTxt.defaultProps = {
  size: 18,
};

export default IconTxt;
