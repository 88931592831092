/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconYunweiguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M943.616 272.2816l-49.664 49.9456a135.4752 135.4752 0 0 1-192 0 137.1648 137.1648 0 0 1 0-193.1264l49.664-49.92c27.392-27.6224 9.0624-60.16-40.32-52.4032a247.5776 247.5776 0 0 0-150.016 83.7888c-48.9472 48.3328-71.3472 134.9632-54.6816 215.0656L90.0096 744.6016a137.216 137.216 0 0 0 0 192.9984 135.3472 135.3472 0 0 0 192 0l416.64-418.9952a247.9104 247.9104 0 0 0 227.328-67.6608 248.7808 248.7808 0 0 0 71.7824-156.0576c5.5808-31.7184-26.7264-50.176-54.144-22.6048zM217.9584 873.2416c-8.1152 8.2688-19.5328 13.2864-32 13.2864a45.3376 45.3376 0 0 1-45.2096-45.4656 45.3888 45.3888 0 0 1 45.2096-45.5168c12.4672 0 23.8592 5.0944 32 13.3632 8.2944 8.1152 13.312 19.5328 13.312 32.1536s-5.0176 23.936-13.312 32.1792zM172.8256 280.5504l156.8 157.824 64-64.384L236.8 216.2176l-35.1488-67.584L89.6 68.1984 25.6 132.608l80 112.64L172.8 280.576z m524.8512 334.7456a22.3744 22.3744 0 0 0-31.9744 0l-96.0768 96.5888a22.7072 22.7072 0 0 0 0 32.1536l226.5088 227.7632a90.2144 90.2144 0 0 0 128 0 91.2384 91.2384 0 0 0 0-128.7168l-226.4576-227.7888z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconYunweiguanli.defaultProps = {
  size: 18,
};

export default IconYunweiguanli;
