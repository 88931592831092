/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Zhongdengde: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M964.923077 846.769231a39.384615 39.384615 0 0 1 39.384615 39.384615v39.384616a39.384615 39.384615 0 0 1-39.384615 39.384615h-708.923077a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384616a39.384615 39.384615 0 0 1 39.384615-39.384615h708.923077z m-866.461539 0a39.384615 39.384615 0 0 1 39.384616 39.384615v39.384616a39.384615 39.384615 0 0 1-39.384616 39.384615h-39.384615a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384616a39.384615 39.384615 0 0 1 39.384615-39.384615h39.384615z m866.461539-393.846154a39.384615 39.384615 0 0 1 39.384615 39.384615v39.384616a39.384615 39.384615 0 0 1-39.384615 39.384615h-708.923077a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384616a39.384615 39.384615 0 0 1 39.384615-39.384615h708.923077z m-866.461539 0a39.384615 39.384615 0 0 1 39.384616 39.384615v39.384616a39.384615 39.384615 0 0 1-39.384616 39.384615h-39.384615a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384616a39.384615 39.384615 0 0 1 39.384615-39.384615h39.384615z m866.461539-393.846154a39.384615 39.384615 0 0 1 39.384615 39.384615v39.384616a39.384615 39.384615 0 0 1-39.384615 39.384615h-708.923077a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384616a39.384615 39.384615 0 0 1 39.384615-39.384615h708.923077z m-866.461539 0a39.384615 39.384615 0 0 1 39.384616 39.384615v39.384616a39.384615 39.384615 0 0 1-39.384616 39.384615h-39.384615a39.384615 39.384615 0 0 1-39.384615-39.384615v-39.384616a39.384615 39.384615 0 0 1 39.384615-39.384615h39.384615z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Zhongdengde;
