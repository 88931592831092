/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconKaifazhezhongxin: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1152 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M0 1024 0 896 1152 896 1152 1024 0 1024ZM0 0 1152 0 1152 832 0 832 0 0ZM1024 704 1024 128 135.552 128 128 704 1024 704ZM248.256 402.752 384 267.008 429.248 312.256 293.504 448 429.248 583.744 384 628.992 248.256 493.248 248.256 493.248 203.008 448 248.256 402.752 248.256 402.752ZM680.576 180.16 532.672 670.336 471.36 651.84 619.328 161.664 680.576 180.16ZM755.648 257.024 908.096 400.256 908.096 400.256 958.912 448 908.096 495.744 908.096 495.744 755.648 638.976 704.832 591.232 857.28 448 704.832 304.768 755.648 257.024Z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconKaifazhezhongxin.defaultProps = {
  size: 18,
};

export default IconKaifazhezhongxin;
