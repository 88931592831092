/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFapiaoshenqing: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M809.248 0H214.816C131.52 0 64 69.92 64 156.192v747.2c0 43.936 45.952 71.488 82.752 49.6l128.352-76.384a53.76 53.76 0 0 1 64.32 6.88l136.288 125.984a53.76 53.76 0 0 0 73.44 0.064l137.6-126.784a53.76 53.76 0 0 1 64.544-6.656l125.632 75.936c36.8 22.272 83.072-5.248 83.072-49.408V156.192C960.064 69.984 892.544 0 809.248 0z m-158.4 548.768c19.52 0 35.296 16.352 35.296 36.544 0 20.192-15.776 36.576-35.296 36.576h-103.456v65.952c0 20.192-15.808 36.576-35.296 36.576-19.52 0-35.296-16.384-35.296-36.576v-65.92h-103.552c-19.488 0-35.296-16.32-35.296-36.544 0-20.16 15.808-36.544 35.296-36.544h103.488v-44.64h-103.488c-12.608 0-24.256-6.976-30.56-18.304a37.696 37.696 0 0 1 0-36.544c6.304-11.328 17.952-18.272 30.56-18.272h69.696l-91.424-150.08a37.408 37.408 0 0 1 11.04-50.464 34.656 34.656 0 0 1 48.736 11.392l100.16 164.416 101.376-166.272a34.656 34.656 0 0 1 48.736-11.424 37.44 37.44 0 0 1 11.04 50.464l-92.64 151.84h70.88c19.52 0 35.296 16.352 35.296 36.544 0 20.192-15.776 36.576-35.296 36.576h-103.456v44.64h103.456v0.064z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconFapiaoshenqing.defaultProps = {
  size: 18,
};

export default IconFapiaoshenqing;
