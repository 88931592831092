/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Youjianmoban: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M85.333333 213.333333a128 128 0 0 1 128-128h469.333334a128 128 0 0 1 128 128v213.333334h-384a170.666667 170.666667 0 0 0-170.666667 170.666666v213.333334c0 14.72 1.877333 29.013333 5.376 42.666666H213.333333a128 128 0 0 1-128-128V213.333333z m160-42.666666a32 32 0 0 0 0 64h405.333334a32 32 0 1 0 0-64h-405.333334z m85.333334 128a32 32 0 0 0 0 64h320a32 32 0 1 0 0-64h-320z m-8.618667 224.853333A127.829333 127.829333 0 0 1 426.666667 469.333333h384a128 128 0 0 1 104.618666 54.186667L618.666667 708.949333l-296.618667-185.344v-0.085333z m-22.784 61.269333C298.837333 588.928 298.666667 593.066667 298.666667 597.333333v213.333334a128 128 0 0 0 128 128h384a128 128 0 0 0 128-128v-213.333334c0-4.266667-0.213333-8.405333-0.597334-12.544l-302.421333 189.013334a32 32 0 0 1-33.962667 0l-302.421333-189.013334z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default Youjianmoban;
