/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconPpt: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M852.537678 966.322357c7.542461 0 14.818718-3.016984 20.142807-8.341075s8.341075-12.600347 8.341075-20.142807V262.033969c0-6.743847-2.395841-13.22149-6.743848-18.368111L725.469393 67.793414c-5.412825-6.388908-13.39896-10.115771-21.740034-10.115771h-532.231543c-7.542461 0-14.818718 3.016984-20.142807 8.341075s-8.341075 12.600347-8.341075 20.142807v851.67695c0 7.542461 3.016984 14.818718 8.341075 20.142807s12.600347 8.341075 20.142807 8.341075h681.039862z"
        fill={getIconColor(color, 0, '#ED6941')}
      />

      <path
        d="M881.02156 265.228423v-3.194454c0-6.743847-2.395841-13.22149-6.743848-18.368111L725.469393 67.793414c-5.412825-6.388908-13.39896-10.115771-21.740034-10.115771h-1.331023v207.55078h178.623224z"
        fill={getIconColor(color, 1, '#FFC2CB')}
      />

      <path
        d="M413.1715775 239.14115133h95.66724375c75.53552906 0 125.78162906 25.12305001 125.78162906 98.41247813 0 70.87694999-50.32928906 103.90294594-123.53552812 103.90294687h-55.40381344v133.1022525h-42.50953125v-335.4176775z m92.42287688 167.95840594c58.98093563 0 86.93240906-21.54592687 86.93240905-69.54592781 0-48.49913344-29.78162906-64.05545906-88.76256562-64.05545906h-48.08318906v133.60138687h49.91334562z"
        fill={getIconColor(color, 2, '#FFFFFF')}
      />

      <path
        d="M348.88981638 703.45436589h25.22457269l2.09115566 12.15484434h0.78418356c9.80229517-8.36462407 22.087837-15.16088253 34.63477388-15.16088253 29.0147915 0 46.13613364 23.78690163 46.13613364 59.85934659 0 40.25475625-24.17899414 63.64956685-49.92635392 63.64956683-10.32508371 0-20.12737887-4.57440382-29.14548899-12.80833186l0.78418355 19.34319384v35.68035391h-30.58316007v-162.71809112z m77.24208228 57.37609694c0-22.47992953-7.18834948-34.8961689-23.26411309-34.89616889-7.97253305 0-15.16088253 4.05161526-23.26411162 12.15484582v51.62541854c7.58044054 6.53486343 15.16088253 8.7567166 21.43435096 8.75671658 14.11530396 0.1306975 25.09387373-12.02414831 25.09387375-37.64081205zM484.0307856 703.45436589h25.22457122l2.09115713 12.15484434h0.78418355c9.93299118-8.36462407 22.087837-15.16088253 34.63477388-15.16088253 29.0147915 0 46.13613364 23.78690163 46.13613365 59.85934659 0 40.25475625-24.17899414 63.64956685-49.92635392 63.64956683-10.32508371 0-20.12737887-4.57440382-29.14548899-12.80833186l0.78418354 19.34319384v35.68035391h-30.71385756v-162.71809112z m77.24208226 57.37609694c0-22.47992953-7.18834948-34.8961689-23.26411307-34.89616889-7.97253305 0-15.16088253 4.05161526-23.26411161 12.15484582v51.62541854c7.58044054 6.53486343 15.16088253 8.7567166 21.43435096 8.75671658 14.24600147 0.1306975 25.09387373-12.02414831 25.09387372-37.64081205zM617.86478271 779.91226314v-52.01750959h-16.59855212v-22.87202054l18.03622173-1.43767109 3.52882526-31.49803967h25.87805876v31.36734364h29.01479147v24.30969019h-29.01479147v52.01750954c0 13.46181791 5.48928487 19.73528635 15.94506608 19.73528784 3.79022027 0 8.36462407-1.3069721 11.50135828-2.48324817l5.09719385 22.47992805c-6.27346843 2.09115566-14.50739648 4.44370631-24.83248018 4.44370778-27.9692144 0-38.55569166-17.51343321-38.55569166-44.04497798z"
        fill={getIconColor(color, 3, '#FFFFFF')}
      />
    </svg>
  );
};

IconPpt.defaultProps = {
  size: 18,
};

export default IconPpt;
