/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACustomersMyCustomersVisit: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M863.08864 0C940.6464 0 1003.52 62.52544 1003.52 139.63264v744.73472C1003.52 961.47456 940.6464 1024 863.08864 1024H254.54592c-68.54656 0-127.05792-49.17248-138.50624-116.36736H172.6464c57.20064 0 103.936-45.40416 105.2672-102.25664 1.37216-56.85248-43.17184-104.40704-100.31104-107.06944l-4.95616-0.12288H114.0736v-81.44896H172.6464c56.23808 0 102.54336-43.95008 105.20576-99.79904L277.9136 512c0-55.9104-44.17536-101.9904-100.352-104.61184l-4.95616-0.12288H114.0736V325.8368H172.6464c56.23808 0 102.54336-43.9296 105.20576-99.79904l0.1024-4.9152c0-55.93088-44.17536-101.9904-100.352-104.63232l-4.95616-0.1024H116.03968C127.488 49.19296 185.99936 0 254.5664 0h608.54272zM172.62592 768a35.0208 35.0208 0 0 1 35.10272 34.9184 35.0208 35.0208 0 0 1-35.10272 34.89792H55.58272A35.0208 35.0208 0 0 1 20.48 802.9184 35.0208 35.0208 0 0 1 55.58272 768H172.6464z m664.71936-486.4a70.43072 70.43072 0 0 0-99.328 0L401.65376 616.12032c-7.22944 7.20896-11.91936 16.5888-13.29152 26.66496l-9.48224 69.632c-0.98304 7.2704 1.536 14.56128 6.7584 19.70176 5.26336 5.14048 12.65664 7.53664 19.92704 6.4512l68.97664-10.3424a46.8992 46.8992 0 0 0 26.15296-13.1072l336.65024-334.7456a69.5296 69.5296 0 0 0 0-98.7136v-0.06144zM172.62592 477.0816A35.0208 35.0208 0 0 1 207.72864 512a35.0208 35.0208 0 0 1-35.10272 34.9184H55.58272A35.0208 35.0208 0 0 1 20.48 512a35.0208 35.0208 0 0 1 35.10272-34.9184H172.6464z m0-290.89792a35.0208 35.0208 0 0 1 35.10272 34.89792A35.0208 35.0208 0 0 1 172.62592 256H55.58272A35.0208 35.0208 0 0 1 20.48 221.0816a35.0208 35.0208 0 0 1 35.10272-34.89792H172.6464z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconACustomersMyCustomersVisit.defaultProps = {
  size: 18,
};

export default IconACustomersMyCustomersVisit;
