/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconGongneng: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M243.2 0a243.2 243.2 0 0 0 0 486.4h175.7952A67.4048 67.4048 0 0 0 486.4 418.9952V243.2A243.2 243.2 0 0 0 243.2 0z m0 81.0752A162.1248 162.1248 0 0 1 405.3248 243.2v145.2032a16.9472 16.9472 0 0 1-16.9216 16.9216H243.2a162.1248 162.1248 0 0 1 0-324.2496z"
        fill={getIconColor(color, 0, '#888888')}
      />

      <path
        d="M780.8 0a243.2 243.2 0 0 1 0 486.4h-175.7952A67.4048 67.4048 0 0 1 537.6 418.9952V243.2A243.2 243.2 0 0 1 780.8 0z m0 81.0752A162.1248 162.1248 0 0 0 618.6752 243.2v145.2032c0 9.344 7.5776 16.9216 16.9216 16.9216H780.8a162.1248 162.1248 0 0 0 0-324.2496z"
        fill={getIconColor(color, 1, '#CCCCCC')}
      />

      <path
        d="M418.9952 537.6a67.4048 67.4048 0 0 1 67.3024 63.4368l0.1024 3.968V780.8a243.2 243.2 0 1 1-486.4 0 243.2 243.2 0 0 1 236.6208-243.1232L243.2 537.6h175.7952z m-30.592 81.0752H243.2a162.1248 162.1248 0 1 0 162.048 166.9888l0.0768-4.864v-145.2032a16.9472 16.9472 0 0 0-14.7968-16.7936l-2.1248-0.128zM605.0048 537.6a67.4048 67.4048 0 0 0-67.3024 63.4368l-0.1024 3.968V780.8a243.2 243.2 0 1 0 486.4 0 243.2 243.2 0 0 0-236.6208-243.1232L780.8 537.6h-175.7952z m30.592 81.0752H780.8a162.1248 162.1248 0 1 1-162.048 166.9888l-0.0768-4.864v-145.2032c0-8.6272 6.4512-15.744 14.7968-16.7936l2.1248-0.128z"
        fill={getIconColor(color, 2, '#888888')}
      />
    </svg>
  );
};

IconGongneng.defaultProps = {
  size: 18,
};

export default IconGongneng;
