/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZiyuanguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M398.848 129.1264c33.8944-4.9664 64.0768 22.528 64.0768 56.704v349.2864a26.368 26.368 0 0 0 26.368 26.3168h349.696c34.1504 0 61.6704 30.1056 56.704 63.9488C863.232 844.288 672.0512 1004.4416 450.56 998.2208c-228.2752-6.2976-418.5088-196.4032-424.8064-424.448-6.2464-221.2096 154.0096-412.16 373.1456-444.6464z m184.3456-103.0912c216.2688 28.3136 386.4576 198.3488 414.7712 414.4128 4.2496 33.2032-22.8864 62.3104-56.3456 62.3104H546.9184a26.112 26.112 0 0 1-26.0864-26.112v-394.24c0-33.5104 29.056-60.6208 62.3616-56.3712z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconZiyuanguanli.defaultProps = {
  size: 18,
};

export default IconZiyuanguanli;
