/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconGeren: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M718.421333 593.664c81.28 31.146667 134.912 88.661333 134.912 175.658667v90.581333c0 43.52-35.285333 78.762667-78.762666 78.762667H249.429333A78.762667 78.762667 0 0 1 170.666667 859.904V769.28c0-87.04 53.632-144.512 134.912-175.658667a37.077333 37.077333 0 0 1 39.850666 8.746667c4.906667 5.034667 9.130667 9.088 12.714667 12.16A235.392 235.392 0 0 0 512 671.488a235.434667 235.434667 0 0 0 157.781333-60.373333c2.56-2.304 5.546667-5.205333 8.96-8.704a36.906667 36.906667 0 0 1 39.68-8.746667zM512 128a196.906667 196.906667 0 0 1 196.906667 196.906667V415.573333a196.906667 196.906667 0 0 1-393.813334 0V324.906667A196.906667 196.906667 0 0 1 512 128z"
        fill={getIconColor(color, 0, '#000000')}
      />
    </svg>
  );
};

IconGeren.defaultProps = {
  size: 18,
};

export default IconGeren;
