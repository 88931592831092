/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconRizhiguanli02: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M830.85 64.26H198.11a65.08 65.08 0 0 0-65 65v153.16H90.55a25 25 0 0 0 0 50h42.56v126.77H90.55a25 25 0 0 0 0 50h42.56V636H90.55a25 25 0 1 0 0 50h42.56v209.1a65.07 65.07 0 0 0 65 65h632.74a65.07 65.07 0 0 0 65-65V129.26a65.08 65.08 0 0 0-65-65z m-647.74 830.8V686h53.14a25 25 0 0 0 0-50h-53.14V509.19h53.14a25 25 0 1 0 0-50h-53.14V332.42h53.14a25 25 0 1 0 0-50h-53.14V129.26a15 15 0 0 1 15-15h116.17v795.8H198.11a15 15 0 0 1-15-15z m662.74 0a15 15 0 0 1-15 15H364.28v-795.8h466.57a15 15 0 0 1 15 15z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M511.31 539.49l-86.65 150.08 86.65 150.08h173.3l86.65-150.08-86.65-150.08z m144.43 250.16H540.18L482.4 689.57l57.78-100.08h115.56l57.79 100.08z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconRizhiguanli02.defaultProps = {
  size: 18,
};

export default IconRizhiguanli02;
