/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Building24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M288 85.333333A96 96 0 0 0 192 181.333333v704c0 17.664 14.336 32 32 32H320v-181.333333a32 32 0 0 1 32-32h320a32 32 0 0 1 32 32v181.333333h96a32 32 0 0 0 32-32v-384a96 96 0 0 0-96-96h-32V181.333333A96 96 0 0 0 608 85.333333h-320z m32 192a42.666667 42.666667 0 1 1 85.333333 0 42.666667 42.666667 0 0 1-85.333333 0z m42.666667 256a42.666667 42.666667 0 1 1 0 85.333334 42.666667 42.666667 0 0 1 0-85.333334z m-42.666667-106.666666a42.666667 42.666667 0 1 1 85.333333 0 42.666667 42.666667 0 0 1-85.333333 0zM512 234.666667a42.666667 42.666667 0 1 1 0 85.333333 42.666667 42.666667 0 0 1 0-85.333333z m-42.666667 341.333333a42.666667 42.666667 0 1 1 85.333334 0 42.666667 42.666667 0 0 1-85.333334 0z m192-42.666667a42.666667 42.666667 0 1 1 0 85.333334 42.666667 42.666667 0 0 1 0-85.333334zM469.333333 426.666667a42.666667 42.666667 0 1 1 85.333334 0 42.666667 42.666667 0 0 1-85.333334 0z"
        fill={getIconColor(color, 0, '#212121')}
      />

      <path
        d="M640 917.333333V768h-96v149.333333H640z m-160 0V768H384v149.333333h96z"
        fill={getIconColor(color, 1, '#212121')}
      />
    </svg>
  );
};

export default Building24Filled;
