/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const WodegongshiXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M891.648 712.405333l-0.042667 1.194667 1.493334 0.213333c9.344 1.877333 18.218667 5.546667 26.112 10.837334l5.76 4.266666 5.333333 4.821334c11.946667 11.946667 19.328 27.605333 20.992 44.245333l0.341333 7.253333v15.274667a112.64 112.64 0 0 1-27.136 73.386667c-44.330667 51.754667-111.36 77.482667-199.125333 77.482666s-154.794667-25.728-198.997333-77.568a112.810667 112.810667 0 0 1-26.666667-64.298666l-0.341333-8.96v-15.36a72.789333 72.789333 0 0 1 65.578666-72.448l7.210667-0.341334h319.488z m-12.8 65.578667l-306.730667 0.042667a7.168 7.168 0 0 0-6.912 5.290666l-0.256 1.877334v15.36c0 11.264 4.010667 22.144 11.349334 30.72 30.890667 36.181333 79.957333 54.528 149.077333 54.528 69.162667 0 118.272-18.346667 149.290667-54.613334 6.101333-7.082667 9.898667-15.829333 11.008-25.088l0.341333-5.546666v-15.36a7.168 7.168 0 0 0-7.168-7.210667zM714.666667 85.333333A138.666667 138.666667 0 0 1 853.333333 224V426.666667a160.597333 160.597333 0 0 0-64-50.688V320h-640v394.666667c0 41.216 33.450667 74.666667 74.666667 74.666666h246.613333c2.56 22.272 9.258667 43.861333 19.541334 64H224A138.666667 138.666667 0 0 1 85.333333 714.666667V224A138.666667 138.666667 0 0 1 224 85.333333h490.666667z m10.709333 307.2a146.133333 146.133333 0 1 1 0 292.266667 146.133333 146.133333 0 0 1 0-292.266667z m0 65.621334a80.512 80.512 0 1 0 0 161.024 80.512 80.512 0 0 0 0-161.024zM714.666667 149.333333H224A74.666667 74.666667 0 0 0 149.333333 224V256h640v-32a74.666667 74.666667 0 0 0-74.666666-74.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default WodegongshiXianxing;
