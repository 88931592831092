/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconDaohang: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M683.05408 73.19552c-47.64672-26.75712-80.65536-37.49888-113.14688-41.33888-46.56128-6.90688-88.05888-7.76704-126.94016 3.49184-229.84704 66.5344-319.24736 257.82272-206.9504 467.78368 54.62528 102.144 245.888 361.7024 277.66272 388.41344 23.48032-18.78528 232.74496-289.11616 296.10496-420.98688 78.91968-164.25984-10.7776-332.24704-126.73024-397.3632z m71.35232 370.75456c-45.52704 94.75072-172.72832 267.91936-240.60416 353.33632-65.18272-83.48672-184.02304-249.11872-223.60576-323.13344-44.4416-83.08736-53.0432-162.31936-24.87808-229.12512C294.51776 175.7696 363.6736 122.26048 460.0576 94.3616c14.34112-4.15232 30.09024-6.1696 48.1536-6.1696 15.32416 0 32.55808 1.45408 52.68992 4.43904l1.81248 0.24064c19.67104 2.32448 44.22656 8.0384 90.2656 33.8944 80.60416 45.27104 168.59136 177.38752 101.4272 317.184z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M512.55808 188.04224c-89.18528 0.65024-161.82272 73.83552-161.82272 163.05152 0.00512 91.20256 75.66336 165.13536 166.90176 163.10272 88.99584-1.98656 160.5632-76.40576 159.2576-165.62176-1.28512-89.10848-75.06944-161.1776-164.33664-160.53248z m3.70688 264.7296c-0.76288 0.01536-1.536 0.0256-2.304 0.0256-56.12032 0-101.78048-45.62432-101.7856-101.7088 0-55.62368 45.23008-101.20704 100.82816-101.61152h0.74752c55.29088 0 100.9152 44.85632 101.7088 99.98336 0.81408 55.73632-43.68384 102.07232-99.19488 103.31136zM770.45248 608.11264a3239.42912 3239.42912 0 0 1-35.68128 51.72736c12.0576 4.8384 23.57248 10.05056 34.304 15.70304 52.224 27.50464 83.4048 62.41792 83.4048 93.40416 0 30.98112-31.1808 65.8944-83.4048 93.39904C703.75936 896.74752 611.93216 916.48 517.13536 916.48s-186.624-19.73248-251.94496-54.12864c-52.224-27.50464-83.4048-62.41792-83.4048-93.39904s31.1808-65.89952 83.40992-93.40416a359.61856 359.61856 0 0 1 30.0544-13.97248 3924.16256 3924.16256 0 0 1-35.57888-51.56352c-85.22752 38.32832-139.32544 95.29344-139.32544 158.94528 0 115.40992 177.64864 208.96768 396.78976 208.96768s396.78464-93.55776 396.78464-208.96768c0-64.69632-55.82336-122.50624-143.46752-160.8448z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconDaohang.defaultProps = {
  size: 18,
};

export default IconDaohang;
