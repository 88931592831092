/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDualScreenSettings24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M566.357333 126.933333a85.333333 85.333333 0 0 1-61.482666 106.538667l-24.96 6.144a244.437333 244.437333 0 0 0 0.298666 77.098667l23.04 5.546666a85.333333 85.333333 0 0 1 61.866667 107.093334l-7.978667 26.965333c18.773333 16.469333 40.106667 29.824 63.36 39.296l21.034667-22.101333a85.333333 85.333333 0 0 1 123.733333 0l21.290667 22.4a225.408 225.408 0 0 0 63.274667-38.954667l-8.448-29.269333a85.333333 85.333333 0 0 1 61.482666-106.496l24.917334-6.144a244.48 244.48 0 0 0-0.256-77.141334l-23.04-5.546666a85.333333 85.333333 0 0 1-61.866667-107.093334l7.978667-26.88a225.365333 225.365333 0 0 0-63.36-39.381333l-21.034667 22.144a85.333333 85.333333 0 0 1-123.733333 0l-21.290667-22.442667c-23.210667 9.386667-44.544 22.613333-63.274667 38.954667l8.448 29.269333zM703.872 341.333333c-34.176 0-61.866667-28.672-61.866667-64 0-35.370667 27.690667-64 61.866667-64 34.133333 0 61.866667 28.629333 61.866667 64 0 35.328-27.733333 64-61.866667 64z m0 213.333334A277.205333 277.205333 0 0 0 938.666667 425.088v438.869333a74.666667 74.666667 0 0 1-74.666667 74.666667H554.666667a75.946667 75.946667 0 0 1-10.88-0.810667V503.893333A276.181333 276.181333 0 0 0 703.872 554.666667z m-10.88 192h-64l-4.352 0.298666a32 32 0 0 0 0 63.402667l4.352 0.298667h64l4.352-0.298667a32 32 0 0 0 0-63.402667l-4.352-0.298666z m-266.538667-469.333334c0-7.168 0.298667-14.250667 0.810667-21.290666H160A74.666667 74.666667 0 0 0 85.333333 330.709333v533.12c0 41.258667 33.450667 74.666667 74.666667 74.666667H469.333333a76.8 76.8 0 0 0 10.453334-0.725333V440.874667A276.053333 276.053333 0 0 1 426.453333 277.333333z m-32 469.333334l4.309334 0.298666a32 32 0 0 1 0 63.402667L394.453333 810.666667H330.368l-4.352-0.298667a32 32 0 0 1 0-63.402667l4.352-0.298666h64.085333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

export default IcFluentDualScreenSettings24Filled;
