/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveBusinessCardAudits: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M877.69088 61.44C958.54592 61.44 1024 128.63488 1024 211.64032l0.04096 408.6784a299.04896 299.04896 0 0 0-22.1184-24.69888c-28.0576-28.09856-59.61728-49.0496-94.69952-62.8736A291.67616 291.67616 0 0 0 799.37536 512c-36.82304 0-72.88832 6.92224-108.17536 20.74624-35.28704 13.824-66.9696 34.77504-95.0272 62.8736-28.0576 28.07808-49.09056 59.67872-63.11936 94.80192A288.58368 288.58368 0 0 0 512 798.39232c0 36.864 7.02464 72.86784 21.05344 107.97056 7.92576 19.8656 18.10432 38.6048 30.5152 56.2176L146.3296 962.56C65.45408 962.56 0 895.36512 0 812.35968V211.64032C0 128.63488 65.45408 61.44 146.30912 61.44h731.38176zM286.80192 286.72c-53.67808 0-97.1776 44.21632-97.1776 98.75456 0 44.99456 29.4912 82.92352 69.95968 94.8224C159.68256 493.8752 81.92 581.44768 81.92 686.592v1.29024c0 16.97792 2.048 33.52576 5.85728 49.37728h397.88544c3.82976-15.85152 5.85728-32.39936 5.85728-49.37728v-1.29024c0.08192-105.14432-77.59872-192.7168-177.50016-206.336 40.448-11.9808 69.95968-49.88928 69.95968-94.80192 0-54.53824-43.49952-98.75456-97.1776-98.75456z m554.2912 75.14112H621.69088c-20.23424 0-36.59776 16.7936-36.59776 37.5808 0 20.7872 16.36352 37.60128 36.59776 37.60128H841.1136c20.23424 0 36.59776-16.7936 36.59776-37.60128 0-20.7872-16.36352-37.5808-36.59776-37.5808z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M799.232 573.44c28.93824 0 57.18016 5.4272 84.74624 16.30208 27.5456 10.8544 52.34688 27.32032 74.40384 49.39776 22.03648 22.05696 38.5024 46.8992 49.3568 74.48576 10.8544 27.58656 16.26112 55.86944 16.26112 84.82816 0 28.9792-5.4272 57.26208-16.2816 84.84864s-27.29984 52.4288-49.3568 74.48576c-22.03648 22.07744-46.83776 38.62528-74.38336 49.664a226.03776 226.03776 0 0 1-84.74624 16.54784c-28.93824 0-57.26208-5.5296-84.992-16.54784a219.5456 219.5456 0 0 1-74.67008-49.664c-22.03648-22.07744-38.56384-46.8992-49.60256-74.48576A226.75456 226.75456 0 0 1 573.44 798.45376c0-28.95872 5.5296-57.2416 16.52736-84.82816 11.03872-27.58656 27.56608-52.4288 49.60256-74.48576 22.05696-22.07744 46.94016-38.54336 74.67008-49.39776a231.17824 231.17824 0 0 1 84.992-16.30208z m91.9552 328.04864h-184.9344a10.09664 10.09664 0 0 0-9.9328 10.24c0 5.67296 4.44416 10.28096 9.9328 10.28096h184.9344a10.09664 10.09664 0 0 0 9.9328-10.26048c0-5.67296-4.44416-10.24-9.9328-10.24z m-91.25888-225.52576c-32.9728 0-59.8016 23.51104-59.8016 52.4288 0 18.55488 11.264 35.67616 29.20448 45.056l-5.81632 41.30816H714.752c-10.15808 0-18.432 7.24992-18.432 16.1792v33.85344c0 8.92928 8.27392 16.19968 18.432 16.19968h167.936c10.15808 0 18.432-7.2704 18.432-16.1792v-33.87392c0-8.92928-8.27392-16.1792-18.432-16.1792h-46.44864l-5.81632-41.24672c18.00192-9.37984 29.32736-26.50112 29.32736-45.11744 0-28.91776-26.8288-52.4288-59.8016-52.4288z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveBusinessCardAudits.defaultProps = {
  size: 18,
};

export default IconAExecutiveBusinessCardAudits;
