/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessFinalDraftReminderCasesManage: FunctionComponent<Props> = ({
  size,
  color,
  style: _style,
  ...rest
}) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M599.53152 0c23.1424 0 44.9536 8.9088 61.35808 24.90368l276.13184 270.1312A83.78368 83.78368 0 0 1 962.56 355.04128v584.15104C962.56 985.98912 923.648 1024 875.78624 1024H148.21376C100.352 1024 61.44 985.98912 61.44 939.19232V84.80768C61.44 38.01088 100.352 0 148.21376 0z m136.31488 763.61728H286.26944a18.3296 18.3296 0 0 0-16.54784 8.17152 16.22016 16.22016 0 0 0 0 17.6128c3.4816 5.4272 9.85088 8.58112 16.54784 8.192H735.8464c9.50272-0.57344 16.896-8.00768 16.896-16.9984 0-8.97024-7.39328-16.40448-16.896-16.9984zM511.05792 378.75712a16.7936 16.7936 0 0 0-12.5952 5.12l-160.01024 144.34304a7.29088 7.29088 0 0 0-1.8432 9.3184 8.6016 8.6016 0 0 0 8.0896 5.12v0.12288h94.4128v144.36352c0.24576 9.29792 8.17152 16.77312 18.0224 17.01888h107.84768c9.85088-0.24576 17.77664-7.72096 18.0224-17.01888v-144.36352h94.4128a7.82336 7.82336 0 0 0 8.0896-5.12 8.84736 8.84736 0 0 0-1.8432-9.29792l-160.01024-144.4864a20.8896 20.8896 0 0 0-12.5952-5.12z m118.25152-258.4576v171.6224h183.9104l-183.9104-171.6224z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessFinalDraftReminderCasesManage.defaultProps = {
  size: 18,
};

export default IconABusinessFinalDraftReminderCasesManage;
