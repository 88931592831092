/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Putongzhangdan: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M226.304 96.028444c-36.636444 0-66.332444 29.639111-66.332444 66.275556v699.392c0 36.636444 29.696 66.275556 66.332444 66.275556h580.551111c36.579556 0 66.275556-29.639111 66.275556-66.275556V162.304c0-36.636444-29.696-66.332444-66.275556-66.332444H226.304z m550.172444 219.761778c0 19.512889-14.506667 36.693333-33.962666 36.693334H293.432889c-19.456 0-34.019556-17.180444-34.019556-36.693334 0-19.456 14.563556-36.636444 34.019556-36.636444h449.080889c19.456 0 33.962667 17.180444 33.962666 36.636444z m0 189.610667c0 19.512889-14.506667 36.636444-33.962666 36.636444H293.432889c-19.456 0-34.019556-17.123556-34.019556-36.636444s14.563556-36.636444 34.019556-36.636445h449.080889c19.456 0 33.962667 17.066667 33.962666 36.636445z m0 195.015111c0 19.512889-14.506667 36.636444-34.019555 36.636444H293.432889c-19.512889 0-34.019556-17.123556-34.019556-36.636444s14.506667-36.636444 34.019556-36.636444h449.024c19.456 0 34.019556 17.123556 34.019555 36.636444z m-9.045333-384.568889c0-15.189333-11.150222-27.477333-24.917333-27.477333H293.432889c-12.856889 0-23.438222 10.695111-24.803556 24.462222 1.365333-13.767111 11.946667-24.462222 24.803556-24.462222h449.080889c13.767111 0 24.917333 12.288 24.917333 27.420444zM742.513778 477.866667c13.767111 0 24.917333 12.288 24.917333 27.477333 0-15.189333-11.150222-27.477333-24.917333-27.477333H293.432889c-12.856889 0-23.438222 10.695111-24.803556 24.462222 1.365333-13.767111 11.946667-24.462222 24.803556-24.462222h449.080889z m0 195.015111c13.710222 0 24.917333 12.288 24.917333 27.477333 0-15.132444-11.207111-27.477333-24.974222-27.477333H293.432889h449.024z"
        fill={getIconColor(color, 0, '#1585FF')}
      />
    </svg>
  );
};

export default Putongzhangdan;
