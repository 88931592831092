/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Pdf: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M756.7872 10.24H217.68192c-41.00096 0.128-74.20416 33.53088-74.33216 74.77248v853.97504c0.128 41.2416 33.3312 74.6496 74.33216 74.77248h706.39104c40.99584-0.128 74.19904-33.53088 74.32704-74.77248V253.34784L756.7872 10.24z"
        fill={getIconColor(color, 0, '#E4F9F4')}
      />

      <path
        d="M756.7872 178.4576c0.12288 41.25184 33.32096 74.67008 74.32704 74.83392h167.22944L756.78208 10.24v168.2176z"
        fill={getIconColor(color, 1, '#6BDDC7')}
      />

      <path
        d="M94.7968 625.11104h567.99744c46.1312 0 69.1968 23.20384 69.1968 69.61152v185.62048c0 46.40768-23.0656 69.61152-69.1968 69.61152H94.7968C48.6656 949.95456 25.6 926.75072 25.6 880.34304v-185.62048c0-46.40768 23.0656-69.61152 69.1968-69.61152z"
        fill={getIconColor(color, 2, '#38D6B8')}
      />

      <path
        d="M243.0464 706.65216c40.58624 0.77824 60.98944 26.33216 60.98944 76.0832v16.09728c0.80384 53.63712-23.2448 80.33792-71.76192 79.5648-12.86144 0-26.0608-2.73408-39.6032-8.192l-0.93696-0.38912 0.01024 68.42368h-24.064v-228.13696h24.064l-0.00512 13.7472 1.8944-1.29536c15.09376-10.15296 30.85312-15.45216 47.24736-15.87712l2.17088-0.0256z m-49.84832 34.56l-1.4592 0.83456v108.81536l1.89952 0.62976c13.74208 4.4288 25.92256 6.77376 36.5824 7.05024l2.10944 0.03072c32.62976 1.49504 48.42496-18.176 47.6416-59.71968v-16.13824c0.78336-38.47168-13.056-56.9856-41.8048-56.2432-10.81344-0.69632-25.12896 3.82464-42.9056 13.5936l-2.06336 1.152z m265.46176-99.01568v232.74496h-22.86592l-0.00512-8.54016-0.93696 0.4096c-17.25952 7.33184-32.90624 11.1872-46.91968 11.55072l-2.2016 0.03072c-41.36448 0.78336-62.6176-22.4512-63.42144-69.18144v-29.9264c0-47.49312 23.2448-71.85408 69.37088-72.63232 15.7184 0 29.70624 1.68448 41.96864 5.05856l0.9472 0.27136v-69.7856h24.064zM347.56608 779.264v29.9776c-0.78336 34.59584 13.38368 50.82624 42.91584 49.3312 13.34784 0 27.42272-3.3792 42.21952-10.14784l1.8944-0.89088v-116.74112l-2.60608-0.6656a164.29056 164.29056 0 0 0-35.584-4.7616l-3.56352-0.04096c-31.07328-0.74752-46.05952 17.02912-45.27616 53.9392z m199.71072-140.53888c-26.89536-0.16896-40.47872 16.57344-40.47872 49.64352v21.7344h-33.44896v19.82976l33.44896-0.00512v143.86688h24.064l-0.00512-143.86688h48.98304v-19.81952l-48.98304-0.00512v-21.7344c-0.768-22.4 6.31808-32.8192 21.36064-32.09728 10.33216 0 19.00544 0.76288 26.07616 2.27328l1.54624 0.3328v-16.29184l-0.8448-0.3072c-6.53824-2.36032-16.62464-3.52768-30.2592-3.52768l-1.4592-0.0256z"
        fill={getIconColor(color, 3, '#FFFFFF')}
      />

      <path
        d="M577.3312 288.67584c0 70.4768-56.79616 127.61088-126.85824 127.61088-70.0672 0-126.86336-57.13408-126.86336-127.61088 0-70.48192 56.80128-197.2224 126.86336-197.2224s126.86336 126.74048 126.86336 197.2224z m-196.05504 232.02304h138.3936v23.20384h-138.3936V520.704z"
        fill={getIconColor(color, 4, '#6BDDC7')}
      />

      <path
        d="M436.05504 335.0784h28.83072v197.2224h-28.8256z"
        fill={getIconColor(color, 5, '#6BDDC7')}
      />

      <path
        d="M462.98624 306.0736l69.1968-81.20832"
        fill={getIconColor(color, 6, '#38D6B8')}
      />
    </svg>
  );
};

export default Pdf;
