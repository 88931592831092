/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksMeetingMyMeeting: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M698.28608 353.44384a92.22144 92.22144 0 0 1 107.52 2.2528 88.96512 88.96512 0 0 1 30.43328 101.62176c141.18912 85.6064 165.92896 276.39808 156.2624 386.3552a50.46272 50.46272 0 0 1 31.39584 49.93024v18.432c0 27.91424-22.97856 50.52416-51.3024 50.52416H51.28192C22.95808 962.56 0 939.9296 0 912.03584v-196.75136c0-98.48832 81.03936-178.31936 181.00224-178.31936H660.0704c99.96288 0 181.00224 79.83104 181.00224 178.31936v121.856h91.09504c3.62496-42.1888 12.0832-253.80864-132.7104-334.6432a93.94176 93.94176 0 0 1-49.50016 14.25408c-39.66976-0.2048-74.62912-25.72288-86.4256-63.03744a89.04704 89.04704 0 0 1 34.75456-100.27008zM421.13024 61.44c109.30176 0 197.91872 87.28576 197.91872 194.9696 0 107.66336-88.61696 194.9696-197.91872 194.9696-109.28128 0-197.89824-87.30624-197.89824-194.9696C223.232 148.72576 311.84896 61.44 421.13024 61.44z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksMeetingMyMeeting.defaultProps = {
  size: 18,
};

export default IconAWorksMeetingMyMeeting;
