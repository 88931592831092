/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentSignOut24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 185.770667v283.776l317.525333-0.042667L756.053333 395.946667a32 32 0 0 1-3.114666-41.642667l3.114666-3.584a32 32 0 0 1 41.642667-3.114667l3.584 3.114667 127.872 127.872a32 32 0 0 1 3.114667 41.642667l-3.114667 3.584-127.829333 128.170666a32 32 0 0 1-48.384-41.6l3.072-3.626666 73.088-73.258667-317.056 0.042667L512 821.333333a32 32 0 0 1-37.546667 31.530667l-362.666666-64.085333A32 32 0 0 1 85.333333 757.333333v-512a32 32 0 0 1 26.794667-31.573333l362.666667-59.562667a32 32 0 0 1 37.205333 31.573334zM362.752 490.666667a42.752 42.752 0 1 0 0 85.504 42.752 42.752 0 0 0 0-85.504z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M554.666667 789.376h32.64l4.352-0.256a32 32 0 0 0 27.648-31.786667l-0.298667-181.333333H554.666667v213.376zM554.752 426.666667L554.666667 372.266667V213.333333h31.786666a32 32 0 0 1 31.701334 27.605334l0.298666 4.352 0.298667 181.376h-64z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

export default IcFluentSignOut24Filled;
