/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Briefcase24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M650.666667 128a32 32 0 0 1 32 32V298.666667h74.666666A138.666667 138.666667 0 0 1 896 437.333333v277.333334A138.666667 138.666667 0 0 1 757.333333 853.333333H266.666667A138.666667 138.666667 0 0 1 128 714.666667v-277.333334A138.666667 138.666667 0 0 1 266.666667 298.666667H341.333333V160a32 32 0 0 1 27.648-31.701333L373.333333 128h277.333334z m-32 64h-213.333334V298.666667h213.333334V192z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
  );
};

export default Briefcase24Filled;
