/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconDenglujilu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M855.1936 111.5648h-314.6752a28.5696 28.5696 0 0 0 0.0512 57.2416h314.6752c31.5904 0 57.1392 25.6 57.1392 57.1904v572.0064c0 31.5904-25.5488 57.1904-57.1392 57.1904h-314.6752a28.5696 28.5696 0 1 0 0 57.2416h314.6752c63.1808 0 114.3808-51.2 114.3808-114.3808V225.9968c0-63.1808-51.2-114.432-114.432-114.432z m0 0"
        fill={getIconColor(color, 0, '#00A0E9')}
      />

      <path
        d="M788.3776 533.1968l-323.84 292.7104c-36.7616 33.28-95.5392 7.2192-95.5392-42.3936V666.624a7.168 7.168 0 0 0-7.2192-7.1168H82.9952a28.672 28.672 0 0 1-28.5696-28.7232V393.1136c0-15.872 12.8-28.6208 28.5696-28.6208h278.8352a7.168 7.168 0 0 0 7.2192-7.168V240.384c0-49.5616 58.7776-75.7248 95.5392-42.4448l323.84 292.7616a28.5184 28.5184 0 0 1-0.0512 42.496z m0 0"
        fill={getIconColor(color, 1, '#00A0E9')}
      />
    </svg>
  );
};

IconDenglujilu.defaultProps = {
  size: 18,
};

export default IconDenglujilu;
