/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveOfficeSuppliesApply: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M340.70528 554.8032h342.58944a85.72928 85.72928 0 0 1-85.64736 85.66784h-171.29472a85.72928 85.72928 0 0 1-85.64736-85.64736zM40.96 340.72576V512h942.08v-171.29472H40.96z m556.68736 342.58944h-171.29472a128.6144 128.6144 0 0 1-128.47104-128.47104H83.78368v374.6816A53.59616 53.59616 0 0 0 137.3184 983.04h749.3632a53.59616 53.59616 0 0 0 53.53472-53.53472V554.82368H726.1184a128.6144 128.6144 0 0 1-128.47104 128.47104z m375.43936-385.41312L827.22816 79.09376A85.48352 85.48352 0 0 0 755.95776 40.96H268.04224a85.46304 85.46304 0 0 0-71.2704 38.13376L50.91328 297.8816h922.17344z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveOfficeSuppliesApply.defaultProps = {
  size: 18,
};

export default IconAExecutiveOfficeSuppliesApply;
