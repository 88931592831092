/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Writeoff: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M409.6 661.504l280.576-278.528c26.624 32.768 38.912 73.728 38.912 120.832 0 112.64-86.016 198.656-198.656 198.656-47.104 0-88.064-14.336-120.832-40.96zM362.496 614.4c-20.48-32.768-32.768-73.728-32.768-112.64 0-112.64 86.016-200.704 198.656-200.704 38.912 0 79.872 14.336 112.64 32.768L362.496 614.4z m165.888 286.72C309.248 901.12 129.024 720.896 129.024 501.76S309.248 102.4 528.384 102.4s399.36 180.224 399.36 399.36S749.568 901.12 528.384 901.12z m0-133.12c147.456 0 266.24-120.832 266.24-266.24 0-147.456-118.784-266.24-266.24-266.24s-266.24 120.832-266.24 266.24 120.832 266.24 266.24 266.24z"
        fill={getIconColor(color, 0, '#F56C6C')}
      />
    </svg>
  );
};

export default Writeoff;
