/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialInvoicesRegister: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M901.12 81.92a122.88 122.88 0 0 1 122.88 122.88v142.27456c-60.6208 30.18752-102.4 92.61056-102.4 164.94592 0 72.31488 41.7792 134.7584 102.4 164.92544V819.2a122.88 122.88 0 0 1-122.88 122.88H122.88a122.88 122.88 0 0 1-122.88-122.88v-142.25408C60.6208 646.7584 102.4 584.33536 102.4 512s-41.7792-134.7584-102.4-164.92544V204.8a122.88 122.88 0 0 1 122.88-122.88h778.24z m-194.56 491.54048h-389.12a30.72 30.72 0 0 0-30.72 30.72v20.50048a30.72 30.72 0 0 0 30.72 30.72h389.12a30.72 30.72 0 0 0 30.72-30.72v-20.48c0-16.97792-13.76256-30.72-30.72-30.72z m0-204.8h-389.12a30.72 30.72 0 0 0-30.72 30.72v20.48a30.72 30.72 0 0 0 30.72 30.74048h389.12a30.72 30.72 0 0 0 30.72-30.72v-20.48c0-16.9984-13.76256-30.72-30.72-30.72z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialInvoicesRegister.defaultProps = {
  size: 18,
};

export default IconAFinancialInvoicesRegister;
