/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Guanlianzhangdan7: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M636.970667 725.333333c13.44 0 24.362667 11.477333 24.362666 25.6 0 14.122667-10.922667 25.6-24.362666 25.6h-24.405334c-40.405333 0-73.130667 34.389333-73.130666 76.8s32.725333 76.8 73.130666 76.8h24.405334c13.44 0 24.362667 11.477333 24.362666 25.6 0 14.122667-10.922667 25.6-24.362666 25.6h-24.405334c-67.328 0-121.898667-57.301333-121.898666-128s54.613333-128 121.898666-128z m243.797333 0c67.328 0 121.898667 57.301333 121.898667 128s-54.613333 128-121.898667 128h-24.405333c-13.44 0-24.362667-11.477333-24.362667-25.6 0-14.122667 10.922667-25.6 24.362667-25.6h24.405333c40.405333 0 73.130667-34.389333 73.130667-76.8s-32.725333-76.8-73.130667-76.8h-24.405333c-13.44 0-24.362667-11.477333-24.362667-25.6 0-14.122667 10.922667-25.6 24.362667-25.6z m-31.701333 85.333334c14.122667 0 25.6 19.114667 25.6 42.666666s-11.477333 42.666667-25.6 42.666667h-204.8c-14.122667 0-25.6-19.114667-25.6-42.666667s11.477333-42.666667 25.6-42.666666h204.8zM448 42.666667c235.648 0 426.666667 191.018667 426.666667 426.666666 0 77.738667-20.778667 150.613333-57.088 213.376L618.666667 682.666667a170.666667 170.666667 0 0 0-165.290667 213.290666L448 896c-235.648 0-426.666667-191.018667-426.666667-426.666667s191.018667-426.666667 426.666667-426.666666z m102.4 213.333333H345.6c-14.122667 0-25.6 13.226667-25.6 29.525333 0 16.341333 11.477333 29.568 25.6 29.568h153.856a832.042667 832.042667 0 0 0-102.272 290.346667 32.128 32.128 0 0 0 8.832 27.733333c7.04 6.741333 16.64 8.661333 25.216 4.992a28.842667 28.842667 0 0 0 16.384-22.698666A764.458667 764.458667 0 0 1 555.093333 326.4c4.394667-6.912 8.96-13.653333 13.738667-20.309333l0.682667-0.938667a33.194667 33.194667 0 0 0 4.224-31.786667C569.557333 262.784 560.426667 256 550.4 256z"
        fill={getIconColor(color, 0, '#636df1')}
      />
    </svg>
  );
};

export default Guanlianzhangdan7;
