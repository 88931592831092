/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessFinalDraftReminderCases: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M910.04124 1023.97952H113.99972A52.407261 52.407261 0 0 1 61.449101 971.695137V52.284589A52.407261 52.407261 0 0 1 113.99972 0.000205h796.04152a52.407261 52.407261 0 0 1 52.530138 52.284384v919.369588c0 13.86468-5.529488 27.176411-15.359689 36.986133a52.632537 52.632537 0 0 1-37.170449 15.33921zM302.493832 823.381972a45.116529 45.116529 0 0 0-45.116529 44.952692 45.05509 45.05509 0 0 0 45.116529 44.973172h466.627374a45.116529 45.116529 0 0 0 45.177968-44.973172 45.116529 45.116529 0 0 0-45.177968-44.952692H302.493832z m138.564881-197.894242a45.05509 45.05509 0 0 0-45.116529 44.973171 45.05509 45.05509 0 0 0 45.116529 44.952692h328.082973a45.116529 45.116529 0 0 0 45.157488-44.952692 45.116529 45.116529 0 0 0-45.177968-44.973171H441.058713z m170.492556-201.846803a45.05509 45.05509 0 0 0-45.116529 44.952692 45.05509 45.05509 0 0 0 45.116529 44.973172h157.569937a45.116529 45.116529 0 0 0 45.177968-44.973172 45.116529 45.116529 0 0 0-45.177968-44.952692h-157.569937z m-385.63061-212.537146a47.778875 47.778875 0 0 0 0 95.51679h69.28244v69.79443a47.471681 47.471681 0 0 0 47.266886 47.06209 47.471681 47.471681 0 0 0 47.266885-47.06209v-69.732991h69.28244a47.942712 47.942712 0 0 0 48.004151-47.778875 47.942712 47.942712 0 0 0-48.004151-47.799354H389.73687V141.165993a47.471681 47.471681 0 0 0-47.266885-47.062089 47.471681 47.471681 0 0 0-47.266886 47.062089V210.939944h-69.28244v0.143357z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessFinalDraftReminderCases.defaultProps = {
  size: 18,
};

export default IconABusinessFinalDraftReminderCases;
