/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconHuiyiyuding: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M698.336 368.576a90.496 90.496 0 0 1 107.52 2.304c31.552 24.288 43.84 66.368 30.464 104 141.184 87.616 165.92 282.88 156.256 395.456 20.224 8.512 32.832 29.056 31.36 51.104v18.848A51.52 51.52 0 0 1 972.704 992H51.36c-13.6 0-26.656-5.44-36.288-15.136-9.6-9.696-15.008-22.848-15.008-36.576v-201.376c0-48.384 19.072-94.816 53.024-129.056a180.256 180.256 0 0 1 128-53.44h479.04c48 0 94.048 19.2 128 53.44a183.296 183.296 0 0 1 53.024 129.056v124.736h91.104c3.616-43.2 12.064-259.776-132.736-342.528a92.352 92.352 0 0 1-49.504 14.592 91.2 91.2 0 0 1-86.432-64.512 92.288 92.288 0 0 1 34.752-102.624zM415.296 0a224 224 0 1 1 0 448 224 224 0 0 1 0-448z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconHuiyiyuding.defaultProps = {
  size: 18,
};

export default IconHuiyiyuding;
