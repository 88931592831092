/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconQingjiashenpi1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M607.3 558.6c-26.4-4-49.2-21.1-61-45.9-14-29.4-54.5-29.4-68.5 0-11.8 24.8-34.6 41.9-61 45.9-31.3 4.7-43.8 44.6-21.2 67.5 19.1 19.3 27.8 47.1 23.3 74.3-5.4 32.3 27.4 56.9 55.4 41.7 23.6-12.9 51.8-12.9 75.4 0 28 15.3 60.8-9.4 55.4-41.7-4.5-27.2 4.2-55 23.3-74.3 22.7-22.9 10.2-62.8-21.1-67.5z"
        fill={getIconColor(color, 0, '#333333')}
      />

      <path
        d="M810.7 192h-85.3c0-23.5-19.2-42.7-42.7-42.7-23.5 0-42.7 19.2-42.7 42.7H384c0-23.5-19.2-42.7-42.7-42.7-23.5 0-42.7 19.2-42.7 42.7h-85.3c-46.9 0-85.3 38.4-85.3 85.3v512c0 46.9 38.4 85.3 85.3 85.3h597.3c46.9 0 85.3-38.4 85.3-85.3v-512c0.1-46.9-38.3-85.3-85.2-85.3zM706 631c-31.8 32.1-46.4 78.4-38.8 123.8 8.9 53.8-45.7 94.9-92.4 69.5-39.3-21.4-86.3-21.4-125.7 0-46.7 25.4-101.3-15.6-92.4-69.5 7.5-45.4-7-91.7-38.8-123.8-37.8-38.1-16.9-104.6 35.3-112.4 44-6.6 82-35.2 101.7-76.5 23.3-49 90.8-49 114.2 0 19.7 41.3 57.7 69.9 101.7 76.5 52.2 7.8 73 74.3 35.2 112.4z m62-268.3H256c-23.5 0-42.7-19.2-42.7-42.7s19.2-42.7 42.7-42.7h42.7c0 23.5 19.2 42.7 42.7 42.7 23.5 0 42.7-19.2 42.7-42.7h256c0 23.5 19.2 42.7 42.7 42.7 23.5 0 42.7-19.2 42.7-42.7H768c23.5 0 42.7 19.2 42.7 42.7s-19.2 42.7-42.7 42.7z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconQingjiashenpi1.defaultProps = {
  size: 18,
};

export default IconQingjiashenpi1;
