/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Huishuai2: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M512 146.304a378.666667 378.666667 0 0 1 156.458667 34.858667V237.653333h178.816C764.202667 141.056 641.408 85.248 512 85.333333c-129.450667-0.085333-252.245333 55.722667-335.36 152.362667h87.552A379.562667 379.562667 0 0 1 512 146.346667zM512 877.824a378.666667 378.666667 0 0 1-156.458667-34.858667v-56.576H176.64c83.114667 96.682667 205.909333 152.490667 335.36 152.405334 129.408 0.042667 252.202667-55.765333 335.274667-152.405334h-87.509334A379.178667 379.178667 0 0 1 512 877.824z"
        fill={getIconColor(color, 0, '#00A6ED')}
      />

      <path
        d="M913.066667 485.205333c14.122667 0 25.6-11.946667 25.6-26.666666a26.154667 26.154667 0 0 0-25.6-26.709334h-63.744l-0.853334-0.853333 81.92-85.418667a28.757333 28.757333 0 0 0-0.725333-38.656 26.026667 26.026667 0 0 0-37.077333-0.810666L810.666667 391.552l-81.92-85.376a26.026667 26.026667 0 0 0-37.077334 0.768 28.757333 28.757333 0 0 0-0.768 38.656l81.92 85.376-0.810666 0.853333H708.266667c-14.122667 0-25.6 11.946667-25.6 26.709334 0 14.72 11.477333 26.666667 25.6 26.666666h76.8v53.376h-76.8c-14.122667 0-25.6 11.946667-25.6 26.709334 0 14.72 11.477333 26.666667 25.6 26.666666h76.8v106.752c0 14.72 11.477333 26.666667 25.6 26.666667 14.122667 0 25.6-11.946667 25.6-26.666667v-106.752h76.8c14.122667 0 25.6-11.946667 25.6-26.666666a26.154667 26.154667 0 0 0-25.6-26.709334h-76.8v-53.333333h76.8zM213.333333 298.709333c17.664 0 32 11.946667 32 26.666667v3.84c44.8 9.685333 79.786667 38.826667 91.52 76.16H266.965333a61.610667 61.610667 0 0 0-21.632-18.432v102.229333C300.458667 501.077333 341.333333 542.421333 341.333333 592.042667c-0.128 48.554667-39.594667 90.88-96 102.954666v3.712c0 14.762667-14.336 26.666667-32 26.666667s-32-11.946667-32-26.666667v-3.712c-44.714667-9.770667-79.872-38.954667-91.52-76.288h69.888c5.376 7.509333 12.8 13.824 21.632 18.474667v-102.229333C126.208 523.008 85.333333 481.621333 85.333333 432.042667s40.917333-90.965333 96-102.826667v-3.84c0-14.72 14.336-26.666667 32-26.666667z m32 248.746667v89.301333c18.517333-9.301333 31.488-25.429333 31.488-44.714666 0-19.2-12.970667-35.413333-31.488-44.629334z m-64-160c-18.474667 9.173333-31.488 25.344-31.488 44.586667 0.256 18.517333 12.245333 35.498667 31.488 44.714666z"
        fill={getIconColor(color, 1, '#533566')}
      />
    </svg>
  );
};

export default Huishuai2;
