/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFlexFold: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M552.19541333 172.18370333a48.54518557 48.54518557 0 0 0 0 97.09037112h396.90543446a48.54518557 48.54518557 0 1 0 0-97.09037112H552.19541333z m-510.69534776 374.47755889l-0.58254223-0.67963221a47.76846222 47.76846222 0 0 1 0-67.96326002l280.00862777-275.2512a49.4189989 49.4189989 0 0 1 69.22543446 0l0.58254222 0.58254222a47.76846222 47.76846222 0 0 1 0 68.06035001L145.96930333 512 390.63703666 752.58993778a47.76846222 47.76846222 0 0 1 0 67.96325888l-0.58254223 0.67963335a49.4189989 49.4189989 0 0 1-69.22543331 0L41.79133667 546.95253333l-0.2912711-0.29127111z m510.69534776-83.20644779a48.54518557 48.54518557 0 0 0 0 97.09037114h396.90543446a48.54518557 48.54518557 0 1 0 0-97.09037114H552.19541333z m0 291.27111112a48.54518557 48.54518557 0 0 0 0 97.09037112h396.90543446a48.54518557 48.54518557 0 1 0 0-97.09037112H552.19541333z"
        fill={getIconColor(color, 0, '#7653D6')}
      />
    </svg>
  );
};

IconFlexFold.defaultProps = {
  size: 18,
};

export default IconFlexFold;
