/* tslint:disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Tijiao: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M0 512C0 229.248 229.248 0 512 0s512 229.248 512 512-229.248 512-512 512S0 794.752 0 512z"
        fill={getIconColor(color, 0, '#FFFFFF')}
      />

      <path
        d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
        fill={getIconColor(color, 1, '#FFFFFF')}
      />

      <path
        d="M776.832 236.928a25.173333 25.173333 0 0 0-27.050667-4.394667L178.090667 483.328l-0.384 0.213333c-15.701333 7.68-25.002667 24.234667-24.021334 41.941334 0.938667 17.621333 11.946667 33.237333 28.714667 38.656l216.490667 70.528 7.850666 158.72v0.085333a27.264 27.264 0 0 0 17.28 24.064 25.6 25.6 0 0 0 28.8-7.765333l72.405334-86.442667 103.808 77.44c6.826667 5.12 14.933333 7.978667 23.466666 7.978667 19.925333 0 36.096-15.104 39.978667-34.56l93.013333-511.274667a28.074667 28.074667 0 0 0-8.661333-25.984z m-360.106667 346.197333L227.029333 521.386667l429.866667-188.672-240.170667 250.453333z m227.712 162.005334l-180.053333-134.357334 252.501333-263.253333-72.448 397.653333z m-188.885333-21.802667l-2.602667-53.973333 29.44 21.973333-26.837333 32z"
        fill={getIconColor(color, 2, '#3A7FCC')}
      />
    </svg>
  );
};

export default Tijiao;
