/* tslint:disable */
import React, { CSSProperties, FunctionComponent, SVGAttributes } from 'react';

import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconDisanfangchajian: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg
      viewBox="0 0 1024 1024"
      width={size + 'px'}
      height={size + 'px'}
      style={style}
      {...rest}
    >
      <path
        d="M789.8112 465.664c36.4544 9.0624 70.7072 25.3184 100.6592 47.7952 120.064 90.1376 143.5136 259.4816 52.3264 378.2144-91.1616 118.7584-262.4256 141.9264-382.5152 51.7632-105.6768-79.1808-138.0352-222.2592-76.544-338.3808 104.96 25.7536 215.3472-12.8768 280.5504-98.2272 9.7536-12.9024 18.304-26.7008 25.5232-41.1648zM100.608 541.44c100.0448-104.0128 264.96-112.2304 375.0912-18.688a272.7936 272.7936 0 0 0-95.3088 205.7472 258.816 258.816 0 0 0 95.3088 202.9824c-110.1056 93.5424-275.0464 85.3248-375.0912-18.688a267.264 267.264 0 0 1 0-371.3536zM503.2448 25.7024C653.7472 21.6576 779.0336 139.008 783.1296 287.8208c3.84 130.176-87.2448 244.3776-216.1664 271.0528-46.336-96.3584-145.6896-156.7744-253.5936-154.2144a203.4688 203.4688 0 0 0-49.664 5.2992 281.472 281.472 0 0 1-25.5488-107.52C234.0864 153.6 352.768 29.696 503.2448 25.6768z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconDisanfangchajian.defaultProps = {
  size: 18,
};

export default IconDisanfangchajian;
